import React, { useEffect } from "react";
import "../../style/main.scss";
import Button from "../shared/button";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the Quill styles
import { useState } from "react";
import parse from "html-react-parser";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { auth, firestoredb } from "../../config";
import { Modal } from "react-bootstrap";

const WorkExperience = ({ setUserData, userData, sections, setSections, isFetched, isPaidUser }) => {
  const [editorHtml, setEditorHtml] = useState("");
  const [first, setfirst] = useState(false);
  const [totalExperience, setTotalExperience] = useState([]);
  const [isCompanyName, setIsCompanyName] = useState(false);
  const [newArrayMade, setNewArrayMade] = useState(false);
  const [already, setAlready] = useState(false);
  const [currentActiveIndex, setCurrentActiveIndex] = useState(null);
  const [loading, setLoading] = useState(false)
  const [downloadLimitExceed, setDownloadLimitExceed] = useState(false);
  const navigate = useNavigate();
  const handleDownloadLimitExceedModal = () => {
    setDownloadLimitExceed(prev => !prev)
  }
  useEffect(() => {
    if (userData?.experience) {

      userData?.experience?.map((item, i) => {
        setTotalExperience(prev => [...prev, 1]);
      })
    }
  }, [isFetched])
  const handleChange = (html) => {
    setEditorHtml(html);
  };
  const handleInfoChange = (e, index = currentActiveIndex) => {
    // console.log(e.target.name);
    // console.log(e.target.value);
    // console.log(index)
    if (index == totalExperience.length) {
      setNewArrayMade(true);
    }

    if (e.target.name == "companyName" && e.target.value == "") {
      setIsCompanyName(false);
    }
    if (e.target.name == "companyName" && e.target.value != "") {
      setIsCompanyName(true);
    }

    // setUserData((prev) => ({
    //   ...prev,
    //   experience: { ...prev?.experience, [e.target.name]: e.target.value },
    // }));

    let temp = userData.experience == undefined ? [] : userData.experience;
    temp[index] = { ...temp[index], [e.target.name]: e.target.value }
    setUserData((prev) => ({ ...prev, experience: temp }))


  };
  const handleQuilChange = (value, index = currentActiveIndex) => {

    // console.log(value);
    // console.log(index);
    if (index == totalExperience.length) {
      setNewArrayMade(true);
    }


    // setUserData((prev) => ({
    //   ...prev,
    //   experience: { ...prev?.experience, description: value },
    // }));

    let temp = userData.experience == undefined ? [] : userData.experience;
    temp[index] = { ...temp[index], description: value }
    setUserData((prev) => ({ ...prev, experience: temp }))
  };



  const handleExp = async (index) => {
    setfirst(!first);
    // console.log("here", index)

    if (newArrayMade) {
      if (userData?.experience) {
        let a = userData.experience;
        a.pop();
        setUserData(prev => ({ ...prev, experience: a }));
        setNewArrayMade(false);

      }
    }
    setCurrentActiveIndex(index);
  };



  const handleAddMore = async () => {
    if (userData.experience ? userData.experience[currentActiveIndex] : false) {

      if (userData.experience[currentActiveIndex].companyName != undefined && userData.experience[currentActiveIndex].companyName != "") {
        setTotalExperience(prev => [...prev, 1]);
        setIsCompanyName(false)
        setfirst(!first)
        setNewArrayMade(false);
      }
      else {
        toast("Please Enter Company Name");
      }
    }
    else {
      toast("Please fill some data first")
    }


  };
  const handleDelete = () => {
    if (userData.experience ? userData.experience[currentActiveIndex] : false) {
      if (!newArrayMade) {
        let temp = userData.experience;
        temp.splice(currentActiveIndex, 1);
        setUserData(prev => ({ ...prev, experience: temp }));
        setIsCompanyName(false)
        setfirst(!first)
        setNewArrayMade(false);
        if (currentActiveIndex != totalExperience.length) {
          let t = totalExperience;
          t.pop();
          setTotalExperience(t)
        }
      }
      else {
        handleExp(null)
      }
    }
    else {
      handleExp(null)
    }



  }

  const handleAIWriter = async () => {

    if (isPaidUser) {
      if (userData?.experience && userData?.experience[currentActiveIndex] && userData?.experience[currentActiveIndex].description && userData?.experience[currentActiveIndex].description.length > 29) {
        let tempData = { description: parse(userData?.experience[currentActiveIndex].description).props.children }
        // console.log("test1", parse(userData?.experience[currentActiveIndex].description))
        // console.log("test", Array.isArray(tempData.description));

        if (Array.isArray(tempData.description)) {
          let newString = "";
          tempData.description.map((item) => {
            newString = newString + " " + item.props.children;
          });
          tempData = { description: newString }
        }

        let tempIndex = currentActiveIndex;
        try {
          const tokenId = await auth.currentUser.getIdToken();
          const config = {
            headers: { Authorization: `Bearer ${tokenId}` }
          };
          setLoading(true);
          // const result = await axios.post("http://localhost:8080/generateJobDescription", tempData);
          const result = await axios.post("https://resume-ai-nodejs.onrender.com/generateJobDescription", tempData, config);
          // console.log("check response", result.data);
          if (!result.data.message) {

            let temp = userData.experience == undefined ? [] : userData.experience;
            temp[tempIndex] = { ...temp[tempIndex], description: result.data.description }
            setUserData((prev) => ({ ...prev, experience: temp }))

            setLoading(false);
          }
          else {

            toast.error("Some Error Occured. Please try again. If the error persists please contact support")
            setLoading(false);
          }


        }
        catch (e) {
          console.error(e.message)
          setLoading(false);
        }
      }
      else {
        toast("Please Provide some details first to generate Job Responsibilities.");
      }
    }
    else {
      const docRef = doc(firestoredb, "users", auth.currentUser.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        if (!docSnap.data().downloadCount) {
          if (userData?.experience && userData?.experience[currentActiveIndex] && userData?.experience[currentActiveIndex].description && userData?.experience[currentActiveIndex].description.length > 29) {
            let tempData = { description: parse(userData?.experience[currentActiveIndex].description).props.children }
            // console.log("test1", parse(userData?.experience[currentActiveIndex].description))
            // console.log("test", Array.isArray(tempData.description));

            if (Array.isArray(tempData.description)) {
              let newString = "";
              tempData.description.map((item) => {
                newString = newString + " " + item.props.children;
              });
              tempData = { description: newString }
            }

            let tempIndex = currentActiveIndex;
            try {
              const tokenId = await auth.currentUser.getIdToken();
              const config = {
                headers: { Authorization: `Bearer ${tokenId}` }
              };
              setLoading(true);
              // const result = await axios.post("http://localhost:8080/generateJobDescription", tempData);
              const result = await axios.post("https://resume-ai-nodejs.onrender.com/generateJobDescription", tempData, config);
              // console.log("check response", result.data);
              if (!result.data.message) {
                let temp = userData.experience == undefined ? [] : userData.experience;
                temp[tempIndex] = { ...temp[tempIndex], description: result.data.description }
                setUserData((prev) => ({ ...prev, experience: temp }))

                setLoading(false);

              }
              else {

                toast.error("Some Error Occured. Please try again. If the error persists please contact support")
                setLoading(false);
              }

            }
            catch (e) {
              console.error(e.message)
              setLoading(false);
            }
          }
          else {
            toast("Please Provide some details first to generate Job Responsibilities.");

          }
        }
        else if (docSnap.data().downloadCount < 2) {
          if (userData?.experience && userData?.experience[currentActiveIndex] && userData?.experience[currentActiveIndex].description && userData?.experience[currentActiveIndex].description.length > 29) {
            let tempData = { description: parse(userData?.experience[currentActiveIndex].description).props.children }
            // console.log("test1", parse(userData?.experience[currentActiveIndex].description))
            // console.log("test", Array.isArray(tempData.description));

            if (Array.isArray(tempData.description)) {
              let newString = "";
              tempData.description.map((item) => {
                newString = newString + " " + item.props.children;
              });
              tempData = { description: newString }
            }

            let tempIndex = currentActiveIndex;
            try {
              const tokenId = await auth.currentUser.getIdToken();
              const config = {
                headers: { Authorization: `Bearer ${tokenId}` }
              };
              setLoading(true);
              // const result = await axios.post("http://localhost:8080/generateJobDescription", tempData);
              const result = await axios.post("https://resume-ai-nodejs.onrender.com/generateJobDescription", tempData, config);
              // console.log("check response", result.data);
              if (!result.data.message) {

                let temp = userData.experience == undefined ? [] : userData.experience;
                temp[tempIndex] = { ...temp[tempIndex], description: result.data.description }
                setUserData((prev) => ({ ...prev, experience: temp }))

                setLoading(false);
              }
              else {

                toast.error("Some Error Occured. Please try again. If the error persists please contact support")
                setLoading(false);
              }
            }
            catch (e) {
              console.error(e.message)
              setLoading(false);
            }
          }
          else {
            toast("Please Provide some details first to generate Job Responsibilities.");

          }
        }
        else {
          // toast("You have exceeded the number of free downloads. Please upgrade your account to Pro Plan.")
          setDownloadLimitExceed(true);
        }
      }
    }


  }

  // Only to check value of totalExperience during development.
  useEffect(() => {
    // console.log(totalExperience);
    // console.log("Current Active Index", currentActiveIndex);
  }, [totalExperience, currentActiveIndex])

  return (
    <div>
      {" "}
      <div className="bahr v-center h-center ">
        <div className="strength w-100">
          <div className="top" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} >
            <p className="text-purple" style={{ margin: "0" }} >Work Experience</p>
            <div style={{ width: "15px", height: "15px", display: "flex", justifyContent: "center", alignItems: "flex-start", border: "1px solid black", backgroundColor: sections.experience ? "black" : "transparent" }} onClick={() => sections.experience ? setSections(prev => ({ ...prev, experience: false })) : setSections(prev => ({ ...prev, experience: true }))}>
              <div style={{ width: "7px", height: "10px", borderRight: "1px solid white", borderBottom: "1px solid white", transform: "rotate(45deg)", visibility: sections.experience ? "visible" : "hidden" }}></div>
            </div>
          </div>

          {first ? (
            <div>
              {/* New items */}
              <div className="new-item gap-3 d-flex">
                <div className="input-box  w-100">
                  <label htmlFor="" className="w-50">
                    <p className="p4">Company Name</p>
                    <input
                      name="companyName"
                      onChange={handleInfoChange}
                      type="text"
                      value={
                        userData?.experience ? userData?.experience[currentActiveIndex]?.companyName
                          ? userData?.experience[currentActiveIndex]?.companyName
                          : "" : ""
                      }
                    />
                  </label>
                  <label htmlFor="" className="w-50">
                    <p className="p4"> Job Title</p>
                    <input
                      name="jobTitle"
                      onChange={handleInfoChange}
                      type="text"
                      value={
                        userData?.experience ? userData?.experience[currentActiveIndex]?.jobTitle
                          ? userData?.experience[currentActiveIndex]?.jobTitle
                          : "" : ""
                      }
                    />
                  </label>
                </div>
              </div>
              <div className="new-item mt-3 gap-3 d-flex">
                <div className="input-box w-100">
                  <label htmlFor="" className="w-50">
                    <p className="p4"> City</p>
                    <input
                      name="cityName"
                      onChange={handleInfoChange}
                      type="text"
                      value={
                        userData?.experience ? userData?.experience[currentActiveIndex]?.cityName
                          ? userData?.experience[currentActiveIndex]?.cityName
                          : "" : ""
                      }
                    />
                  </label>
                  <label htmlFor="" className="w-50">
                    <p className="p4"> Country</p>
                    <input
                      name="country"
                      onChange={handleInfoChange}
                      type="text"
                      value={
                        userData?.experience ? userData?.experience[currentActiveIndex]?.country
                          ? userData?.experience[currentActiveIndex]?.country
                          : "" : ""
                      }
                    />
                  </label>
                </div>
              </div>
              <div className="para-z mt-3">
                <p className="p4">Time Period</p>
              </div>
              <div className="new-item time-period mt-2 gap-3 d-flex">
                <div className="input-box w-100">
                  <label htmlFor="" className="w-25">
                    <input
                      name="startMonth"
                      onChange={handleInfoChange}
                      type="text"
                      placeholder="Month"
                      value={
                        userData?.experience ? userData?.experience[currentActiveIndex]?.startMonth
                          ? userData?.experience[currentActiveIndex]?.startMonth
                          : "" : ""
                      }
                    />
                  </label>
                  <label htmlFor="" className="w-25">
                    <input
                      name="startYear"
                      onChange={handleInfoChange}
                      type="text"
                      placeholder="Year"
                      value={
                        userData?.experience ? userData?.experience[currentActiveIndex]?.startYear
                          ? userData?.experience[currentActiveIndex]?.startYear
                          : "" : ""
                      }
                    />
                  </label>
                  <label htmlFor="" className="w-25">
                    <input
                      name="endMonth"
                      onChange={handleInfoChange}
                      type="text"
                      placeholder="Month"
                      value={
                        userData?.experience ? userData?.experience[currentActiveIndex]?.endMonth
                          ? userData?.experience[currentActiveIndex]?.endMonth
                          : "" : ""
                      }
                    />
                  </label>
                  <label htmlFor="" className="w-25">
                    <input
                      name="endYear"
                      onChange={handleInfoChange}
                      type="text"
                      placeholder="Year"
                      value={
                        userData?.experience ? userData?.experience[currentActiveIndex]?.endYear
                          ? userData?.experience[currentActiveIndex]?.endYear
                          : "" : ""
                      }
                    />
                  </label>
                </div>
              </div>
              <div className="currently v-center h-center mt-2">
                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    border: "1px solid black",
                    marginRight: "5px",
                    backgroundColor: `${userData?.experience ? userData?.experience[currentActiveIndex]?.currentlyWorkHere
                      ? "black"
                      : "transparent"
                      : "transparent"}`,
                  }}
                  onClick={() => {
                    let temp = userData.experience == undefined ? [] : userData.experience;
                    temp[currentActiveIndex] = { ...temp[currentActiveIndex], currentlyWorkHere: temp[currentActiveIndex] ? !temp[currentActiveIndex]?.currentlyWorkHere : true }
                    setUserData((prev) => ({ ...prev, experience: temp }))
                  }
                  }
                ></div>
                <p className="p4">I currently work here</p>
              </div>

              {/* Multiple Inputs */}
              <div className="new-item-1  w-100 gap-4 ">
                {/* Description */}
                <div className="input-box d-flex flex-column gap-0 w-100 mt-14">
                  <div className="input-box w-100 mt-14">
                    <label htmlFor="" className="w-100">
                      <p className="p4">Job Responsibilities</p>
                    </label>
                  </div>
                  <div className="w-100 editor-wrapper position-relative">
                    <ReactQuill
                      placeholder={`Add information about your job or Just write few things about your role and OUR AI will generate it for you.`}
                      value={
                        userData?.experience ? userData?.experience[currentActiveIndex]?.description
                          ? userData?.experience[currentActiveIndex]?.description
                          : "" : ""
                      }
                      onChange={(value) => handleQuilChange(value)}
                      modules={WorkExperience.modules}
                      formats={WorkExperience.formats}
                      height="300px"
                    />
                    <div className="writer-btn pointer">
                      {!loading ? <Button
                        text="AI Writer"
                        width="137px"
                        height="39px"
                        className="btn-prime-1 btn-primary text-white rounded  "
                        onClick={handleAIWriter}

                      />
                        :
                        <div style={{ margin: "0", position: "relative", top: "48px", left: "42px" }} class="lds-ring"><div></div><div></div><div></div><div></div></div>}


                    </div>
                  </div>
                </div>
              </div>
              {/* AI Writer button */}
              <div className="threebtnwrapper">
                <Button
                  text="Save"
                  height="39px"
                  className="bg-scroll rounded bg-darkPurple w-100"
                  onClick={handleAddMore}
                  disabled={loading}
                />
                <Button
                  text="Close"
                  height="39px"
                  className="bg-scroll rounded bg-darkPurple w-100"
                  onClick={() => handleExp(null)}
                  disabled={loading}
                />
                <Button
                  text="Delete"
                  height="39px"
                  className="bg-scroll rounded bg-darkPurple w-100"
                  onClick={() => handleDelete(null)}
                  disabled={loading}
                />
              </div>
            </div>
          ) : (
            <div className="input-box mt-5 ">
              <label htmlFor="" className="w-100 h-center v-center" style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                {totalExperience.map((item, index) => {
                  return (
                    userData.experience && userData.experience[index]?.companyName != undefined && userData.experience[index]?.companyName != "" ?
                      <Button
                        key={index}
                        text={`${userData.experience[index]?.companyName}`}
                        height="39px"
                        className="bg-scroll rounded bg-darkPurple w-100"
                        onClick={() => {
                          // console.log(index);
                          handleExp(index)
                        }}
                      /> : null
                  )
                })}

              </label>
            </div>
          )}

          {/* Scroll Button */}
          {!first ? <div className="input-box mt-5 ">
            <label htmlFor="" className="w-100 h-center v-center">
              <Button
                text="Add more"
                height="39px"
                className="bg-scroll rounded bg-darkPurpleDotted w-100"
                onClick={() => handleExp(totalExperience.length)}
              />
            </label>
          </div>
            :
            null}

        </div>
      </div>
      <Modal show={downloadLimitExceed} onHide={handleDownloadLimitExceedModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Free Resources Utilized!</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          <div style={{ padding: "30px 10px", display: "flex", flexDirection: "column", gap: "30px", alignItems: "center" }}>
            <div style={{ display: "flex", alignItems: "flex-start", gap: "5px", width: "100%", flexDirection: "column" }}>
              <h5>Oh no, have exceeded the number of free downloads. Please upgrade your account to Pro Plan to continue enjoying Resume360. </h5>
              <h6 style={{ marginTop: "20px" }}>Benefits of Upgrading:</h6>
              <ul>
                <li>
                  Proofreading From Experts.
                </li>
                <li>
                  35+ professional resumes templates.
                </li>
                <li>
                  AI resume checker.
                </li>
                <li>
                  AI Writer.
                </li>
                <li>
                  Priority emails support.
                </li>
                <li>
                  And Much More.
                </li>
              </ul>
            </div>
            <button style={{ backgroundColor: "#562863", padding: "5px 7px", borderRadius: "5px" }} onClick={() => navigate("/money")} >Upgrade</button>
          </div>


        </Modal.Body>
      </Modal>
    </div>
  );
};
// Quill modules and formats configurations
WorkExperience.modules = {
  toolbar: [
    // [{ header: "1" }, { header: "2" }, { font: [] }],
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
    // ["link", "image"],
    // ["clean"],
  ],
};

WorkExperience.formats = [
  "header",
  "font",
  "list",
  "bullet",
  "bold",
  "italic",
  "underline",
  "link",
  "image",
];
export default WorkExperience;
