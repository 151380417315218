import React, { useState, useEffect, useRef } from "react";
import "../../style/main.scss";
import search from "../../assets/icons/search.svg";
import review from "../../assets/icons/reviews.svg";
import target from "../../assets/icons/target.svg";
import girl from "../../assets/images/Dashboard/girl.png";
import boy from "../../assets/images/Dashboard/boy.png";
import { setDoc, updateDoc, doc, getDoc } from "firebase/firestore";
import { auth, firestoredb, storage } from "../../config";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import parse from "html-react-parser";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";


const ProofReading = ({ userData, handleDbSave, isPaidUser }) => {

  const [loading, setLoading] = useState("")
  const [onReviewClick, setOnReviewClick] = useState(false);
  const [reviewText, setReviewText] = useState("");
  const [isRequested, setIsRequested] = useState(false);
  const [userId, setUserId] = useState("");
  const reviewRef = useRef();
  const user = auth.currentUser;
  const navigate = useNavigate();
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (auth.currentUser != null) {
        setUserId(auth.currentUser.uid);
      }
    });
  }, []);

  const handleOnReviewClick = () => {
    setOnReviewClick(true);
    setReviewText(userData.reviewText);
  }

  const handleRequestReview = async () => {
    // console.log("User ", auth.currentUser);
    const currentUserRef = doc(firestoredb, "users", auth.currentUser.uid);
    // setUserData((prev) => ({ ...prev, cvPicture: "" }));


    try {

      //fetching previous data first
      if (auth.currentUser) {
        // console.log("Fetching Data from DB");
        const docRef = doc(firestoredb, "users", auth.currentUser.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          // console.log("Document data gs:", docSnap.data());
          if (docSnap.data().cvData) {
            await updateDoc(docRef, {
              cvForReview: true
            });
            // await handleDbSave(true);
            setIsRequested(true);
            setLoading(true);
            const reviewDocRef = doc(firestoredb, "review", "allreviews");
            const reviewDocSnap = await getDoc(reviewDocRef);
            if (reviewDocSnap.exists()) {
              await updateDoc(reviewDocRef, {
                [auth.currentUser.uid]: { ...userData, userID: auth.currentUser.uid, isReviewed: false }
              });
            }
            else {
              await setDoc(reviewDocRef, {
                [auth.currentUser.uid]: { ...userData, userID: auth.currentUser.uid, isReviewed: false }
              })
            }
            toast("Your Resume Successfully Submitted for Review!");
          }
          else {

            // // console.log("Success CV Data");
            toast("Save your CV first!");

          }
        } else {
          // docSnap.data() will be undefined in this case
          // console.log("No such document!");
        }
      }

      setLoading(false);
    } catch (e) {
      toast("Some Error Occured! Please try again")
      console.error("Error adding document: ", e);
      setLoading(false);
    }
  };


  const getDataFromDb = async () => {
    setLoading(true);

    if (user) {
      // console.log("Fetching Data from DB");
      const docRef = doc(firestoredb, "users", user.uid);
      const docSnap = await getDoc(docRef);
      let reviewArr = []
      if (docSnap.exists()) {

        setIsRequested(docSnap.data().cvForReview ? true : false)

      } else {
        // docSnap.data() will be undefined in this case
        // console.log("No such document!");
        setLoading(false);

      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getDataFromDb();
  }, [userId]);

  const downloadResumeWord = () => {
    const prtContent = reviewRef.current;

    // Export to word
    var filename;
    var preHtml = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
    var postHtml = "</body></html>";

    var html = preHtml + prtContent.innerHTML + postHtml;

    var blob = new Blob(['\ufeff', html], {
      type: 'application/msword'
    });

    var url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);


    filename = 'Review.doc';


    var downloadLink = document.createElement("a");

    document.body.appendChild(downloadLink);

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {

      downloadLink.href = url;
      downloadLink.download = filename;
      downloadLink.click();
    }

    document.body.removeChild(downloadLink);
  }

  return (
    <div>
      <div className="ProofReading ">
        <p className="p6">Proofreading</p>
        <p className="p5-1 mb-0">Have it proofread by professional</p>
        <p className="p4-1">
          Don’t let a silly mistake cost you a job opportunity.
        </p>
        {!userData.reviewText ?
          <>
            <div className="inside-ProofReading   ">
              <div className="two d-flex">
                <img src={search} alt="search-icon" />
                <p className="p4-1">
                  Grammer correction from Our Experts            </p>
              </div>
            </div>
            <div className="inside-ProofReading   ">
              <div className="two d-flex">
                <img src={review} alt="review-icon" />
                <p className="p4-1">Enhanced readability review</p>
              </div>
            </div>
            <div className="inside-ProofReading   ">
              <div className="two d-flex">
                <img src={target} alt="target-icon" />
                <p className="p4-1">Increase your chances of getting hired </p>
              </div>
            </div>
          </>
          :
          ""}
        {/* Meet our proofreader */}
        <p className="p6 mt-14">Meet our proofreader</p>
        <p className="p4-1">Prefectionist who put ‘pro’ in proofreading.</p>
        {/* Img Section */}
        <div className="inside-ProofReading-new  ">
          <div className="two d-flex v-center h-center">
            <img src={girl} alt="girl-image" />
            <div className="d-flex flex-column">
              <p className="p4-1">
                Sarah John <span className="english">English</span>
              </p>
              <p className="body-small-1 mt-3">

                Diligent proofreader with keen eye for detail. Adept at polishing documents for accuracy and clarity. Dedicated to perfecting written content.
              </p>
            </div>
          </div>
        </div>
        {
          userData.reviewText ?
            <div className="inside-reviewRequest" style={{ marginBottom: "10px", border: "1px solid #cb6ce6" }}>
              <div className="adminReviewContainer">
                {/* <img src={search} alt="search-icon" /> */}
                <span>{userData.timeStamp}</span>
                <p className="p4-1">
                  Our Experts have reviewed your resume!

                </p>
                <div className="reviewBtnContainer">
                  <button className="reviewBtn" onClick={handleOnReviewClick}>See Comments</button>

                </div>
              </div>
            </div>
            :
            ""
        }
        {/* <div className="inside-reviewRequest" style={{ marginBottom: "10px", border: "1px solid #cb6ce6" }}>
          <div className="adminReviewContainer"> */}
        {/* <img src={search} alt="search-icon" /> */}
        {/* <p className="p4-1">
              Our Experts have reviewed your resume!

            </p>
            <div className="reviewBtnContainer">
              <button className="reviewBtn" onClick={handleOnReviewClick}>See Comments</button>

            </div>
          </div>
        </div> */}
        {isPaidUser ?
          !isRequested ?
            <button
              style={{
                backgroundColor: "#AC50C6",
                marginTop: "30px",
                padding: "5px 26px",
                borderRadius: "5px",
                // position: "absolute",
                // right: "0px",
              }}
              onClick={handleRequestReview}
            >
              Request for Review

            </button>

            :
            <button
              style={{
                backgroundColor: "#c68dd6",
                marginTop: "30px",
                padding: "5px 26px",
                borderRadius: "5px",
                // position: "absolute",
                // right: "0px",
              }}
              disabled={true}
            >
              Requested for Review

            </button>
          :
          <button
            style={{
              backgroundColor: "#AC50C6",
              marginTop: "30px",
              padding: "5px 26px",
              borderRadius: "5px",
              // position: "absolute",
              // right: "0px",
            }}
            onClick={() => navigate("/money")}
          >
            Upgrade Your Account

          </button>
        }

      </div>

      <Modal show={loading} centered>
        <Modal.Header closeButton>
          <Modal.Title>Processing Your Resume</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div class="lds-ringDashboard">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={onReviewClick} centered onHide={() => setOnReviewClick(false)} >
        <Modal.Header closeButton style={{ backgroundColor: "white", color: "black" }}>
          <Modal.Title>Your Review</Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ backgroundColor: "white" }}>
          <div className="reviewTextModalContainer">
            <div className="reviewTextModal" style={{ color: "black" }} ref={reviewRef}>
              {/* <h1>Review</h1> */}
              {parse(reviewText)}
            </div>
            <button style={{
              backgroundColor: "#AC50C6",
              marginTop: "50px",
              padding: "5px 26px",
              borderRadius: "5px",
              // position: "absolute",
              // right: "0px",
            }}
              onClick={downloadResumeWord}> Download Review </button>
          </div>
        </Modal.Body>
      </Modal>
    </div >
  );
};



export default ProofReading;
