import React from "react";
import banner from "../../assets/videos/banner.mp4";
import Button from "../shared/button";
import "../../style/main.scss";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useTypewriter, Typewriter, Cursor } from "react-simple-typewriter";
import Modal from "react-bootstrap/Modal";
import upload from "../../assets/icons/upload.svg";
import new1 from "../../assets/icons/New.svg";
import search2 from "../../assets/icons/Search2.svg";
import file from "../../assets/icons/File.svg";
import line from "../../assets/icons/line.svg"
import axios from 'axios';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { auth, firestoredb } from "../../config";
import { toast } from "react-toastify";
import handleBackToResume from "../../utils/loginredirect";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { adminUsers } from "../../adminUsers";
const Banner = ({ userData, setUserData, setGeneratedFromCv, fromWelcome, userName, isPaidUser }) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [linkedinModelShow, setLinkedinModelShow] = useState(false)
  const [desBox, setDesBox] = useState(false);
  const [cv, setCv] = useState("");
  const [loading, setLoading] = useState(false)
  // const [newResume, setNewResume] = useState(false);
  const [userisLogged, setUserisLogged] = useState(false);
  const [jobDescription, setJobDescription] = useState("")
  const [isLinkedinClicked, setIsLinkedinClicked] = useState(false);
  const [linkedinUrl, setLinkedinUrl] = useState("");
  const [downloadLimitExceed, setDownloadLimitExceed] = useState(false)

  const handleDownloadLimitExceedModal = () => {
    setDownloadLimitExceed(prev => !prev)
  }

  const handleResumeModal = () => setShow(!show);
  const handleOneClick = () => setDesBox(!desBox);
  const handleLinkedinModelShow = () => {
    if (!loading) {
      setLinkedinModelShow(!linkedinModelShow);
    }
  }

  // typewriter
  const [text] = useTypewriter({
    words: ["ATS-Optimized Resume", "ATS-Optimized Resume"],
    loop: 0,
  });

  const [welcomeText] = useTypewriter({
    words: ["Welcome", "Let's create!"],
    loop: 0,
  });



  const handleCvGenerateButton = async () => {
    // // console.log("Hello", cv.type);
    if (cv && cv.type != "application/pdf") {
      toast.error("Please select .pdf file!")
      setLoading(false);
    }
    else {
      setLoading(true);
      setUserData({})
      const formData = new FormData();
      formData.append("cv", cv);
      try {
        const tokenId = await auth.currentUser.getIdToken();
        const config = {
          headers: { Authorization: `Bearer ${tokenId}` }
        };
        setGeneratedFromCv(true);
        // const result = await axios.post("https://octaloop-resume-ai-50bd4465d773.herokuapp.com/pdfResume", formData);
        // const result = await axios.post("http://localhost:8080/pdfResume", formData);
        const result = await axios.post("https://resume-ai-nodejs.onrender.com/pdfResume", formData, config);
        // console.log("result", result.data);
        if (!result.data.message) {
          setUserData(result.data);
          navigate("/dashboard")

        }
        else {
          toast.error("Oops, some error occured, Please try again")
          setGeneratedFromCv(false);

        }

        setLoading(false);
        // downloadPng();
      } catch (e) {
        setGeneratedFromCv(false)
        console.error(e.message)
        setLoading(false);
      }
    }


  }

  const handleCvGenerateButtonLimit = async () => {
    if (isPaidUser) {
      handleCvGenerateButton();
    }
    else {
      const docRef = doc(firestoredb, "users", auth.currentUser.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        if (!docSnap.data().revampCount) {
          handleCvGenerateButton();
          updateDoc(docRef, {
            revampCount: 1
          })
        }
        else if (docSnap.data().revampCount < 1) {
          handleCvGenerateButton();
          updateDoc(docRef, {
            revampCount: Number(docSnap.data().revampCount) + 1
          })
        }
        else {
          // toast("You have exceeded the number of free downloads. Please upgrade your account to Pro Plan.")
          setDownloadLimitExceed(true);
        }
      }
    }
  }

  const monthInWords = (month) => {
    if (month == 1) {
      return "January";
    }
    else if (month == 2) {
      return "February";
    }
    else if (month == 3) {
      return "March";
    }
    else if (month == 4) {
      return "April";
    }
    else if (month == 5) {
      return "May";
    }
    else if (month == 6) {
      return "June";
    }
    else if (month == 7) {
      return "July";
    }
    else if (month == 8) {
      return "August";
    }
    else if (month == 9) {
      return "September";
    }
    else if (month == 10) {
      return "October";
    }
    else if (month == 11) {
      return "November";
    }
    else if (month == 12) {
      return "December";
    }
    else {
      return null;
    }
  }

  const handleGenerateFromLinkedin = async () => {
    if (linkedinUrl != "") {
      setLoading(true);
      setUserData({})
      const formData = new FormData();
      formData.append("cv", cv);





      const options = {
        method: 'GET',
        url: 'https://linkedin-profile-data-api.p.rapidapi.com/',
        params: {
          username: `${linkedinUrl}`
        },
        headers: {
          'X-RapidAPI-Key': '320f203981msh51cd0eed9f015c1p16ce88jsn72ee9cf5a96c',
          'X-RapidAPI-Host': 'linkedin-profile-data-api.p.rapidapi.com'
        }
      };


      try {
        const result = await axios.request(options);
        // console.log("lets see", result.data);
        let experiences
        if (Array.isArray(result.data.position)) {
          experiences = result.data.position.map((experience) => {
            return ({
              companyName: `${experience?.companyName ? experience?.companyName : "Your Company Name"}`,
              jobTitle: `${experience?.title ? experience?.title : ""}`,
              description: `${experience?.description ? experience?.description : ""}`,
              startMonth: `${experience?.start?.month ? monthInWords(experience?.start?.month) ? monthInWords(experience?.start?.month) : experience?.start?.month : ""}`,
              startYear: `${experience?.starts?.year ? experience?.starts?.year : ""}`,
              endMonth: `${experience?.end?.month ? monthInWords(experience?.end?.month) ? monthInWords(experience?.end?.month) : experience?.end?.month : ""}`,
              endYear: `${experience?.end?.year ? experience?.end?.year : ""}`,
              cityName: `${experience?.location ? experience?.location : ""}`,
              country: `${experience?.location ? experience?.location : ""}`
            })
          })
        }
        let educations;
        if (Array.isArray(result.data.educations)) {
          educations = result.data.educations.map((education) => {
            return ({
              schoolName: `${education?.schoolName ? education?.schoolName : "Your School"}`,
              degreeName: `${education?.degree ? education?.degree : ""}`,
              description: `${education?.fieldOfStudy ? education?.fieldOfStudy : ""}`,
              startMonth: `${education?.start?.month ? monthInWords(education?.start?.month) ? monthInWords(education?.start?.month) : education?.start?.month : ""}`,
              startYear: `${education?.start?.year ? education?.start?.year : ""}`,
              endMonth: `${education?.end?.month ? monthInWords(education?.end?.month) ? monthInWords(education?.end?.month) : education?.end?.month : ""}`,
              endYear: `${education?.end?.year ? education?.end?.year : ""}`,
              cityName: `${education?.location ? education?.location : ""}`,
              country: `${education?.location ? education?.location : ""}`
            })
          })
        }
        let skillsExtracted = []
        if (Array.isArray(result.data.skills)) {
          let skills = result.data.skills.map((skill) => {

            skillsExtracted.push(skill.name)

          })
        }




        setUserData({
          fName: result.data.firstName,
          lName: result.data.lastName,
          summary: result.data.summary,
          experience: experiences,
          role: experiences[0] ? experiences[0].jobTitle : "",
          skills: skillsExtracted,
          education: educations,
          country: result.data.geo.country,
          city: result.data.geo.city,
          web: "linkedin.com/in/" + linkedinUrl,
          cvPicture: result.data.profilePicture
        });
        navigate("/dashboard")
        setLoading(false);


      } catch (error) {
        setLoading(false)
        console.error(error);
      }

      // try {
      //   setGeneratedFromCv(true);
      //   // const result = await axios.post("https://octaloop-resume-ai-50bd4465d773.herokuapp.com/pdfResume", formData);
      //   // const result = await axios.post("http://localhost:8080/pdfResume", formData);
      //   const result = await axios.get("https://cors-anywhere.herokuapp.com/https://nubela.co/proxycurl/api/v2/linkedin", {
      //     params: { url: "https://www.linkedin.com/in/" + linkedinUrl },
      //     headers: {
      //       Authorization: "Bearer lMh91tIR7vuzmFx9HOprlg",
      //     }
      //   });
      //   // console.log(result.data);
      //   let experiences = result.data.experiences.map((experience) => {
      //     return ({
      //       companyName: experience?.company,
      //       jobTitle: experience?.title,
      //       description: experience?.description,
      //       startMonth: experience?.starts_at?.month,
      //       startYear: experience?.starts_at?.year,
      //       endMonth: experience?.ends_at?.month,
      //       endYear: experience?.ends_at?.year
      //     })
      //   })

      //   let educations = result.data.education.map((education) => {
      //     return ({
      //       schoolName: education?.school,
      //       degreeName: education?.title,
      //       description: education?.description,
      //       startMonth: education?.starts_at?.month,
      //       startYear: education?.starts_at?.year,
      //       endMonth: education?.ends_at?.month,
      //       endYear: education?.ends_at?.year
      //     })
      //   })

      //   setUserData({
      //     fName: result.data.first_name,
      //     lName: result.data.last_name,
      //     summary: result.data.summary,
      //     experience: experiences,
      //     role: result.data.experiences[0] ? result.data.experiences[0].title : "",
      //     skills: result.data.skills,
      //     education: educations,
      //     country: result.data.country_full_name,
      //     city: result.data.city,
      //     web: "linkedin.com/in/" + result.data.public_identifier
      //   });
      //   navigate("/dashboard")
      //   setLoading(false);
      //   // downloadPng();
      // } catch (e) {
      //   setGeneratedFromCv(false)
      //   console.error(e.message)
      //   setLoading(false);
      // }

    }
    else {
      alert("Please enter Linkedin Url.")
    }

  }


  const handleGenerateFromJobDescription = async () => {
    setLoading(true);
    // console.log(jobDescription);
    // const formData = new FormData();
    // formData.append("cv", cv);
    let tempData = {
      fName: userData.fName,
      lName: userData.lName,
      email: userData.email,
      web: userData.web ? userData.web : "Your Website",
      education: userData.education ? userData.education : "",
      experience: userData.experience ? userData.experience : "Generate Demo experience according to job description",
      project: userData.projects ? userData.projects : "Generate Demo project according to job description",

      jobDescription: jobDescription
    };
    // console.log(tempData)

    try {
      setGeneratedFromCv(true);
      // const result = await axios.post("https://octaloop-resume-ai-50bd4465d773.herokuapp.com/pdfResume", formData);
      // const result = await axios.post("http://localhost:8080/pdfResume", formData);
      const result = await axios.post("http://localhost:8080/textResume", tempData);
      // console.log(result.data);
      setUserData(result.data);
      navigate("/dashboard")
      setLoading(false);
      // downloadPng();
    } catch (e) {
      setGeneratedFromCv(false)
      console.error(e.message)
      setLoading(false);
    }

  }

  const handleGenerateFromScratch = () => {
    setGeneratedFromCv(false);
    setUserData({})
    navigate("/templates")
  }

  const handleNavigateToProofReaders = () => {
    navigate("/dashboard", { state: { comeFromWelcome: true } })
  }

  useEffect(() => {
    if (cv !== "") {
      handleCvGenerateButtonLimit();
    }

  }, [cv])

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in.
        const { uid, email } = user;
        // navigate("/");
        setUserisLogged(user);
      } else {
        // User is signed out.
        setUserisLogged(user);

        // router.push({
        //     pathname: "/login",
        // });
      }
    });
    return () => unsubscribe();
  }, []);

  return (
    <div>
      <div className="landing-banner">
        {
          fromWelcome ?
            <>
              <h1 className="text-center text-white" style={{ marginTop: userisLogged ? "5%" : "10%" }}>
                Welcome <span>{userName}</span>

              </h1>
            </>

            :
            <>
              <p className="text-center text-white">BEST AI RESUME BUILDER</p>
              <h1 className="text-center text-white">
                Your career success story begins
              </h1>
              <h1 className="text-center text-white">
                with an <span>{text}</span>
                <Cursor />
              </h1>
              <p className="body-large text-center text-white fw-light" style={{ fontSize: "16px" }}>
                Boost interview invitation frequencies & amplify call rate by using our AI resume building solution. Easy to use and done within minutes - try now for free

              </p>
            </>
        }
        {/* <p className="text-center text-white">BEST AI RESUME BUILDER</p>
        <h1 className="text-center text-white">
          Your Career Success Story Begins
        </h1>
        <h1 className="text-center text-white">
          With A <span>{text}</span>
          <Cursor />
        </h1>
        <p className="body-large text-center text-white fw-light">
          Use professional field-tested resume templates that follow the exact
          ‘resume rules’ employers look for. Easy to use and done within minutes
          - try now for free!.
        </p> */}

        <div className="banner-btn">
          {/* Modal Code */}
          {!userisLogged ?
            <>
              <Button
                text="Create"
                variant="primary"
                onClick={handleResumeModal}
                className="text-white btn-primary br-10"
                width="110px"
                height="40.93px"
              ></Button>
              <Button
                text="Revamp"
                variant="primary"
                // onClick={() => navigate("/login", { state: { toWhere: "/fix" } })}
                onClick={() => handleBackToResume()}
                className="text-white btn-primary br-10"
                width="110px"
                height="40.93px"
              ></Button>
              <Button
                text="Analyze"
                variant="primary"
                // onClick={() => navigate("/login", { state: { toWhere: "/fix" } })}
                onClick={() => handleBackToResume()}
                className="text-white btn-primary br-10"
                width="110px"
                height="40.93px"
              ></Button>
              <Button
                text="Fix"
                variant="primary"
                // onClick={() => navigate("/login", { state: { toWhere: "/fix" } })}
                onClick={() => handleBackToResume()}
                className="text-white btn-primary br-10"
                width="110px"
                height="40.93px"
              ></Button>
            </>
            :
            !adminUsers.includes(auth.currentUser.email) ?
              <div style={{ position: "relative" }}>
                <div className="createResumeOptions" style={{ marginBottom: "8px" }}>
                  <div className="createResumeOption" onClick={handleGenerateFromScratch}>
                    <div><img src={new1} alt="new-icon" /></div>
                    <div>New Resume</div>
                  </div>
                  <img src={line} alt="line-icon" style={{ alignSelf: "center", width: "75%" }} />
                  <label htmlFor="newUICvUpload" style={{ fontWeight: "500" }}>
                    <div className="createResumeOption" >
                      <div><img src={new1} alt="new-icon" /></div>
                      <div>Revamp existing resume</div>
                    </div>
                  </label>
                  <input id="newUICvUpload" type="file" accept="application/pdf" style={{ display: "none" }} onChange={(e) => { setCv(e.target.files[0]) }}></input>
                  <img src={line} alt="line-icon" style={{ alignSelf: "center", width: "75%" }} />
                  <div className="createResumeOption" onClick={() => navigate("/fix")}>
                    <div><img src={search2} alt="search2-icon" /></div>
                    <div>Analyze and Fix resume</div>
                  </div>
                  <img src={line} alt="line-icon" style={{ alignSelf: "center", width: "75%" }} />
                  <div className="createResumeOption" onClick={handleNavigateToProofReaders}>
                    <div><img src={file} alt="file-icon" /></div>
                    <div>Proof and Review resume</div>
                  </div>


                </div>
                <p style={{ position: "absolute", left: "0", fontWeight: "300" }}>or Upload from <a style={{ fontWeight: "400", color: "inherit", textDecoration: "underline", cursor: "pointer" }} onClick={handleLinkedinModelShow} >Linkedin</a></p>
              </div>
              :
              <div style={{ position: "relative" }}>
                <div className="createResumeOptions" style={{ marginBottom: "8px", height: "100%", paddingTop: "20px", paddingBottom: "20px" }}>
                  <div className="createResumeOption" onClick={() => navigate("/dashboard")}>
                    <div><img src={new1} alt="new-icon" /></div>
                    <div>View All Requests</div>
                  </div>
                </div>
              </div>}

          <Modal show={loading && !linkedinModelShow} centered>
            <Modal.Header>
              <Modal.Title>Processing Your Resume</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>
            </Modal.Body>
          </Modal>

          <Modal show={linkedinModelShow} onHide={handleLinkedinModelShow} centered>
            <Modal.Header closeButton>
              <Modal.Title>Import Data from Linkedin</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              {loading ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>
                :
                <div style={{ padding: "30px 10px", display: "flex", flexDirection: "column", gap: "30px", alignItems: "center" }}>
                  <div style={{ display: "flex", alignItems: "center", gap: "5px", width: "100%" }}>
                    linkedin.com/in/
                    <input type="text" placeholder="linkedin Username" style={{ border: "none", borderRadius: "5px", padding: "2px 10px", width: "100%" }} value={linkedinUrl} onChange={(e) => setLinkedinUrl(e.target.value)} ></input>
                  </div>
                  <button style={{ backgroundColor: "#562863", padding: "5px", borderRadius: "5px" }} onClick={handleGenerateFromLinkedin} >Import</button>
                </div>
              }

            </Modal.Body>
          </Modal>

          <Modal show={show} onHide={handleResumeModal} centered>
            <Modal.Header closeButton>
              <Modal.Title>Create your Resume</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              {
                !userisLogged ?
                  <div className="create-btn" style={{ marginTop: "30px", marginBottom: "30px", flexDirection: "column", gap: "10px" }}>
                    <div style={{ display: "block", fontSize: "18px" }}> Sign in to Resumifi</div>
                    <div className="new-btn" style={{ width: "100px", height: "35px" }}>

                      <Button
                        text="Login"
                        fontSize={"14px"}
                        width={"50px"}
                        height={"30px"}
                        // onClick={() => { // console.log("clicked"); navigate("/login") }}
                        onClick={() => handleBackToResume()}

                      />
                    </div>
                  </div>
                  :
                  !desBox ? (
                    <div>
                      <div className="create">
                        {!loading ?
                          <label
                            htmlFor="file"
                            className="d-flex flex-column pointer"
                          >
                            <img src={upload} alt="upload-icon" />
                            <input
                              type="file"
                              name=""
                              id="file"
                              className="d-none"
                              accept=".pdf"
                              onChange={(e) => setCv(e.target.files[0])}
                            />
                            <p>Polish Your Existing Resume</p>
                          </label>
                          : <div style={{ margin: "10px" }} class="lds-ring"><div></div><div></div><div></div><div></div></div>}

                      </div>
                      <div style={{ textAlign: "center", margin: "15px 0" }}>Or</div>
                      {/* <div className="create-btn" style={{ marginTop: "30px" }}>

                    <div className="new-btn">

                      <Button
                        text="Create New Resume"
                        onClick={handleOneClick}
                        disabled={loading}
                      />
                    </div>
                  </div> */}
                      <div className="create-btn" style={{ marginTop: "30px" }}>

                        <div className="new-btn">

                          <Button
                            text="Create From Job Description"
                            onClick={handleOneClick}
                            disabled={loading}

                          />
                        </div>
                      </div>
                      <div style={{ textAlign: "center", margin: "15px 0" }}>Or</div>
                      {/* <div className="create-btn" style={{ marginTop: "30px" }}>

                    <div className="new-btn">

                      <Button
                        text="Create New Resume"
                        onClick={handleOneClick}
                        disabled={loading}
                      />
                    </div>
                  </div> */}
                      <div className="create-btn" style={{ marginTop: "30px", flexDirection: "column", gap: "10px" }}>
                        <input type="text" placeholder="Enter Linkedin Url" style={{ borderRadius: "5px", border: "none", padding: "3px", width: "100%" }} onChange={(e) => setLinkedinUrl(e.target.value)}></input>
                        <div className="new-btn">

                          <Button
                            text="Import From LinkedIn"
                            onClick={handleGenerateFromLinkedin}
                            disabled={loading}
                          />
                        </div>

                      </div>
                      <div style={{ textAlign: "center", margin: "15px 0" }}>Or</div>
                      {/* <div className="create-btn" style={{ marginTop: "30px" }}>

                    <div className="new-btn">

                      <Button
                        text="Create New Resume"
                        onClick={handleOneClick}
                        disabled={loading}
                      />
                    </div>
                  </div> */}
                      <div className="create-btn" style={{ marginTop: "30px" }}>

                        <div className="new-btn">

                          <Button
                            text="Create From Scratch"
                            onClick={handleGenerateFromScratch}
                            disabled={loading}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

              {desBox ? (
                <div className="description ">
                  <p>Write a description to create your resume :</p>
                  <textarea
                    name=""
                    id=""
                    cols="40"
                    rows="8"
                    className="w-100"
                    placeholder="Write a description to create your resume !"
                    onChange={(e) => setJobDescription(e.target.value)}
                    value={jobDescription}
                  ></textarea>
                  <div className="outer">

                    {!loading ? <>
                      <button className="mx-2" onClick={handleOneClick} disabled={loading}>
                        Back
                      </button>
                      <button onClick={handleGenerateFromJobDescription} >Submit</button>
                    </>
                      :
                      <div style={{}} class="lds-ring"><div></div><div></div><div></div><div></div></div>
                    }

                  </div>
                </div>
              ) : (
                <></>
              )
              }


            </Modal.Body>
          </Modal>

          {/* <Button
            text="See Examples"
            className="text-white btn-secondary br-35"
            width="242px"
            height="40.93px"
            onClick={(e) => navigate("/templates")}
          /> */}
        </div>

        {!fromWelcome ?
          <div className="video m-auto">
            <video autoPlay loop muted playsInline className="img-100">
              <source src={"/assets/resumifi.mp4"} type="video/mp4" />
            </video>
          </div>
          :
          null
        }

      </div>
      <Modal show={downloadLimitExceed} onHide={handleDownloadLimitExceedModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Free Resources Utilized!</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          <div style={{ padding: "30px 10px", display: "flex", flexDirection: "column", gap: "30px", alignItems: "center" }}>
            <div style={{ display: "flex", alignItems: "flex-start", gap: "5px", width: "100%", flexDirection: "column" }}>
              <h5>Oh no, have exceeded the number of free Resume Revamps. Please upgrade your account to Pro Plan to continue enjoying Resumifi. </h5>
              <h6 style={{ marginTop: "20px" }}>Benefits of Upgrading:</h6>
              <ul>
                <li>
                  Proofreading From Experts.
                </li>
                <li>
                  35+ professional resumes templates.
                </li>
                <li>
                  AI resume checker.
                </li>
                <li>
                  AI Writer.
                </li>
                <li>
                  Priority emails support.
                </li>
                <li>
                  And Much More.
                </li>
              </ul>
            </div>
            <button style={{ backgroundColor: "#562863", padding: "5px 7px", borderRadius: "5px" }} onClick={() => navigate("/money")} >Upgrade</button>
          </div>


        </Modal.Body>
      </Modal>
    </div >
  );
};
export default Banner;
