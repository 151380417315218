import React, { useEffect } from "react";
import "./template12.scss"; // Import the SCSS file
import { Icon } from "@iconify/react";
import parse from "html-react-parser"
import girl from "../../assets/images/girl.jpg"
const Template12 = ({ userData, forDownload, sections, color, setColor, font, setFont, fontSize, setFontSize, lineHeight, setLineHeight, fromPreview }) => {
  useEffect(() => {

    if (!fromPreview) {
      setColor(userData.templateColor ? userData.templateColor : "#369188")
    }
  }, [])
  const getColor = () => {
    return `:root{--t12Color: ${color};
    --t12LineHeight : ${lineHeight};
  --t12Font : ${font};
--paraFontSize: ${fontSize * 0.70}px;
--subHeadingFontSize: ${fontSize}px; }`;
  };
  const getPageMarginsF = () => {
    return `@page :first { margin: ${"0px"} ${"0px"} ${"0px"} ${"0px"} !important; }`;
  };
  const getPageMargins = () => {
    return `@page { margin: ${"0px"} ${"0px"} ${"0px"} ${"0px"} !important; }`;
  };
  const getPageMarginst = () => {
    return `.personal-sec,
    .skill-sec,
    .experience-sec,
    .project-sec,
    .volunteer-sec,
    .education-sec,
    .certificate-sec,
    .award-sec,
    .publisher-sec,
    .interest-sec,
    .language-sec,
    .reference-sec { page-break-inside: avoid; }`;
  };

  return (
    <div className="template12-sec" ref={forDownload}>
      <style>{getColor()}</style>
      <style>{getPageMargins()}</style>
      <style>{getPageMarginsF()}</style>
      {/* <style>{getPageMarginst()}</style> */}
      <div className="top">
        <div className="left">
          <img
            className="profile-img"
            src={`${userData?.cvPictureObj ? URL.createObjectURL(userData?.cvPictureObj) : userData?.cvPicture ? userData?.cvPicture : girl}`}
            alt="..."
          />
        </div>
        <div className="right">
          <h2 className="heading">{`${userData?.fName || userData?.lName
            ? `${userData?.fName ? userData?.fName : ""} ${userData?.lName ? userData?.lName : ""
            }`
            : "Your Name"
            }`}</h2>
          <h3 className="status">{userData?.role ? userData?.role : "Your Role"}</h3>
        </div>
      </div>
      <div className="personal-sec">
        <div className="left">
          <h4 className="sub-heading">Personal Information</h4>
        </div>
        <div className="right">
          <div className="email">
            <p className="para bold-para">Email Address:</p>
            <p className="para">{userData?.email ? userData?.email : "Your Email"}</p>
          </div>
          <div className="email">
            <p className="para bold-para">Contact Number:</p>
            <p className="para">{userData?.phone ? userData?.phone : "Your Phone"}</p>
          </div>
          <div className="email">
            <p className="para bold-para">Website:</p>
            <a href="#" className="para">
              {userData?.web ? userData?.web : "Your Linkedin"}
            </a>
          </div>
        </div>
      </div>
      {sections.skills || sections.strengths ? <>
        <div className="skill-sec">
          <div className="left">
            <h4 className="sub-heading">Skills</h4>
          </div>
          <div className="right">
            {sections.skills ? <>
              <div className="hard-skill">
                <p className="para bold-para">Hard Skills:</p>
                <div>
                  {userData.skills ?
                    userData.skills.map((skill, index) => (
                      <>
                        <p className="para">{skill}</p>

                      </>
                    ))
                    :
                    <>
                      <p className="para">Skill1</p>
                      <p className="para">Skill2</p>
                      <p className="para">Skill3</p>
                      <p className="para">Skill4</p>
                    </>}

                </div>
              </div>
            </> : ""}
            {sections.strengths ? <>
              <div className="soft-skill">
                <p className="para bold-para">Soft Skills:</p>
                <div>
                  {userData.strengths ?
                    userData.strengths.map((strength, index) => (
                      <p className="para">{strength}</p>
                    ))
                    :
                    <><p className="para">Skill1</p>
                      <p className="para">Skill2</p>
                      <p className="para">Skill3</p>
                      <p className="para">Skill4</p>
                      <p className="para">Skill1</p>
                      <p className="para">Skill2</p>
                      <p className="para">Skill3</p>
                      <p className="para">Skill4</p>
                      <p className="para">Skill1</p>
                      <p className="para">Skill2</p>
                      <p className="para">Skill3</p>
                      <p className="para">Skill4</p>
                      <p className="para">Skill1</p>
                      <p className="para">Skill2</p>
                      <p className="para">Skill3</p>
                      <p className="para">Skill4</p></>}

                </div>
              </div>
            </> : ""}


          </div>
        </div>
      </> : ""}

      {sections.experience ? <>
        <div className="experience-sec">
          <div className="left">
            <h4 className="sub-heading">Work Experience</h4>
          </div>
          <div className="right">
            {userData.experience ? (
              userData.experience.map((experience, i) => (
                <>
                  <div className="ist">
                    <p className="para bold-para">{experience.jobTitle}</p>
                    <p className="para dark-para">{experience.companyName}</p>
                    <p className="para light-para">{`${experience?.startMonth ||
                      experience?.startYear ||
                      experience?.endMonth ||
                      experience?.endYear ||
                      experience?.currentlyWorkHere
                      ? `${experience?.startMonth ? experience?.startMonth : ""
                      } ${experience?.startYear ? experience?.startYear : ""
                      } ${experience?.endMonth ||
                        experience?.endYear ||
                        experience?.currentlyWorkHere
                        ? "-"
                        : ""
                      } ${experience?.currentlyWorkHere
                        ? "Present"
                        : `${experience?.endMonth ? experience?.endMonth : ""
                        } ${experience?.endYear ? experience?.endYear : ""
                        }`
                      }`
                      : "Your Company Tenure"
                      }`}</p>
                    <p>
                      {parse(
                        experience?.description
                          ? experience?.description
                          : "Your Job Description"
                      )}
                    </p>
                  </div>
                </>
              ))
            ) : (
              <>
                <div className="ist">
                  <p className="para bold-para">Role</p>
                  <p className="para dark-para">Company Name</p>
                  <p className="para light-para">Date</p>
                  <ul>
                    <li>
                      <p className="para">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet,
                        atque?
                      </p>
                    </li>
                    <li>
                      <p className="para">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet,
                        atque?
                      </p>
                    </li>
                    <li>
                      <p className="para">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet,
                        atque?
                      </p>
                    </li>
                  </ul>
                </div>
              </>
            )}

          </div>
        </div>
      </> : ""}
      {sections.projects ? <>
        <div className="project-sec">
          <div className="left">
            <h4 className="sub-heading">Projects</h4>
          </div>
          <div className="right">
            {userData.projects ? (
              userData.projects.map((project, i) => (
                <>
                  <div className="ist">
                    <p className="para bold-para">{project.projectType}</p>
                    <p className="para dark-para">{project.projectName}</p>
                    <p className="para light-para">{`${project?.startMonth ||
                      project?.startYear ||
                      project?.endMonth ||
                      project?.endYear ||
                      project?.currentlyWorkingOnThisProject
                      ? `${project?.startMonth ? project?.startMonth : ""
                      } ${project?.startYear ? project?.startYear : ""
                      } ${project?.endMonth ||
                        project?.endYear ||
                        project?.currentlyWorkingOnThisProject
                        ? "-"
                        : ""
                      } ${project?.currentlyWorkingOnThisProject
                        ? "Present"
                        : `${project?.endMonth ? project?.endMonth : ""
                        } ${project?.endYear ? project?.endYear : ""
                        }`
                      }`
                      : "Your Company Tenure"
                      }`}</p>
                    <p>
                      {parse(
                        project?.description
                          ? project?.description
                          : "Your Job Description"
                      )}
                    </p>
                  </div>
                </>
              ))
            ) : (
              <>
                <div className="ist">
                  <p className="para bold-para">Project Company</p>
                  <p className="para dark-para">Project Name</p>
                  <p className="para light-para">Date</p>
                  <ul>
                    <li>
                      <p className="para">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet,
                        atque?
                      </p>
                    </li>
                    <li>
                      <p className="para">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet,
                        atque?
                      </p>
                    </li>
                    <li>
                      <p className="para">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet,
                        atque?
                      </p>
                    </li>
                  </ul>
                </div>
              </>
            )}

          </div>
        </div>
      </> : ""}
      {sections.volunteer ? <>
        <div className="volunteer-sec">
          <div className="left">
            <h4 className="sub-heading">Volunteers</h4>
          </div>
          <div className="right">
            {userData.volunteer ? (
              userData.volunteer.map((volunteer, i) => (
                <>
                  <div className="ist">
                    <p className="para bold-para">{volunteer.institutionName}</p>
                    <p className="para dark-para">{`${volunteer?.startMonth ||
                      volunteer?.startYear ||
                      volunteer?.endMonth ||
                      volunteer?.endYear ||
                      volunteer?.currentlyVolunteerHere
                      ? `${volunteer?.startMonth ? volunteer?.startMonth : ""
                      } ${volunteer?.startYear ? volunteer?.startYear : ""
                      } ${volunteer?.endMonth ||
                        volunteer?.endYear ||
                        volunteer?.currentlyVolunteerHere
                        ? "-"
                        : ""
                      } ${volunteer?.currentlyVolunteerHere
                        ? "Present"
                        : `${volunteer?.endMonth ? volunteer?.endMonth : ""
                        } ${volunteer?.endYear ? volunteer?.endYear : ""
                        }`
                      }`
                      : "Your Company Tenure"
                      }`}</p>
                    <p className="para">
                      {parse(
                        volunteer?.description
                          ? volunteer?.description
                          : "Your Volunteer Description"
                      )}
                    </p>
                  </div>
                </>
              ))
            ) : (
              <>
                <div className="ist">
                  <p className="para bold-para">Volunteer Name</p>
                  <p className="para dark-para">Date</p>
                  <ul>
                    <li>
                      <p className="para">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet,
                        atque?
                      </p>
                    </li>
                    <li>
                      <p className="para">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet,
                        atque?
                      </p>
                    </li>
                    <li>
                      <p className="para">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet,
                        atque?
                      </p>
                    </li>
                  </ul>
                </div>
              </>
            )}

          </div>
        </div>
      </> : ""}

      {sections.education ? <>
        <div className="education-sec">
          <div className="left">
            <h4 className="sub-heading">Education</h4>
          </div>
          <div className="right">
            {userData.education ? userData.education.map((education, index) => (
              <>
                <div className="ist">
                  <p className="para bold-para">{education.degreeName}</p>
                  <p className="para light-para">{`${education.schoolName}`}</p>
                  <p className="para">{`${education.cityName} ${education.country}`}</p>
                  <p className="para">{`${education?.startMonth ||
                    education?.startYear ||
                    education?.endMonth ||
                    education?.endYear ||
                    education?.currentlyStudyHere
                    ? `${education?.startMonth
                      ? education?.startMonth
                      : ""
                    } ${education?.startYear
                      ? education?.startYear
                      : ""
                    } ${education?.endMonth ||
                      education?.endYear ||
                      education?.currentlyStudyHere
                      ? "-"
                      : ""
                    } ${education?.currentlyStudyHere
                      ? "Present"
                      : `${education?.endMonth
                        ? education?.endMonth
                        : ""
                      } ${education?.endYear
                        ? education?.endYear
                        : ""
                      }`
                    }`
                    : "Your Degree Tenure"
                    }`}</p>
                </div>
              </>
            )) :
              <>
                <div className="ist">
                  <p className="para bold-para">Degree</p>
                  <p className="para light-para">Institute</p>
                  <p className="para">Date City,Country</p>
                </div>
              </>
            }

          </div>
        </div>
      </> : ""}
      {sections.certificates ? <>
        <div className="certificate-sec">
          <div className="left">
            <h4 className="sub-heading">Certificates</h4>
          </div>
          <div className="right">
            {userData.certificates ? (
              userData.certificates.map((certificate, i) => (
                <>
                  <div className="ist">

                    <p className="para bold-para">{certificate.certificateName}</p>
                    <p className="para light-para">{certificate.authority}</p>
                    <p className="para">{`${certificate.certificateDate ? certificate.certificateDate : ""}`}</p>
                  </div>

                </>
              ))
            ) : (
              <>
                <div className="ist">
                  <p className="para bold-para">Certificate Name</p>
                  <p className="para light-para">Institute</p>
                  <p className="para">Date </p>
                </div>
              </>
            )}

          </div>
        </div>
      </> : ""}
      {sections.awards ? <>
        <div className="award-sec">
          <div className="left">
            <h4 className="sub-heading">Awards</h4>
          </div>
          <div className="right">
            {userData.awards ? userData.awards.map((award, index) => (
              <div className="ist">
                <p className="para bold-para">{award.awardTitle}</p>
                <p className="para">{`${award.awardMonth ? award.awardMonth : ""}${award.awardMonth && award.awardYear ? ", " : ""}${award.awardYear ? award.awardYear : ""}`}</p>
              </div>
            ))
              :
              <div className="ist">
                <p className="para bold-para">Award Name</p>
                <p className="para">Date</p>
              </div>
            }

          </div>
        </div>
      </> : ""}
      {sections.publications ? <>
        <div className="publisher-sec">
          <div className="left">
            <h4 className="sub-heading">Publications</h4>
          </div>
          <div className="right">
            {userData.publications ? (
              userData.publications.map((publication, i) => (
                <>
                  <div className="ist">
                    <p className="para bold-para">{publication.publisher}</p>
                    <p className="para dark-para">{publication.publicationTitle}</p>
                    <p className="para light-para">{`${publication.publicationMonth ? publication.publicationMonth : ""}${publication.publicationMonth && publication.publicationYear ? ", " : ""}${publication.publicationYear ? publication.publicationYear : ""}`}</p>
                    <p className="para">
                      {parse(
                        publication?.description
                          ? publication?.description
                          : "Your Publication Description"
                      )}
                    </p>
                  </div>

                </>
              ))
            ) : (
              <>
                <div className="ist">
                  <p className="para bold-para">Publisher Name</p>
                  <p className="para dark-para">Book Name</p>
                  <p className="para light-para">Date</p>
                  <ul>
                    <li>
                      <p className="para">
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                      </p>
                    </li>
                  </ul>
                </div>
              </>
            )}

          </div>
        </div>
      </> : ""}
      {sections.interests ? <>
        <div className="interest-sec">
          <div className="left">
            <h4 className="sub-heading">Interests</h4>
          </div>
          <div className="right">
            <div className="ist">
              {userData.interests ? userData.interests.map((interest, index) => (
                <p className="para">{interest}</p>

              ))
                :
                <>

                  <p className="para">Interest1</p>
                  <p className="para">Interest2</p>
                  <p className="para">Interest3</p>
                  <p className="para">Interest4</p>

                </>}
            </div>
          </div>
        </div>
      </> : ""}

      {/* <div className="language-sec">
        <div className="left">
          <h4 className="sub-heading">Languages</h4>
        </div>
        <div className="right">
          <div className="ist">
            <p className="para">Language1</p>
            <p className="para">Language2</p>
            <p className="para">Language3</p>
            <p className="para">Language4</p>
          </div>
        </div>
      </div> */}
      {sections.reference ? <>
        <div className="reference-sec">
          <div className="left">
            <h4 className="sub-heading">Reference</h4>
          </div>
          <div className="right">
            {userData.reference ? userData.reference.map((reference, index) => (
              <>
                <div className="ist">

                  <p className="para bold-para">{reference.referenceCompany}</p>
                  <p className="para dark-para">{reference.personName}</p>
                  <p className="para">{reference.contact1}</p>
                  <p className="para">{reference.contact2}</p>
                </div>

              </>
            ))
              :
              <>
                <div className="ist">
                  <p className="para bold-para">Company Name</p>
                  <p className="para dark-para">Person Name</p>
                  <p className="para">Contact 1</p>
                  <p className="para">Contact 2</p>
                </div>
              </>}

          </div>
        </div>
      </> : ""}

    </div>
  );
};

export default Template12;
