import React, { useEffect, useState } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import "./template6.scss";
import parse from "html-react-parser";

function Template6({ userData, forDownload, sections, color, setColor, font, setFont, fontSize, setFontSize, lineHeight, setLineHeight, fromPreview }) {

  const [currentScale, setCurrentScale] = useState("100%")

  useEffect(() => {
    if (!fromPreview) {
      setColor(userData.templateColor ? userData.templateColor : userData.templateColor ? userData.templateColor : "#3B3B80");
      setFont(`Poppins`);
    }

  }, []);

  const getColor = () => {
    return `:root{--t6Color: ${color};
    --t6LineHeight : ${lineHeight};
  --t6Font : ${font};
--paraFontSize: ${fontSize * 0.70}px;
--subHeadingFontSize: ${fontSize}px; }`;
  };
  const getPageMarginsF = () => {
    return `@page :first { margin: ${"0px"} ${"0px"} ${"10px"} ${"0px"} !important; }`;
  };
  const getPageMargins = () => {
    return `@page { margin: ${"30px"} ${"0px"} ${"10px"} ${"0px"} !important; }`;
  };
  const getPageMarginst = () => {
    return `table { page-break-inside: avoid; }`;
  };
  // // console.log("ddd", color);
  function handleScaleChange(event) {
    setCurrentScale(`${Math.trunc((event.instance.transformState.scale * 100))}%`);
  }
  return (
    // <TransformWrapper
    //   initialScale={1}
    //   maxScale={2}
    //   minScale={0.5}
    //   initialPositionX={0}
    //   initialPositionY={0}
    //   onTransformed={(e) => handleScaleChange(e)}
    //   wheel={{ disabled: true }}

    // >
    //   {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
    //     <React.Fragment>
    //       <div className="tools" style={{ width: "25%", margin: "10px", display: "flex", gap: "6px" }}>
    //         <div style={{ display: "flex", gap: "10px" }}>
    //           <button onClick={() => zoomOut(0.10)} style={{ backgroundColor: "#180045", padding: "0 8px", borderRadius: "5px" }}>-</button>
    //           <p style={{ margin: "0", padding: "0", color: "black" }}>{currentScale}</p>
    //           <button onClick={() => zoomIn(0.10)} style={{ backgroundColor: "#180045", padding: "0 8px", borderRadius: "5px" }}>+</button>

    //         </div>

    //         <button onClick={() => resetTransform()} style={{ backgroundColor: "#180045", padding: "0 10px", borderRadius: "5px" }}>Reset</button>
    //       </div>
    //       <TransformComponent>
    <section className="template6-sec" ref={forDownload}>
      <style>{getColor()}</style>
      <style>{getPageMargins()}</style>
      <style>{getPageMarginsF()}</style>
      <style>{getPageMarginst()}</style>
      <div className="head back">
        <h1 className="main-heading">{`${userData?.fName || userData?.lName
          ? `${userData?.fName ? userData?.fName : ""} ${userData?.lName ? userData?.lName : ""
          }`
          : "Your Name"
          }`}</h1>
      </div>
      <div className="content">
        <div className="top">
          <p className="para">{`${userData?.city || userData?.country
            ? `${userData?.city ? userData?.city : ""} , ${userData?.country ? userData?.country : ""
            }`
            : "Your Location"
            }`}</p>
          <p className="para">|</p>
          <p className="para">
            {userData?.phone ? userData?.phone : "Your Phone"}
          </p>
          <p className="para">|</p>
          <p className="para">
            {userData?.email ? userData?.email : "Your Email"}
          </p>
          <span className="para">|</span>
        </div>

        <div className="professional-sec">
          <h2 className="sub-heading">PROFESSIONAL SUMMARY</h2>
          <div className="back small-line"></div>
          <div className="back large-line"></div>
          <p className="para">
            {parse(
              userData?.summary
                ? userData?.summary
                : `Lorem ipsum dolor sit amet, consectetur adip iscing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ulla mco
                laboris nisi ut aliquip ex ea commodo consequat.`
            )}
          </p>
        </div>
        {sections.skills || sections.strengths ?
          <div className="skills-sec">
            <h2 className="sub-heading">KEY SKILLS & TECHNOLOGIES</h2>
            <div className="back small-line"></div>
            <div className="back large-line"></div>
            <ul>
              {sections.skills ?
                <li>
                  <p className="para">
                    <span className="para bold-para">Skills : </span>
                    {userData?.skills ? (
                      userData?.skills.map((skill, index) => {
                        if (index == userData.skills.length - 1) {
                          return `${skill}`;
                        } else {
                          return `${skill}, `;
                        }
                      })
                    ) : (
                      <li>Your Skills</li>
                    )}
                  </p>
                </li>
                :

                ""}
              {sections.strengths ?
                <li>
                  <p className="para">
                    <span className="para bold-para">Soft Skills : </span>
                    {userData?.strengths ? (
                      userData?.strengths.map((strength, index) => {
                        if (index == userData.strengths.length - 1) {
                          return `${strength}`;
                        } else {
                          return `${strength}, `;
                        }
                      })
                    ) : (
                      <li>Your Skills</li>
                    )}
                  </p>
                </li>
                :
                ""}

              {/* <li>
            <p className="para ">
              <span className="para bold-para">Software : </span>Power BI,
              Tableau, Excel, PostgreSQL, Scikit-learn, Pandas, Matplotlib,
              Seaborn, Google Big Query, Google Analytics
            </p>
          </li>
          <li>
            <p className="para">
              {" "}
              <span className="para bold-para">
                Programming languages:
              </span>{" "}
              Python, SQL
            </p>
          </li>
          <li>
            <p className="para">
              <span className="para bold-para">Soft Skills : </span>{" "}
              Communication, Collaboration, Presentation, Project Management,
              Team Collaboration, Problem Solving{" "}
            </p>
          </li> */}
            </ul>
          </div>
          :

          ""}

        {/* <div className="achivements-sec">
          <h2 className="sub-heading">ACHIEVEMENTS</h2>
          <div className="back small-line"></div>
          <div className="back large-line"></div>
          <ul>
            <li>
              <p className="para ">
                <span className="para bold-para">Developed</span> and
                implemented a comprehensive analytics dashboard utilizing SQL,
                Power BI, and Tableau, consolidating data from various sources
                into a centralized platform. This initiative resulted in a
                remarkable reduction of 20 hours in manual reporting time and
                provided efficient tracking of key metrics.
              </p>
            </li>
            <li>
              <p className="para ">
                <span className="para bold-para">Led</span> the seamless
                integration of the SQL database, spearheading the development of
                intricate queries for retrieving data from the relational
                database. These queries played a pivotal role in supporting the
                creation of the analytics dashboard, ensuring accurate and
                timely information retrieval.
              </p>
            </li>
            <li>
              <p className="para ">
                <span className="para bold-para">Developed </span> regression
                models used for predicting sales by conducting model training,
                optimization, and validation processes to fine-tune the
                regression model, achieving a mean absolute error reduction of
                15%.
              </p>
            </li>
          </ul>
        </div> */}
        {sections.experience ?
          <div className="experience-sec">
            {/* <table style={{ width: "100%" }}> */}
            <h2 className="sub-heading">PROFESSIONAL EXPERIENCE</h2>
            <div className="back small-line"></div>
            <div className="back large-line"></div>
            {/* </table> */}
            {userData.experience ? (
              userData.experience.map((experience, i) => (
                < >
                  <p
                    className="para "
                    style={{ marginTop: `${i != 0 ? "15px" : ""}` }}
                  >
                    {experience.companyName}
                  </p>
                  <div className="duration">
                    <h3 className="para bold-para">{experience.jobTitle}</h3>
                    <p className="para ">{`${experience?.startMonth ||
                      experience?.startYear ||
                      experience?.endMonth ||
                      experience?.endYear ||
                      experience?.currentlyWorkHere
                      ? `${experience?.startMonth ? experience?.startMonth : ""
                      } ${experience?.startYear ? experience?.startYear : ""
                      } ${experience?.endMonth ||
                        experience?.endYear ||
                        experience?.currentlyWorkHere
                        ? "-"
                        : ""
                      } ${experience?.currentlyWorkHere
                        ? "Present"
                        : `${experience?.endMonth ? experience?.endMonth : ""
                        } ${experience?.endYear ? experience?.endYear : ""
                        }`
                      }`
                      : "Your Company Tenure"
                      }`}</p>
                  </div>
                  {/* <div className="experience-detail">
                <p className="para ">
                  <span className="bold-para">Marketing | Sales Analytics </span>{" "}
                  (Skills: EDA, Time-series, Root-cause Analysis, Pivot table,
                  Dashboard)
                </p>
              </div> */}

                  <p className="para ">
                    {parse(
                      experience?.description
                        ? experience?.description
                        : "Your Job Description"
                    )}
                  </p>
                </>
              ))
            ) : (
              <>
                <p className="para ">Company Name</p>
                <div className="duration">
                  <h3 className="para bold-para">Job Title</h3>
                  <p className="para ">APR 2023 – Jul 2023</p>
                </div>
                {/* <div className="experience-detail">
                <p className="para ">
                  <span className="bold-para">
                    Marketing | Sales Analytics{" "}
                  </span>{" "}
                  (Skills: EDA, Time-series, Root-cause Analysis, Pivot table,
                  Dashboard)
                </p>
              </div> */}
                <ul>
                  <li>
                    <p className="para ">
                      Lorem ipsum dolor sit amet, consectetur adip iscing elit, sed
                      do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                      Ut enim ad minim veniam, quis nostrud exercitation ulla mco
                      laboris nisi ut aliquip ex ea commodo consequat.
                    </p>
                  </li>
                </ul>
              </>
            )}
            {/* <p className="para ">SoofEazi</p>
          <div className="duration">
            <h3 className="para bold-para">DATA ANALYST (PROJECTS)</h3>
            <p className="para ">APR 2023 – Jul 2023</p>
          </div>
          <div className="experience-detail">
            <p className="para ">
              <span className="bold-para">Marketing | Sales Analytics </span>{" "}
              (Skills: EDA, Time-series, Root-cause Analysis, Pivot table,
              Dashboard)
            </p>
          </div>
          <ul>
            <li>
              <p className="para ">
                Conducted various data analysis techniques such as exploratory
                data analysis, root cause analysis, variance analysis,
                regression analysis, and time series analysis on marketing data
                to track the effectiveness of marketing campaigns. I used tools
                like Tableau, Pandas, ARIMA, Power BI, and Python to extract
                insights and recommendations to improve conversion rates{" "}
              </p>
            </li>
          </ul>
          <div className="experience-detail">
            <p className="para ">
              <span className="bold-para">HR Analysis </span> (skills: Analysis,
              Dashboard development, Data storytelling)
            </p>
          </div>
          <ul>
            <li>
              <p className="para ">
                Conducted in-depth analysis of HR data on gender equality,
                diversity, pay rates, and hiring trends and developed an
                analytics dashboard to track key performance indicator (KPI)
                metrics related to employee attrition rate
              </p>
            </li>
          </ul>
          <div className="experience-detail">
            <p className="para ">
              <span className="bold-para">Product Analytics</span> (Skills:
              Product Analytics, Web Analytics, Google Analytics, A/B testing)
            </p>
          </div>
          <ul>
            <li>
              <p className="para ">
                Analyzed website user behavior data using Google Analytics to
                identify key drivers of engagement, acquisition, and retention
                on a new feature on the product landing page. I delivered
                insights and recommendations on A/B testing experimentation
              </p>
            </li>
          </ul>
          <div className="experience-detail">
            <p className="para ">
              <span className="bold-para">SQL Database Development </span>{" "}
              (Skills: SQL commands, data modeling, ER diagram, PostgreSQL,
              Google Big Query)
            </p>
          </div>
          <ul>
            <li>
              <p className="para">
                Designed and implemented a robust data model and relational
                database, effectively integrating customer data from multiple
                sources using SQL DML and DDL commands in PostgreSQL. Optimized
                SQL queries for efficient data extraction and transformation,
                seamlessly integrated into Power BI for streamlined data
                workflow and reporting.
              </p>
            </li>
          </ul>
          <div className="experience-detail">
            <p className="para ">
              <span className="bold-para">Financial Analytics </span>
              (Skills: Power BI, customer segmentation, statistical analysis,
              data transformation)
            </p>
          </div>
          <ul>
            <li>
              <p className="para ">
                Performed a comprehensive analysis of income statements, balance
                sheets, and cash flow statements to identify significant trends.
                Developed and implemented a financial reporting dashboard
                solution that provided key stakeholders with real-time, accurate
                financial data, thereby improving decision-making processes.
              </p>
            </li>
          </ul>
          <div className="experience-detail">
            <p className="para ">
              <span className="para bold-para">Machine Learning</span>
              (Skills: EDA, Feature engineering, Time series analysis,
              predictive analytics, Regression)
            </p>
          </div>
          <ul>
            <li>
              <p className="para ">
                Employed techniques such as exploratory data analysis, feature
                engineering, time series analysis, predictive analytics,
                regression, and the random forest algorithm to develop
                regression and classification models. These models were used for
                forecasting user behavior, click-through rates (CTR), and return
                on advertising spend (ROAS).
              </p>
            </li>
            <li>
              <p className="para ">
                Gathered and preprocessed relevant data, including historical
                sales data, market trends, and external factors, ensuring data
                quality and integrity. Employed feature engineering and
                selection techniques, model training, optimization & validation
                to identify the most impactful variables for the model,
                resulting in a 10% improvement in prediction accuracy.
              </p>
            </li>
          </ul>
          <p className="para ">KPMG Data Analytics on Forage | 2023</p>
          <p className="para bold-para">Data Analytics Intern</p>
          <ul>
            <li>
              <p className="para ">
                Conducted thorough data cleaning and validation processes,
                ensuring data accuracy and integrity for analysis purposes.
              </p>
            </li>
            <li>
              <p className="para ">
                Employed data cleansing techniques and performed rigorous
                quality checks to eliminate inconsistencies and errors,
                resulting in reliable and trustworthy datasets for analysis.
              </p>
            </li>
            <li>
              <p className="para ">
                Conducted in-depth data analysis using Python and SQL to extract
                insights from large datasets, contributing to the development of
                a comprehensive customer segmentation model.
              </p>
            </li>
            <li>
              <p className="para ">
                Demonstrated strong attention to detail by conducting thorough
                quality assurance checks on data inputs and outputs, minimizing
                errors, and improving overall data reliability.
              </p>
            </li>
          </ul> */}
          </div>
          :
          ""}

        {sections.projects ?
          <div className="projects-sec">
            {/* <table style={{ width: "100%" }}> */}
            <h2 className="sub-heading">PROJECTS</h2>
            <div className="back small-line"></div>
            <div className="back large-line"></div>
            {/* </table> */}
            {userData.projects ? (
              userData.projects.map((project, i) => (
                // <table>
                <>
                  <p
                    className="para "
                    style={{ marginTop: `${i != 0 ? "15px" : ""}` }}
                  >
                    {project.projectType}
                  </p>
                  <div className="duration">
                    <h3 className="para bold-para">{project.projectName}</h3>
                    <p className="para ">{`${project?.startMonth ||
                      project?.startYear ||
                      project?.endMonth ||
                      project?.endYear ||
                      project?.currentlyWorkingOnThisProject
                      ? `${project?.startMonth ? project?.startMonth : ""
                      } ${project?.startYear ? project?.startYear : ""
                      } ${project?.endMonth ||
                        project?.endYear ||
                        project?.currentlyWorkingOnThisProject
                        ? "-"
                        : ""
                      } ${project?.currentlyWorkingOnThisProject
                        ? "Present"
                        : `${project?.endMonth ? project?.endMonth : ""
                        } ${project?.endYear ? project?.endYear : ""
                        }`
                      }`
                      : "Your Company Tenure"
                      }`}</p>
                  </div>
                  {
                    parse(
                      project?.description
                        ? project?.description
                        : "Your Job Description"
                    )}
                </>
                // </table>
              ))
            ) : (
              <>
                <div className="duration">
                  <h3 className="para bold-para">Project Name</h3>
                  <p className="para ">APR 2023 – Jul 2023</p>
                </div>
                <ul>
                  <li>
                    <p className="para ">
                      Lorem ipsum dolor sit amet, consectetur adip iscing elit, sed do
                      eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                      enim ad minim veniam, quis nostrud exercitation ulla mco laboris
                      nisi ut aliquip ex ea commodo consequat.
                    </p>
                  </li>
                </ul>
              </>
            )}

            {/* <div className="duration">
            <h3 className="para bold-para">Project Name</h3>
            <p className="para ">APR 2023 – Jul 2023</p>
          </div>
          <ul>
            <li>
              <p className="para ">
                Lorem ipsum dolor sit amet, consectetur adip iscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ulla mco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
            </li>
          </ul> */}

          </div>
          :
          ""}
        {sections.volunteer ?
          <div className="volunteer-sec">
            {/* <table style={{ width: "100%" }}> */}
            <h2 className="sub-heading">VOLUNTEER</h2>
            <div className="back small-line"></div>
            <div className="back large-line"></div>
            {/* </table> */}
            {userData.volunteer ? (
              userData.volunteer.map((volunteer, i) => (
                // <table>
                <>
                  <div className="duration">
                    <h3 className="para bold-para">{`${volunteer.institutionName ? volunteer.institutionName : ""}${volunteer.institutionName && (volunteer.cityName || volunteer.country) ? " | " : ""}${volunteer.cityName ? volunteer.cityName : ""}${volunteer.cityName && volunteer.country ? ", " : ""}${volunteer.country ? volunteer.country : ""}`}</h3>
                    <p className="para ">{`${volunteer?.startMonth ||
                      volunteer?.startYear ||
                      volunteer?.endMonth ||
                      volunteer?.endYear ||
                      volunteer?.currentlyVolunteerHere
                      ? `${volunteer?.startMonth ? volunteer?.startMonth : ""
                      } ${volunteer?.startYear ? volunteer?.startYear : ""
                      } ${volunteer?.endMonth ||
                        volunteer?.endYear ||
                        volunteer?.currentlyVolunteerHere
                        ? "-"
                        : ""
                      } ${volunteer?.currentlyVolunteerHere
                        ? "Present"
                        : `${volunteer?.endMonth ? volunteer?.endMonth : ""
                        } ${volunteer?.endYear ? volunteer?.endYear : ""
                        }`
                      }`
                      : "Your Company Tenure"
                      }`}</p>
                  </div>
                  <p className="para ">
                    {parse(
                      volunteer?.description
                        ? volunteer?.description
                        : "Your Volunteer Description"
                    )}
                  </p>
                  {/* </table> */}
                </>
              ))
            ) : (
              <>
                <div className="duration">
                  <h3 className="para bold-para">Title</h3>
                  <p className="para ">APR 2023 – Jul 2023</p>
                </div>
                <ul>
                  <li>
                    <p className="para ">
                      Lorem ipsum dolor sit amet, consectetur adip iscing elit, sed do
                      eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                      enim ad minim veniam, quis nostrud exercitation ulla mco laboris
                      nisi ut aliquip ex ea commodo consequat.
                    </p>
                  </li>
                </ul>
              </>
            )}


          </div>
          :

          ""}

        {sections.education || sections.certificates ?
          <div className="education-sec">
            {/* <table style={{ width: "100%" }}> */}
            <h2 className="sub-heading">EDUCATION & CERTIFICATIONS </h2>
            <div className="back small-line"></div>
            <div className="back large-line"></div>
            {/* </table> */}
            <ul>
              {sections.education ? userData.education ? userData.education.map((education, index) => (
                // <table>
                <>
                  <li>
                    <div className="inner">
                      <p className="para ">
                        {`${education.schoolName ? education.schoolName : ""} ${education.schoolName && (education.cityName || education.country) ? "-" : ""} ${education.cityName ? education.cityName : ""} ${education.cityName && education.country ? `,` : ""} ${education.country ? education.country : ""} ${education.schoolName || education.cityName || education.country ? "|" : ""} ${education.degreeName ? education.degreeName : ""}`}
                      </p>
                      <p className="para ">{`${education?.startMonth ||
                        education?.startYear ||
                        education?.endMonth ||
                        education?.endYear ||
                        education?.currentlyStudyHere
                        ? `${education?.startMonth
                          ? education?.startMonth
                          : ""
                        } ${education?.startYear
                          ? education?.startYear
                          : ""
                        } ${education?.endMonth ||
                          education?.endYear ||
                          education?.currentlyStudyHere
                          ? "-"
                          : ""
                        } ${education?.currentlyStudyHere
                          ? "Present"
                          : `${education?.endMonth
                            ? education?.endMonth
                            : ""
                          } ${education?.endYear
                            ? education?.endYear
                            : ""
                          }`
                        }`
                        : "Your Degree Tenure"
                        }`}</p>
                    </div>
                  </li>
                  {/* </table> */}
                </>
              )) :
                <li>
                  <div className="inner">
                    <p className="para ">
                      Indiana University- Purdue Global | BSc in Computer Science
                    </p>
                    <p className="para ">In-view</p>
                  </div>
                </li>
                : ""}
              {sections.certificates ? userData.certificates ? userData.certificates.map((certificate, index) => (
                // <table>
                <>
                  <li>
                    <div className="inner">
                      <p className="para ">
                        {`${certificate.certificateName ? certificate.certificateName : ""} ${certificate.certificateName && certificate.authority ? "|" : ""} ${certificate.authority ? certificate.authority : ""}`}
                      </p>
                      <p className="para ">{certificate.certificateDate ? certificate.certificateDate : ""}</p>
                    </div>
                  </li>
                  {/* </table> */}
                </>
              )) :
                <li>
                  <div className="inner">
                    <p className="para ">
                      Google Data Analytics | Coursera
                    </p>
                    <p className="para ">2023-10-01</p>
                  </div>
                </li>
                : ""}
              {/* <li>
              <div className="inner">
                <p className="para ">
                  Indiana University- Purdue Global | BSc in Computer Science
                </p>
                <p className="para ">In-view</p>
              </div>
            </li>
            <li>
              <div className="inner">
                <p className="para ">
                  Data Analytics Certificate, Data Techcon{" "}
                </p>
                <p className="para ">2023</p>
              </div>
            </li> */}
            </ul>
          </div>
          :

          ""}



        {sections.awards ?

          <div className="award-sec">
            {/* <table style={{ width: "100%" }}> */}
            <h2 className="sub-heading">AWARDS</h2>
            <div className="back small-line"></div>
            <div className="back large-line"></div>
            {/* </table> */}
            <ul>
              {userData.awards ? userData.awards.map((award, index) => (
                // <table>
                <>
                  <li>
                    <div className="inner">
                      <p className="para ">{award.awardTitle}</p>
                      <p className="para ">{`${award.awardMonth ? award.awardMonth : ""}${award.awardMonth && award.awardYear ? " - " : ""}${award.awardYear ? award.awardYear : ""}`}</p>
                    </div>
                  </li>
                  {/* </table> */}
                </>
              ))
                :
                <li>
                  <div className="inner">
                    <p className="para ">Award Name</p>
                    <p className="para ">Date</p>
                  </div>
                </li>
              }
              {/* <li>
              <div className="inner">
                <p className="para ">Award Name</p>
                <p className="para ">Date</p>
              </div>
            </li> */}
            </ul>
          </div>
          :
          ""}

        {sections.publications ?
          <div className="publication-sec">
            {/* <table style={{ width: "100%" }}> */}
            <h2 className="sub-heading">PUBLICATIONS</h2>
            <div className="back small-line"></div>
            <div className="back large-line"></div>
            {/* </table> */}
            {userData.publications ? (
              userData.publications.map((publication, i) => (
                // <table>
                <>
                  <p className="para ">{publication.publisher ? publication.publisher : ""}</p>
                  <div className="duration">
                    <h3 className="para bold-para">{publication.publicationTitle ? publication.publicationTitle : ""}</h3>
                    <p className="para ">{`${publication.publicationMonth ? publication.publicationMonth : ""}${publication.publicationMonth && publication.publicationYear ? ", " : ""}${publication.publicationYear ? publication.publicationYear : ""}`}</p>
                  </div>
                  <p className="para">
                    {parse(
                      publication?.description
                        ? publication?.description
                        : "Your Publication Description"
                    )}
                  </p>
                  {/* </table> */}
                </>
              ))
            ) : (
              <>
                <p className="para ">Title</p>
                <div className="duration">
                  <h3 className="para bold-para">Book Name</h3>
                  <p className="para ">Date</p>
                </div>
                <ul>
                  <li>
                    <p className="para ">
                      Lorem ipsum dolor sit amet, consectetur adip iscing elit, sed do
                      eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                      enim ad minim veniam, quis nostrud exercitation ulla mco laboris
                      nisi ut aliquip ex ea commodo consequat.
                    </p>
                  </li>
                </ul>
              </>
            )}


          </div>
          :
          ""}

        {sections.interests ?
          <div className="interest-sec">
            {/* <table style={{ width: "100%" }}> */}
            <h2 className="sub-heading">INTERESTS</h2>
            <div className="back small-line"></div>
            <div className="back large-line"></div>
            {/* </table> */}
            <ul>

              {userData.interests ? userData.interests.map((interest, index) => (
                // <table>
                <li>
                  <p className="para">{interest}</p>
                </li>
                // </table>
              )) :
                <li>
                  <p className="para">Interest1</p>
                </li>
              }

            </ul>
          </div>
          :
          ""}
        {/* {sections.languages ?
                  <div className="language-sec">
                    <table style={{ width: "100%" }}>
                      <h2 className="sub-heading">LANGUAGES</h2>
                      <div className="back small-line"></div>
                      <div className="back large-line"></div>
                    </table>
                    <ul>
                      {userData.languages ? userData.languages.map((language, index) => (
                        <table>
                          <li>
                            <p className="para">{language}</p>
                          </li>
                        </table>
                      )) :
                        <li>
                          <p className="para">Language1</p>
                        </li>
                      }

                    </ul>
                  </div>
                  :
                  ""} */}

        {sections.reference ?
          <div className="reference-sec">
            {/* <table style={{ width: "100%" }}> */}
            <h2 className="sub-heading">REFERENCE</h2>
            <div className="back small-line"></div>
            <div className="back large-line"></div>
            {/* </table> */}
            {userData.reference ? userData.reference.map((reference, index) => (
              // <table>
              <>
                <div className="duration">
                  <h3 className="para bold-para">{reference.referenceCompany}</h3>
                  <p className="para ">{reference.contact1}</p>
                </div>
                <div className="duration">
                  <h3 className="para ">{reference.personName}</h3>
                  <p className="para ">{reference.contact2}</p>
                </div>
                {parse(
                  reference?.description
                    ? reference?.description
                    : "Your Reference Description"
                )}
                {/* </table> */}
              </>
            ))
              :
              <>
                <div className="duration">
                  <h3 className="para bold-para">Company Name</h3>
                  <p className="para ">Contact1</p>
                </div>
                <div className="duration">
                  <h3 className="para ">Person Name</h3>
                  <p className="para ">Contact2</p>
                </div>
                <ul>
                  <li>
                    <p className="para ">
                      Lorem ipsum dolor sit amet, consectetur adip iscing elit, sed do
                      eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                      enim ad minim veniam, quis nostrud exercitation ulla mco laboris
                      nisi ut aliquip ex ea commodo consequat.
                    </p>
                  </li>
                </ul></>
            }

          </div>
          :

          ""}

      </div>
    </section >
    //       </TransformComponent>
    //     </React.Fragment>
    //   )}
    // </TransformWrapper>

  );
}

export default Template6;
