import React, { useEffect } from "react";
import "./template17.scss";
import parse from "html-react-parser"
import girl from "../../assets/images/girl.jpg"

function Template17({ userData, forDownload, sections, color, setColor, font, setFont, fontSize, setFontSize, lineHeight, setLineHeight, fromPreview }) {

  useEffect(() => {

    if (!fromPreview) {

      setColor(userData.templateColor ? userData.templateColor : "rgb(0, 210, 230)")
    }
  }, [])

  const getColor = () => {
    return `:root{--t17Color: ${color};
    --t17LineHeight : ${lineHeight};
  --t17Font : ${font};
--paraFontSize: ${fontSize * 0.70}px;
--subHeadingFontSize: ${fontSize}px; }`;
  };
  const getPageMarginsF = () => {
    return `@page :first { margin: ${"0px"} ${"0px"} ${"10px"} ${"0px"} !important; }`;
  };
  const getPageMargins = () => {
    return `@page { margin: ${"30px"} ${"0px"} ${"10px"} ${"0px"} !important; }`;
  };
  const getPageMarginst = () => {
    return `table { page-break-inside: avoid; }`;
  };

  return (
    <section className="templat17-sec" ref={forDownload}>
      <style>{getColor()}</style>
      <style>{getPageMargins()}</style>
      <style>{getPageMarginsF()}</style>
      <style>{getPageMarginst()}</style>
      <div className="header">
        <h2 className="heading">{`${userData?.fName || userData?.lName
          ? `${userData?.fName ? userData?.fName : ""} ${userData?.lName ? userData?.lName : ""
          }`
          : "Your Name"
          }`}</h2>
        <h3 className="status">{userData?.role ? userData?.role : "Your Role"}</h3>
      </div>
      <div className="temp-body">
        <div className="left">
          <img src={`${userData?.cvPictureObj ? URL.createObjectURL(userData?.cvPictureObj) : userData?.cvPicture ? userData?.cvPicture : girl}`} alt="..." />
          <div className="contact-sec">
            <h4 className="sub-heading">Contact</h4>
            <div className="ist">
              <p className="para">{userData?.email ? userData?.email : "Your Email"}</p>
              <p className="para">{userData?.phone ? userData?.phone : "Your Phone"}</p>
              <a href="#">{userData?.web ? userData?.web : "Your Linkedin"}</a>
              <p className="para">{`${userData?.city || userData?.country
                ? `${userData?.city ? userData?.city : ""} , ${userData?.country ? userData?.country : ""
                }`
                : "Your Location"
                }`}</p>
            </div>
          </div>
          {/* {sections.skills ? <>

          </> : ""} */}
          {sections.strengths || sections.skills ? <>
            <div className="skill-sec">
              <h4 className="sub-heading">Skills</h4>
              <div className="ist">
                {sections.skills && userData.skills ?
                  userData.skills.map((skill, index) => (
                    <>
                      <p className="para">{skill}{userData.skills.length - 1 == index ? "" : ", "}</p>

                    </>
                  ))
                  :
                  <>

                  </>}
                {sections.strengths && userData.strengths ?
                  userData.strengths.map((strength, index) => (
                    <>
                      <p className="para">{strength}{userData.strengths.length - 1 == index ? "" : ", "}</p>

                    </>
                  ))
                  :
                  <>
                    {/* <p className="para">Skill1</p>
                    <p className="para">Skill2</p>
                    <p className="para">Skill3</p>
                    <p className="para">Skill4</p> */}
                  </>}
              </div>
            </div>
          </> : ""}

          {sections.education ? <>
            <div className="education-sec">
              <h4 className="sub-heading">Education</h4>
              {userData.education ? userData.education.map((education, index) => (
                <>
                  <div className="ist">
                    <p className="para bold-para">{education.degreeName}</p>
                    <p className="para dark-para">{`${education.schoolName}`}</p>
                    <p className="para light-para">{`${education.cityName}`}{`${education.cityName && education.country ? ", " : ""}`}{`${education.country}`}</p>
                    <p className="para">{`${education?.startMonth ||
                      education?.startYear ||
                      education?.endMonth ||
                      education?.endYear ||
                      education?.currentlyStudyHere
                      ? `${education?.startMonth
                        ? education?.startMonth
                        : ""
                      } ${education?.startYear
                        ? education?.startYear
                        : ""
                      } ${education?.endMonth ||
                        education?.endYear ||
                        education?.currentlyStudyHere
                        ? "-"
                        : ""
                      } ${education?.currentlyStudyHere
                        ? "Present"
                        : `${education?.endMonth
                          ? education?.endMonth
                          : ""
                        } ${education?.endYear
                          ? education?.endYear
                          : ""
                        }`
                      }`
                      : "Your Degree Tenure"
                      }`}</p>
                  </div>
                </>
              )) :
                <>
                  <div className="ist">
                    <p className="para bold-para">Degree</p>
                    <p className="para dark-para">Institute</p>
                    <p className="para light-para">City, Country</p>
                    <p className="para">Date</p>
                  </div>
                </>
              }

            </div>
          </> : ""}
          {sections.certificates ? <>
            <div className="certificate-sec">
              <h4 className="sub-heading">Certificates</h4>
              {userData.certificates ? (
                userData.certificates.map((certificate, i) => (
                  <>
                    <div className="ist">
                      <p className="para bold-para">{certificate.certificateName}</p>
                      <p className="para dark-para">{certificate.authority}</p>
                      {/* <p className="para light-para">City,Country</p> */}
                      <p className="para">{`${certificate.certificateDate ? certificate.certificateDate : ""}`}</p>
                    </div>

                  </>
                ))
              ) : (
                <>
                  <div className="ist">
                    <p className="para bold-para">Certificate Name</p>
                    <p className="para dark-para">Institute</p>
                    <p className="para light-para">City,Country</p>
                    <p className="para">Date</p>
                  </div>
                </>
              )}

            </div>
          </> : ""}
          {sections.awards ? <>
            <div className="award-sec">
              <h4 className="sub-heading">Awards</h4>
              {userData.awards ? userData.awards.map((award, index) => (
                <div className="ist">
                  <p className="para bold-para">{award.awardTitle}</p>
                  <p className="para">{`${award.awardMonth ? award.awardMonth : ""}${award.awardMonth && award.awardYear ? ", " : ""}${award.awardYear ? award.awardYear : ""}`}</p>
                </div>
              ))
                :
                <>
                  <div className="ist">
                    <p className="para bold-para">Award</p>
                    <p className="para">date</p>
                  </div>
                </>
              }


            </div>
          </> : ""}
          {sections.interests ? <>
            <div className="interest-sec">
              <h4 className="sub-heading">Interests</h4>
              <div className="ist">
                {userData.interests ? userData.interests.map((interest, index) => (
                  <p className="para">{interest}{userData.interests.length - 1 == index ? "" : ", "}</p>


                ))
                  :
                  <>

                    <p className="para">Skill 1</p>
                    <p className="para">Skill 1</p>
                    <p className="para">Skill 1</p>

                  </>}
              </div>
            </div>
          </> : ""}

        </div>
        <div className="right">
          <div className="about-sec">
            <h4 className="sub-heading">About Me</h4>
            <p className="para">
              {parse(
                userData?.summary
                  ? userData?.summary
                  : `Lorem ipsum dolor sit amet, consectetur adip iscing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ulla mco
                laboris nisi ut aliquip ex ea commodo consequat.`
              )}
            </p>
          </div>
          {sections.experience ? <>
            <div className="experience-sec">
              <h4 className="sub-heading">Experience</h4>
              {userData.experience ? (
                userData.experience.map((experience, i) => (
                  <>
                    <div className="ist">
                      <p className="para bold-para">{experience.jobTitle}</p>
                      <p className="para dark-para">{experience.companyName}</p>
                      <p className="para light-para">{`${experience?.startMonth ||
                        experience?.startYear ||
                        experience?.endMonth ||
                        experience?.endYear ||
                        experience?.currentlyWorkHere
                        ? `${experience?.startMonth ? experience?.startMonth : ""
                        } ${experience?.startYear ? experience?.startYear : ""
                        } ${experience?.endMonth ||
                          experience?.endYear ||
                          experience?.currentlyWorkHere
                          ? "-"
                          : ""
                        } ${experience?.currentlyWorkHere
                          ? "Present"
                          : `${experience?.endMonth ? experience?.endMonth : ""
                          } ${experience?.endYear ? experience?.endYear : ""
                          }`
                        }`
                        : "Your Company Tenure"
                        }`}</p>
                      <p className="para">{parse(
                        experience?.description
                          ? experience?.description
                          : "Your Job Description"
                      )}</p>
                    </div>
                  </>
                ))
              ) : (
                <>
                  <div className="ist">
                    <p className="para bold-para">Developer</p>
                    <p className="para dark-para">Company Name</p>
                    <p className="para light-para">Date</p>
                    <p className="para">Details</p>
                  </div>
                </>
              )}

            </div>
          </> : ""}

          {sections.projects ? <>
            <div className="project-sec">
              <h4 className="sub-heading">Projects</h4>
              {userData.projects ? (
                userData.projects.map((project, i) => (
                  <>
                    <div className="ist">
                      <p className="para bold-para">{project.projectType}</p>
                      <p className="para dark-para">{project.projectName}</p>
                      <p className="para light-para">{`${project?.startMonth ||
                        project?.startYear ||
                        project?.endMonth ||
                        project?.endYear ||
                        project?.currentlyWorkingOnThisProject
                        ? `${project?.startMonth ? project?.startMonth : ""
                        } ${project?.startYear ? project?.startYear : ""
                        } ${project?.endMonth ||
                          project?.endYear ||
                          project?.currentlyWorkingOnThisProject
                          ? "-"
                          : ""
                        } ${project?.currentlyWorkingOnThisProject
                          ? "Present"
                          : `${project?.endMonth ? project?.endMonth : ""
                          } ${project?.endYear ? project?.endYear : ""
                          }`
                        }`
                        : "Your Company Tenure"
                        }`}</p>
                      <p className="para">{parse(
                        project?.description
                          ? project?.description
                          : "Your Job Description"
                      )}</p>
                    </div>
                  </>
                ))
              ) : (
                <>
                  <div className="ist">
                    <p className="para bold-para">Company Name</p>
                    <p className="para dark-para">Project Name</p>
                    <p className="para light-para">Date</p>
                    <p className="para">Details</p>
                  </div>
                </>
              )}

            </div>
          </> : ""}

          {sections.volunteer ? <>
            <div className="volunteer-sec">
              <h4 className="sub-heading">Volunteers</h4>
              {userData.volunteer ? (
                userData.volunteer.map((volunteer, i) => (
                  <>
                    <div className="ist">
                      <p className="para dark-para">{volunteer.institutionName}</p>
                      <p className="para light-para">{`${volunteer?.startMonth ||
                        volunteer?.startYear ||
                        volunteer?.endMonth ||
                        volunteer?.endYear ||
                        volunteer?.currentlyVolunteerHere
                        ? `${volunteer?.startMonth ? volunteer?.startMonth : ""
                        } ${volunteer?.startYear ? volunteer?.startYear : ""
                        } ${volunteer?.endMonth ||
                          volunteer?.endYear ||
                          volunteer?.currentlyVolunteerHere
                          ? "-"
                          : ""
                        } ${volunteer?.currentlyVolunteerHere
                          ? "Present"
                          : `${volunteer?.endMonth ? volunteer?.endMonth : ""
                          } ${volunteer?.endYear ? volunteer?.endYear : ""
                          }`
                        }`
                        : "Your Company Tenure"
                        }`}</p>
                      <p className="para">{parse(
                        volunteer?.description
                          ? volunteer?.description
                          : "Your Volunteer Description"
                      )}</p>
                    </div>
                  </>
                ))
              ) : (
                <>
                  <div className="ist">
                    <p className="para dark-para">Project Name</p>
                    <p className="para light-para">Date</p>
                    <p className="para">Details</p>
                  </div>
                </>
              )}

            </div>
          </> : ""}

          {sections.publications ? <>
            <div className="publication-sec">
              <h4 className="sub-heading">Publications</h4>
              {userData.publications ? (
                userData.publications.map((publication, i) => (
                  <>
                    <div className="ist">
                      <p className="para bold-para">{publication.publisher}</p>
                      <p className="para dark-para">{publication.publicationTitle}</p>
                      <p className="para light-para">{`${publication.publicationMonth ? publication.publicationMonth : ""}${publication.publicationMonth && publication.publicationYear ? ", " : ""}${publication.publicationYear ? publication.publicationYear : ""}`}</p>
                      <p className="para">{parse(
                        publication?.description
                          ? publication?.description
                          : "Your Publication Description"
                      )}</p>
                    </div>

                  </>
                ))
              ) : (
                <>
                  <div className="ist">
                    <p className="para bold-para">Publisher Name</p>
                    <p className="para dark-para">Book Name</p>
                    <p className="para light-para">Date</p>
                    <p className="para">Details</p>
                  </div>
                </>
              )}

            </div>
          </> : ""}

          {sections.reference ? <>
            <div className="reference-sec">
              <h4 className="sub-heading">Reference</h4>
              {userData.reference ? userData.reference.map((reference, index) => (
                <>
                  <div className="ist">
                    <p className="para bold-para">{reference.referenceCompany}</p>
                    <p className="para dark-para">{reference.personName}</p>
                    <p className="para light-para">{reference.contact1}</p>
                    <p className="para light-para">{reference.contact2}</p>
                    <p className="para">{parse(
                      reference?.description
                        ? reference?.description
                        : "Your Reference Description"
                    )}</p>
                  </div>

                </>
              ))
                :
                <>
                  <div className="ist">
                    <p className="para bold-para">Company Name</p>
                    <p className="para dark-para">Person Name</p>
                    <p className="para light-para">Contact1</p>
                    <p className="para light-para">Contact2</p>
                    <p className="para">Details</p>
                  </div>
                </>}

            </div>
          </> : ""}

        </div>
      </div>
    </section>
  );
}

export default Template17;
