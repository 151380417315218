import React from "react";
import "../../style/main.scss";
const Achievement = () => {
  return (
    <div>
      <div className="achievement">
        <div className="left">
          <img src="" alt="" />
          <div className="premium"></div>
        </div>
      </div>
    </div>
  );
};

export default Achievement;
