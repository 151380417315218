import React, { useState } from "react";
import "./../../style/main.scss";

const Timeline = () => {
  const [activeTab, setActiveTab] = useState("");

  const handleTab = (tag) => {
    setActiveTab(tag);
  };
  return (
    <>
      <div className="how-work  ">
        <div className="working-tabs mt-5 pt-5 ">
          <div className="tab-btn">
            <button
              className={`${activeTab === "select" ? "tab-active" : " "}`}
              style={{ cursor: "default" }}
            // onClick={() => handleTab("select")}
            >
              01
            </button>
            {/* <p>Selecting an AI tool</p> */}
            <p>Create a resume</p>
          </div>

          <div className="tab-btn">
            <button
              className={`${activeTab === "keywords" ? "tab-active" : " "}`}
              style={{ cursor: "default" }}
            // onClick={() => handleTab("keywords")}
            >
              02
            </button>
            {/* <p>Write your keywords</p> */}
            <p>Select template</p>
          </div>

          <div className="tab-btn">
            <button
              className={`${activeTab === "cvMaker" ? "tab-active" : "  "}`}
              style={{ cursor: "default" }}
            // onClick={() => handleTab("cvMaker")}
            >
              03
            </button>
            {/* <p>A recruiter-tested CV maker tool</p> */}
            <p>Edit & Design</p>
          </div>

          <div className="tab-btn">
            <button
              className={`${activeTab === "interview" ? "tab-active" : " "}`}
              style={{ cursor: "default" }}
            // onClick={() => handleTab("interview")}
            >
              04
            </button>
            <p>Save & download</p>
          </div>
          {/* <div className="border-section"></div> */}
        </div>

        {/* Timeline code hidden */}
      </div>
    </>
  );
};
const timelineData = [
  {
    title: "Button 1 Data",
    content: "This is the content for Button 1.",
  },
  {
    title: "Button 2 Data",
    content: "This is the content for Button 2.",
  },
  {
    title: "Button 3 Data",
    content: "This is the content for Button 3.",
  },
  {
    title: "Button 4 Data",
    content: "This is the content for Button 4.",
  },
];
export default Timeline;
