import { Route, Routes } from "react-router-dom";
import { useState, useEffect } from "react";
import Home from "./pages/home";
import Navbar from "./components/shared/navbar";
import Templates from "./components/template/templates";
import Login from "./components/account/login";
import Register from "./components/account/register";
import Message from "./components/message";
import PersonalInformation from "./components/dashboard/personalInformation";
import Strength from "./components/dashboard/strength";
import Skill from "./components/dashboard/skill";
import WorkExperience from "./components/dashboard/workExperience";
import SkillCategory from "./components/dashboard/skillCategory";
import Hobbies from "./components/dashboard/hobbies";
import Achievement from "./components/dashboard/achievement";
import Dashboard from "./components/dashboard/Dashbaord";
import FillIn from "./components/dashboard/fillIn";
import Design from "./components/dashboard/design";
import ToolBox from "./components/dashboard/toolBox";
import ProofReading from "./components/dashboard/proofReading";
import Share from "./components/dashboard/share";
import Money from "./components/home/money";
import ForgetPassword from "./components/account/ForgetPassword";
import Welcome from "./components/welcome/Welcome";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import { LinkedInCallback } from 'react-linkedin-login-oauth2';

import { auth, firestoredb } from "./config";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import Fix from "./components/dashboard/fix";
import Profile from "./components/home/profile";
import Resumehistory from "./components/home/resumehistory";
import Contactus from "./components/home/contactus";
import Cancel from "./components/cancel/Cancel";
import Success from "./components/success/Success";
import Privacy from "./components/privacy/Privacy";
import Terms from "./components/terms/Terms";
import Return from "./components/return/Return";
import Coverletter from "./components/dashboard/coverletter";
import Notfound from "./components/home/notfound";

function App() {
  const [userData, setUserData] = useState({});
  const [generatedFromCv, setGeneratedFromCv] = useState(false);
  const [userId, setUserId] = useState("");
  const [isFetched, setIsFetched] = useState(false);
  const [newSignInWithGoogle, setNewSignInWithGoogle] = useState(false);
  const [userName, setUserName] = useState("User");
  const [first, setFirst] = useState(true);
  const [isPaidUser, setIsPaidUser] = useState(false);
  const [currentSubdomain, setCurrentSubdomain] = useState("")
  const user = auth.currentUser;

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (auth.currentUser != null) {
        setUserId(auth.currentUser.uid);
      }
    });
  }, []);

  const getDataFromDb = async () => {
    if (user) {
      // // console.log("Fetching Data from DB");
      const docRef = doc(firestoredb, "users", user.uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        // // console.log("Document data gs:", docSnap.data());
        let currentDate = new Date();
        let isSubcriptionExpired = false;
        if (docSnap.data().accountType == "pro") {
          setIsPaidUser(true);


          if (docSnap.data().subsciptionExpiry) {
            // console.log("At expiry check if")
            if (Date(docSnap.data().subsciptionExpiry) < currentDate) {
              await updateDoc(docRef, {
                accountType: "free",
                stripeSessionId: "",
                subscriptionDate: ""

              })
              isSubcriptionExpired = true;
              // console.log("Reseting subscription");
              // console.log("Expiry", Date(docSnap.data().subsciptionExpiry));
              // console.log("currentDate", currentDate);

              setIsPaidUser(false);
            }
          }
        }
        if (docSnap.data().cvData) {
          setUserData({ ...docSnap.data().cvData, skills: docSnap.data().cvData.skills ? docSnap.data().cvData.skills : [], strengths: docSnap.data().cvData.strengths ? docSnap.data().cvData.strengths : [], accountType: isSubcriptionExpired ? "free" : docSnap.data().accountType ? docSnap.data().accountType : "free", subscriptionDate: isSubcriptionExpired ? "" : docSnap.data().subscriptionDate ? docSnap.data().subscriptionDate : "", stripeSessionId: isSubcriptionExpired ? "" : docSnap.data().stripeSessionId ? docSnap.data().stripeSessionId : "" })
          setIsFetched(true);
          setUserName(
            docSnap.data().firstName ? docSnap.data().firstName : "User"
          );
        }
      } else {
        // docSnap.data() will be undefined in this case
        // // console.log("No such document!");
      }
      // setFirst(true);

    }
  };

  useEffect(() => {
    getDataFromDb();
  }, [userId]);

  useEffect(() => {
    // // console.log(window.location.origin);

    //     let postData={
    // grant_type:"authorization_code",
    // code:"AQTBLckKfYD7yWw5BIKuQ9XZsSUB4UDOWZPYyG_6GwJIMLIwmK2r7D41g02slW0HX8IPVr0_AWtlFaLbM8lr_Y4EUzfpPh2muu_QRKPMcn0YvIbWG_srcHN0F88Y315mJLcq9sP4TpqDn-j2EQzTTpsULAz3W3-S1hAIXjUXTQfLGIe5G4IHfx64iWeFvNaPIFyQdGAjs9-1zWp3DXs",
    // client_id:"77j0dryzgonq4c",
    // client_secret:"LbYdx1b89sKReE2P",
    // redirect_uri:{your_callback_url},
    //     }
    // axios.post("https://api.linkedin.com/v2/me",postData,{'Content-Type': application/x-www-form-urlencoded})
  });


  const getRelavantRoute = () => {
    // console.log(window.location.hostname);
    const locationParts = window.location.hostname.split(".");
    let sliceTill = -2;

    // for localhost
    // console.log("lp", locationParts);
    // console.log("test", locationParts.slice(-1)[0] == "localhost");
    const isLocalHost = locationParts.slice(-1)[0] == "localhost";
    if (isLocalHost) {
      sliceTill = -1;
    }

    const subdomain = locationParts.slice(0, sliceTill).join("");
    // console.log(subdomain);
    setCurrentSubdomain(subdomain)

  }
  // const currrentSubdomain = getRelavantRoute()

  useEffect(() => {
    "heree"
    getRelavantRoute()
  }, [])

  return (
    <div className="App">
      <ToastContainer />
      <Navbar />
      <Routes>
        {currentSubdomain == "app" ?
          <>
            <Route
              path="/dashboard"
              element={
                <Dashboard
                  userData={userData}
                  setUserData={setUserData}
                  generatedFromCv={generatedFromCv}
                  isFetched={isFetched}
                  setIsFetched={setIsFetched}
                  first={first}
                  setFirst={setFirst}
                  isPaidUser={isPaidUser}
                />
              }
            />

            <Route
              path="/"
              element={
                <Welcome
                  userData={userData}
                  setUserData={setUserData}
                  setGeneratedFromCv={setGeneratedFromCv}
                  userName={userName}
                  isPaidUser={isPaidUser}
                />
              }
            />

            <Route path="/forgetpassword" element={<ForgetPassword />} />
            <Route
              path="/login"
              element={
                <Login
                  newSignInWithGoogle={newSignInWithGoogle}
                  setNewSignInWithGoogle={setNewSignInWithGoogle}
                />
              }
            />
            <Route path="/register" element={<Register />} />
            <Route
              path="/welcome"
              element={
                <Welcome
                  userData={userData}
                  setUserData={setUserData}
                  setGeneratedFromCv={setGeneratedFromCv}
                  userName={userName}
                  isPaidUser={isPaidUser}
                />
              }
            />
            <Route path="/fix" element={<Fix setUserData={setUserData}
              setGeneratedFromCv={setGeneratedFromCv} isPaidUser={isPaidUser} />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/history" element={<Resumehistory userData={userData} setUserData={setUserData} />} />
            <Route path="/coverletter" element={<Coverletter getDataFromDb={getDataFromDb} isPaidUser={isPaidUser}></Coverletter>} />
            <Route path="/success" element={<Success getDataFromDb={getDataFromDb}></Success>} />
            <Route path="/cancel" element={<Cancel></Cancel>} />
            <Route path="/privacy" element={<Privacy></Privacy>} />
            <Route path="/terms" element={<Terms></Terms>} />
            <Route path="/refund" element={<Return></Return>} />
            <Route path="/money" element={<Money isPaidUser={isPaidUser} />}></Route>
            <Route path="/templates" element={<Templates setUserData={setUserData} />} />
            <Route path="/contactus" element={<Contactus userData={userData} setUserData={setUserData} />} />
            <Route path="*" element={<Notfound></Notfound>} />


          </>

          :
          <>
            <Route
              path="/"
              element={
                <Home
                  userData={userData}
                  setUserData={setUserData}
                  setGeneratedFromCv={setGeneratedFromCv}
                />
              }
            />
            <Route path="/money" element={<Money isPaidUser={isPaidUser} />}></Route>
            <Route path="/templates" element={<Templates setUserData={setUserData} />} />

            <Route path="/message" element={<Message />} />
            <Route path="/info" element={<PersonalInformation />} />
            <Route path="/strength" element={<Strength />} />
            <Route path="/skill" element={<Skill />} />
            <Route path="/workExperience" element={<WorkExperience />} />
            <Route path="/skillCategory" element={<SkillCategory />} />
            <Route path="/hobbies" element={<Hobbies />} />
            <Route path="/achievement" element={<Achievement />} />


            <Route path="/fillIn" element={<FillIn />} />
            <Route path="/design" element={<Design />} />
            <Route path="/toolBox" element={<ToolBox />} />
            <Route path="/proofReading" element={<ProofReading />} />
            <Route path="/share" element={<Share />} />

            <Route path="/contactus" element={<Contactus userData={userData} setUserData={setUserData} />} />

            {/* <Route path="/signin-linkedin" element={<LinkedInCallback />} /> */}
            {/* <Route path="/loginwithlink" element={<Share />} /> */}
            <Route path="/success" element={<Success getDataFromDb={getDataFromDb}></Success>} />
            <Route path="/cancel" element={<Cancel></Cancel>} />
            <Route path="/privacy" element={<Privacy></Privacy>} />
            <Route path="/terms" element={<Terms></Terms>} />
            <Route path="/refund" element={<Return></Return>} />
            <Route path="*" element={<Notfound></Notfound>} />

          </>
        }

      </Routes>
    </div>
  );
}

export default App;
