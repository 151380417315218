import React from "react";
import "../../src/style/main.scss";
import Button from "../../src/components/shared/button";
import AI from "../../src/assets/icons/message.svg";
import shade from "../../src/assets/images/shades/shade2.png";

const Message = () => {
  const message = () => {};
  return (
    <div>
      <div className="message d-flex flex-column v-center h-center">
        <div className="shade">
          <img src={shade} alt="" />
        </div>
        <div className="input-box">
          <input type="text" name="" id="" placeholder="Write your message" />
          <div className="message-btn pointer">
            <Button
              text="Start with AI"
              width="212px"
              height="54px"
              className="btn-prime-2 btn-primary text-white rounded btn-startAI "
              imageSrc={AI}
            />
          </div>
        </div>
        {/* <div className="message-btn">
          <Button
            text="Start with AI"
            width="212px"
            height="54px"
            className="btn-prime-2 btn-primary text-white rounded "
            imageSrc={AI}
          />
        </div> */}
        <div className="bottom mt-4">
          <p className=" fw-light text-center">
            or Upload Your Resume For{" "}
            <span className="fw-semibold"> Optimization </span>
            <br />
            or
            <span className="fw-semibold"> Upload </span>
            Upload from{" "}
            <a
              href="https://www.linkedin.com/home"
              className="text-white"
              target="_blank"
            >
              <b className="text-decoration-underline">LinkedIn </b>
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Message;
