import React, { useEffect } from "react";
import "./template18.scss";
import { Icon } from "@iconify/react";
import parse from "html-react-parser"

function Template18({ userData, forDownload, sections, color, setColor, font, setFont, fontSize, setFontSize, lineHeight, setLineHeight, fromPreview }) {
  useEffect(() => {

    if (!fromPreview) {

      setColor(userData.templateColor ? userData.templateColor : "#DCB4A3")
    }
  }, [])
  const getColor = () => {
    return `:root{--t18Color: ${color};
    --t18LineHeight : ${lineHeight};
  --t18Font : ${font};
--paraFontSize: ${fontSize * 0.70}px;
--subHeadingFontSize: ${fontSize}px; }`;
  };
  const getPageMarginsF = () => {
    return `@page :first { margin: ${"0px"} ${"0px"} ${"10px"} ${"0px"} !important; }`;
  };
  const getPageMargins = () => {
    return `@page { margin: ${"30px"} ${"0px"} ${"10px"} ${"0px"} !important; }`;
  };
  const getPageMarginst = () => {
    return `table { page-break-inside: avoid; }`;
  };

  return (
    <section className="template18-sec" ref={forDownload}>
      <style>{getColor()}</style>
      <style>{getPageMargins()}</style>
      <style>{getPageMarginsF()}</style>
      <style>{getPageMarginst()}</style>
      <div className="top">
        <h2 className="heading">{`${userData?.fName || userData?.lName
          ? `${userData?.fName ? userData?.fName : ""} ${userData?.lName ? userData?.lName : ""
          }`
          : "Your Name"
          }`}</h2>
        <h3 className="status">{userData?.role ? userData?.role : "Your Role"}</h3>
        <div>
          <p className="para">Address</p>
          <Icon icon="bxs:left-arrow" color="black" width="18" height="18" />
          <p className="para">{userData?.phone ? userData?.phone : "Your Phone"}</p>
          <Icon icon="bxs:right-arrow" color="black" width="18" height="18" />
          <Icon icon="bxs:left-arrow" color="black" width="18" height="18" />
          <p className="para">{userData?.email ? userData?.email : "Your Email"}</p>
          <Icon icon="bxs:right-arrow" color="black" width="18" height="18" />
          <a className="para" href="#">
            {userData?.web ? userData?.web : "Your Linkedin"}
          </a>
        </div>
      </div>
      <div className="profile-sec">
        <div className="head">
          <Icon icon="ep:d-arrow-right" color="black" width="20" height="20" />
        </div>
        <div className="content">
          <h4 className="sub-heading">Profile</h4>
          <p className="para">
            {parse(
              userData?.summary
                ? userData?.summary
                : `Lorem ipsum dolor sit amet, consectetur adip iscing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ulla mco
                laboris nisi ut aliquip ex ea commodo consequat.`
            )}
          </p>
        </div>
      </div>
      {sections.education ? <>
        <div className="education-sec">
          <div className="head">
            <Icon icon="ep:d-arrow-right" color="black" width="20" height="20" />
          </div>
          <div className="content">
            <h4 className="sub-heading">Education</h4>
            {userData.education ? userData.education.map((education, index) => (
              <>
                <div className="ist">
                  <p className="para bold-para">{`${education.schoolName}`}, {`${education.cityName}`}, {`${education.country}`}</p>
                  <p className="para ">{education.degreeName}, {`${education?.startMonth ||
                    education?.startYear ||
                    education?.endMonth ||
                    education?.endYear ||
                    education?.currentlyStudyHere
                    ? `${education?.startMonth
                      ? education?.startMonth
                      : ""
                    } ${education?.startYear
                      ? education?.startYear
                      : ""
                    } ${education?.endMonth ||
                      education?.endYear ||
                      education?.currentlyStudyHere
                      ? "-"
                      : ""
                    } ${education?.currentlyStudyHere
                      ? "Present"
                      : `${education?.endMonth
                        ? education?.endMonth
                        : ""
                      } ${education?.endYear
                        ? education?.endYear
                        : ""
                      }`
                    }`
                    : "Your Degree Tenure"
                    }`}</p>
                </div>
              </>
            )) :
              <>
                <div className="ist">
                  <p className="para bold-para">Institute Name , City ,Country</p>
                  <p className="para ">Degree , Date</p>
                </div>
              </>
            }

          </div>
        </div>
      </> : ""}

      {sections.certificates ? <>
        <div className="certificate-sec">
          <div className="head">
            <Icon icon="ep:d-arrow-right" color="black" width="20" height="20" />
          </div>
          <div className="content">
            <h4 className="sub-heading">Certificates</h4>
            {userData.certificates ? (
              userData.certificates.map((certificate, i) => (
                <>
                  <div className="ist">
                    <p className="para bold-para">{certificate.authority}</p>
                    <p className="para ">{certificate.certificateName}, {`${certificate.certificateDate ? certificate.certificateDate : ""}`}</p>
                  </div>

                </>
              ))
            ) : (
              <>
                <div className="ist">
                  <p className="para bold-para">Institute Name , City ,Country</p>
                  <p className="para ">Certificate Name , Date</p>
                </div>
              </>
            )}

          </div>
        </div>
      </> : ""}

      {sections.skills || sections.strengths ? <>
        <div className="skill-sec">
          <div className="head">
            <Icon icon="ep:d-arrow-right" color="black" width="20" height="20" />
          </div>
          <div className="content">
            <h4 className="sub-heading">Skills</h4>
            {sections.skills ? <>
              <p className="para bold-para"></p>
              <div className="second">
                {userData.skills ?
                  userData.skills.map((skill, index) => (
                    <>
                      <p className="para">{skill}</p>

                    </>
                  ))
                  :
                  <>
                    <p className="para">Skill1</p>
                    <p className="para">Skill2</p>
                    <p className="para">Skill3</p>
                    <p className="para">Skill4</p>
                  </>}
                {/* <p className="para">Skill1</p>
            <p className="para">Skill2</p>
            <p className="para">Skill2</p> */}
              </div>
            </> : ""}
            {sections.strengths ? <>
              <p className="para bold-para">Soft Skills</p>
              <div className="second">
                {userData.strengths ?
                  userData.strengths.map((strength, index) => (
                    <p className="para">{strength}</p>
                  ))
                  :
                  <><p className="para">Skill1</p>
                    <p className="para">Skill2</p>
                    <p className="para">Skill3</p>
                    <p className="para">Skill4</p>
                    <p className="para">Skill1</p>
                    <p className="para">Skill2</p>
                    <p className="para">Skill3</p>
                    <p className="para">Skill4</p>
                    <p className="para">Skill1</p>
                    <p className="para">Skill2</p>
                    <p className="para">Skill3</p>
                    <p className="para">Skill4</p>
                    <p className="para">Skill1</p>
                    <p className="para">Skill2</p>
                    <p className="para">Skill3</p>
                    <p className="para">Skill4</p></>}
              </div>
            </> : ""}


          </div>
        </div>
      </> : ""}

      {sections.experience ? <>
        <div className="experience-sec">
          <div className="head">
            <Icon icon="ep:d-arrow-right" color="black" width="20" height="20" />
          </div>
          <div className="content">
            <h4 className="sub-heading">Experience</h4>
            {userData.experience ? (
              userData.experience.map((experience, i) => (
                <>
                  <div className="ist">
                    <p className="para bold-para">{experience.jobTitle}, {experience.companyName}, {`${experience?.startMonth ||
                      experience?.startYear ||
                      experience?.endMonth ||
                      experience?.endYear ||
                      experience?.currentlyWorkHere
                      ? `${experience?.startMonth ? experience?.startMonth : ""
                      } ${experience?.startYear ? experience?.startYear : ""
                      } ${experience?.endMonth ||
                        experience?.endYear ||
                        experience?.currentlyWorkHere
                        ? "-"
                        : ""
                      } ${experience?.currentlyWorkHere
                        ? "Present"
                        : `${experience?.endMonth ? experience?.endMonth : ""
                        } ${experience?.endYear ? experience?.endYear : ""
                        }`
                      }`
                      : "Your Company Tenure"
                      }`}</p>
                    <p className="para">{parse(
                      experience?.description
                        ? experience?.description
                        : "Your Job Description"
                    )}</p>
                  </div>
                </>
              ))
            ) : (
              <>
                <div className="ist">
                  <p className="para bold-para">Developer , Company Name , Date</p>
                  <p className="para">Details</p>
                </div>
              </>
            )}

          </div>
        </div>
      </> : ""}

      {sections.projects ? <>
        <div className="project-sec">
          <div className="head">
            <Icon icon="ep:d-arrow-right" color="black" width="20" height="20" />
          </div>
          <div className="content">
            <h4 className="sub-heading">Projects</h4>
            {userData.projects ? (
              userData.projects.map((project, i) => (
                <>
                  <div className="ist">
                    <p className="para bold-para">{project.projectType}, {project.projectName}, {`${project?.startMonth ||
                      project?.startYear ||
                      project?.endMonth ||
                      project?.endYear ||
                      project?.currentlyWorkingOnThisProject
                      ? `${project?.startMonth ? project?.startMonth : ""
                      } ${project?.startYear ? project?.startYear : ""
                      } ${project?.endMonth ||
                        project?.endYear ||
                        project?.currentlyWorkingOnThisProject
                        ? "-"
                        : ""
                      } ${project?.currentlyWorkingOnThisProject
                        ? "Present"
                        : `${project?.endMonth ? project?.endMonth : ""
                        } ${project?.endYear ? project?.endYear : ""
                        }`
                      }`
                      : "Your Company Tenure"
                      }`}</p>
                    <p className="para">{parse(
                      project?.description
                        ? project?.description
                        : "Your Job Description"
                    )}</p>
                  </div>
                </>
              ))
            ) : (
              <>
                <div className="ist">
                  <p className="para bold-para">Company Name , project Name , Date</p>
                  <p className="para">Details</p>
                </div>
              </>
            )}

          </div>
        </div>
      </> : ""}

      {sections.volunteer ? <>
        <div className="volunteer-sec">
          <div className="head">
            <Icon icon="ep:d-arrow-right" color="black" width="20" height="20" />
          </div>
          <div className="content">
            <h4 className="sub-heading">Volunteers</h4>
            {userData.volunteer ? (
              userData.volunteer.map((volunteer, i) => (
                <>
                  <div className="ist">
                    <p className="para bold-para">{volunteer.institutionName}, {`${volunteer?.startMonth ||
                      volunteer?.startYear ||
                      volunteer?.endMonth ||
                      volunteer?.endYear ||
                      volunteer?.currentlyVolunteerHere
                      ? `${volunteer?.startMonth ? volunteer?.startMonth : ""
                      } ${volunteer?.startYear ? volunteer?.startYear : ""
                      } ${volunteer?.endMonth ||
                        volunteer?.endYear ||
                        volunteer?.currentlyVolunteerHere
                        ? "-"
                        : ""
                      } ${volunteer?.currentlyVolunteerHere
                        ? "Present"
                        : `${volunteer?.endMonth ? volunteer?.endMonth : ""
                        } ${volunteer?.endYear ? volunteer?.endYear : ""
                        }`
                      }`
                      : "Your Company Tenure"
                      }`}</p>
                    <p className="para">{parse(
                      volunteer?.description
                        ? volunteer?.description
                        : "Your Volunteer Description"
                    )}</p>
                  </div>
                </>
              ))
            ) : (
              <>
                <div className="ist">
                  <p className="para bold-para">Company Namee , Date</p>
                  <p className="para">Details</p>
                </div>
              </>
            )}

          </div>
        </div>
      </> : ""}

      {sections.publications ? <>
        <div className="publication-sec">
          <div className="head">
            <Icon icon="ep:d-arrow-right" color="black" width="20" height="20" />
          </div>
          <div className="content">
            <h4 className="sub-heading">Publications</h4>
            {userData.publications ? (
              userData.publications.map((publication, i) => (
                <>
                  <div className="ist">
                    <p className="para bold-para">{publication.publisher}, {publication.publicationTitle}, {`${publication.publicationMonth ? publication.publicationMonth : ""}${publication.publicationMonth && publication.publicationYear ? ", " : ""}${publication.publicationYear ? publication.publicationYear : ""}`}</p>
                    <p className="para">{parse(
                      publication?.description
                        ? publication?.description
                        : "Your Publication Description"
                    )}</p>
                  </div>

                </>
              ))
            ) : (
              <>
                <div className="ist">
                  <p className="para bold-para">Publisher Name , Book Name , Date</p>
                  <p className="para">Details</p>
                </div>
              </>
            )}

          </div>
        </div>
      </> : ""}

      {sections.reference ? <>
        <div className="reference-sec">
          <div className="head">
            <Icon icon="ep:d-arrow-right" color="black" width="20" height="20" />
          </div>
          <div className="content">
            <h4 className="sub-heading">Reference</h4>
            {userData.reference ? userData.reference.map((reference, index) => (
              <>
                <div className="ist">
                  <p className="para bold-para">{reference.referenceCompany}, {reference.personName}</p>
                  <p className="para">{`${reference.contact1 ? reference.contact1 : ""}${reference.contact1 && reference.contact2 ? ", " : ""}${reference.contact2 ? reference.contact2 : ""}`}</p>
                </div>

              </>
            ))
              :
              <>
                <div className="ist">
                  <p className="para bold-para">Company Name , Person Name</p>
                  <p className="para">Contact1 , Contact2</p>
                </div>
              </>}

          </div>
        </div>
      </> : ""}

      {sections.awards ? <>
        <div className="award-sec">
          <div className="head">
            <Icon icon="ep:d-arrow-right" color="black" width="20" height="20" />
          </div>
          <div className="content">
            <h4 className="sub-heading">Awards</h4>
            {userData.awards ? userData.awards.map((award, index) => (
              <div className="ist">
                <p className="para bold-para">{award.awardTitle}{award.awardMonth && award.awardYear ? ", " : ""}{`${award.awardMonth ? award.awardMonth : ""}${award.awardMonth && award.awardYear ? ", " : ""}${award.awardYear ? award.awardYear : ""}`}</p>
              </div>
            ))
              :
              <>
                <div className="ist">
                  <p className="para bold-para">Award,Date</p>
                </div>
              </>
            }

          </div>
        </div>
      </> : ""}

      {sections.interests ? <>
        <div className="interest-sec">
          <div className="head">
            <Icon icon="ep:d-arrow-right" color="black" width="20" height="20" />
          </div>
          <div className="content">
            <h4 className="sub-heading">Interests</h4>
            <div className="second">
              {userData.interests ? userData.interests.map((interest) => {
                return (
                  <p className="para">{interest}</p>

                )
              })
                :

                <p className="para">Interest2</p>
              }
            </div>
          </div>
        </div>
      </> : ""}

    </section>
  );
}

export default Template18;
