import { useState, useEffect } from "react";
import "./template26.scss";
import { Icon } from "@iconify/react";
import parse from "html-react-parser"


const Template26 = ({ userData, forDownload, sections, color, setColor, font, setFont, fontSize, setFontSize, lineHeight, setLineHeight, fromPreview }) => {
  const [currentScale, setCurrentScale] = useState("100%")
  useEffect(() => {
    if (!fromPreview) {
      setColor(userData.templateColor ? userData.templateColor : "#f4f4f4");
      setFont(`Lora`);
      setLineHeight("1.4")

    }

  }, []);
  function handleScaleChange(event) {
    setCurrentScale(`${Math.trunc((event.instance.transformState.scale * 100))}%`);
  }
  const getColor = () => {
    return `:root{--t26Color: ${color};
    --t26LineHeight : ${lineHeight};
  --t26Font : ${font};
--paraFontSize: ${(fontSize + 21) * 0.555}px;
--subHeadingFontSize: ${(fontSize + 21)}px; 
--subHeadingFontSizethree: ${(fontSize + 21) * 0.40}px;
--subHeadingFontSizefour: ${(fontSize + 21) * 0.333}px;
--subHeadingFontSizefive: ${(fontSize + 21) * 0.266}px;
--subHeadingFontSizesix: ${(fontSize + 21) * 0.311}px;
}`;
  };
  const getPageMarginsF = () => {
    return `@page :first { margin: ${"0px"} ${"0px"} ${"10px"} ${"0px"} !important; }`;
  };
  const getPageMargins = () => {
    return `@page { margin: ${"30px"} ${"0px"} ${"10px"} ${"0px"}; }`;
  };
  const getPageMarginst = () => {
    return `table { page-break-inside: avoid; }`;
  };

  return (
    <div ref={forDownload}>
      <style>{getColor()}</style>
      <style>{getPageMarginsF()}</style>
      <style>{getPageMargins()}</style>
      <style>{getPageMarginst()}</style>
      <div className="temp26">
        <div className="header">
          <div className="left">
            <h1>{userData.fName ? userData.fName : ""} {userData.lName ? userData.lName : ""}</h1>
            <h3>{userData.role ? userData.role : ""}</h3>
          </div>
          <div className="right">
            <div className="content">
              <p>
                <span> {userData?.phone ? userData?.phone : "Your Phone"} </span>
                <Icon
                  icon="ri:whatsapp-fill"
                  color="#5b5050"
                  width="30"
                  height="30"
                />
              </p>
            </div>
            <div className="content">
              <p>
                <span> {userData?.email ? userData?.email : "Your Email"}</span>
                <Icon
                  icon="clarity:email-solid"
                  color="#5b5050"
                  width="30"
                  height="30"
                />
              </p>
            </div>
            <div className="content">
              <p>
                <span> {`${userData?.city || userData?.country
                  ? `${userData?.city ? userData?.city : ""} , ${userData?.country ? userData?.country : ""
                  }`
                  : "Your Location"
                  }`}</span>
                <Icon
                  icon="clarity:home-solid"
                  color="#5b5050"
                  width="30"
                  height="30"
                />
              </p>
            </div>
          </div>
        </div>
        <div className="summary-content">
          <div className="top">
            <h3>SUMMARY</h3>
            <p>
              {parse(
                userData?.summary
                  ? userData?.summary
                  : `Lorem ipsum dolor sit amet, consectetur adip iscing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ulla mco
                laboris nisi ut aliquip ex ea commodo consequat.`
              )}
            </p>
          </div>
          <div className="bottom">
            <div className="left">
              {sections.education ?
                <div className="content">
                  <h3>Education</h3>
                  {userData.education ? userData.education.map((education, index) => (
                    <div className="two">
                      <h6>{`${education.schoolName ? education.schoolName : ""}`}</h6>
                      <p className="p2">
                        {`${education.degreeName ? education.degreeName : ""}`} <br />
                        {`${education?.startMonth ||
                          education?.startYear ||
                          education?.endMonth ||
                          education?.endYear ||
                          education?.currentlyStudyHere
                          ? `${education?.startMonth
                            ? education?.startMonth
                            : ""
                          } ${education?.startYear
                            ? education?.startYear
                            : ""
                          } ${education?.endMonth ||
                            education?.endYear ||
                            education?.currentlyStudyHere
                            ? "-"
                            : ""
                          } ${education?.currentlyStudyHere
                            ? "Present"
                            : `${education?.endMonth
                              ? education?.endMonth
                              : ""
                            } ${education?.endYear
                              ? education?.endYear
                              : ""
                            }`
                          }`
                          : "Your Degree Tenure"
                          }`}
                      </p>
                    </div>
                  ))
                    :
                    <div className="two">
                      <h6>Ginyard International Co. University</h6>
                      <p className="p2">
                        Bachelor's Degree in Business Administration <br /> 2016 – 2020
                      </p>
                    </div>
                  }
                  {/* <div className="two">
            <h6>Ginyard International Co. University</h6>
            <p className="p2">
              Bachelor's Degree in Business Administration 2016 – 2020
            </p>
          </div>
          <div className="two">
            <h6>Studio Shodwe University</h6>
            <p className="p2">
              Business Administration on Executive Assistance 2020 – 2022
            </p>
          </div> */}
                </div>
                :
                ""}

              {sections.skills ?
                <div className="content mt-4">
                  <h3>Skills</h3>
                  <div className="skill-list">
                    <ul>
                      {userData.skills ? userData.skills.map((skill) => (
                        <li>{skill}</li>

                      ))
                        :
                        <li>Strong organizational and time-management skills</li>

                      }
                      {/* <li>Strong organizational and time-management skills</li>
              <li>Exceptional communication and interpersonal skills</li>
              <li>Ability to work independently and as part of a team</li> */}
                    </ul>
                  </div>
                </div>
                :
                ""}

              {sections.certificates ?
                <div className="content mt-4">
                  <h3>Certifications</h3>
                  <div className="skill-list">
                    <ul>
                      {userData.certificates ? userData.certificates.map((certificate, index) => (
                        <li>
                          {`${certificate.certificateName ? certificate.certificateName : ""}`}<br />
                          {`${certificate.authority ? certificate.authority : ""}${certificate.authority && certificate.certificateDate ? " | " : ""}${certificate.certificateDate ? certificate.certificateDate : ""}`}
                        </li>
                      ))
                        :
                        <li>
                          Executive Secretary and Business Administration
                          Certification
                        </li>
                      }
                      {/* <li>
                Executive Secretary and Business Administration
                Certification
              </li>
              <li>
                Advanced Executive Assistant and Office Manager Diploma
              </li> */}
                    </ul>
                  </div>
                </div>
                :
                ""}

              {sections.awards ?
                <div className="content mt-4">
                  <h3>Awards</h3>
                  {userData.awards ? userData.awards.map((award, index) => (
                    <div className="skill-list">
                      <p>{`${award.awardTitle ? award.awardTitle : ""}`}</p>
                      <p className="mt-2">{`${award.awardMonth ? award.awardMonth : ""}${award.awardMonth && award.awardYear ? " - " : ""}${award.awardYear ? award.awardYear : ""}`}</p>

                    </div>
                  ))
                    :
                    <div className="skill-list">
                      <p>Award name</p>
                      <p className="mt-2">Month | Year</p>

                    </div>
                  }

                </div>
                :
                ""}

              {sections.interests ?
                <div className="content mt-4">
                  <h3>Interests</h3>
                  <div className="skill-list">
                    <ul>
                      {userData.interests ? userData.interests.map((interest) => (
                        <li>{interest}</li>

                      ))

                        :
                        <li>Reading</li>

                      }
                      {/* <li>Reading</li>
                      <li>Sports</li> */}
                    </ul>
                  </div>
                </div>
                :
                ""}

            </div>
            <div className="right">
              {sections.experience ?
                <div className="content">
                  <h3>Professional Experience</h3>
                  {
                    userData.experience ? userData.experience.map((experience) => (
                      <div className="two">
                        <h6>{experience.jobTitle}</h6>
                        <p className="p2">{`${experience.companyName ? experience.companyName : ""}${experience.companyName && (experience.cityName || experience.country) ? " - " : ""}${experience.cityName ? experience.cityName : ""}${experience.cityName && experience.country ? ", " : ""}${experience.country ? ` ${experience.country}` : ""}${(experience.companyName || experience.cityName || experience.country) && (experience.startMonth || experience.startYear || experience.endMonth || experience.endYear) ? " | " : ""}${experience?.startMonth ||
                          experience?.startYear ||
                          experience?.endMonth ||
                          experience?.endYear ||
                          experience?.currentlyWorkHere
                          ? `${experience?.startMonth ? experience?.startMonth : ""
                          } ${experience?.startYear ? experience?.startYear : ""
                          } ${experience?.endMonth ||
                            experience?.endYear ||
                            experience?.currentlyWorkHere
                            ? "-"
                            : ""
                          } ${experience?.currentlyWorkHere
                            ? "Present"
                            : `${experience?.endMonth ? experience?.endMonth : ""
                            } ${experience?.endYear ? experience?.endYear : ""
                            }`
                          }`
                          : "Your Company Tenure"
                          }`}</p>
                        {parse(
                          experience?.description
                            ? experience?.description
                            : "Your Job Description"
                        )}
                      </div>
                    ))

                      :
                      <div className="two">
                        <h6>Executive Secretary</h6>
                        <p className="p2">Ingoude Company | 2018 - Present</p>
                        <ul>
                          <li>
                            Manage the schedules and calendars of the CEO and other
                            senior executives
                          </li>
                          <li>
                            Coordinate and schedule meetings and conferences with
                            internal and external stakeholders
                          </li>
                        </ul>
                      </div>
                  }
                  {/* <div className="two">
            <h6>Executive Secretary</h6>
            <p className="p2">Ingoude Company | 2018 - Present</p>
            <ul>
              <li>
                Manage the schedules and calendars of the CEO and other
                senior executives
              </li>
              <li>
                Coordinate and schedule meetings and conferences with
                internal and external stakeholders
              </li>
            </ul>
          </div> */}
                </div>
                :
                ""}

              {sections.volunteer ?
                <div className="content mt-5">
                  <h3> Volunteer </h3>
                  {userData.volunteer ? (
                    userData.volunteer.map((volunteer, i) => (
                      <div className="two">
                        <h6> {`${volunteer.institutionName ? volunteer.institutionName : ""}`} </h6>
                        <p className="p2">{`${volunteer?.startMonth ||
                          volunteer?.startYear ||
                          volunteer?.endMonth ||
                          volunteer?.endYear ||
                          volunteer?.currentlyVolunteerHere
                          ? `${volunteer?.startMonth ? volunteer?.startMonth : ""
                          } ${volunteer?.startYear ? volunteer?.startYear : ""
                          } ${volunteer?.endMonth ||
                            volunteer?.endYear ||
                            volunteer?.currentlyVolunteerHere
                            ? "-"
                            : ""
                          } ${volunteer?.currentlyVolunteerHere
                            ? "Present"
                            : `${volunteer?.endMonth ? volunteer?.endMonth : ""
                            } ${volunteer?.endYear ? volunteer?.endYear : ""
                            }`
                          }`
                          : "Your Company Tenure"
                          }`}</p>
                        <p>{parse(
                          volunteer?.description
                            ? volunteer?.description
                            : "Your Volunteer Description"
                        )}</p>
                      </div>
                    )))
                    :
                    <div className="two">
                      <h6> Police </h6>
                      <p className="p2"> 2018 </p>
                      <p>I volunteer </p>
                    </div>
                  }
                  {/* <div className="two">
            <h6> Police </h6>
            <p className="p2"> 2018 </p>
            <p>I volunteer </p>
          </div> */}

                </div>
                :
                ""}

              {sections.publications ?
                <div className="content mt-5">
                  <h3> Publications </h3>
                  {userData.publications ? userData.publications.map((publication, index) => (
                    <div className="two">
                      <h6> {publication.publicationTitle ? publication.publicationTitle : ""} </h6>
                      <span>{publication.publisher ? publication.publisher : ""}</span>
                      <p className="p2"> {`${publication.publicationMonth ? publication.publicationMonth : ""}${publication.publicationMonth && publication.publicationYear ? ", " : ""}${publication.publicationYear ? publication.publicationYear : ""}`} </p>
                      <p>{parse(
                        publication?.description
                          ? publication?.description
                          : "Your Reference Description"
                      )}</p>
                    </div>
                  ))
                    :
                    <div className="two">
                      <h6> Rawalipindi Publishers </h6>
                      <p className="p2"> 2018 </p>
                      <p>This is my publication </p>
                    </div>
                  }
                  {/* <div className="two">
            <h6> Rawalipindi Publishers </h6>
            <p className="p2"> 2018 </p>
            <p>This is my publication </p>
          </div> */}
                </div>
                :
                ""}

              {sections.reference ?
                <div className="content mt-5">
                  <h3> Reference </h3>
                  {userData.reference ? userData.reference.map((reference, index) => (
                    <div className="two">
                      <h6> {reference.referenceCompany ? reference.referenceCompany : ""} </h6>
                      <p className="p2"> {reference.personName ? reference.personName : ""}</p>
                      <p>{reference.contact1 ? reference.contact1 : ""}<br />
                        {reference.contact2 ? reference.contact2 : ""} </p>

                    </div>
                  ))
                    :
                    <div className="two">
                      <h6> Company Name </h6>
                      <p className="p2"> Name of reference</p>
                      <p>123-456-7890 </p>
                    </div>
                  }
                  {/* <div className="two">
            <h6> Company Name </h6>
            <p className="p2"> Name of reference</p>
            <p>123-456-7890 </p>
          </div> */}
                </div>
                :
                ""}

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Template26;
