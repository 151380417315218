import React from 'react'
import Button from '../shared/button'
import { auth } from '../../config';

const Notfound = () => {
    const user = auth.currentUser;
    const handleBackToResume = () => {

        if (user) {
            const locationParts = window.location.hostname.split(".");
            let sliceTill = -2;

            // for localhost
            // console.log("lp", locationParts);
            // console.log("test", locationParts.slice(-1)[0] == "localhost");
            const isLocalHost = locationParts.slice(-1)[0] == "localhost";
            if (isLocalHost) {
                sliceTill = -1;
            }

            const subdomain = locationParts.slice(0, sliceTill).join("");
            // console.log(subdomain);

            //
            if (subdomain != "app") {

                // window.location.href = window.location.protocol + "//" + "app." + window.location.host;
            }
            if (!subdomain) {

                window.location.href = window.location.protocol + "//" + "app." + window.location.host;
            }
            else {

                window.location.href = window.location.protocol + "//" + "app." + window.location.host.split(".")[1];
            }


        }
        else {
            // console.log(window.location.protocol);
            // console.log(window.location.host);
            alert("Here")
            window.location.href = window.location.protocol + "//" + window.location.host.split(".")[1];
        }

    }


    return (
        <div style={{ display: "flex", justifyContent: "center", height: "50vh", alignItems: "center" }}>
            <div className="notFoundContainer" style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: "5px", border: "1px solid white", width: "25%", height: "35%", borderRadius: "7px" }}>
                <p>Opss</p>
                <p>404 Page not Found.</p>
                <button style={{ backgroundColor: "#AC50C6", padding: "6px 10px", borderRadius: "5px" }} onClick={handleBackToResume}>
                    Back to Resumifi
                </button>
            </div>
        </div>
    )
}

export default Notfound