import React, { useRef, useState } from "react";
import "../../style/main.scss";
import download from "../../assets/icons/download.svg";
import ex from "../../assets/icons/export.svg";
import send from "../../assets/icons/send.svg";
import save from "../../assets/icons/save.svg";
import circle from "../../assets/icons/circle.svg";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import emailjs from '@emailjs/browser';
import { PDFDocument, rgb } from 'pdf-lib';
import TemplateN1 from "../template1/Template1";
import ReactDOMServer from 'react-dom/server';
import { useReactToPrint } from 'react-to-print';
import { auth, firestoredb, storage } from "../../config";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";




const Download = ({ resumeRef, userData, isPaidUser }) => {

  const [downloadLimitExceed, setDownloadLimitExceed] = useState(false);
  const navigate = useNavigate();
  const handleDownloadLimitExceedModal = () => {
    setDownloadLimitExceed(prev => !prev)
  }
  // const generatePDF = async (resumeComponent) => {
  //   // Create a new PDF document
  //   const pdfDoc = await PDFDocument.create();

  //   // Add a new page
  //   const page = pdfDoc.addPage([600, 800]);

  //   // Embed your React component as HTML
  //   const html = `
  //   <!DOCTYPE html>
  //   <html>
  //   <head></head>
  //   <body>
  //     ${ReactDOMServer.renderToString(resumeComponent)}
  //   </body>
  //   </html>
  // `;

  //   // Add the HTML content to the page
  //   page.drawText(html, {
  //     x: 10,
  //     y: page.getHeight() - 10,
  //     size: 12,
  //     color: rgb(0, 0, 0),
  //   });

  //   // Save the PDF as bytes
  //   const pdfBytes = await pdfDoc.save();

  //   return pdfBytes;
  // };

  // const handleGeneratePDF = async () => {
  //   const pdfBytes = await generatePDF(<TemplateN1 userData={userData} />);

  //   // Create a Blob from the PDF bytes
  //   const blob = new Blob([pdfBytes], { type: 'application/pdf' });

  //   // Create a URL for the Blob
  //   const url = URL.createObjectURL(blob);

  //   // Open the PDF in a new tab
  //   window.open(url);
  // };

  const handleDownloadPdf = () => {

    const input = resumeRef.current;

    // var doc = new jsPDF({
    //   orientation: 'portrait',
    //   unit: 'mm',
    //   format: 'a4',
    //   putOnlyUsedFonts: true
    // });
    // // console.log(doc.getFontList())

    // // const contentDiv = document.getElementById('yourDivId');

    // const divWidthInMM = input.offsetWidth * 0.264583;
    // const divHeightInMM = input.offsetHeight * 0.264583;
    // doc.setFont("helvetica", "bold");
    // // Decrease the scale factor to make the content smaller
    // doc.html(input, {
    //   callback: function (doc) {
    //     doc.save('your-pdf-filename.pdf');
    //   },

    //   html2canvas: { scale: 0.3527 }, // Adjust the scale as needed (e.g., 0.5 for 50% scaling)
    //   // margin: { top: 10, right: 10, bottom: 10, left: 10 },
    //   width: divWidthInMM,
    //   height: divHeightInMM,
    // });

    // var doc = new jsPDF();

    // doc.html(input, {
    //   callback: function (doc) {
    //     doc.save();
    //   },
    //   x: 0,
    //   y: 0,
    //   scaleWi
    // });

    html2canvas(input).then((canvas) => {
      // // console.log("Canvas", canvas)
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "px", "a4", true);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      // console.log("w", pdfWidth)
      // console.log("h", pdfHeight)
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 0;
      pdf.addImage(imgData, "PNG", imgX, imgY, imgWidth * ratio, imgHeight * ratio);
      pdf.save(`CV by OctaCv.pdf`)
    })
  }

  const downloadResumeWord = () => {
    const prtContent = resumeRef.current;

    // Export to word
    var filename;
    var preHtml = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
    var postHtml = "</body></html>";

    var html = preHtml + prtContent.innerHTML + postHtml;

    var blob = new Blob(['\ufeff', html], {
      type: 'application/msword'
    });

    var url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);

    // console.log("url", url)
    filename = 'document.doc';


    var downloadLink = document.createElement("a");

    document.body.appendChild(downloadLink);

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {

      downloadLink.href = url;
      downloadLink.download = filename;
      downloadLink.click();
    }

    document.body.removeChild(downloadLink);
  }

  const downloadResumeWordCustom = async () => {
    if (isPaidUser) {
      downloadResumeWord();
    }
    else {
      const docRef = doc(firestoredb, "users", auth.currentUser.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        if (!docSnap.data().downloadCount) {
          downloadResumeWord();
          updateDoc(docRef, {
            downloadCount: 1
          })
        }
        else if (docSnap.data().downloadCount < 2) {
          downloadResumeWord();
          updateDoc(docRef, {
            downloadCount: Number(docSnap.data().downloadCount) + 1
          })
        }
        else {
          // toast("You have exceeded the number of free downloads. Please upgrade your account to Pro Plan.")
          setDownloadLimitExceed(true);
        }
      }
    }
  }

  function sendEmailWithResume(email) {
    const templateParams = {
      to_email: "sherazrajpoot@gmail.com",
      // Add other email parameters as needed

    };

    const formData = new FormData();

    formData.append('to_email', "justonly4utube@gmail.com");
    formData.append('to_name', "kali_linux");
    formData.append('from_name', "Okay");
    // Add other email parameters as needed

    // Append the resume file to the FormData
    formData.append('resume', "okayy");

    emailjs
      .sendForm(
        'service_v7y77hj',
        'template_p3m2tgr',
        formData,
        'd5f8SuV9dMOPubWGW'
      )
      .then(
        (response) => {
          // console.log('Email sent successfully:', response);
          alert('Resume sent successfully!');
        },
        (error) => {
          console.error('Email sending failed:', error);
          alert('Error sending the resume. Please try again later.');
        }
      );
  }

  const handlePrint = useReactToPrint({
    content: () => resumeRef.current,
  });
  const handlePrintCustom = async () => {
    if (isPaidUser) {
      handlePrint();
    }
    else {
      const docRef = doc(firestoredb, "users", auth.currentUser.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        if (!docSnap.data().downloadCount) {
          handlePrint();
          updateDoc(docRef, {
            downloadCount: 1
          })
        }
        else if (docSnap.data().downloadCount < 2) {
          handlePrint();
          updateDoc(docRef, {
            downloadCount: Number(docSnap.data().downloadCount) + 1
          })
        }
        else {
          // toast("You have exceeded the number of free downloads. Please upgrade your account to Pro Plan.")
          setDownloadLimitExceed(true);
        }
      }
    }
  }

  const handleEmailSend = async () => {
    try {
      const prtContent = resumeRef.current;

      // Export to word
      var filename;
      var preHtml = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
      var postHtml = "</body></html>";

      var html = preHtml + prtContent.innerHTML + postHtml;

      var blob = new Blob(['\ufeff', html], {
        type: 'application/msword'
      });
      let timeStamp = new Date();
      timeStamp = String(timeStamp);
      timeStamp = timeStamp.split(" GMT");
      timeStamp = timeStamp[0];

      const storageRef = ref(storage, `/email/${auth.currentUser.uid}/${timeStamp}`);

      // 'file' comes from the Blob or File API
      await uploadBytes(storageRef, blob).then((snapshot) => {
        // console.log('Uploaded a blob or file!');
        getDownloadURL(snapshot.ref).then(async (url) => {
          // setUserData((prev) => ({ ...prev, pfp: url }))
          // console.log("URL", url)
          window.location.href = `mailto:admin@resumebuilderai.com?subject=Please check out my resume!&body=Hi my name is ${userData.fName}. Please check my resume: ${String(url)}`;

        })
      });
    } catch (e) {
      // console.log("Error uploading email file", e)
    }
  }

  return (
    <div>
      <div className="download ">
        {/* <div className="one d-flex v-center ">

          <button onClick={handlePrint} style={{}}>Download PDF</button>
        </div> */}
        <div className="one d-flex v-center ">
          <img src={download} alt="" />
          <p className=" p6 mb-0">Download</p>
        </div>
        <div className="top d-flex v-center align-items-start ">
          <img src={circle} alt="circle-image" style={{ marginTop: "1px" }} />
          <p className="text-black2 mb-0">
            For the best formatting, we suggest downloading in PDF. Word format will export text only and may lose some design elements. Choose PDF for a professional presentation.
          </p>
        </div>
        <div className="inside-toolbox " onClick={handlePrintCustom} style={{ cursor: "pointer" }}>
          <div className="two v-center">
            <img src={download} alt="download-image" />
            <p className="text-black2 mb-0">Download in PDF</p>
          </div>
        </div>
        <div className="inside-toolbox " onClick={downloadResumeWordCustom} style={{ cursor: "pointer" }}>
          <div className="two v-center">
            <img src={ex} alt="download-image" />
            <p className="text-black2 mb-0">Export Text to Word</p>
          </div>
        </div>
        {/* <div className="inside-toolbox " onClick={(e) => {
          handleEmailSend();
          // window.location.href = `mailto:admin@resumebuilderai.com?subject=Please check out my resume!&body=Hi my name is ${userData.fName}. I have made my resume through ResumeBuilderAI App.`;
          e.preventDefault();
        }}

          style={{ cursor: "pointer" }}>
          <div className="two v-center">
            <img src={send} alt="download-image" />
            <p className="text-black2 mb-0">Send to Email</p>
          </div>
        </div> */}
        {/* <div className="inside-toolbox ">
          <div className="two v-center">
            <img src={save} alt="download-image" />
            <p className="text-black2 mb-0">Save to Dropbox</p>
          </div>
        </div>
        <div className="inside-toolbox ">
          <div className="two v-center">
            <img src={save} alt="download-image" />
            <p className="text-black2 mb-0">Save toGoogle Drive</p>
          </div>
        </div> */}
      </div>
      <Modal show={downloadLimitExceed} onHide={handleDownloadLimitExceedModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Free Resources Utilized!</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          <div style={{ padding: "30px 10px", display: "flex", flexDirection: "column", gap: "30px", alignItems: "center" }}>
            <div style={{ display: "flex", alignItems: "flex-start", gap: "5px", width: "100%", flexDirection: "column" }}>
              <h5>Oh no, have exceeded the number of free downloads. Please upgrade your account to Pro Plan to continue enjoying Resumifi. </h5>
              <h6 style={{ marginTop: "20px" }}>Benefits of Upgrading:</h6>
              <ul>
                <li>
                  Proofreading From Experts.
                </li>
                <li>
                  35+ professional resumes templates.
                </li>
                <li>
                  AI resume checker.
                </li>
                <li>
                  AI Writer.
                </li>
                <li>
                  Priority emails support.
                </li>
                <li>
                  And Much More.
                </li>
              </ul>
            </div>
            <button style={{ backgroundColor: "#562863", padding: "5px 7px", borderRadius: "5px" }} onClick={() => navigate("/money")} >Upgrade</button>
          </div>


        </Modal.Body>
      </Modal>
    </div >
  );
};

export default Download;
