import React, { useState } from "react";
import { Icon } from "@iconify/react";
import template1 from "../../assets/images/tempelates/1.png";
import template2 from "../../assets/images/tempelates/2.png";
import template3 from "../../assets/images/tempelates/3.png";
import template4 from "../../assets/images/tempelates/4.png";
import template5 from "../../assets/images/tempelates/5.png";
import template6 from "../../assets/images/tempelates/6.png";
import Template9 from "../template9/template9";
import techData from "./updatedData.json";
import Button from "../shared/button";
import { useNavigate } from "react-router-dom";
const Templates = ({ setUserData }) => {
  const [currentCategory, setCurrentCategory] = useState("All");
  const [fromPreview, setFromPreview] = useState("true");
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const sections = {
    skills: true,
    strengths: true,
    experience: true,
    projects: true,
    volunteer: false,
    education: true,
    certificates: true,
    awards: true,
    publications: false,
    interests: false,
    languages: true,
    reference: false
  }
  const templates = [
    {
      template: template1,
    },
    {
      template: template2,
    },
    {
      template: template3,
    },
    {
      template: template4,
    },
    {
      template: template5,
    },
    // {
    //   template: template6,
    // },
    {
      template: template1,
    },
    {
      template: template2,
    },
    {
      template: template3,
    },
    {
      template: template4,
    },
    {
      template: template5,
    },
    // {
    //   template: template6,
    // },
  ];

  const searchFunction = (item) => {
    const toSmall = item.role.toLowerCase();
    const searchSmall = search.toLowerCase();
    // console.log(toSmall)
    // console.log(searchSmall)

    if (toSmall.includes(searchSmall)) {
      return true
    }
    else {
      return false
    }
  }



  return (
    <div>
      <div className="templates">
        <h1 className="head">Templates</h1>
        <div className="search-div">
          <Icon
            icon="iconamoon:search-fill"
            color="#4d79ff"
            className="search-icon"
          />
          <input
            type="text"
            placeholder="Search resume and cover letter samples by role or skill"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
        </div>
        <div className="temp-tabs">
          <p
            className={currentCategory == "All" ? "active" : ""}
            onClick={() => setCurrentCategory("All")}
          >
            ALL {`(${techData.length})`}
          </p>
          <p
            className={currentCategory == "Tech" ? "active" : ""}
            onClick={() => setCurrentCategory("Tech")}
          >
            TECH{" "}
            {`(${techData.filter((item) => {
              if (item.category == "Tech") {
                return 1;
              }
            }).length
              })`}
          </p>
          <p
            className={currentCategory == "Business" ? "active" : ""}
            onClick={() => setCurrentCategory("Business")}
          >
            BUSINESS{" "}
            {`(${techData.filter((item) => {
              if (item.category == "Business") {
                return 1;
              }
            }).length
              })`}
          </p>
          <p
            className={currentCategory == "Marketing" ? "active" : ""}
            onClick={() => setCurrentCategory("Marketing")}
          >
            MARKETING{" "}
            {`(${techData.filter((item) => {
              if (item.category == "Marketing") {
                return 1;
              }
            }).length
              })`}
          </p>
          <p
            className={currentCategory == "Engineering" ? "active" : ""}
            onClick={() => setCurrentCategory("Engineering")}
          >
            ENGINEERING{" "}
            {`(${techData.filter((item) => {
              if (item.category == "Engineering") {
                return 1;
              }
            }).length
              })`}
          </p>
          <p
            className={currentCategory == "Medical" ? "active" : ""}
            onClick={() => setCurrentCategory("Medical")}
          >
            MEDICAL{" "}
            {`(${techData.filter((item) => {
              if (item.category == "Medical") {
                return 1;
              }
            }).length
              })`}
          </p>
          <p
            className={currentCategory == "Finance" ? "active" : ""}
            onClick={() => setCurrentCategory("Finance")}
          >
            FINANCE{" "}
            {`(${techData.filter((item) => {
              if (item.category == "Finance") {
                return 1;
              }
            }).length
              })`}
          </p>
          <p
            className={currentCategory == "Legal" ? "active" : ""}
            onClick={() => setCurrentCategory("Legal")}
          >
            LEGAL{" "}
            {`(${techData.filter((item) => {
              if (item.category == "Legal") {
                return 1;
              }
            }).length
              })`}
          </p>
          <p
            className={currentCategory == "Other" ? "active" : ""}
            onClick={() => setCurrentCategory("Other")}
          >
            Other{" "}
            {`(${techData.filter((item) => {
              if (item.category == "Other") {
                return 1;
              }
            }).length
              })`}
          </p>
        </div>
        <div className="templates-content">
          {
            search ?

              techData.map((item, index) => {
                return (
                  // <div className="template-card">
                  //   <img src={item.template} alt="template" className="img-fluid" />
                  //   <div className="use-template ">
                  //     <Button
                  //       text="Use Template"
                  //       width="118px"
                  //       height="30px"
                  //       className="btn-prime-2 btn-primary text-white rounded "
                  //       onClick={(e) => alert("Template Selected")}
                  //     />
                  //   </div>
                  // </div>
                  searchFunction(item) ? (
                    <div className="template-card">
                      <h5
                        style={{
                          textAlign: "center",
                          marginBottom: "5px",
                          padding: "5px",
                        }}
                      >
                        {item.role}
                      </h5>
                      <div
                        className="cv-inner"
                        style={{
                          backgroundColor: "white",
                          overflow: "hidden"
                        }}
                      >
                        <Template9
                          userData={item}
                          lineHeight={"1.8"}
                          color={"darkBlue"}
                          fromPreview={fromPreview}
                          sections={sections}
                        ></Template9>
                      </div>
                      <div className="use-template ">
                        <Button
                          text="Use Template"
                          width="118px"
                          height="30px"
                          className="btn-prime-2 btn-primary text-white rounded "
                          onClick={(e) => {
                            setUserData(item);
                            navigate("/dashboard");
                          }}
                        />
                      </div>
                    </div>
                  ) : null
                );
              })

              :



              currentCategory == "All"
                ? techData.map((item, index) => {
                  return (
                    // <div className="template-card">
                    //   <img src={item.template} alt="template" className="img-fluid" />
                    //   <div className="use-template ">
                    //     <Button
                    //       text="Use Template"
                    //       width="118px"
                    //       height="30px"
                    //       className="btn-prime-2 btn-primary text-white rounded "
                    //       onClick={(e) => alert("Template Selected")}
                    //     />
                    //   </div>
                    // </div>

                    <div className="template-card">
                      <h5
                        style={{
                          textAlign: "center",
                          marginBottom: "5px",
                          padding: "5px",
                        }}
                      >
                        {item.role}
                      </h5>
                      <div
                        className="cv-inner"
                        style={{
                          backgroundColor: "white",
                          overflow: "hidden"
                        }}
                      >
                        <Template9
                          userData={item}
                          lineHeight={"1.8"}
                          color={"darkBlue"}
                          fromPreview={fromPreview}
                          sections={sections}
                        ></Template9>
                      </div>
                      <div className="use-template ">
                        <Button
                          text="Use Template"
                          width="118px"
                          height="30px"
                          className="btn-prime-2 btn-primary text-white rounded "
                          onClick={(e) => {
                            setUserData(item);
                            navigate("/dashboard");
                          }}
                        />
                      </div>
                    </div>
                  );
                })
                : techData.map((item, index) => {
                  return (
                    // <div className="template-card">
                    //   <img src={item.template} alt="template" className="img-fluid" />
                    //   <div className="use-template ">
                    //     <Button
                    //       text="Use Template"
                    //       width="118px"
                    //       height="30px"
                    //       className="btn-prime-2 btn-primary text-white rounded "
                    //       onClick={(e) => alert("Template Selected")}
                    //     />
                    //   </div>
                    // </div>
                    currentCategory == item.category ? (
                      <div className="template-card">
                        <h5
                          style={{
                            textAlign: "center",
                            marginBottom: "5px",
                            padding: "5px",
                          }}
                        >
                          {item.role}
                        </h5>
                        <div
                          className="cv-inner"
                          style={{
                            backgroundColor: "white",
                            overflow: "hidden"
                          }}
                        >
                          <Template9
                            userData={item}
                            lineHeight={"1.8"}
                            color={"darkBlue"}
                            fromPreview={fromPreview}
                            sections={sections}
                          ></Template9>
                        </div>
                        <div className="use-template ">
                          <Button
                            text="Use Template"
                            width="118px"
                            height="30px"
                            className="btn-prime-2 btn-primary text-white rounded "
                            onClick={(e) => {
                              setUserData(item);
                              navigate("/dashboard");
                            }}
                          />
                        </div>
                      </div>
                    ) : null
                  );
                })}
        </div>
      </div>
    </div>
  );
};

export default Templates;
