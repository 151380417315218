import React, { useEffect, useState } from "react";
import "../../style/main.scss";
import Accordion from "react-bootstrap/Accordion";
import PersonalInformation from "./personalInformation";
import WorkExperience from "./workExperience";
import Skill from "./skill";
import Strength from "./strength";
import Hobbies from "./hobbies";
import Education from "./Education";
import Projects from "./Projects";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Award from "./award";
import Certificate from "./certificate";
import Publication from "./publication";
import Language from "./language";
import Volunteer from "./volunteer";
import Reference from "./reference";
import Interest from "./interest";

const FillIn = ({ setUserData, userData, sections, setSections, isFetched, template, isPaidUser }) => {
  const [userId, setUserId] = useState("");




  return (
    <div>
      <div className="two-section">
        <div className="accord">
          <Accordion defaultActiveKey="">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Personal Information</Accordion.Header>
              <Accordion.Body>
                <PersonalInformation userData={userData} setUserData={setUserData} template={template} isPaidUser={isPaidUser} />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header>Education</Accordion.Header>
              <Accordion.Body>
                <Education userData={userData} setUserData={setUserData} isFetched={isFetched} sections={sections} setSections={setSections} />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Work experience</Accordion.Header>
              <Accordion.Body>
                <WorkExperience userData={userData} setUserData={setUserData} sections={sections} setSections={setSections} isFetched={isFetched} isPaidUser={isPaidUser} />
              </Accordion.Body>
            </Accordion.Item>
            {/* <Accordion.Item eventKey="3">
              <Accordion.Header>Projects</Accordion.Header>
              <Accordion.Body>
                <Projects userData={userData} setUserData={setUserData} sections={sections} setSections={setSections} isFetched={isFetched} />
              </Accordion.Body>
            </Accordion.Item> */}
            <Accordion.Item eventKey="4">
              <Accordion.Header>Skills</Accordion.Header>
              <Accordion.Body>
                <Skill userData={userData} setUserData={setUserData} sections={sections} setSections={setSections} isPaidUser={isPaidUser} />
              </Accordion.Body>
            </Accordion.Item>

            {/* <Accordion.Item eventKey="5">
              <Accordion.Header>Soft Skills</Accordion.Header>
              <Accordion.Body>
                <Strength userData={userData} setUserData={setUserData} sections={sections} setSections={setSections} />
              </Accordion.Body>
            </Accordion.Item> */}
            <Accordion.Item eventKey="6">
              <Accordion.Header>Awards</Accordion.Header>
              <Accordion.Body>
                <Award userData={userData} setUserData={setUserData} sections={sections} setSections={setSections} />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>Certificates</Accordion.Header>
              <Accordion.Body>
                <Certificate userData={userData} setUserData={setUserData} sections={sections} setSections={setSections} />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
              <Accordion.Header>Publications</Accordion.Header>
              <Accordion.Body>
                <Publication userData={userData} setUserData={setUserData} sections={sections} setSections={setSections} />
              </Accordion.Body>
            </Accordion.Item>
            {/* <Accordion.Item eventKey="9">
              <Accordion.Header>Languages</Accordion.Header>
              <Accordion.Body>
                <Language userData={userData} setUserData={setUserData} sections={sections} setSections={setSections} />
              </Accordion.Body>
            </Accordion.Item> */}
            <Accordion.Item eventKey="10">
              <Accordion.Header>Volunteer</Accordion.Header>
              <Accordion.Body>
                <Volunteer userData={userData} setUserData={setUserData} sections={sections} setSections={setSections} isFetched={isFetched} />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="11">
              <Accordion.Header>Interests</Accordion.Header>
              <Accordion.Body>
                <Interest userData={userData} setUserData={setUserData} sections={sections} setSections={setSections} />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="12">
              <Accordion.Header>References</Accordion.Header>
              <Accordion.Body>
                <Reference userData={userData} setUserData={setUserData} sections={sections} setSections={setSections} isFetched={isFetched} />
              </Accordion.Body>
            </Accordion.Item>

            {/* <Accordion.Item eventKey="5">
              <Accordion.Header>Hobbies</Accordion.Header>
              <Accordion.Body>
                <Hobbies />
              </Accordion.Body>
            </Accordion.Item> */}

          </Accordion>
        </div>
        {/* <div className="add-new-section">
          <p className="p6">+ Add New Section</p>
          <div className="new d-flex ">
            <div className="left1 ">
              <p className="p7 pointer">Achievements</p>
              <p className="p7 pointer">Awards</p>
              <p className="p7 pointer">Hobbies</p>
              <p className="p7 pointer">Education</p>
              <p className="p7 pointer">Graphs</p>
              <p className="p7 pointer">Strengths</p>
              <p className="p7 pointer">Publications</p>
              <p className="p7 pointer">References</p>
            </div>
            <div className="left1 ">
              <p className="p7 pointer">Strengths</p>
              <p className="p7 pointer">Skills</p>
              <p className="p7 pointer">Social Media</p>
              <p className="p7 pointer">Text</p>
              <p className="p7 pointer">Volunteering</p>
              <p className="p7 pointer">Work Experience</p>
              <p className="p7 pointer">Certificates</p>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default FillIn;
