import React, { useState, useEffect } from "react";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import parse from "html-react-parser"
import "./template10.scss";
function Template10({ userData, forDownload, sections, color, setColor, font, setFont, fontSize, setFontSize, lineHeight, setLineHeight, fromPreview }) {
  const [currentScale, setCurrentScale] = useState("100%")
  useEffect(() => {
    if (!fromPreview) {
      setColor(userData.templateColor ? userData.templateColor : "#000");
      setFont(`Arial`)
      setLineHeight("1.4")
    }

  }, []);
  function handleScaleChange(event) {
    setCurrentScale(`${Math.trunc((event.instance.transformState.scale * 100))}%`);
  }

  const getColor = () => {
    return `:root{--t10Color: ${color};
    --t10LineHeight : ${lineHeight};
  --t10Font : ${font};
--paraFontSize: ${fontSize * 0.70}px;
--subHeadingFontSize: ${fontSize}px; }`;
  };
  const getPageMarginsF = () => {
    return `@page :first { margin: ${"0px"} ${"0px"} ${"10px"} ${"0px"} !important; }`;
  };
  const getPageMargins = () => {
    return `@page { margin: ${"30px"} ${"0px"} ${"10px"} ${"0px"} !important;}`;
  };
  const getPageMarginst = () => {
    return `table { page-break-inside: avoid; }`;
  };

  return (
    // <TransformWrapper
    //   initialScale={1}
    //   maxScale={2}
    //   minScale={0.5}
    //   initialPositionX={0}
    //   initialPositionY={0}
    //   onTransformed={(e) => handleScaleChange(e)}

    // >
    //   {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
    //     <React.Fragment>
    //       <div className="tools" style={{ width: "25%", margin: "10px", display: "flex", gap: "6px" }}>
    //         <div style={{ display: "flex", gap: "10px" }}>
    //           <button onClick={() => zoomOut(0.1)} style={{ backgroundColor: "#180045", padding: "0 8px", borderRadius: "5px" }}>-</button>
    //           <p style={{ margin: "0", padding: "0", color: "black" }}>{currentScale}</p>
    //           <button onClick={() => zoomIn(0.1)} style={{ backgroundColor: "#180045", padding: "0 8px", borderRadius: "5px" }}>+</button>

    //         </div>

    //         <button onClick={() => resetTransform()} style={{ backgroundColor: "#180045", padding: "0 10px", borderRadius: "5px" }}>Reset</button>
    //       </div>
    //       <TransformComponent>
    <section className="template10-sec" ref={forDownload}>
      <style>{getColor()}</style>
      <style>{getPageMargins()}</style>
      <style>{getPageMarginsF()}</style>
      <style>{getPageMarginst()}</style>
      <div className="top">
        <h2 className="heading">{`${userData?.fName || userData?.lName
          ? `${userData?.fName ? userData?.fName : ""} ${userData?.lName ? userData?.lName : ""
          }`
          : "Your Name"
          }`}</h2>
        <h3 className="role">{userData.role ? userData.role : "Your Role"}</h3>
      </div>
      <p className="para">
        {parse(
          userData?.summary
            ? userData?.summary
            : `Lorem ipsum dolor sit amet, consectetur adip iscing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ulla mco
                laboris nisi ut aliquip ex ea commodo consequat.`
        )}
      </p>
      <div className="line"></div>
      <div className="contact-sec">
        <div className="left">
          <h4 className="sub-heading">CONTACT</h4>
        </div>
        <div className="right">
          <p className="para">{userData?.phone ? userData?.phone : "Your Phone"}</p>
          <p className="para">{userData?.email ? userData?.email : "Your Email"}</p>
          <p className="para">{`${userData?.city || userData?.country
            ? `${userData?.city ? userData?.city : ""}, ${userData?.country ? userData?.country : ""
            }`
            : "Your Location"
            }`}</p>
        </div>
      </div>
      {sections.skills ? <>
        <div className="line"></div>
        <div className="skill-sec">
          <div className="left">
            <h4 className="sub-heading">SKILLS</h4>
          </div>
          <div className="right">
            <ul>
              {userData.skills ? userData.skills.map((skill) => (
                <li>
                  {skill}
                </li>
              )) :
                <li>
                  Lorem ipsum dolor sit amet.
                </li>}

            </ul>
          </div>
        </div>
      </>
        :
        ""}
      {sections.strengths ? <>
        <div className="line"></div>

        <div className="skill-sec">
          <div className="left">
            <h4 className="sub-heading">SOFT SKILLS</h4>
          </div>
          <div className="right">
            <ul>
              {userData.strengths ? userData.strengths.map((strength, index) => (
                <li>
                  <p className="para">{strength}</p>
                </li>
              ))
                :
                <li>
                  <p className="para">Lorem ipsum dolor sit amet.</p>
                </li>
              }
              {/* <li>
        <p className="para">Lorem ipsum dolor sit amet.</p>
      </li>
      <li>
        <p className="para">Lorem ipsum dolor sit amet.</p>
      </li>
      <li>
        <p className="para">Lorem ipsum dolor sit amet.</p>
      </li>
      <li>
        <p className="para">Lorem ipsum dolor sit amet.</p>
      </li> */}
            </ul>
          </div>
        </div>
      </>
        :
        ""}
      {sections.experience ? <>
        <div className="line"></div>
        <div className="experience-sec">
          <div className="left">
            <h4 className="sub-heading">EXPERIENCE</h4>
          </div>
          <div className="right">

            {userData.experience ? userData.experience.map((experience) => (
              <div className="ist">
                <p className="para bold-para">{experience.jobTitle}</p>
                <p className="para light-para">{`${experience.companyName ? experience.companyName : ""}${experience.companyName && (experience.cityName || experience.country) ? " - " : ""}${experience.cityName ? experience.cityName : ""}${experience.cityName && experience.country ? ", " : ""}${experience.country ? ` ${experience.country}` : ""}${(experience.companyName || experience.cityName || experience.country) && (experience.startMonth || experience.startYear || experience.endMonth || experience.endYear) ? " | " : ""}${experience?.startMonth ||
                  experience?.startYear ||
                  experience?.endMonth ||
                  experience?.endYear ||
                  experience?.currentlyWorkHere
                  ? `${experience?.startMonth ? experience?.startMonth : ""
                  } ${experience?.startYear ? experience?.startYear : ""
                  } ${experience?.endMonth ||
                    experience?.endYear ||
                    experience?.currentlyWorkHere
                    ? "-"
                    : ""
                  } ${experience?.currentlyWorkHere
                    ? "Present"
                    : `${experience?.endMonth ? experience?.endMonth : ""
                    } ${experience?.endYear ? experience?.endYear : ""
                    }`
                  }`
                  : "Your Company Tenure"
                  }`}</p>
                {parse(
                  experience?.description
                    ? experience?.description
                    : "Your Job Description"
                )}
              </div>
            ))

              :
              <div className="ist">
                <p className="para bold-para">SOCIAL MEDIA PRODUCER</p>
                <p className="para light-para">Company name</p>
                <ul>
                  <li>
                    <p className="para">
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                      Velit, aperiam?
                    </p>
                  </li>
                </ul>
              </div>
            }


          </div>
        </div>
      </>
        :
        ""}
      {sections.projects ? <>
        <div className="line"></div>
        <div className="project-sec">
          <div className="left">
            <h4 className="sub-heading">PROJECTS</h4>
          </div>
          <div className="right">
            {userData.projects ? userData.projects.map((project, index) => (
              <div className="ist">
                <p className="para bold-para">{`${project.projectName ? project.projectName : ""}${project.projectName && (project.startMonth || project.endMonth || project.startYear || project.endYear) ? " | " : ""}${project.projectName && project.project ? project.projectName : ""}${project?.startMonth ||
                  project?.startYear ||
                  project?.endMonth ||
                  project?.endYear ||
                  project?.currentlyWorkingOnThisProject
                  ? `${project?.startMonth
                    ? project?.startMonth
                    : ""
                  } ${project?.startYear
                    ? project?.startYear
                    : ""
                  } ${project?.endMonth ||
                    project?.endYear ||
                    project?.currentlyWorkingOnThisProject
                    ? "-"
                    : ""
                  } ${project?.currentlyWorkingOnThisProject
                    ? "Present"
                    : `${project?.endMonth
                      ? project?.endMonth
                      : ""
                    } ${project?.endYear
                      ? project?.endYear
                      : ""
                    }`
                  }`
                  : "Your Degree Tenure"
                  }`}</p>

                <p className="para">
                  {parse(
                    project?.description
                      ? project?.description
                      : "Your Project Description"
                  )}
                </p>

              </div>
            ))
              :
              <div className="ist">
                <p className="para bold-para">Project Name / Date</p>
                <ul>
                  <li>
                    <p className="para">
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                      Velit, aperiam?
                    </p>
                  </li>
                </ul>
              </div>
            }
            {/* <div className="ist">
            <p className="para bold-para">Project Name / Date</p>
            <ul>
              <li>
                <p className="para">
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Velit, aperiam?
                </p>
              </li>
            </ul>
          </div> */}
            {/* <div className="ist">
            <p className="para bold-para">Project Name / Date</p>
            <ul>
              <li>
                <p className="para">
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Velit, aperiam?
                </p>
              </li>
            </ul>
          </div> */}
          </div>
        </div>
      </>
        :
        ""}
      {sections.volunteer ? <>
        <div className="line"></div>
        <div className="volunteer-sec">
          <div className="left">
            <h4 className="sub-heading">VOLUNTEER</h4>
          </div>

          <div className="right">
            {userData.volunteer ? userData.volunteer.map((volunteer, index) => (
              <div className="ist">
                <p className="para bold-para">{`${volunteer.institutionName ? volunteer.institutionName : ""}${volunteer.institutionName && (volunteer.startMonth || volunteer?.startYear || volunteer?.endMonth || volunteer?.endYear) ? " | " : ""}${volunteer?.startMonth ||
                  volunteer?.startYear ||
                  volunteer?.endMonth ||
                  volunteer?.endYear ||
                  volunteer?.currentlyVolunteerHere
                  ? `${volunteer?.startMonth
                    ? volunteer?.startMonth
                    : ""
                  } ${volunteer?.startYear
                    ? volunteer?.startYear
                    : ""
                  } ${volunteer?.endMonth ||
                    volunteer?.endYear ||
                    volunteer?.currentlyVolunteerHere
                    ? "-"
                    : ""
                  } ${volunteer?.currentlyVolunteerHere
                    ? "Present"
                    : `${volunteer?.endMonth
                      ? volunteer?.endMonth
                      : ""
                    } ${volunteer?.endYear
                      ? volunteer?.endYear
                      : ""
                    }`
                  }`
                  : "Your Degree Tenure"
                  }`}</p>

                <p className="para">
                  {parse(
                    volunteer?.description
                      ? volunteer?.description
                      : "Your Volunteer Description"
                  )}
                </p>

              </div>
            ))
              :
              <div className="ist">
                <p className="para bold-para">Project Name / Date</p>
                <ul>
                  <li>
                    <p className="para">
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                      Velit, aperiam?
                    </p>
                  </li>
                </ul>
              </div>
            }
            {/* <div className="ist">
            <p className="para bold-para">Project Name / Date</p>
            <ul>
              <li>
                <p className="para">
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Velit, aperiam?
                </p>
              </li>
            </ul>
          </div>
          <div className="ist">
            <p className="para bold-para">Project Name / Date</p>
            <ul>
              <li>
                <p className="para">
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Velit, aperiam?
                </p>
              </li>
            </ul>
          </div> */}
          </div>
        </div>
      </>
        :
        ""}
      {sections.education ? <>
        <div className="line"></div>
        <div className="education-sec">
          <div className="left">
            <h4 className="sub-heading">EDUCATION</h4>
          </div>
          <div className="right">

            {userData.education ? userData.education.map((education) => (
              <div className="ist">
                <p className="para bold-para">
                  {`${education.degreeName ? education.degreeName : ""}${education.degreeName && (education.startMonth || education.startYear || education.endMonth || education.endYear) ? " | " : ""}${education?.startMonth ||
                    education?.startYear ||
                    education?.endMonth ||
                    education?.endYear ||
                    education?.currentlyStudyHere
                    ? `${education?.startMonth
                      ? education?.startMonth
                      : ""
                    } ${education?.startYear
                      ? education?.startYear
                      : ""
                    } ${education?.endMonth ||
                      education?.endYear ||
                      education?.currentlyStudyHere
                      ? "-"
                      : ""
                    } ${education?.currentlyStudyHere
                      ? "Present"
                      : `${education?.endMonth
                        ? education?.endMonth
                        : ""
                      } ${education?.endYear
                        ? education?.endYear
                        : ""
                      }`
                    }`
                    : "Your Degree Tenure"
                    }`}
                </p>
                <p className="para light-para">
                  {`${education.schoolName ? education.schoolName : ""} ${education.schoolName && (education.cityName || education.country) ? "-" : ""} ${education.cityName ? education.cityName : ""} ${education.cityName && education.country ? `,` : ""} ${education.country ? education.country : ""}`}
                </p>
                <ul>
                  <li>
                    <p className="para">
                      {parse(
                        education?.description
                          ? education?.description
                          : "Your Job Description"
                      )}
                    </p>
                  </li>
                </ul>
              </div>
            ))

              :
              <div className="ist">
                <p className="para bold-para">
                  Education Qualification / Degree,Major
                </p>
                <p className="para light-para">
                  University Name,Any City | 2020-2022
                </p>
                <ul>
                  <li>
                    <p className="para">
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                      Velit, aperiam?
                    </p>
                  </li>
                </ul>
              </div>
            }


          </div>
        </div>
      </>
        :
        ""}
      {sections.certificates ? <>

        <div className="line"></div>
        <div className="certificate-sec">
          <div className="left">
            <h4 className="sub-heading">CERTIFICATES</h4>
          </div>
          <div className="right">
            {userData.certificates ? userData.certificates.map((certificate, index) => (
              <div className="ist">
                <p className="para bold-para">{`${certificate.certificateName ? certificate.certificateName : ""}${certificate.certificateName && certificate.certificateDate ? " | " : ""}${certificate.certificateDate ? certificate.certificateDate : ""}`}</p>



              </div>
            ))
              :
              <div className="ist">
                <p className="para bold-para">Certificate Name / Date</p>
                <ul>
                  <li>
                    <p className="para">
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                      Velit, aperiam?
                    </p>
                  </li>
                </ul>
              </div>
            }
            {// <div className="ist">
              //   <p className="para bold-para">Certificate Name / Date</p>
              //   <ul>
              //     <li>
              //       <p className="para">
              //         Lorem, ipsum dolor sit amet consectetur adipisicing elit.
              //         Velit, aperiam?
              //       </p>
              //     </li>
              //   </ul>
              // </div>
            }
            {/* <div className="ist">
            <p className="para bold-para">Certificate Name / Date</p>
            <ul>
              <li>
                <p className="para">
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Velit, aperiam?
                </p>
              </li>
            </ul>
          </div> */}
          </div>
        </div>
      </>
        :
        ""}
      {sections.awards ? <>
        <div className="line"></div>

        <div className="award-sec">
          <div className="left">
            <h4 className="sub-heading">AWARDS</h4>
          </div>
          <div className="right">
            <ul>
              {userData.awards ? userData.awards.map((award, index) => (
                <li>
                  <p className="para">{`${award.awardTitle ? award.awardTitle : ""}${award.awardTitle && award.issuer ? " | " : ""}${award.issuer ? award.issuer : ""}${award.issuer && (award.cityName || award.country) ? " | " : ""}${award.cityName ? award.cityName : ""}${award.cityName && award.country ? ", " : ""}${award.country ? ` ${award.country}` : ""}${(award.issuer || award.cityName || award.country) && (award.awardMonth || award.awardYear) ? " | " : ""}${award.awardMonth ? award.awardMonth : ""}${award.awardMonth && award.awardYear ? ", " : ""}${award.awardYear ? award.awardYear : ""}`}</p>
                </li>
              ))
                :
                <li>
                  <p className="para">Lorem ipsum dolor sit amet.</p>
                </li>
              }
              {/* <li>
        <p className="para">Lorem ipsum dolor sit amet.</p>
      </li>
      <li>
        <p className="para">Lorem ipsum dolor sit amet.</p>
      </li>
      <li>
        <p className="para">Lorem ipsum dolor sit amet.</p>
      </li>
      <li>
        <p className="para">Lorem ipsum dolor sit amet.</p>
      </li> */}
            </ul>
          </div>
        </div>
      </>
        :
        ""}
      {sections.publications ? <>
        <div className="line"></div>
        <div className="publication-sec">
          <div className="left">
            <h4 className="sub-heading">PUBLICATIONS</h4>
          </div>
          <div className="right">
            {userData.publications ? userData.publications.map((publication, index) => (
              <div className="ist">
                <p className="para bold-para">{publication ? publication.publicationTitle : ""}</p>
                <p className="para light-para">{`${publication.publisher ? publication.publisher : ""}${publication.publisher && (publication.publicationMonth || publication.publicationYear) ? " | " : ""}${publication.publicationMonth ? publication.publicationMonth : ""}${publication.publicationMonth && publication.publicationYear ? ", " : ""}${publication.publicationYear ? publication.publicationYear : ""}`}</p>
                <ul>
                  <li>
                    <p className="para">
                      {parse(
                        publication?.description
                          ? publication?.description
                          : "Your Publication Description"
                      )}
                    </p>
                  </li>
                </ul>
              </div>
            ))
              :
              <div className="ist">
                <p className="para bold-para">Title </p>
                <p className="para light-para">Book Name| 2020-2022</p>
                <ul>
                  <li>
                    <p className="para">
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                      Velit, aperiam?
                    </p>
                  </li>
                </ul>
              </div>
            }

          </div>
        </div>
      </>
        :
        ""}
      {sections.interests ? <>
        <div className="line"></div>

        <div className="interest-sec">
          <div className="left">
            <h4 className="sub-heading">INTERESTS</h4>
          </div>
          <div className="right">
            <ul>
              {userData.interests ? userData.interests.map((interest, index) => (
                <li>
                  <p className="para">{interest}</p>
                </li>
              ))
                :
                <li>
                  <p className="para">Lorem ipsum dolor sit amet.</p>
                </li>
              }
              {/* <li>
        <p className="para">Lorem ipsum dolor sit amet.</p>
      </li>
      <li>
        <p className="para">Lorem ipsum dolor sit amet.</p>
      </li>
      <li>
        <p className="para">Lorem ipsum dolor sit amet.</p>
      </li>
      <li>
        <p className="para">Lorem ipsum dolor sit amet.</p>
      </li> */}
            </ul>
          </div>
        </div>
      </>
        :
        ""}
      {/* {sections.languages ? <>
                <div className="line"></div>

                <div className="language-sec">
                  <div className="left">
                    <h4 className="sub-heading">LANGUAGES</h4>
                  </div>
                  <div className="right">
                    <ul>
                      {userData.languages ? userData.languages.map((language, index) => (
                        <li>
                          <p className="para">{language}</p>
                        </li>
                      ))
                        :
                        <li>
                          <p className="para">Lorem ipsum dolor sit amet.</p>
                        </li>
                      }
                      {// <li>
                        //   <p className="para">Lorem ipsum dolor sit amet.</p>
                        // </li>
                        // <li>
                        //   <p className="para">Lorem ipsum dolor sit amet.</p>
                        // </li>
                        // <li>
                        //   <p className="para">Lorem ipsum dolor sit amet.</p>
                        // </li>
                        // <li>
                        //   <p className="para">Lorem ipsum dolor sit amet.</p>
                        // </li>
                      }
                    </ul>
                  </div>
                </div>
              </>
                :
                ""} */}
      {sections.reference ? <>
        <div className="line"></div>
        <div className="reference-sec">
          <div className="left">
            <h4 className="sub-heading">REFERENCE</h4>
          </div>
          <div className="right">
            {userData.reference ? userData.reference.map((reference, index) => (
              <div className="ist">
                <div className="head">
                  <p className="para bold-para">{reference.referenceCompany}</p>
                  <p className="para">{reference.contact1}</p>
                </div>
                <div className="head">
                  <p className="para light-para">{reference.personName}</p>
                  <p className="para">{reference.contact2}</p>
                </div>


                <p className="para">
                  {parse(
                    reference?.description
                      ? reference?.description
                      : "Your Reference Description"
                  )}
                </p>


              </div>
            ))
              :
              <div className="ist">
                <div className="head">
                  <p className="para bold-para">Company Name</p>
                  <p className="para">Contact1</p>
                </div>
                <div className="head">
                  <p className="para light-para">Person Name</p>
                  <p className="para">Contact1</p>
                </div>
                <ul>
                  <li>
                    <p className="para">
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                      Velit, aperiam?
                    </p>
                  </li>
                </ul>
              </div>
            }
            {/* <div className="ist">
            <div className="head">
              <p className="para bold-para">Company Name</p>
              <p className="para">Contact1</p>
            </div>
            <div className="head">
              <p className="para light-para">Person Name</p>
              <p className="para">Contact1</p>
            </div>
            <ul>
              <li>
                <p className="para">
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Velit, aperiam?
                </p>
              </li>
            </ul>
          </div> */}
          </div>
        </div>
      </>
        :
        ""}















    </section>
    //           </TransformComponent >
    //         </React.Fragment >
    //       )
    // }
    //     </TransformWrapper >
  );
}

export default Template10;
