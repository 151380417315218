import React from "react";
import "../../style/main.scss";
import Button from "../shared/button";
import remove from "../../assets/icons/delete.svg";
import plane from "../../assets/icons/plane.svg";
import lost from "../../assets/icons/lost.svg";
import camera from "../../assets/icons/camera.svg";
import musical from "../../assets/icons/musical-note.svg";
import sports from "../../assets/icons/sports.svg";
const Hobbies = () => {
  return (
    <div>
      <div className="bahr v-center h-center">
        <div className="strength w-100">
          <div className="delete d-flex justify-content-end ">
            <img src={remove} alt="delete-icon" />
          </div>
          <div className="hobbies w-100 mt-2">
            <div className="input-box hobbies-box w-100">
              <label htmlFor="" className="h-center flex-column v-center">
                <img
                  src={plane}
                  alt="plane-img"
                  className="img-53  pos-r-10 "
                />
                <input type="text" placeholder="Exploring distant lands" />
              </label>
            </div>
            <div className="input-box hobbies-box w-100">
              <label htmlFor="" className="h-center flex-column v-center">
                <img src={lost} alt="plane-img" className="img-53  pos-r-10 " />

                <input type="text" placeholder="Getting lost in a good" />
              </label>
            </div>
            <div className="input-box hobbies-box w-100">
              <label htmlFor="" className="h-center flex-column v-center">
                <img
                  src={camera}
                  alt="plane-img"
                  className="img-53  pos-r-10 "
                />

                <input type="text" placeholder="Capering moments" />
              </label>
            </div>

            <div className="input-box hobbies-box w-100">
              <label htmlFor="" className="h-center flex-column v-center">
                <img
                  src={musical}
                  alt="plane-img"
                  className="img-53  pos-r-10 "
                />

                <input type="text" placeholder="Feeling the music" />
              </label>
            </div>
            <div className="input-box hobbies-box w-100">
              <label htmlFor="" className="h-center flex-column v-center">
                <img
                  src={sports}
                  alt="plane-img"
                  className="img-53  pos-r-10 "
                />

                <input type="text" placeholder="Every kind of sport" />
              </label>
            </div>
            <div className="input-box hobbies-box w-100">
              <label
                htmlFor=""
                className="h-center mt-0 flex-column v-center add-custom-field"
              >
                <Button text=" + Add Skills" height="108px" width="213px" />
              </label>
            </div>
          </div>
          {/* Scroll Button */}
          <div className="input-box mt-5 ">
            <label htmlFor="" className="w-100 h-center v-center">
              <Button
                text="^"
                height="39px"
                className="bg-scroll rounded  w-100"
              />
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hobbies;
