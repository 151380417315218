import React, { useEffect } from "react";
import "./template11.scss";
import parse from "html-react-parser"
import girl from "../../assets/images/girl.jpg"


function Template11({ userData, forDownload, sections, color, setColor, font, setFont, fontSize, setFontSize, lineHeight, setLineHeight, fromPreview }) {

  useEffect(() => {

    if (!fromPreview) {
      setColor(userData.templateColor ? userData.templateColor : "rgb(64, 64, 64)")
    }

  }, [])

  const getColor = () => {
    return `:root{--t11Color: ${color};
    --t11LineHeight : ${lineHeight};
  --t11Font : ${font};
--paraFontSize: ${fontSize * 0.70}px;
--subHeadingFontSize: ${fontSize}px; }`;
  };
  const getPageMarginsF = () => {
    return `@page :first { margin: ${"0px"} ${"0px"} ${"10px"} ${"0px"} !important; }`;
  };
  const getPageMargins = () => {
    return `@page { margin: ${"30px"} ${"0px"} ${"10px"} ${"0px"} !important; }`;
  };
  const getPageMarginst = () => {
    return `table { page-break-inside: avoid; }`;
  };

  return (
    <section className="template11-sec" ref={forDownload}>
      <style>{getColor()}</style>
      <style>{getPageMargins()}</style>
      <style>{getPageMarginsF()}</style>
      <style>{getPageMarginst()}</style>
      <div className="top">
        {" "}
        <img
          className="user-img"
          src={`${userData?.cvPictureObj ? URL.createObjectURL(userData?.cvPictureObj) : userData?.cvPicture ? userData?.cvPicture : girl}`}
          alt="..."
        />
        <h2 className="heading">{`${userData?.fName || userData?.lName
          ? `${userData?.fName ? userData?.fName : ""} ${userData?.lName ? userData?.lName : ""
          }`
          : "Your Name"
          }`}</h2>
        <h3 className="status">{userData?.role ? userData?.role : "Your Role"}</h3>
      </div>
      <div className="details">
        <p className="para">{userData?.email ? userData?.email : "Your Email"}</p>
        <p className="para">{userData?.phone ? userData?.phone : "Your Phone"}</p>
        <a href="#" className="para">
          {userData?.web ? userData?.web : "Your Linkedin"}
        </a>
      </div>
      <div className="line"></div>
      <div className="about-sec">
        <h4 className="sub-heading">ABOUT ME</h4>
        <p className="para">
          {parse(
            userData?.summary
              ? userData?.summary
              : `Lorem ipsum dolor sit amet, consectetur adip iscing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ulla mco
                laboris nisi ut aliquip ex ea commodo consequat.`
          )}
        </p>
      </div>

      {sections.skills ? <>
        <div className="line"></div>
        <div className="skills-sec">
          <h4 className="sub-heading">TECHNICAL SKILLS</h4>
          <ol type="i">
            {userData.skills ?
              userData.skills.map((skill, index) => (
                <li>
                  <p className="para">{skill}</p>
                </li>
              ))
              :
              <li>
                <p className="para">Skill1</p>
              </li>}
            {/* <li>
            <p className="para">Skill1</p>
          </li>
          <li>
            <p className="para">Skill2</p>
          </li>
          <li>
            <p className="para">Skill3</p>
          </li>
          <li>
            <p className="para">Skill4</p>
          </li> */}
          </ol>
        </div>
      </> : ""}
      {sections.strengths ? <>
        <div className="line"></div>
        <div className="strength-sec">
          <h4 className="sub-heading">SOFT SKILLS</h4>
          <ol type="i">
            {userData.strengths ?
              userData.strengths.map((strength, index) => (
                <li>
                  <p className="para">{strength}</p>
                </li>
              ))
              :
              <li>
                <p className="para">Strength1</p>
              </li>}
            {/* <li>Strength1</li>
          <li>Strength2</li>
          <li>Strength3</li>
          <li>Strength4</li> */}
          </ol>
        </div>
      </> : ""}


      {sections.experience ? <>
        <div className="line"></div>
        <div className="experience-sec">
          <h4 className="sub-heading">EXPERIENCE</h4>
          {userData.experience ? (
            userData.experience.map((experience, i) => (
              <>
                <div className="ist">
                  <div>
                    <p className="para">{experience.jobTitle}</p> <span>|</span>
                    <p className="para">{experience.companyName}</p>
                    <span>|</span>
                    <p className="para">{`${experience?.startMonth ||
                      experience?.startYear ||
                      experience?.endMonth ||
                      experience?.endYear ||
                      experience?.currentlyWorkHere
                      ? `${experience?.startMonth ? experience?.startMonth : ""
                      } ${experience?.startYear ? experience?.startYear : ""
                      } ${experience?.endMonth ||
                        experience?.endYear ||
                        experience?.currentlyWorkHere
                        ? "-"
                        : ""
                      } ${experience?.currentlyWorkHere
                        ? "Present"
                        : `${experience?.endMonth ? experience?.endMonth : ""
                        } ${experience?.endYear ? experience?.endYear : ""
                        }`
                      }`
                      : "Your Company Tenure"
                      }`}</p>
                  </div>
                  <p className="para">
                    {parse(
                      experience?.description
                        ? experience?.description
                        : "Your Job Description"
                    )}
                  </p>
                </div>
              </>
            ))
          ) : (
            <>
              <div className="ist">
                <div>
                  <p className="para">Virtual Assistant</p> <span>|</span>
                  <p className="para">Studio Shodwe</p>
                  <span>|</span>
                  <p className="para"> Jan 2016-Present</p>
                </div>
                <p className="para">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore,
                  itaque!
                </p>
              </div>
            </>
          )}
          {/* <div className="ist">
          <div>
            <p className="para">Virtual Assistant</p> <span>|</span>
            <p className="para">Studio Shodwe</p>
            <span>|</span>
            <p className="para"> Jan 2016-Present</p>
          </div>
          <p className="para">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore,
            itaque!
          </p>
        </div> */}
        </div>
      </> : ""}

      {sections.projects ? <>
        <div className="line"></div>

        <div className="project-sec">
          <h4 className="sub-heading">PROJECTS</h4>
          {userData.projects ? (
            userData.projects.map((project, i) => (
              <>
                <div className="ist">
                  <div>
                    <p className="para">{project.projectType}</p>
                    <span>|</span>
                    <p className="para">{project.projectName}</p>
                    <span>|</span>
                    <p className="para">{`${project?.startMonth ||
                      project?.startYear ||
                      project?.endMonth ||
                      project?.endYear ||
                      project?.currentlyWorkingOnThisProject
                      ? `${project?.startMonth ? project?.startMonth : ""
                      } ${project?.startYear ? project?.startYear : ""
                      } ${project?.endMonth ||
                        project?.endYear ||
                        project?.currentlyWorkingOnThisProject
                        ? "-"
                        : ""
                      } ${project?.currentlyWorkingOnThisProject
                        ? "Present"
                        : `${project?.endMonth ? project?.endMonth : ""
                        } ${project?.endYear ? project?.endYear : ""
                        }`
                      }`
                      : "Your Company Tenure"
                      }`}</p>
                  </div>
                  <p className="para">
                    {parse(
                      project?.description
                        ? project?.description
                        : "Your Job Description"
                    )}
                  </p>
                </div>
              </>
            ))
          ) : (
            <>
              <div className="ist">
                <div>
                  <p className="para">Project-Company</p>
                  <span>|</span>
                  <p className="para">Project-Name</p>
                  <span>|</span>
                  <p className="para">Date</p>
                </div>
                <p className="para">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia
                  numquam similique.
                </p>
              </div>
            </>
          )}

        </div>
      </> : ""}
      {sections.volunteer ? <>
        <div className="line"></div>
        <div className="volunteer-sec">
          <h4 className="sub-heading">VOLUNTEER</h4>
          {userData.volunteer ? (
            userData.volunteer.map((volunteer, i) => (
              <>
                <div className="ist">
                  <div>
                    <p className="para">{volunteer.institutionName}</p>
                    <span>|</span>
                    <p className="para">{`${volunteer?.startMonth ||
                      volunteer?.startYear ||
                      volunteer?.endMonth ||
                      volunteer?.endYear ||
                      volunteer?.currentlyVolunteerHere
                      ? `${volunteer?.startMonth ? volunteer?.startMonth : ""
                      } ${volunteer?.startYear ? volunteer?.startYear : ""
                      } ${volunteer?.endMonth ||
                        volunteer?.endYear ||
                        volunteer?.currentlyVolunteerHere
                        ? "-"
                        : ""
                      } ${volunteer?.currentlyVolunteerHere
                        ? "Present"
                        : `${volunteer?.endMonth ? volunteer?.endMonth : ""
                        } ${volunteer?.endYear ? volunteer?.endYear : ""
                        }`
                      }`
                      : "Your Company Tenure"
                      }`}</p>
                  </div>
                  <p className="para">
                    {parse(
                      volunteer?.description
                        ? volunteer?.description
                        : "Your Volunteer Description"
                    )}
                  </p>
                </div>
              </>
            ))
          ) : (
            <>
              <div className="ist">
                <div>
                  <p className="para">Volunteer-Name</p>
                  <span>|</span>
                  <p className="para">Date</p>
                </div>
                <p className="para">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia
                  numquam similique.
                </p>
              </div>
            </>
          )}
          {/* <div className="ist">
          <div>
            <p className="para">Volunteer-Name</p>
            <span>|</span>
            <p className="para">Date</p>
          </div>
          <p className="para">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia
            numquam similique.
          </p>
        </div> */}
        </div>
      </> : ""}

      {sections.education ? <>
        <div className="line"></div>
        <div className="education-sec">
          <h4 className="sub-heading">EDUCATION</h4>
          {userData.education ? userData.education.map((education, index) => (
            <>
              <div className="ist">
                <p className="para">{education.degreeName}</p> <span>|</span>
                <p className="para">{`${education.schoolName} ${education.cityName}`}</p>
                <span>|</span>
                <p className="para">{`${education.country}`}</p>
                <span>|</span>
                <p className="para">{`${education?.startMonth ||
                  education?.startYear ||
                  education?.endMonth ||
                  education?.endYear ||
                  education?.currentlyStudyHere
                  ? `${education?.startMonth
                    ? education?.startMonth
                    : ""
                  } ${education?.startYear
                    ? education?.startYear
                    : ""
                  } ${education?.endMonth ||
                    education?.endYear ||
                    education?.currentlyStudyHere
                    ? "-"
                    : ""
                  } ${education?.currentlyStudyHere
                    ? "Present"
                    : `${education?.endMonth
                      ? education?.endMonth
                      : ""
                    } ${education?.endYear
                      ? education?.endYear
                      : ""
                    }`
                  }`
                  : "Your Degree Tenure"
                  }`}</p>
              </div>
            </>
          )) :
            <>
              <div className="ist">
                <p className="para">Degree</p> <span>|</span>
                <p className="para">City</p>
                <span>|</span>
                <p className="para">Country</p>
                <span>|</span>
                <p className="para">Date</p>
              </div>
            </>
          }
          {/* <div className="ist">
          <p className="para">Degree</p> <span>|</span>
          <p className="para">City</p>
          <span>|</span>
          <p className="para">Country</p>
          <span>|</span>
          <p className="para">Date</p>
        </div> */}
        </div>
      </> : ""}
      {sections.certificates ? <>
        <div className="line"></div>
        <div className="certificate-sec">
          <h4 className="sub-heading">CERTIFICATE</h4>
          {userData.certificates ? (
            userData.certificates.map((certificate, i) => (
              <>
                <div className="ist">
                  <p className="para">{certificate.certificateName}</p> <span>|</span>
                  <p className="para">{certificate.authority}</p> <span>|</span>
                  <p className="para">{`${certificate.certificateDate ? certificate.certificateDate : ""}`}</p>
                </div>

              </>
            ))
          ) : (
            <>
              <div className="ist">
                <p className="para">Certificate Name</p> <span>|</span>
                <p className="para">Place</p> <span>|</span>
                <p className="para">Date</p>
              </div>
            </>
          )}
          {/* <div className="ist">
          <p className="para">Certificate Name</p> <span>|</span>
          <p className="para">Place</p>
          <p className="para">Date</p>
        </div> */}
        </div>
      </> : ""}

      {sections.awards ? <>
        <div className="line"></div>
        <div className="award-sec">
          <h4 className="sub-heading">AWARDS</h4>
          {userData.awards ? userData.awards.map((award, index) => (
            <div className="ist">
              <p className="para">{award.awardTitle}</p>
              <span>|</span>
              <p className="para">{`${award.awardMonth ? award.awardMonth : ""}${award.awardMonth && award.awardYear ? ", " : ""}${award.awardYear ? award.awardYear : ""}`}</p>
            </div>
          ))
            :
            <div className="ist">
              <p className="para">Award Name</p>
              <span>|</span>
              <p className="para">Date</p>
            </div>
          }
          {/* <div className="ist">
          <p className="para">Award Name</p>
          <span>|</span>
          <p className="para">Date</p>
        </div> */}
        </div>
      </> : ""}
      {sections.publications ? <>
        <div className="line"></div>
        <div className="publication-sec">
          <h4 className="sub-heading">PUBLICATIONS</h4>
          {userData.publications ? (
            userData.publications.map((publication, i) => (
              <>
                <div className="ist">
                  <div>
                    <p className="para">{publication.publisher}</p>
                    <span>|</span>
                    <p className="para">{publication.publicationTitle}</p>
                    <span>|</span>
                    <p className="para">{`${publication.publicationMonth ? publication.publicationMonth : ""}${publication.publicationMonth && publication.publicationYear ? ", " : ""}${publication.publicationYear ? publication.publicationYear : ""}`}</p>
                  </div>
                  <p className="para">
                    {parse(
                      publication?.description
                        ? publication?.description
                        : "Your Publication Description"
                    )}
                  </p>
                </div>

              </>
            ))
          ) : (
            <>
              <div className="ist">
                <div>
                  <p className="para">Publisher Name</p>
                  <span>|</span>
                  <p className="para">Book Name</p>
                  <span>|</span>
                  <p className="para">Date</p>
                </div>
                <p className="para">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Mollitia
                  sit debitis est perferendis veritatis, saepe neque assumenda
                  eligendi velit nam quisquam illum quis laboriosam officiis. Minima
                  ipsa consequatur rem laudantium voluptatibus odio nisi rerum aliquid
                  maiores. Facilis nesciunt amet suscipit.
                </p>
              </div>
            </>
          )}

        </div>
      </> : ""}
      {sections.interests ? <>
        <div className="line"></div>
        <div className="interest-sec">
          <h4 className="sub-heading">INTERESTS</h4>
          <ol type="i">
            {userData.interests ? userData.interests.map((interest, index) => (
              <li>{interest}</li>

            ))
              :
              <>
                <li>Interest1</li>
                <li>Interest2</li>
                <li>Interest3</li>
                <li>Interest4</li>
              </>}

          </ol>
        </div>
      </> : ""}

      {/* <div className="line"></div>
      <div className="language-sec">
        <h4 className="sub-heading">LANGUAGES</h4>
        <ol type="i">
          <li>Language1</li>
          <li>Language2</li>
          <li>Language3</li>
          <li>Language4</li>
        </ol>
      </div> */}
      {sections.reference ? <>
        <div className="line"></div>
        <div className="reference-sec">
          <h4 className="sub-heading">REFERENCE</h4>
          {userData.reference ? userData.reference.map((reference, index) => (
            <>
              <div className="ist">
                <div>

                  <p className="para">{reference.referenceCompany}</p>
                  <span>|</span>
                  <p className="para">{reference.personName}</p>
                  <span>|</span>
                  <p className="para">{reference.contact1}</p>
                  <span>|</span>
                  <p className="para">{reference.contact2}</p>
                </div>
                <p className="para">
                  {parse(
                    reference?.description
                      ? reference?.description
                      : "Your Reference Description"
                  )}
                </p>
              </div>

            </>
          ))
            :
            <>
              <div className="ist">
                <div>

                  <p className="para">Company Name</p>
                  <span>|</span>
                  <p className="para">Person Name</p>
                  <span>|</span>
                  <p className="para">Contact1</p>
                  <span>|</span>
                  <p className="para">Contact2</p>
                </div>
                <p className="para">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                </p>
              </div>
            </>}

        </div>
      </> : ""}
      <div className="line"></div>

    </section>
  );
}

export default Template11;
