import React from "react";
import "../../style/main.scss";
import Button from "../shared/button";
import remove from "../../assets/icons/delete.svg";

const SkillCategory = () => {
  return (
    <div>
      {" "}
      <div className="bahr v-center h-center">
        <div className="strength ">
          <div className="delete d-flex justify-content-end ">
            <img src={remove} alt="delete-icon" />
          </div>
          <div className="top">
            <p className="p4-2">Skill Category #1</p>
          </div>

          {/* New items */}
          <div className="new-item mt-3 d-flex">
            <div className="input-box  w-100">
              <label htmlFor="" className="w-50">
                <p className="p4">Skill category name</p>
                <input type="text" />
              </label>
            </div>
          </div>
          <div className="new-item mt-3 gap-3 d-flex">
            <div className="input-box w-100">
              <label htmlFor="" className="w-50">
                <p className="p4"> Skill name</p>
                <input type="text" />
              </label>
              <label htmlFor="" className="w-50">
                <p className="p4"> Skill level</p>
                <input type="text" />
              </label>
            </div>
          </div>
          <div className="new-item mt-3 gap-3 d-flex">
            <div className="input-box w-100">
              <label htmlFor="" className="w-50">
                <p className="p4"> Skill name</p>
                <input type="text" />
              </label>
              <label htmlFor="" className="w-50">
                <p className="p4"> Skill level</p>
                <input type="text" />
              </label>
            </div>
          </div>
          <div className="new-item mt-3 gap-3 d-flex">
            <div className="input-box w-100">
              <label htmlFor="" className="w-50">
                <p className="p4"> Skill name</p>
                <input type="text" />
              </label>
              <label htmlFor="" className="w-50">
                <p className="p4"> Skill level</p>
                <input type="text" />
              </label>
            </div>
          </div>
          <div className="new-item mt-3 gap-3 d-flex">
            <div className="input-box w-100">
              <label htmlFor="" className="w-50">
                <p className="p4"> Skill name</p>
                <input type="text" />
              </label>
              <label htmlFor="" className="w-50">
                <p className="p4"> Skill level</p>
                <input type="text" />
              </label>
            </div>
          </div>
          {/* Add custom field Buttons */}
          <div className="add-custom-field pointer ">
            <Button text=" + Add Skills" width="100%" height="34px" />
          </div>
          <div className="add-custom-field pointer ">
            <Button text="+ Add Custom Fields" width="100%" height="34px" />
          </div>
          {/* Multiple Inputs */}
          <div className="new-item  w-100  pt-3 gap-4 ">
            {/* AI Writer button */}
            <div className="writer-btn pointer mt-2 d-flex justify-content-end">
              <Button
                text="AI Writer"
                width="137px"
                height="39px"
                className="btn-prime-1 btn-primary text-white rounded  "
              />
            </div>
          </div>
          {/* Scroll Button */}
          <div className="input-box mt-5 ">
            <label htmlFor="" className="w-100 h-center v-center">
              <Button
                text="^"
                height="39px"
                className="bg-scroll rounded  w-100"
              />
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkillCategory;
