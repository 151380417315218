import React from "react";
import "../../style/main.scss";
import summary from "../../assets/icons/AI-summary.svg";
import approved from "../../assets/icons/approved.svg";
import Pre from "../../assets/icons/AI-pre.svg";
import Optimized from "../../assets/icons/optimized.svg";
import Multi from "../../assets/icons/multi-format.svg";
import Cover from "../../assets/icons/cover.svg";
import Easy from "../../assets/icons/easyAI.svg";
import Automatic from "../../assets/icons/automatic.svg";
import Data from "../../assets/icons/your-data.svg";
const Dreamjob = () => {
  const dreamJob = [
    {
      featureName: "AI summary generator",
      featureImg: summary,
      featureDisc:
        "Create a powerful resume profile or cover letter in one click. Writer’s block is no longer an obstacle. Try for free!",
    },
    {
      featureName: "Approved templates",
      featureImg: approved,
      featureDisc:
        "Professionally-designed resume templates and examples (+guides). Just edit and download in 5 minutes.",
    },
    {
      featureName: "AI pre-written phrases",
      featureImg: Pre,
      featureDisc:
        "Use the power of AI and data analysis, choose pre-generated effective phrases and keywords.",
    },
    {
      featureName: "Optimized resumes",
      featureImg: Optimized,
      featureDisc:
        "Formats and designs are optimized for resume-filtering algorithms. Ensure humans see your application!",
    },
    {
      featureName: "Multi-format resume options",
      featureImg: Multi,
      featureDisc:
        "Save your perfect resume in any common format, including Microsoft Word and PDF in a single click.",
    },
    {
      featureName: "Cover letters",
      featureImg: Cover,
      featureDisc:
        "Our cover letter builder works with the same ease and use of elegant templates as the resume creator.",
    },
    {
      featureName: "Easy AI resume builder",
      featureImg: Easy,
      featureDisc:
        "Create an awesome resume in minutes, without leaving your web browser.",
    },
    {
      featureName: "Automatic spell-checker",
      featureImg: Automatic,
      featureDisc:
        "Our built-in spell-checker takes care of the grammar for you. Create a resume with zero typos or errors.",
    },
    {
      featureName: "Your data is safe",
      featureImg: Data,
      featureDisc:
        "Your data is kept private and protected by strong 256-bit encryption.",
    },
  ];
  return (
    <div id="features">
      <div className="dreamjob">
        <div className="heading v-center h-center d-flex flex-column">
          <h3 className="text-white">Features Designed To Help You Win </h3>

          <h4 className="pinky ">Your Dream Job</h4>
        </div>
        <div className="dreamjob-content">
          {dreamJob.map((item, index) => {
            return (
              <div className="AI d-flex justify-content-center">
                <div className="AI-img">
                  <img src={item.featureImg} alt="" />
                </div>
                <div className="AI-data">
                  <h4 className="new-head">{item.featureName}</h4>
                  <p className="new-para">{item.featureDisc}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Dreamjob;
