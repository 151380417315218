import React, { useEffect } from "react";
import "../../style/main.scss";
import Button from "../shared/button";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the Quill styles
import { useState } from "react";
import parse from "html-react-parser";
import axios from "axios";
import { toast } from "react-toastify";
const Award = ({ setUserData, userData, sections, setSections, isFetched }) => {
    const [editorHtml, setEditorHtml] = useState("");
    const [first, setfirst] = useState(false);
    const [totalExperience, setTotalExperience] = useState([]);
    const [isCompanyName, setIsCompanyName] = useState(false);
    const [newArrayMade, setNewArrayMade] = useState(false);
    const [already, setAlready] = useState(false);
    const [currentActiveIndex, setCurrentActiveIndex] = useState(null);
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        if (userData?.awards) {

            userData?.awards?.map((item, i) => {
                setTotalExperience(prev => [...prev, 1]);
            })
        }
    }, [isFetched])
    const handleChange = (html) => {
        setEditorHtml(html);
    };
    const handleInfoChange = (e, index = currentActiveIndex) => {
        // // console.log(e.target.name);
        // // console.log(e.target.value);
        // // console.log(index)
        if (index == totalExperience.length) {
            setNewArrayMade(true);
        }

        if (e.target.name == "awardTitle" && e.target.value == "") {
            setIsCompanyName(false);
        }
        if (e.target.name == "awardTitle" && e.target.value != "") {
            setIsCompanyName(true);
        }

        // setUserData((prev) => ({
        //   ...prev,
        //   awards: { ...prev?.awards, [e.target.name]: e.target.value },
        // }));

        let temp = userData.awards == undefined ? [] : userData.awards;
        temp[index] = { ...temp[index], [e.target.name]: e.target.value }
        setUserData((prev) => ({ ...prev, awards: temp }))


    };
    const handleQuilChange = (value, index = currentActiveIndex) => {

        // // console.log(value);
        // // console.log(index);
        if (index == totalExperience.length) {
            setNewArrayMade(true);
        }


        // setUserData((prev) => ({
        //   ...prev,
        //   awards: { ...prev?.awards, description: value },
        // }));

        let temp = userData.awards == undefined ? [] : userData.awards;
        temp[index] = { ...temp[index], description: value }
        setUserData((prev) => ({ ...prev, awards: temp }))
    };



    const handleExp = async (index) => {
        setfirst(!first);
        // console.log("here", index)

        if (newArrayMade) {
            if (userData?.awards) {
                let a = userData.awards;
                a.pop();
                setUserData(prev => ({ ...prev, awards: a }));
                setNewArrayMade(false);

            }
        }
        setCurrentActiveIndex(index);
    };



    const handleAddMore = async () => {

        if (userData.awards ? userData.awards[currentActiveIndex] : false) {
            if (userData.awards[currentActiveIndex].awardTitle != undefined && userData.awards[currentActiveIndex].awardTitle != "") {
                setTotalExperience(prev => [...prev, 1]);
                setIsCompanyName(false)
                setfirst(!first)
                setNewArrayMade(false);
            }
            else {
                toast("Please Enter Award Title");
            }

        }
        else {
            toast("Please fill some data first")
        }



    };
    const handleDelete = () => {
        if (userData.awards ? userData.awards[currentActiveIndex] : false) {
            if (!newArrayMade) {
                let temp = userData.awards;
                temp.splice(currentActiveIndex, 1);
                setUserData(prev => ({ ...prev, awards: temp }));
                setIsCompanyName(false)
                setfirst(!first)
                setNewArrayMade(false);
                if (currentActiveIndex != totalExperience.length) {
                    let t = totalExperience;
                    t.pop();
                    setTotalExperience(t)
                }
            }
            else {
                handleExp(null)
            }
        }
        else {
            handleExp(null)
        }

    }

    const handleAIWriter = async () => {
        if (userData?.awards && userData?.awards[currentActiveIndex] && userData?.awards[currentActiveIndex].description && userData?.awards[currentActiveIndex].description.length > 29) {
            let tempData = { description: parse(userData?.awards[currentActiveIndex].description).props.children }
            // console.log("test1", parse(userData?.awards[currentActiveIndex].description))
            // console.log("test", Array.isArray(tempData.description));

            if (Array.isArray(tempData.description)) {
                let newString = "";
                tempData.description.map((item) => {
                    newString = newString + " " + item.props.children;
                });
                tempData = { description: newString }
            }

            let tempIndex = currentActiveIndex;
            try {
                setLoading(true);
                // const result = await axios.post("http://localhost:8080/generateJobDescription", tempData);
                const result = await axios.post("https://resume-ai-nodejs.onrender.com/generateJobDescription", tempData);
                // console.log("check response", result.data);

                let temp = userData.awards == undefined ? [] : userData.awards;
                temp[tempIndex] = { ...temp[tempIndex], description: result.data.description }
                setUserData((prev) => ({ ...prev, awards: temp }))

                setLoading(false);
            }
            catch (e) {
                console.error(e.message)
                setLoading(false);
            }
        }
        else {
            alert("Please Provide some details first to generate summary");
        }
    }

    // Only to check value of totalExperience during development.
    useEffect(() => {
        // // console.log(totalExperience);
        // // console.log("Current Active Index", currentActiveIndex);
    }, [totalExperience, currentActiveIndex])

    return (
        <div>
            {" "}
            <div className="bahr v-center h-center ">
                <div className="strength w-100">
                    <div className="top" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} >
                        <p className="text-purple" style={{ margin: "0" }} >Awards</p>
                        <div style={{ width: "15px", height: "15px", display: "flex", justifyContent: "center", alignItems: "flex-start", border: "1px solid black", backgroundColor: sections.awards ? "black" : "transparent" }} onClick={() => sections.awards ? setSections(prev => ({ ...prev, awards: false })) : setSections(prev => ({ ...prev, awards: true }))}>
                            <div style={{ width: "7px", height: "10px", borderRight: "1px solid white", borderBottom: "1px solid white", transform: "rotate(45deg)", visibility: sections.awards ? "visible" : "hidden" }}></div>
                        </div>
                    </div>

                    {first ? (
                        <div>
                            {/* New items */}
                            <div className="new-item gap-3 d-flex">
                                <div className="input-box  w-100">
                                    <label htmlFor="" className="w-50">
                                        <p className="p4">Award Title</p>
                                        <input
                                            name="awardTitle"
                                            onChange={handleInfoChange}
                                            type="text"
                                            value={
                                                userData?.awards ? userData?.awards[currentActiveIndex]?.awardTitle
                                                    ? userData?.awards[currentActiveIndex]?.awardTitle
                                                    : "" : ""
                                            }
                                        />
                                    </label>
                                    <label htmlFor="" className="w-50">
                                        <p className="p4"> Issuer</p>
                                        <input
                                            name="issuer"
                                            onChange={handleInfoChange}
                                            type="text"
                                            value={
                                                userData?.awards ? userData?.awards[currentActiveIndex]?.issuer
                                                    ? userData?.awards[currentActiveIndex]?.issuer
                                                    : "" : ""
                                            }
                                        />
                                    </label>
                                </div>
                            </div>
                            {/* <div className="new-item mt-3 gap-3 d-flex">
                                <div className="input-box w-100">
                                    <label htmlFor="" className="w-50">
                                        <p className="p4"> City</p>
                                        <input
                                            name="cityName"
                                            onChange={handleInfoChange}
                                            type="text"
                                            value={
                                                userData?.awards ? userData?.awards[currentActiveIndex]?.cityName
                                                    ? userData?.awards[currentActiveIndex]?.cityName
                                                    : "" : ""
                                            }
                                        />
                                    </label>
                                    <label htmlFor="" className="w-50">
                                        <p className="p4"> Country</p>
                                        <input
                                            name="country"
                                            onChange={handleInfoChange}
                                            type="text"
                                            value={
                                                userData?.awards ? userData?.awards[currentActiveIndex]?.country
                                                    ? userData?.awards[currentActiveIndex]?.country
                                                    : "" : ""
                                            }
                                        />
                                    </label>
                                </div>
                            </div> */}
                            <div className="para-z mt-3">
                                <p className="p4">Award Url</p>
                            </div>
                            <div className="new-item time-period mt-2 gap-3 d-flex">

                                <div className="input-box w-100">
                                    <label htmlFor="" className="w-100">
                                        <input
                                            name="awardUrl"
                                            onChange={handleInfoChange}
                                            type="text"
                                            placeholder="Award Url"
                                            value={
                                                userData?.awards ? userData?.awards[currentActiveIndex]?.awardUrl
                                                    ? userData?.awards[currentActiveIndex]?.awardUrl
                                                    : "" : ""
                                            }
                                        />
                                    </label>

                                </div>
                            </div>
                            <div className="para-z mt-3">
                                <p className="p4">Date of Award</p>
                            </div>
                            <div className="new-item time-period mt-2 gap-3 d-flex">

                                <div className="input-box w-100">
                                    <label htmlFor="" className="w-50">
                                        <input
                                            name="awardMonth"
                                            onChange={handleInfoChange}
                                            type="text"
                                            placeholder="Month"
                                            value={
                                                userData?.awards ? userData?.awards[currentActiveIndex]?.awardMonth
                                                    ? userData?.awards[currentActiveIndex]?.awardMonth
                                                    : "" : ""
                                            }
                                        />
                                    </label>
                                    <label htmlFor="" className="w-50">
                                        <input
                                            name="awardYear"
                                            onChange={handleInfoChange}
                                            type="text"
                                            placeholder="Year"
                                            value={
                                                userData?.awards ? userData?.awards[currentActiveIndex]?.awardYear
                                                    ? userData?.awards[currentActiveIndex]?.awardYear
                                                    : "" : ""
                                            }
                                        />
                                    </label>
                                    {/* <label htmlFor="" className="w-25">
                                        <input
                                            name="endMonth"
                                            onChange={handleInfoChange}
                                            type="text"
                                            placeholder="Month"
                                            value={
                                                userData?.awards ? userData?.awards[currentActiveIndex]?.endMonth
                                                    ? userData?.awards[currentActiveIndex]?.endMonth
                                                    : "" : ""
                                            }
                                        />
                                    </label>
                                    <label htmlFor="" className="w-25">
                                        <input
                                            name="endYear"
                                            onChange={handleInfoChange}
                                            type="text"
                                            placeholder="Year"
                                            value={
                                                userData?.awards ? userData?.awards[currentActiveIndex]?.endYear
                                                    ? userData?.awards[currentActiveIndex]?.endYear
                                                    : "" : ""
                                            }
                                        />
                                    </label> */}
                                </div>
                            </div>
                            {/* <div className="currently v-center h-center mt-2">
                                <div
                                    style={{
                                        width: "10px",
                                        height: "10px",
                                        border: "1px solid black",
                                        marginRight: "5px",
                                        backgroundColor: `${userData?.awards ? userData?.awards[currentActiveIndex]?.currentlyWorkHere
                                            ? "black"
                                            : "transparent"
                                            : "transparent"}`,
                                    }}
                                    onClick={() => {
                                        let temp = userData.awards == undefined ? [] : userData.awards;
                                        temp[currentActiveIndex] = { ...temp[currentActiveIndex], currentlyWorkHere: temp[currentActiveIndex] ? !temp[currentActiveIndex]?.currentlyWorkHere : true }
                                        setUserData((prev) => ({ ...prev, awards: temp }))
                                    }
                                    }
                                ></div>
                                <p className="p4">I currently work here</p>
                            </div> */}

                            {/* Multiple Inputs */}
                            {/* <div className="new-item-1  w-100 gap-4 ">
                    {/* Description
                    <div className="input-box d-flex flex-column gap-0 w-100 mt-14">
                        <div className="input-box w-100 mt-14">
                            <label htmlFor="" className="w-100">
                                <p className="p4">Description</p>
                            </label>
                        </div>
                        <div className="w-100 editor-wrapper position-relative">
                            <ReactQuill
                                placeholder={`Add information about your award.`}
                                value={
                                    userData?.awards ? userData?.awards[currentActiveIndex]?.description
                                        ? userData?.awards[currentActiveIndex]?.description
                                        : "" : ""
                                }
                                onChange={(value) => handleQuilChange(value)}
                                modules={Award.modules}
                                formats={Award.formats}
                                height="300px"
                            />
                            {/* <div className="writer-btn pointer">
                                {!loading ? <Button
                                    text="AI Writer"
                                    width="137px"
                                    height="39px"
                                    className="btn-prime-1 btn-primary text-white rounded  "
                                    onClick={handleAIWriter}

                                />
                                    :
                                    <div style={{ margin: "0", position: "relative", top: "48px", left: "42px" }} class="lds-ring"><div></div><div></div><div></div><div></div></div>}


                            </div>
                        </div>
                    </div>
                </div> */}
                            {/* AI Writer button */}
                            <div className="threebtnwrapper">
                                <Button
                                    text="Save"
                                    height="39px"
                                    className="bg-scroll rounded bg-darkPurple"
                                    onClick={handleAddMore}
                                    disabled={loading}
                                />
                                <Button
                                    text="Close"
                                    height="39px"
                                    className="bg-scroll rounded bg-darkPurple"
                                    onClick={() => handleExp(null)}
                                    disabled={loading}
                                />
                                <Button
                                    text="Delete"
                                    height="39px"
                                    className="bg-scroll rounded bg-darkPurple"
                                    onClick={() => handleDelete(null)}
                                    disabled={loading}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="input-box mt-5 ">
                            <label htmlFor="" className="w-100 h-center v-center" style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                                {totalExperience.map((item, index) => {
                                    return (
                                        userData.awards && userData.awards[index]?.awardTitle != undefined && userData.awards[index]?.awardTitle != "" ?
                                            <Button
                                                key={index}
                                                text={`${userData.awards[index]?.awardTitle}`}
                                                height="39px"
                                                className="bg-scroll rounded bg-darkPurple w-100"
                                                onClick={() => {
                                                    // // console.log(index);
                                                    handleExp(index)
                                                }}
                                            /> : null
                                    )
                                })}

                            </label>
                        </div>
                    )}

                    {/* Scroll Button */}
                    {!first ? <div className="input-box mt-5 ">
                        <label htmlFor="" className="w-100 h-center v-center">
                            <Button
                                text="Add more"
                                height="39px"
                                className="bg-scroll rounded bg-darkPurpleDotted w-100"
                                onClick={() => handleExp(totalExperience.length)}
                            />
                        </label>
                    </div>
                        :
                        null}

                </div>
            </div>
        </div>
    );
};
// Quill modules and formats configurations
Award.modules = {
    toolbar: [
        // [{ header: "1" }, { header: "2" }, { font: [] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        // ["link", "image"],
        // ["clean"],
    ],
};

Award.formats = [
    "header",
    "font",
    "list",
    "bullet",
    "bold",
    "italic",
    "underline",
    "link",
    "image",
];
export default Award;
