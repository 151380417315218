import React from "react";
import "../../style/main.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Icon } from "@iconify/react";
const Reviewed = () => {
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3.3,
    slidesToScroll: 1,
    centerPadding: "0px",
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 630,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2.5,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };
  return (
    <div>
      <div className="reviewed">
        <div className="heading v-center h-center d-flex flex-column">
          <h1 className="text-white">Reviewed by the community </h1>
          <h1 className="pinky mt-2"> Trusted by professionals</h1>
        </div>

        <div className="slider ">
          <div className="slider-content-1  ">
            <div className="inside v-center h-center flex-column ">
              <h3 className="new-h3  text-white">4.5 out of 5</h3>
              <p>
                <Icon icon="ion:star" color="ffd600" width="48" height="48" />
                <Icon icon="ion:star" color="ffd600" width="48" height="48" />
                <Icon icon="ion:star" color="ffd600" width="48" height="48" />
                <Icon icon="ion:star" color="ffd600" width="48" height="48" />
                <Icon
                  icon="ion:star-half"
                  color="ffd600"
                  width="48"
                  height="48"
                />
              </p>
              <div className="one-star mr-4 mt-4 v-center h-center flex-column">
                <div className="copil d-flex ">
                <div>
                
                  {" "}
                  <Icon class="iconify ml-2" icon="ion:star" color="ffd600" width="25" height="25"  /> </div>
               <div> <p className="" >Trustpilot
                </p></div>  </div>
                <p className="p2 mt-3 ml-2">based on 46,316 reviews</p>
               
              </div>
            </div>
          </div>
          <div className="template-slider">
            <Slider {...settings}>
              <div className="my-slider">
                <div className="slider-content-2">
                  <p>
                    <Icon
                      icon="ion:star"
                      color="ffd600"
                      width="48"
                      height="48"
                    />
                    <Icon
                      icon="ion:star"
                      color="ffd600"
                      width="48"
                      height="48"
                    />
                    <Icon
                      icon="ion:star"
                      color="ffd600"
                      width="48"
                      height="48"
                    />
                    <Icon
                      icon="ion:star"
                      color="ffd600"
                      width="48"
                      height="48"
                    />
                    <Icon
                      icon="ion:star"
                      color="ffd600"
                      width="48"
                      height="48"
                    />
                  </p>
                  <h3 className="new-h3  text-white">Great Product!</h3>
                  <p className="p2">
                    Great Product! Most professional and easy to setup way to
                    write your CV. Especially good for...
                  </p>
                  <p className="p2 client-name">
                    Ana Lucia Soler <b>.</b>   <span>01 day ago</span>
                  </p>
                </div>
              </div>

              <div className="my-slider">
                <div className="slider-content-2">
                  <p>
                    <Icon
                      icon="ion:star"
                      color="ffd600"
                      width="48"
                      height="48"
                    />
                    <Icon
                      icon="ion:star"
                      color="ffd600"
                      width="48"
                      height="48"
                    />
                    <Icon
                      icon="ion:star"
                      color="ffd600"
                      width="48"
                      height="48"
                    />
                    <Icon
                      icon="ion:star"
                      color="ffd600"
                      width="48"
                      height="48"
                    />
                    <Icon
                      icon="ion:star"
                      color="ffd600"
                      width="48"
                      height="48"
                    />
                  </p>
                  <h3 className="new-h3  text-white">Fantastic</h3>
                  <p className="p2">
                  Fantastic Product! The most user-friendly and efficient method to create your CV. 
                  </p>
                  <p className="p2 client-name">
                    Emy Smith <b>.</b><span>01 day ago</span>
                  </p>
                </div>
              </div>

              <div className="my-slider">
                <div className="slider-content-2">
                  <p>
                    <Icon
                      icon="ion:star"
                      color="ffd600"
                      width="48"
                      height="48"
                    />
                    <Icon
                      icon="ion:star"
                      color="ffd600"
                      width="48"
                      height="48"
                    />
                    <Icon
                      icon="ion:star"
                      color="ffd600"
                      width="48"
                      height="48"
                    />
                    <Icon
                      icon="ion:star"
                      color="ffd600"
                      width="48"
                      height="48"
                    />
                    <Icon
                      icon="ion:star"
                      color="ffd600"
                      width="48"
                      height="48"
                    />
                  </p>
                  <h3 className="new-h3  text-white">Resume builder...</h3>
                  <p className="p2">
                    Great Product! Most professional and easy to setup way to
                    write your CV. Especially good for...
                  </p>
                  <p className="p2 client-name">
                    Elia Jordan <b>.</b><span>01 day ago</span>
                  </p>
                </div>
              </div>

              <div className="my-slider">
                <div className="slider-content-2">
                  <p>
                    <Icon
                      icon="ion:star"
                      color="ffd600"
                      width="48"
                      height="48"
                    />
                    <Icon
                      icon="ion:star"
                      color="ffd600"
                      width="48"
                      height="48"
                    />
                    <Icon
                      icon="ion:star"
                      color="ffd600"
                      width="48"
                      height="48"
                    />
                    <Icon
                      icon="ion:star"
                      color="ffd600"
                      width="48"
                      height="48"
                    />
                    <Icon
                      icon="ion:star"
                      color="ffd600"
                      width="48"
                      height="48"
                    />
                  </p>
                  <h3 className="new-h3  text-white">Great Product!</h3>
                  <p className="p2">
                  Outstanding Product! The easiest and most professional way to craft your CV. 
                  </p>
                  <p className="p2 client-name">
                    Hailey Sam <b>.</b><span>01 day ago</span>
                  </p>
                </div>
              </div>

              <div className="my-slider">
                <div className="slider-content-2">
                  <p>
                    <Icon
                      icon="ion:star"
                      color="ffd600"
                      width="48"
                      height="48"
                    />
                    <Icon
                      icon="ion:star"
                      color="ffd600"
                      width="48"
                      height="48"
                    />
                    <Icon
                      icon="ion:star"
                      color="ffd600"
                      width="48"
                      height="48"
                    />
                    <Icon
                      icon="ion:star"
                      color="ffd600"
                      width="48"
                      height="48"
                    />
                    <Icon
                      icon="ion:star"
                      color="ffd600"
                      width="48"
                      height="48"
                    />
                  </p>
                  <h3 className="new-h3  text-white">Great Product!</h3>
                  <p className="p2">
                  Impressive Product! An incredibly smooth and professional setup for writing your CV. 
                  </p>
                  <p className="p2 client-name">
                    Ana Lucia Soler <b>.</b><span>01 day ago</span>
                  </p>
                </div>
              </div>

              <div className="my-slider">
                <div className="slider-content-2">
                  <p>
                    <Icon
                      icon="ion:star"
                      color="ffd600"
                      width="48"
                      height="48"
                    />
                    <Icon
                      icon="ion:star"
                      color="ffd600"
                      width="48"
                      height="48"
                    />
                    <Icon
                      icon="ion:star"
                      color="ffd600"
                      width="48"
                      height="48"
                    />
                    <Icon
                      icon="ion:star"
                      color="ffd600"
                      width="48"
                      height="48"
                    />
                    <Icon
                      icon="ion:star"
                      color="ffd600"
                      width="48"
                      height="48"
                    />
                  </p>
                  <h3 className="new-h3  text-white">Great Product!</h3>
                  <p className="p2 ">
                    Great Product! Most professional and easy to setup way to
                    write your CV. Especially good for...
                  </p>
                  <p className="p2 client-name">
                    Jenny grace <b>.</b><span>01 day ago</span>
                  </p>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reviewed;
