import React, { useState } from "react";
import "../../style/main.scss";
import Button from "../../components/shared/button";
import shade from "../../assets/images/shades/shade2.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { Modal } from "react-bootstrap";
import { onAuthStateChanged } from "firebase/auth";

import { auth, firestoredb } from "../../config";
import { doc, getDoc, updateDoc } from "firebase/firestore";


const Coverletter = ({ setUserData, setGeneratedFromCv, isPaidUser }) => {

    const [data, setData] = useState({});
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [reviews, setReviews] = useState("");
    const [coverLetter, setCoverLetter] = useState("")
    const [coverLetterModalShow, setCoverLetterModalShow] = useState(false)
    const [downloadLimitExceed, setDownloadLimitExceed] = useState(false)
    const user = auth.currentUser;
    const handleDownloadLimitExceedModal = () => {
        setDownloadLimitExceed(prev => !prev)
    }

    onAuthStateChanged(auth, (user) => {
        if (!user) {
            navigate("/login");
        }
    });


    const handleCoverLetterGenerationLimit = async () => {

        if (isPaidUser) {
            handleCoverLetterGeneration();
        }
        else {
            const docRef = doc(firestoredb, "users", auth.currentUser.uid);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                if (!docSnap.data().coverLetterDownloadCount) {
                    handleCoverLetterGeneration();
                    updateDoc(docRef, {
                        coverLetterDownloadCount: 1
                    })
                }
                else if (docSnap.data().coverLetterDownloadCount < 1) {
                    handleCoverLetterGeneration();
                    updateDoc(docRef, {
                        coverLetterDownloadCount: Number(docSnap.data().coverLetterDownloadCount) + 1
                    })
                }
                else {
                    // toast("You have exceeded the number of free downloads. Please upgrade your account to Pro Plan.")
                    setDownloadLimitExceed(true);
                }
            }
        }

    }

    const handleCoverLetterGeneration = async () => {
        // console.log(data)
        if (data.jobTitle) {
            setLoading(true);
            // setUserData({})
            const formData = new FormData();
            // formData.append("cv", data.cv);
            formData.append("jobTitle", data.jobTitle);
            if (data.jobDescription) {

                formData.append("jobDescription", data.jobDescription);
            }
            try {
                const tokenId = await auth.currentUser.getIdToken();
                const config = {
                    headers: { Authorization: `Bearer ${tokenId}` }
                };
                // setGeneratedFromCv(true);
                // const result = await axios.post("https://octaloop-resume-ai-50bd4465d773.herokuapp.com/pdfResume", formData);
                // const result = await axios.post("http://localhost:8080/pdfResume", formData);
                // const result = await axios.post("https://resumebuilderserver.fly.dev/fixResume", formData);
                // const result = await axios.post("https://resume-ai-nodejs.onrender.com/fixResume", formData);
                // const result = await axios.post("https://resume-ai-nodejs.onrender.com/generateCoverLetter", formData);
                const result = await axios.post("https://resume-ai-nodejs.onrender.com/generateCoverLetter", formData, config);
                // const result = await axios.post("http://localhost:8080/generateCoverLetter", formData);
                // console.log("ddnormal", result.data);
                if (!result.data.message) {
                    let coverLetterFormatted = result.data.coverletter.replace(/(\r\n|\r|\n\n|\n)/g, "<br/>");
                    // console.log("dddouble", { "hi": coverLetterFormatted });
                    coverLetterFormatted = coverLetterFormatted.replace("\n", "<br/>");
                    // console.log("single", { "single": coverLetterFormatted });

                    setCoverLetter({ coverletter: coverLetterFormatted });
                    if (result.data) {
                        setCoverLetterModalShow(true)
                        // downloadResumeWord(result.data.coverletter)
                    }
                    // navigate("/dashboard")
                    setLoading(false);
                    // downloadPng();
                }
                else {
                    toast.error("Oops, some error occured, Please try again")
                    setLoading(false)
                }

            } catch (e) {
                // setGeneratedFromCv(false)
                console.error(e.message)
                toast.error("Oops, some error occured, please try again later");
                setLoading(false);
            }
        }
        else {
            toast("Please Enter Job Title")
        }
    }

    const downloadResumeWord = (htmlContent) => {
        if (data.jobDescription && htmlContent) {
            // const prtContent = resumeRef.current;

            // Export to word
            var filename;
            var preHtml = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
            var postHtml = "</body></html>";

            var html = preHtml + htmlContent + postHtml;

            var blob = new Blob(['\ufeff', html], {
                type: 'application/msword'
            });

            var url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);

            // // console.log("url", url)
            filename = `${data.jobTitle}.doc`;


            var downloadLink = document.createElement("a");

            document.body.appendChild(downloadLink);

            if (navigator.msSaveOrOpenBlob) {
                navigator.msSaveOrOpenBlob(blob, filename);
            } else {

                downloadLink.href = url;
                downloadLink.download = filename;
                downloadLink.click();
            }

            document.body.removeChild(downloadLink);
        }
        else {

        }

    }

    const handleReviewMyResume = async () => {
        // console.log(data)
        if (data.cv && data.jobTitle && data.jobDescription) {
            setLoading(true);
            setUserData({})
            const formData = new FormData();
            formData.append("cv", data.cv);
            formData.append("jobTitle", data.jobTitle);
            try {
                formData.append("jobDescription", data.jobDescription);
                setGeneratedFromCv(true);
                // const result = await axios.post("https://octaloop-resume-ai-50bd4465d773.herokuapp.com/pdfResume", formData);
                // const result = await axios.post("http://localhost:8080/pdfResume", formData);
                // const result = await axios.post("https://resumebuilderserver.fly.dev/fixResume", formData);
                const result = await axios.post("https://resume-ai-nodejs.onrender.com/fixResume", formData);
                // console.log(result.data);
                setUserData(result.data);
                navigate("/dashboard")
                setLoading(false);
                // downloadPng();
            } catch (e) {
                setGeneratedFromCv(false)
                console.error(e.message)
                setLoading(false);
            }
        }
        else {
            alert("Make sure all Data is Given")
        }


    }

    const handleReviewMyResumeNew = async () => {
        // console.log(data)
        if (data.cv && data.jobTitle && data.jobDescription) {
            setLoading(true);
            setUserData({})
            const formData = new FormData();
            formData.append("cv", data.cv);
            formData.append("jobTitle", data.jobTitle);
            try {
                formData.append("jobDescription", data.jobDescription);
                setGeneratedFromCv(true);
                // const result = await axios.post("https://octaloop-resume-ai-50bd4465d773.herokuapp.com/pdfResume", formData);
                // const result = await axios.post("http://localhost:8080/pdfResume", formData);
                // const result = await axios.post("https://resumebuilderserver.fly.dev/fixResume", formData);
                const result = await axios.post("https://resume-ai-nodejs.onrender.com/fixNewResume", formData);
                // console.log("Reviews Data", result.data);
                setReviews(result.data);
                // navigate("/dashboard")
                setLoading(false);
                // downloadPng();
            } catch (e) {
                setGeneratedFromCv(false)
                console.error(e.message)
                setLoading(false);
            }
        }
        else {
            // alert("Make sure all Fields are Filled");
            toast("Make sure all Fields are Filled");
        }


    }

    const handleAIWriter = async () => {
        if (data && data.jobTitle) {
            const tempData = { jobTitle: data.jobTitle }
            // console.log(tempData);
            try {
                if (!loading) {


                    const tokenId = await auth.currentUser.getIdToken();
                    const config = {
                        headers: { Authorization: `Bearer ${tokenId}` }
                    };
                    setLoading(true);
                    // const result = await axios.post("http://localhost:8080/generateSummary", tempData);
                    const result = await axios.post("https://resume-ai-nodejs.onrender.com/generateJobDescriptionNew", tempData, config);
                    // console.log(result.data);
                    if (!result.data.message) {

                        setData((prev) => ({ ...prev, jobDescription: result.data.jobDescription }));
                        setLoading(false);
                    }
                    else {
                        toast.error("Oops, some error occured, Please try again")
                        setLoading(false)
                    }
                }
            }
            catch (e) {
                // console.error(e.message)
                toast.error("Some Error Occured! Please Try Again")
                setLoading(false);
            }
        }
        else {
            // alert("Please Provide some details first to generate summary");
            toast("Please Provide job title first to generate job description")
        }
    }


    return (
        <div>
            <div className="fix flex-column v-center h-center">
                <div className="one flex-column v-center h-center">
                    <h2>Generate Cover Letter using AI</h2>
                    {/* <p className="mt-2">
                        Find out if your resume fits the job you’re applying for
                    </p> */}
                    {/* <div className="upload-btn">
                        <label htmlFor="uploadCv">
                            <div
                                text="Upload Your Resume"

                                className="btn-secondary-1 mt-4"
                                style={{ minWidth: "221px", height: "53px", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "lighter", cursor: "pointer", padding: "10px" }}

                            >{data.cv != "" && data.cv != undefined ? data.cv.name : `Upload Your Resume`}</div>
                        </label>
                        <input id="uploadCv" type="file" onChange={(e) => setData({ ...data, cv: e.target.files[0] })} style={{ display: "none" }}></input>
                    </div> */}
                    <div className="input-box flex-column">
                        <label htmlFor="#">
                            <p className="d-flex">Enter the job title you are applying for</p>
                            <input value={data.jobTitle ? data.jobTitle : ""} type="text" name="" id="" placeholder="UIUX Designer" onChange={(e) => { setData({ ...data, jobTitle: e.target.value }) }} />
                        </label>
                        <label htmlFor="#" className="mt-4">
                            <p className="d-flex">Enter the job description you are applying for &nbsp; <a style={{ color: "#3898FF", cursor: "pointer" }} onClick={handleAIWriter}>(Generate Here)</a></p>
                            <textarea
                                name=""
                                id=""
                                cols="30"
                                rows="10"
                                placeholder="Input job description."
                                value={data.jobDescription ? data.jobDescription : ""}
                                onChange={(e) => { setData({ ...data, jobDescription: e.target.value }) }}
                            ></textarea>
                        </label>
                        <div className="review-btn" style={{ display: "flex", justifyContent: "center" }}>
                            {!loading ?
                                <div className="btnOuterWrapper" style={{ alignItems: "center" }}>
                                    <Button
                                        text="Generate and Download"
                                        className="btn-secondary-1 mt-2  "
                                        onClick={handleCoverLetterGenerationLimit}
                                        width={"220px"}

                                    />
                                    <Button
                                        text="Back"
                                        className="btn-secondary-1   "
                                        onClick={() => navigate("/welcome")}
                                        width={"220px"}
                                    />
                                </div>
                                :
                                <div style={{}} class="lds-ring"><div style={{ borderColor: "#4A58FF transparent transparent transparent" }}></div><div style={{ borderColor: "#4A58FF transparent transparent transparent" }}></div><div style={{ borderColor: "#4A58FF transparent transparent transparent" }}></div><div style={{ borderColor: "#4A58FF transparent transparent transparent" }}></div></div>}

                        </div>
                    </div>




                </div>
                <div className="two">
                    <div className="shade-img">
                        <img src={shade} alt="shade" className="img-fluid" />
                    </div>
                </div>
            </div >
            <Modal show={coverLetterModalShow} onHide={() => setCoverLetterModalShow(!coverLetterModalShow)} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>Cover Letter</Modal.Title>
                </Modal.Header>

                <Modal.Body>

                    <div className="w-200 editor-wrapper position-relative coverLetterPreview" style={{ backgroundColor: "white", borderRadius: "5px" }}>
                        <ReactQuill
                            value={coverLetter.coverletter ? coverLetter.coverletter : "d"}
                            onChange={(value) => setCoverLetter({ coverletter: value })}
                            modules={Coverletter.modules}
                            formats={Coverletter.formats}
                            placeholder="Add information about yourself or Just write few things about yourself and OUR AI will generate it for you."
                            style={{ height: "75vh", overflowY: "auto", padding: "10px" }}
                        />


                    </div>
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
                        <Button
                            text="Download"
                            className="btn-secondary-1 mt-2  "
                            onClick={() => {
                                if (coverLetter) {
                                    // setCoverLetterModalShow(true)
                                    downloadResumeWord(coverLetter.coverletter)
                                }
                            }}
                            width={"180px"}
                            height={"40px"}

                        />
                    </div>

                </Modal.Body>
            </Modal>
            <Modal show={downloadLimitExceed} onHide={handleDownloadLimitExceedModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Free Resources Utilized!</Modal.Title>
                </Modal.Header>

                <Modal.Body>

                    <div style={{ padding: "30px 10px", display: "flex", flexDirection: "column", gap: "30px", alignItems: "center" }}>
                        <div style={{ display: "flex", alignItems: "flex-start", gap: "5px", width: "100%", flexDirection: "column" }}>
                            <h5>Oh no, have exceeded the number of free Cover Letter generation. Please upgrade your account to Pro Plan to continue enjoying Resumifi. </h5>
                            <h6 style={{ marginTop: "20px" }}>Benefits of Upgrading:</h6>
                            <ul>
                                <li>
                                    Proofreading From Experts.
                                </li>
                                <li>
                                    35+ professional resumes templates.
                                </li>
                                <li>
                                    AI resume checker.
                                </li>
                                <li>
                                    AI Writer.
                                </li>
                                <li>
                                    Priority emails support.
                                </li>
                                <li>
                                    And Much More.
                                </li>
                            </ul>
                        </div>
                        <button style={{ backgroundColor: "#562863", padding: "5px 7px", borderRadius: "5px" }} onClick={() => navigate("/money")} >Upgrade</button>
                    </div>


                </Modal.Body>
            </Modal>
        </div >
    );
};
// Quill modules and formats configurations
Coverletter.modules = {
    toolbar: [
        // [{ header: "1" }, { header: "2" }, { font: [] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        // ["link", "image"],
        // ["clean"],
    ],
};

Coverletter.formats = [
    "header",
    "font",
    "list",
    "bullet",
    "bold",
    "italic",
    "underline",
    "link",
    "image",
];
export default Coverletter;
