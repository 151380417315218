import React, { useEffect, useState } from "react";
import "../../style/main.scss";
import remove from "../../assets/icons/delete.svg";
import Button from "../shared/button";
const Strength = ({ userData, setUserData, sections, setSections }) => {

  const [totalStrengths, setTotalStrengths] = useState([1]);
  // const [bgColor, setBgColor] = useState("transparent")
  const handleChange = (e, index) => {
    // console.log(e.target.name)
    // console.log(index)
    let temp = userData?.strengths ? userData?.strengths : [];
    temp[+index] = e.target.value;

    setUserData((prev) => ({ ...prev, strengths: temp }));
  }

  const handleAddMore = () => {
    setTotalStrengths(prev => [...prev, 1])
  }
  const handleDelete = (index) => {

    let t = userData?.strengths;
    t.splice(index, 1);
    setUserData(prev => ({ ...prev, strengths: t }));

    let temp = totalStrengths;
    temp.pop()
    setTotalStrengths(temp)
  }
  useEffect(() => {
    // console.log(totalStrengths)
  }, [totalStrengths])

  useEffect(() => {
    if (!userData?.strengths) {
      setUserData(prev => ({ ...prev, strengths: [] }))
    }
    else {
      setTotalStrengths([]);
      userData?.strengths.map((item, i) => {
        setTotalStrengths(prev => [...prev, 1])
      })
    }
  }, [userData.strengths])



  // const handleDisplay = (sectionName) => {
  //   // console.log(sections.findIndex((section) => section == sectionName) >= 0)
  //   if (sections.findIndex((section) => section == sectionName) >= 0) {
  //     let index = sections.findIndex((section) => section == sectionName)
  //     let temp = sections;
  //     temp.splice(index, 1);
  //     setSections(temp)
  //     setBgColor("transparent")
  //   }
  //   else {
  //     setSections(prev => [...prev, "strengths"])
  //     setBgColor("black")
  //   }
  // }

  return (
    <div>
      <div className="outer v-center h-center">
        <div className="Skill ">
          <div className="top" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <p className="text-purple" style={{ margin: "0" }}>Soft Skills</p>
            <div style={{ width: "15px", height: "15px", display: "flex", justifyContent: "center", alignItems: "flex-start", border: "1px solid black", backgroundColor: sections.strengths ? "black" : "transparent" }} onClick={() => sections.strengths ? setSections(prev => ({ ...prev, strengths: false })) : setSections(prev => ({ ...prev, strengths: true }))}>
              <div style={{ width: "7px", height: "10px", borderRight: "1px solid white", borderBottom: "1px solid white", transform: "rotate(45deg)", visibility: sections.strengths ? "visible" : "hidden" }}></div>
            </div>

          </div>
          {/* <div className="delete d-flex justify-content-end ">
            <img src={remove} alt="delete-icon" />
          </div>

          {// New items}
          <div className="new-item  new-item-phone gap-3 d-flex">
            <div className="input-box w-75">
              <label htmlFor="" className="w-100">
                <p className="p4"> New items</p>
                <input type="text" />
              </label>
            </div>
            <div className="input-box w-25">
              <label htmlFor="" className="w-100">
                <p className="p4"> Phone Number</p>
                <input type="text" />
              </label>
            </div>
          </div>
          <div className="suggestion mt-2">
            <p className="p4-1 text-purple">Suggestions:</p>
            <div className="suggestion-content d-flex v-center justify-content-between">
              <div className="content d-flex">
                <p className="p4">Designing</p>
                <p className="p4">Artist:</p>
                <p className="p4">Responsible:</p>
              </div>
              {// AI Writer Button}
              <div className="writer-btn pointer  d-flex justify-content-end">
                <Button
                  text="AI Writer"
                  width="137px"
                  height="39px"
                  className="btn-prime-1 btn-primary text-white rounded  "
                />
              </div>
            </div>
          </div> */}
          {/* Multiple Inputs */}
          <div className="new-item  w-100 mt-4 pt-3 gap-4 ">
            {totalStrengths.map((skill, index) => (
              <div className={`input-box ${index > 0 ? "mt-4" : ""}`}>
                <label htmlFor="" className="w-100 h-center v-center">
                  <input name={`Skill ${index}`} onChange={(e) => handleChange(e, index)} type="text" placeholder="Write Soft Skills Here" value={userData?.strengths ? userData?.strengths[index] : ""} />
                  <img src={remove} alt="delete-icon" className="ms-3" onClick={() => handleDelete(index)} />
                </label>
              </div>
            ))}

            {/* AI Writer button */}
            <div className="writer-btn pointer mt-5">
              {/* <Button
                text="AI Writer"
                width="137px"
                height="39px"
                className="btn-prime-1 btn-primary text-white rounded  "
              /> */}
              <Button
                text="Add More"
                onClick={handleAddMore}
                width="137px"
                height="39px"
                className="btn-prime-1 btn-primary text-white rounded  "

              />
            </div>
          </div>
          {/* Scroll Button */}
          {/* <div className="input-box mt-5">
            <label htmlFor="" className="w-100 h-center v-center">
              <Button
                text="^"
                height="39px"
                className="bg-scroll rounded  w-100"
              />
            </label>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Strength;
