import React from "react";
import "../../style/main.scss";
import Button from "../shared/button";
import { useNavigate } from "react-router-dom";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import Timeline from "./timeline";
import handleBackToResume from "../../utils/loginredirect";

const Work = () => {
  const navigate = useNavigate();

  function Dates() {
    return (
      <>
        111 <span> 22</span>
      </>
    );
  }

  return (
    <div>
      <div className="work ">
        <div className="heading v-center h-center">
          <h1 className="text-white">How It</h1>
          <h1 className="pinky mx-2">WORKS</h1>
        </div>

        {/* Work Section */}
        <Timeline />
        {/* <VerticalTimeline>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
            date={
              <div className="top">
                <span>Selecting an AI tool:</span>
                <span>
                  {" "}
                  Choose from a variety of AI tools to write resumes...
                </span>
              </div>
            }
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          >
            <video autoPlay loop muted playsInline className="mix-blend">
              <source
                src="../../../assets/video/homepage-videos/AI-Tool.mp4"
                type="video/mp4"
              />
            </video>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
            date={
              <div className="top ">
                <span className="d-flex align-items-start">
                  Write your keywords
                </span>
                <span className="text-start">
                  {" "}
                  Explain to the AI ​​what you want to write. The more details,
                  the better outputs.
                </span>
              </div>
            }
          >
            <video autoPlay loop muted playsInline className="mix-blend">
              <source
                src="../../../assets/video/homepage-videos/security.mp4"
                type="video/mp4"
              />
            </video>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
            date={
              <div className="top">
                <span>A recruiter-tested CV maker tool</span>
                <span>
                  {" "}
                  Use our resume builder and its pre-generated content are
                  tested by recruiters and IT experts. We help your resume
                  become truly competitive in the hiring process.
                </span>
              </div>
            }
          >
            <video autoPlay loop muted playsInline className="mix-blend">
              <source
                src="../../../assets/video/homepage-videos/recruiter.mp4"
                type="video/mp4"
              />
            </video>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
            date={
              <div className="top">
                <span className="d-flex align-items-start">
                  Make a resume that wins interviews
                </span>
                <span className="text-start">
                  {" "}
                  Use our resume maker with its advanced creation tools to tell
                  a professional story that engages recruiters, hiring managers
                  and even CEOs
                </span>
              </div>
            }
          >
            <video autoPlay loop muted playsInline className="mix-blend">
              <source
                src="../../../assets/video/homepage-videos/robot.mp4"
                type="video/mp4"
              />
            </video>
          </VerticalTimelineElement>
        </VerticalTimeline> */}

        {/* --------------------------------------------------------------------------------------------------------------------------------------------- */}

        {/* <div className="work-content">
          <div className="left-content left-diamond">
            <div className="left">
              <div className="video-wrapper ">
                <video autoPlay loop muted playsInline>
                  <source
                    src="../../../assets/video/homepage-videos/AI-Tool.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>

              <div className="text-wrapper">
                <h4>Write your keywords</h4>
                <p>
                  Explain to the AI ​​what you want to write. The more details,
                  the better outputs.
                </p>
              </div>

              <div className="video-wrapper ">
                <video autoPlay loop muted playsInline>
                  <source
                    src=" \assets\video\homepage-videos\recruiter.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>

              <div className="text-wrapper">
                <h4>Make a resume that wins interviews</h4>
                <p>
                  Use our resume maker with its advanced creation tools to tell
                  a professional story that engages recruiters, hiring managers
                  and even CEOs
                </p>
              </div>
            </div>
          </div>
          <div className="left-content right-content">
            <div className="left">
              <div className="text-wrapper">
                <h4>Selecting an AI tool</h4>
                <p>         </p>
              </div>

              <div className="video-wrapper ">
                <video autoPlay loop muted playsInline>
                  <source
                    src="\assets\video\homepage-videos\security.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>

              <div className="text-wrapper">
                <h4>A recruiter-tested CV maker tool</h4>
                <p>
                  Use our resume builder and its pre-generated content are
                  tested by recruiters and IT experts. We help your resume
                  become truly competitive in the hiring process.
                </p>
              </div>

              <div className="video-wrapper ">
                <video autoPlay loop muted playsInline>
                  <source
                    src="\assets\video\homepage-videos\robot.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
          </div>
        </div> */}

        {/* Create resume button */}
        <div className="resume-btn v-center h-center pointer">
          <Button
            text="Create My Resume"
            width="242px"
            height="40px"
            className="btn-prime-3 btn-primary text-white rounded  "
            // onClick={(e) => navigate("/login")}
            onClick={() => handleBackToResume()}
          />
        </div>
      </div>
    </div>
  );
};

export default Work;
