import { useState, useEffect } from "react";
import "./template30.scss";
import { Icon } from "@iconify/react";
import parse from "html-react-parser"

const Template30 = ({ userData, forDownload, sections, color, setColor, font, setFont, fontSize, setFontSize, lineHeight, setLineHeight, fromPreview }) => {
  const [currentScale, setCurrentScale] = useState("100%")
  useEffect(() => {
    if (!fromPreview) {
      setColor(userData.templateColor ? userData.templateColor : userData.templateColor ? userData.templateColor : "#d69460");
      setFont(`Lato`);
      setLineHeight("1.4")
    }

  }, []);
  function handleScaleChange(event) {
    setCurrentScale(`${Math.trunc((event.instance.transformState.scale * 100))}%`);
  }
  const getColor = () => {
    return `:root{--t30Color: ${color};
    --t30LineHeight : ${lineHeight};
  --t30Font : ${font};
--paraFontSize: ${(fontSize + 16) * 0.375}px;
--subHeadingFontSize: ${(fontSize + 16)}px;
--subHeadingFontSizethree: ${(fontSize + 16) * 0.35}px;
 }`;
  };
  const getPageMarginsF = () => {
    return `@page :first { margin: ${"0px"} ${"0px"} ${"10px"} ${"0px"} !important; }`;
  };
  const getPageMargins = () => {
    return `@page { margin: ${"30px"} ${"0px"} ${"10px"} ${"0px"} !important; }`;
  };
  const getPageMarginst = () => {
    return `table { page-break-inside: avoid; }`;
  };

  return (
    <div ref={forDownload}>
      <style>{getColor()}</style>
      <style>{getPageMarginsF()}</style>
      <style>{getPageMargins()}</style>
      <style>{getPageMarginst()}</style>
      <div className="template30">
        <div className="top-line"></div>
        <div className="name mt-4">
          <h1>{userData.fName ? userData.fName : ""} {userData.lName ? userData.lName : ""} </h1>
          <h4>{userData.role ? userData.role : ""}</h4>
        </div>
        <div className="contact">
          <p>
            <Icon
              icon="entypo:old-phone"
              color={color}
              width="30"
              height="30"
            />
            <span>{userData?.phone ? userData?.phone : "Your Phone"}</span>
          </p>
          <p>
            <Icon
              icon="iconamoon:email"
              color={color}
              width="30"
              height="30"
            />
            <span>{userData?.email ? userData?.email : "Your Email"}</span>
          </p>
          <p>
            <Icon
              icon="mingcute:location-2-fill"
              color={color}
              width="30"
              height="30"
            />
            <span>{`${userData?.city || userData?.country
              ? `${userData?.city ? userData?.city : ""} , ${userData?.country ? userData?.country : ""
              }`
              : "Your Location"
              }`}</span>
          </p>
        </div>
        <div className="description">
          <p>
            {parse(
              userData?.summary
                ? userData?.summary
                : `Lorem ipsum dolor sit amet, consectetur adip iscing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ulla mco
                laboris nisi ut aliquip ex ea commodo consequat.`
            )}
          </p>
        </div>
        {sections.skills ?
          <div className="expertise">
            <div className="top">
              <div className="heading">
                <h4>Skills</h4>
              </div>
              <div className="line"></div>
            </div>
            <div className="three">
              <div className="content">
                {userData.skills ? userData.skills.map((skill) => (

                  <li>{skill}</li>
                ))
                  :
                  <li>Your Skills</li>

                }
              </div>
            </div>
          </div>
          :

          ""
        }
        {sections.experience ?
          <div className="experience">
            <div className="top">
              <div className="heading">
                <h4>Experience</h4>
              </div>
              <div className="line"></div>
            </div>
            <div className="three">
              {
                userData.experience ? userData.experience.map((experience) => (
                  <div className="content">
                    <div className="left">
                      <h4>{experience.jobTitle}</h4>
                      <p>{experience.companyName}</p>
                      <p>{`${experience?.startMonth ||
                        experience?.startYear ||
                        experience?.endMonth ||
                        experience?.endYear ||
                        experience?.currentlyWorkHere
                        ? `${experience?.startMonth ? experience?.startMonth : ""
                        } ${experience?.startYear ? experience?.startYear : ""
                        } ${experience?.endMonth ||
                          experience?.endYear ||
                          experience?.currentlyWorkHere
                          ? "-"
                          : ""
                        } ${experience?.currentlyWorkHere
                          ? "Present"
                          : `${experience?.endMonth ? experience?.endMonth : ""
                          } ${experience?.endYear ? experience?.endYear : ""
                          }`
                        }`
                        : "Your Company Tenure"
                        }`}</p>
                    </div>
                    <div className="right">
                      {parse(
                        experience?.description
                          ? experience?.description
                          : "Your Job Description"
                      )}
                    </div>
                  </div>
                ))

                  :
                  <div className="content">
                    <div className="left">
                      <h4>Applications developer</h4>
                      <p>Really Great Company</p>
                      <p>2016 - Present</p>
                    </div>
                    <div className="right">
                      <ul>
                        <li>Database administration and website design</li>
                        <li>
                          Built the logic for a streamlined ad-serving platform that
                          scaled
                        </li>
                        <li>
                          Educational institutions and online classroom management
                        </li>
                      </ul>
                    </div>
                  </div>
              }

            </div>
          </div>
          :

          ""}


        {sections.volunteer ?
          <div className="volunteer">
            <div className="top">
              <div className="heading">
                <h4>volunteer</h4>
              </div>
              <div className="line"></div>
            </div>
            <div className="three">
              <div className="content">
                {userData.volunteer ? (
                  userData.volunteer.map((volunteer, i) => (
                    <div className="left">
                      <h4>{`${volunteer.institutionName ? volunteer.institutionName : ""}${volunteer.institutionName && (volunteer.cityName || volunteer.country) ? " | " : ""}${volunteer.cityName ? volunteer.cityName : ""}${volunteer.cityName && volunteer.country ? ", " : ""}${volunteer.country ? volunteer.country : ""}`}</h4>
                      <p>{`${volunteer?.startMonth ||
                        volunteer?.startYear ||
                        volunteer?.endMonth ||
                        volunteer?.endYear ||
                        volunteer?.currentlyVolunteerHere
                        ? `${volunteer?.startMonth ? volunteer?.startMonth : ""
                        } ${volunteer?.startYear ? volunteer?.startYear : ""
                        } ${volunteer?.endMonth ||
                          volunteer?.endYear ||
                          volunteer?.currentlyVolunteerHere
                          ? "-"
                          : ""
                        } ${volunteer?.currentlyVolunteerHere
                          ? "Present"
                          : `${volunteer?.endMonth ? volunteer?.endMonth : ""
                          } ${volunteer?.endYear ? volunteer?.endYear : ""
                          }`
                        }`
                        : "Your Company Tenure"
                        }`}</p>
                      <p>{parse(
                        volunteer?.description
                          ? volunteer?.description
                          : "Your Volunteer Description"
                      )}</p>
                    </div>
                  )))

                  :
                  <div className="left">
                    <h4>Name</h4>
                    <p>2016 - Present</p>
                    <p>Really Great Company</p>
                  </div>
                }
                {/* <div className="left">
                <h4>Name</h4>
                <p>2016 - Present</p>
                <p>Really Great Company</p>
              </div>
              <div className="left">
                <h4>Name</h4>
                <p>2016 - Present</p>
                <p>Really Great Company</p>
              </div>
              <div className="left">
                <h4>Name</h4>
                <p>2016 - Present</p>
                <p>Really Great Company</p>
              </div>
              <div className="left">
                <h4>Name</h4>
                <p>2016 - Present</p>
                <p>Really Great Company</p>
              </div> */}
              </div>
            </div>
          </div>
          :
          ""

        }


        {sections.education ?
          <div className="education">
            <div className="top">
              <div className="heading">
                <h4>education</h4>
              </div>
              <div className="line"></div>
            </div>
            <div className="three">
              <div className="content">
                {userData.education ? userData.education.map((education, index) => (
                  <div className="left">
                    <h4>{`${education.schoolName ? education.schoolName : ""} ${education.schoolName && (education.cityName || education.country) ? "-" : ""} ${education.cityName ? education.cityName : ""}${education.cityName && education.country ? `,` : ""} ${education.country ? education.country : ""} ${education.schoolName || education.cityName || education.country ? "|" : ""} ${education.degreeName ? education.degreeName : ""}`}</h4>
                    <p>{parse(
                      education?.description
                        ? education?.description
                        : "Your Job Description"
                    )}</p>
                    <h4>{`${education?.startMonth ||
                      education?.startYear ||
                      education?.endMonth ||
                      education?.endYear ||
                      education?.currentlyStudyHere
                      ? `${education?.startMonth
                        ? education?.startMonth
                        : ""
                      } ${education?.startYear
                        ? education?.startYear
                        : ""
                      } ${education?.endMonth ||
                        education?.endYear ||
                        education?.currentlyStudyHere
                        ? "-"
                        : ""
                      } ${education?.currentlyStudyHere
                        ? "Present"
                        : `${education?.endMonth
                          ? education?.endMonth
                          : ""
                        } ${education?.endYear
                          ? education?.endYear
                          : ""
                        }`
                      }`
                      : "Your Degree Tenure"
                      }`}</h4>
                  </div>
                ))
                  :
                  <div className="left">
                    <h4>Master of Technology</h4>
                    <p>Really Great Company</p>
                    <h4>2016 - Present</h4>
                  </div>
                }
                {/* <div className="left">
                <h4>Master of Technology</h4>
                <p>Really Great Company</p>
                <h4>2016 - Present</h4>
              </div>
              <div className="left">
                <h4>Bachelor of Technology</h4>
                <p>Really Great Company</p>
                <h4>2014 - 2016</h4>
              </div>

              <div className="left">
                <h4>secondary school</h4>
                <p>Really Great Company</p>
                <h4>2010 - 2014</h4>
              </div> */}
              </div>
            </div>
          </div>
          :

          ""
        }


        {sections.certificates ?
          <div className="certification">
            <div className="top">
              <div className="heading">
                <h4>certification</h4>
              </div>
              <div className="line"></div>
            </div>
            <div className="three">
              <div className="content">
                {userData.certificates ? userData.certificates.map((certificate, index) => (
                  <div className="left">
                    <h4>{`${certificate.certificateName ? certificate.certificateName : ""}`}</h4>
                    <p>{certificate.certificateDate ? certificate.certificateDate : ""}</p>
                    <h4>{`${certificate.authority ? certificate.authority : ""}`}</h4>
                    {/* <p>Really Great Company</p> */}
                  </div>
                ))
                  :
                  <div className="left">
                    <h4>Name</h4>
                    <p>2016 - Present</p>
                    <p>Really Great Company</p>
                  </div>
                }
                {/* <div className="left">
        <h4>Name</h4>
        <p>2016 - Present</p>
        <p>Really Great Company</p>
      </div>
      <div className="left">
        <h4>Name</h4>
        <p>2016 - Present</p>
        <p>Really Great Company</p>
      </div>
      <div className="left">
        <h4>Name</h4>
        <p>2016 - Present</p>
        <p>Really Great Company</p>
      </div>
      <div className="left">
        <h4>Name</h4>
        <p>2016 - Present</p>
        <p>Really Great Company</p>
      </div> */}
              </div>
            </div>
          </div>
          :

          ""}


        {sections.awards ?
          <div className="award">
            <div className="top">
              <div className="heading">
                <h4>award</h4>
              </div>
              <div className="line"></div>
            </div>
            <div className="three">
              <div className="content">
                {userData.awards ? userData.awards.map((award, index) => (
                  <div className="left">
                    <h4>{`${award.awardTitle ? award.awardTitle : ""}`}</h4>
                    <p>{`${award.awardMonth ? award.awardMonth : ""}${award.awardMonth && award.awardYear ? " - " : ""}${award.awardYear ? award.awardYear : ""}`}</p>
                    <h4>{`${award.issuer ? award.issuer : ""}`}</h4>
                    {/* <p>Really Great Company</p> */}
                  </div>
                ))
                  :
                  <div className="left">
                    <h4>Name</h4>
                    <p>2016 - Present</p>
                    {/* <p>Really Great Company</p> */}
                  </div>
                }
                {/* <div className="left">
                <h4>Name</h4>
                <p>2016 - Present</p>
                <p>Really Great Company</p>
              </div>
              <div className="left">
                <h4>Name</h4>
                <p>2016 - Present</p>
                <p>Really Great Company</p>
              </div>
              <div className="left">
                <h4>Name</h4>
                <p>2016 - Present</p>
                <p>Really Great Company</p>
              </div>
              <div className="left">
                <h4>Name</h4>
                <p>2016 - Present</p>
                <p>Really Great Company</p>
              </div> */}
              </div>
            </div>
          </div>
          :
          ""

        }


        {sections.publications ?
          <div className="publication">
            <div className="top">
              <div className="heading">
                <h4>publication</h4>
              </div>
              <div className="line"></div>
            </div>
            <div className="three">
              <div className="content">
                {userData.publications ? userData.publications.map((publication, index) => (
                  <div className="left">
                    <h4>{publication.publicationTitle ? publication.publicationTitle : ""}</h4>
                    <p>{publication.publisher ? publication.publisher : ""}</p>
                    <p>{`${publication.publicationMonth ? publication.publicationMonth : ""}${publication.publicationMonth && publication.publicationYear ? ", " : ""}${publication.publicationYear ? publication.publicationYear : ""}`}</p>
                    <p>{parse(
                      publication?.description
                        ? publication?.description
                        : "Your Reference Description"
                    )}</p>
                  </div>
                ))
                  :
                  <div className="left">
                    <h4>Name</h4>
                    <p>Publisher name</p>
                    <p>2016 - Present</p>
                    <p>Really Great Company</p>
                  </div>
                }
                {/* <div className="left">
                <h4>Name</h4>
                <p>Publisher name</p>
                <p>2016 - Present</p>
                <p>Really Great Company</p>
              </div>
              <div className="left">
                <h4>Name</h4>
                <p>Publisher name</p>
                <p>2016 - Present</p>
                <p>Really Great Company</p>
              </div>
              <div className="left">
                <h4>Name</h4>
                <p>Publisher name</p>
                <p>2016 - Present</p>
                <p>Really Great Company</p>
              </div>
              <div className="left">
                <h4>Name</h4>
                <p>Publisher name</p>
                <p>2016 - Present</p>
                <p>Really Great Company</p>
              </div> */}
              </div>
            </div>
          </div>
          :

          ""
        }


        {sections.interests ?
          <div className="interest">
            <div className="top">
              <div className="heading">
                <h4>interest</h4>
              </div>
              <div className="line"></div>
            </div>
            <div className="three">
              <div className="content">
                {userData.interests ? userData.interests.map((interest, index) => (
                  <li>{interest}</li>

                ))
                  :
                  <li>Cricket</li>

                }
                {/* <li>English</li>
              <li> Urdu</li>
              <li> Cricket</li> */}
              </div>
            </div>
          </div>
          :

          ""
        }


        {sections.reference ?
          <div className="reference">
            <div className="top">
              <div className="heading">
                <h4>reference</h4>
              </div>
              <div className="line"></div>
            </div>
            <div className="three">
              <div className="content">
                {userData.reference ? userData.reference.map((reference, index) => (
                  <div className="left">
                    <div className="two">
                      <h4>{reference.personName ? reference.personName : ""}</h4>
                      <p>{reference.contact1 ? reference.contact1 : ""}</p>
                    </div>
                    <div className="two">
                      <p>{reference.referenceCompany ? reference.referenceCompany : ""}</p>
                      <p>{reference.contact2 ? reference.contact2 : ""}</p>
                    </div>
                    <p>{parse(
                      reference?.description
                        ? reference?.description
                        : "Your Reference Description"
                    )}</p>
                  </div>
                ))
                  :
                  <div className="left">
                    <div className="two">
                      <h4>Name</h4>
                      <p>123-456-7890</p>
                    </div>
                    <div className="two">
                      <p>Compoany Name</p>
                      <p>123-456-7890</p>
                    </div>
                    <p >Really Great Company</p>

                  </div>
                }
                {/* <div className="left">
                <div className="two">
                  <h4>Name</h4>
                  <p>123-456-7890</p>
                </div>
                <div className="two">
                  <p>Compoany Name</p>
                  <p>123-456-7890</p>
                </div>
                <p >Really Great Company</p>
              </div> */}
              </div>
            </div>
          </div>

          :

          ""
        }
      </div>
    </div>
  );
};

export default Template30;
