const handleBackToResume = () => {


    const locationParts = window.location.hostname.split(".");
    let sliceTill = -2;

    // for localhost
    // console.log("lp", locationParts);
    // console.log("test", locationParts.slice(-1)[0] == "localhost");
    const isLocalHost = locationParts.slice(-1)[0] == "localhost";
    if (isLocalHost) {
        sliceTill = -1;
    }

    const subdomain = locationParts.slice(0, sliceTill).join("");
    // console.log(subdomain);

    //
    if (subdomain != "app") {

        // window.location.href = window.location.protocol + "//" + "app." + window.location.host;
    }

    if (!subdomain) {

        window.location.href = window.location.protocol + "//" + "app." + window.location.host + "/login";
    }
    else if (subdomain == "www") {
        window.location.href = window.location.protocol + "//" + "app." + window.location.host.split(".")[1] + ".com" + "/login";

    }
    else {

        window.location.href = window.location.protocol + "//" + "app." + window.location.host.split(".")[1] + ".com" + "/login";
    }


}

export default handleBackToResume;