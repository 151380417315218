import React, { useEffect } from "react";
import "./template24.scss";
import { Icon } from "@iconify/react";
import parse from "html-react-parser"
import girl from "../../assets/images/girl.jpg"

function Template24({ userData, forDownload, sections, color, setColor, font, setFont, fontSize, setFontSize, lineHeight, setLineHeight, fromPreview }) {
  useEffect(() => {
    if (!fromPreview) {
      setColor(userData.templateColor ? userData.templateColor : "#2B582B")
    }
  }, [])
  const getColor = () => {
    return `:root{--t24Color: ${color};
    --t24LineHeight : ${lineHeight};
  --t24Font : ${font};
--paraFontSize: ${fontSize * 0.70}px;
--subHeadingFontSize: ${fontSize}px; }`;
  };
  const getPageMarginsF = () => {
    return `@page :first { margin: ${"0px"} ${"0px"} ${"10px"} ${"0px"} !important; }`;
  };
  const getPageMargins = () => {
    return `@page { margin: ${"30px"} ${"0px"} ${"10px"} ${"0px"} !important; }`;
  };
  const getPageMarginst = () => {
    return `.bottom { page-break-after: always; }`;
  };

  return (
    <section className="template24-sec" ref={forDownload}>
      <style>{getColor()}</style>
      <style>{getPageMargins()}</style>
      <style>{getPageMarginsF()}</style>
      <style>{getPageMarginst()}</style>
      <div className="top">
        <div className="left">
          <h2 className="heading">{`${userData?.fName || userData?.lName
            ? `${userData?.fName ? userData?.fName : ""} ${userData?.lName ? userData?.lName : ""
            }`
            : "Your Name"
            }`}</h2>
          <h3 className="status">{userData?.role ? userData?.role : "Your Role"}</h3>
        </div>
        <div className="right">
          <div className="address">
            {/* <p className="para bold-para">Your Street AddressHere</p> */}
            <p className="para bold-para">{`${userData?.city || userData?.country
              ? `${userData?.city ? userData?.city : ""} , ${userData?.country ? userData?.country : ""
              }`
              : "Your Location"
              }`}
            </p>
          </div>
          <div className="contact">
            <div>
              <p className="para">{userData?.phone ? userData?.phone : "Your Phone"}</p>
              <Icon
                icon="mingcute:phone-call-fill"
                color="black"
                width="18"
                height="18"
              />
            </div>
            <div>
              <p className="para">{userData?.email ? userData?.email : "Your Email"}</p>
              <Icon
                icon="ic:baseline-email"
                color="black"
                width="18"
                height="18"
              />
            </div>
            <div>
              <a href="#" className="para">
                {userData?.web ? userData?.web : "Your Linkedin"}
              </a>
              <Icon
                icon="mdi:web"
                color="black"
                width="18"
                height="18"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bottom">
        <div className="right">
          <img src={`${userData?.cvPictureObj ? URL.createObjectURL(userData?.cvPictureObj) : userData?.cvPicture ? userData?.cvPicture : girl}`} alt="..." />
          {/* <div className="summary para">
            <h4 className="sub-heading">About Me</h4>
            <p className="para">
              {parse(
                userData?.summary
                  ? userData?.summary
                  : `Lorem ipsum dolor sit amet, consectetur adip iscing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ulla mco
                laboris nisi ut aliquip ex ea commodo consequat.`
              )}

            </p>
          </div> */}
          {sections.education ? <>
            <div className="education-sec">
              <h4 className="sub-heading">Education</h4>
              {userData.education ? userData.education.map((education, index) => (
                <>
                  <div className="ist">
                    <p className="para bold-para">{education.degreeName}</p>
                    <p className="para light-para">
                      {`${education.schoolName}`} <span>|</span> {`${education.cityName}`} <span>|</span> {`${education.country}`}
                    </p>
                    <p className="para">{`${education?.startMonth ||
                      education?.startYear ||
                      education?.endMonth ||
                      education?.endYear ||
                      education?.currentlyStudyHere
                      ? `${education?.startMonth
                        ? education?.startMonth
                        : ""
                      } ${education?.startYear
                        ? education?.startYear
                        : ""
                      } ${education?.endMonth ||
                        education?.endYear ||
                        education?.currentlyStudyHere
                        ? "-"
                        : ""
                      } ${education?.currentlyStudyHere
                        ? "Present"
                        : `${education?.endMonth
                          ? education?.endMonth
                          : ""
                        } ${education?.endYear
                          ? education?.endYear
                          : ""
                        }`
                      }`
                      : "Your Degree Tenure"
                      }`}</p>
                  </div>
                </>
              )) :
                <>
                  <div className="ist">
                    <p className="para bold-para">Degree</p>
                    <p className="para light-para">
                      Institute <span>|</span> City <span>|</span> Country
                    </p>
                    <p className="para">Date</p>
                  </div>
                </>
              }

            </div>
          </> : ""}

          {sections.certificates ? <>
            <div className="certificate-sec">
              <h4 className="sub-heading">Certificates</h4>
              {userData.certificates ? (
                userData.certificates.map((certificate, i) => (
                  <>
                    <div className="ist">
                      <p className="para bold-para">{certificate.certificateName}</p>
                      <p className="para light-para">
                        {certificate.authority}
                      </p>
                      <p className="para">{`${certificate.certificateDate ? certificate.certificateDate : ""}`}</p>
                    </div>

                  </>
                ))
              ) : (
                <>
                  <div className="ist">
                    <p className="para bold-para">Degree</p>
                    <p className="para light-para">
                      Institute <span>|</span> City <span>|</span> Country
                    </p>
                    <p className="para">Date</p>
                  </div>
                </>
              )}

            </div>
          </> : ""}
          {sections.skills ? <>

          </> : ""}
          {sections.skills ? <>
            <div className="skill-sec">
              <h4 className="sub-heading">Skills</h4>
              <div className="ist">
                {userData.skills
                  ? userData.skills.map((skill, index) => (

                    <p className="para">{skill}{userData.skills.length - 1 == index ? "" : ", "}</p>

                  ))
                  : <>
                    <p className="para">Skill 1</p>
                    <p className="para">Skill 1</p>
                    <p className="para">Skill 1</p>
                    <p className="para">Skill 1</p>
                    <p className="para">Skill 1</p>
                  </>}
              </div>
            </div>
          </> : ""}

          {sections.awards ? <>
            <div className="award-sec">
              <h4 className="sub-heading">AWARDS</h4>
              <div>
                {userData.awards ? userData.awards.map((award, index) => (
                  <p className="para">
                    {award.awardTitle} <span>|</span> {`${award.awardMonth ? award.awardMonth : ""}${award.awardMonth && award.awardYear ? ", " : ""}${award.awardYear ? award.awardYear : ""}`}
                  </p>
                ))
                  :
                  <>
                    <p className="para">
                      Skill 1 <span>|</span> Date
                    </p>
                    <p className="para">
                      Skill 1 <span>|</span> Date
                    </p>
                  </>
                }

              </div>
            </div>
          </> : ""}
          {sections.interests ? <>
            <div className="interest-sec">
              <h4 className="sub-heading">Interests</h4>
              <div>
                {userData.interests ? userData.interests.map((interest, index) => (
                  <p className="para">{interest}{userData.interests.length - 1 == index ? "" : ", "}</p>


                ))
                  :
                  <>

                    <p className="para">Skill 1</p>
                    <p className="para">Skill 1</p>
                    <p className="para">Skill 1</p>

                  </>}

              </div>
            </div>
          </> : ""}

        </div>
        <div className="left">
          <div className="expereince-sec">
            <h4 className="sub-heading">About Me</h4>
            <>
              <div className="ist">
                <p className="para">
                  {parse(
                    userData?.summary
                      ? userData?.summary
                      : `Lorem ipsum dolor sit amet, consectetur adip iscing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ulla mco
                laboris nisi ut aliquip ex ea commodo consequat.`
                  )}
                </p>



              </div>
            </>
          </div>
          {sections.experience ? <>
            <div className="expereince-sec">
              <h4 className="sub-heading">Experience</h4>
              {userData.experience ? (
                userData.experience.map((experience, i) => (
                  <>
                    <div className="ist">
                      <p className="para bold-para">{experience.jobTitle}</p>
                      <p className="para light-para">
                        {experience.companyName} <span>| </span>
                        {`${experience?.startMonth ||
                          experience?.startYear ||
                          experience?.endMonth ||
                          experience?.endYear ||
                          experience?.currentlyWorkHere
                          ? `${experience?.startMonth ? experience?.startMonth : ""
                          } ${experience?.startYear ? experience?.startYear : ""
                          } ${experience?.endMonth ||
                            experience?.endYear ||
                            experience?.currentlyWorkHere
                            ? "-"
                            : ""
                          } ${experience?.currentlyWorkHere
                            ? "Present"
                            : `${experience?.endMonth ? experience?.endMonth : ""
                            } ${experience?.endYear ? experience?.endYear : ""
                            }`
                          }`
                          : "Your Company Tenure"
                          }`}
                      </p>
                      <p className="para">
                        {parse(
                          experience?.description
                            ? experience?.description
                            : "Your Job Description"
                        )}
                      </p>
                    </div>
                  </>
                ))
              ) : (
                <>
                  <div className="ist">
                    <p className="para bold-para">Developer</p>
                    <p className="para light-para">
                      Company Name <span>|</span>
                      Date
                    </p>
                    <p className="para">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum,
                      illo.
                    </p>
                  </div>
                </>
              )}

            </div>
          </> : ""}

          {sections.projects ? <>
            <div className="project-sec">
              <h4 className="sub-heading">Projects</h4>
              {userData.projects ? (
                userData.projects.map((project, i) => (
                  <>
                    <div className="ist">
                      <p className="para bold-para">{project.projectType}</p>
                      <p className="para light-para">
                        {project.projectName} <span>| </span>
                        {`${project?.startMonth ||
                          project?.startYear ||
                          project?.endMonth ||
                          project?.endYear ||
                          project?.currentlyWorkingOnThisProject
                          ? `${project?.startMonth ? project?.startMonth : ""
                          } ${project?.startYear ? project?.startYear : ""
                          } ${project?.endMonth ||
                            project?.endYear ||
                            project?.currentlyWorkingOnThisProject
                            ? "-"
                            : ""
                          } ${project?.currentlyWorkingOnThisProject
                            ? "Present"
                            : `${project?.endMonth ? project?.endMonth : ""
                            } ${project?.endYear ? project?.endYear : ""
                            }`
                          }`
                          : "Your Company Tenure"
                          }`}
                      </p>
                      <p className="para">
                        {parse(
                          project?.description
                            ? project?.description
                            : "Your Job Description"
                        )}
                      </p>
                    </div>
                  </>
                ))
              ) : (
                <>
                  <div className="ist">
                    <p className="para bold-para">Company Name</p>
                    <p className="para light-para">
                      Project Name <span>|</span>
                      Date
                    </p>
                    <p className="para">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum,
                      illo.
                    </p>
                  </div>
                </>
              )}

            </div>
          </> : ""}
          {sections.volunteer ? <>
            <div className="volunteer-sec">
              <h4 className="sub-heading">Volunteers</h4>
              {userData.volunteer ? (
                userData.volunteer.map((volunteer, i) => (
                  <>
                    <div className="ist">
                      <p className="para light-para">
                        {volunteer.institutionName} <span>|</span>
                        {`${volunteer?.startMonth ||
                          volunteer?.startYear ||
                          volunteer?.endMonth ||
                          volunteer?.endYear ||
                          volunteer?.currentlyVolunteerHere
                          ? `${volunteer?.startMonth ? volunteer?.startMonth : ""
                          } ${volunteer?.startYear ? volunteer?.startYear : ""
                          } ${volunteer?.endMonth ||
                            volunteer?.endYear ||
                            volunteer?.currentlyVolunteerHere
                            ? "-"
                            : ""
                          } ${volunteer?.currentlyVolunteerHere
                            ? "Present"
                            : `${volunteer?.endMonth ? volunteer?.endMonth : ""
                            } ${volunteer?.endYear ? volunteer?.endYear : ""
                            }`
                          }`
                          : "Your Company Tenure"
                          }`}
                      </p>
                      <p className="para">
                        {parse(
                          volunteer?.description
                            ? volunteer?.description
                            : "Your Volunteer Description"
                        )}
                      </p>
                    </div>
                  </>
                ))
              ) : (
                <>
                  <div className="ist">
                    <p className="para light-para">
                      Project Name <span>|</span>
                      Date
                    </p>
                    <p className="para">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum,
                      illo.
                    </p>
                  </div>
                </>
              )}
              {/* <div className="ist">
              <p className="para light-para">
                Project Name <span>|</span>
                Date
              </p>
              <p className="para">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum,
                illo.
              </p>
            </div> */}
            </div>
          </> : ""}

          {sections.publications ? <>
            <div className="publication-sec">
              <h4 className="sub-heading">Publications</h4>
              {userData.publications ? (
                userData.publications.map((publication, i) => (
                  <>
                    <div className="ist">
                      <p className="para bold-para">{publication.publisher}</p>
                      <p className="para light-para">
                        {publication.publicationTitle} <span>| </span>
                        {`${publication.publicationMonth ? publication.publicationMonth : ""}${publication.publicationMonth && publication.publicationYear ? ", " : ""}${publication.publicationYear ? publication.publicationYear : ""}`}
                      </p>
                      <p className="para">
                        {parse(
                          publication?.description
                            ? publication?.description
                            : "Your Publication Description"
                        )}
                      </p>
                    </div>

                  </>
                ))
              ) : (
                <>
                  <div className="ist">
                    <p className="para bold-para">Publisher Name</p>
                    <p className="para light-para">
                      Book Name <span>|</span>
                      Date
                    </p>
                    <p className="para">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum,
                      illo.
                    </p>
                  </div>
                </>
              )}

            </div>
          </> : ""}

          {sections.reference ? <>
            <div className="reference-sec">
              <h4 className="sub-heading">Reference</h4>
              {userData.reference ? userData.reference.map((reference, index) => (
                <>
                  <div className="ist">
                    <p className="para bold-para">{reference.personName}</p>
                    <p className="para dark-para">{reference.referenceCompany}</p>
                    <p className="para light-para">
                      {reference.contact1} <span>|</span> {reference.contact2}
                    </p>
                    <p className="para">
                      {parse(
                        reference?.description
                          ? reference?.description
                          : "Your Reference Description"
                      )}
                    </p>
                  </div>

                </>
              ))
                :
                <>
                  <div className="ist">
                    <p className="para bold-para">Person Name</p>
                    <p className="para dark-para">Company Name</p>
                    <p className="para light-para">
                      Contact1 <span>|</span> Contact2
                    </p>
                    <p className="para">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum,
                      illo.
                    </p>
                  </div>
                </>}

            </div>
          </> : ""}

        </div>
      </div>
    </section>
  );
}

export default Template24;
