import React, { useEffect, useState } from "react";
import "../../style/main.scss";
import Button from "../shared/button";
import remove from "../../assets/icons/delete.svg";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../config";
import { useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { firestoredb } from "../../config";
import { toast } from "react-toastify";


const Skill = ({ setUserData, userData, sections, setSections, isPaidUser }) => {
  const [totalSkills, setTotalSkills] = useState([1]);
  const [loading, setLoading] = useState(false);
  const [forUpdate, setForUpdate] = useState(false);
  const [downloadLimitExceed, setDownloadLimitExceed] = useState(false);
  const navigate = useNavigate();
  const handleDownloadLimitExceedModal = () => {
    setDownloadLimitExceed(prev => !prev)
  }
  const handleCategoryChange = (e) => {
    setUserData((prev) => ({ ...prev, skillName: e.target.value }));
  };

  const handleChange = (e, index) => {
    // console.log(e.target.name);
    // console.log(index);
    let temp = userData?.skills ? userData?.skills : [];
    temp[+index] = e.target.value;

    setUserData((prev) => ({ ...prev, skills: temp }));
  };

  const handleAddMore = () => {
    setTotalSkills((prev) => [...prev, 1]);
  };
  const handleDelete = (index) => {
    // console.log(index);
    let t = userData?.skills;
    t.splice(index, 1);
    setUserData((prev) => ({ ...prev, skills: t }));

    let temp = totalSkills;
    temp.splice(index, 1);

    setTotalSkills(temp);
  };

  const handleASkillsGeneration = async () => {
    if (isPaidUser) {
      if (userData?.role && userData.role.length > 1) {
        try {
          const tokenId = await auth.currentUser.getIdToken();
          const config = {
            headers: { Authorization: `Bearer ${tokenId}` }
          };
          setLoading(true);
          // const result = await axios.post("http://localhost:8080/generateSummary", tempData);
          // const result = await axios.post("https://resumebuilderserver.fly.dev/generateSkills", { role: userData.role });
          const result = await axios.post(
            "https://resume-ai-nodejs.onrender.com/generateSkills",
            { role: userData.role }, config
          );
          // const result = await axios.post(
          //   "http://localhost:8080/generateSkills",
          //   { role: userData.role }
          // );
          // console.log(result.data);
          if (!result.data.message) {

            setUserData((prev) => ({
              ...prev,
              skills: [...userData.skills, ...result.data.skills],
            }));
            setLoading(false);

          }
          else {
            toast.error("Some Error Occured. Please try again. If the error persists please contact support")
            setLoading(false);
          }

          setForUpdate(!forUpdate);
          setLoading(false);
        } catch (e) {
          console.error(e.message);
          setLoading(false);
        }
      } else {
        toast("Please Enter Valid Role First.");
      }
    }
    else {
      const docRef = doc(firestoredb, "users", auth.currentUser.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        if (!docSnap.data().downloadCount) {
          if (userData?.role && userData.role.length > 1) {
            try {
              const tokenId = await auth.currentUser.getIdToken();
              const config = {
                headers: { Authorization: `Bearer ${tokenId}` }
              };
              setLoading(true);
              // const result = await axios.post("http://localhost:8080/generateSummary", tempData);
              // const result = await axios.post("https://resumebuilderserver.fly.dev/generateSkills", { role: userData.role });
              const result = await axios.post(
                "https://resume-ai-nodejs.onrender.com/generateSkills",
                { role: userData.role }, config
              );
              // console.log(result.data);
              if (!result.data.message) {

                setUserData((prev) => ({
                  ...prev,
                  skills: [...userData.skills, ...result.data.skills],
                }));
              }

              setForUpdate(!forUpdate);
              setLoading(false);
            } catch (e) {
              console.error(e.message);
              setLoading(false);
            }
          } else {
            toast("Please Enter Valid Role First.");
          }
        }
        else if (docSnap.data().downloadCount < 2) {
          if (userData?.role && userData.role.length > 1) {
            try {
              const tokenId = await auth.currentUser.getIdToken();
              const config = {
                headers: { Authorization: `Bearer ${tokenId}` }
              };
              setLoading(true);
              // const result = await axios.post("http://localhost:8080/generateSummary", tempData);
              // const result = await axios.post("https://resumebuilderserver.fly.dev/generateSkills", { role: userData.role });
              const result = await axios.post(
                "https://resume-ai-nodejs.onrender.com/generateSkills",
                { role: userData.role }, config
              );
              // console.log(result.data);
              if (!result.data.message) {

                setUserData((prev) => ({
                  ...prev,
                  skills: [...userData.skills, ...result.data.skills],
                }));
              }

              setForUpdate(!forUpdate);
              setLoading(false);
            } catch (e) {
              console.error(e.message);
              setLoading(false);
            }
          } else {
            toast("Please Enter Valid Role First.");
          }
        }
        else {
          // toast("You have exceeded the number of free downloads. Please upgrade your account to Pro Plan.")
          setDownloadLimitExceed(true);
        }
      }
    }

  };

  useEffect(() => {
    // console.log(totalSkills);
  }, [totalSkills]);

  useEffect(() => {
    if (!userData?.skills) {
      setUserData((prev) => ({ ...prev, skills: [] }));
    } else {
      setTotalSkills([]);
      userData?.skills.map((item, i) => {
        setTotalSkills((prev) => [...prev, 1]);
      });
    }
  }, [userData.skills]);

  return (
    <div>
      {" "}
      <div className="outer v-center h-center">
        <div className="Skill ">
          <div
            className="top "
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p className="text-purple" style={{ margin: "0" }}>
              Skills
            </p>
            <div
              style={{
                width: "15px",
                height: "15px",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                border: "1px solid black",
                backgroundColor: sections.skills ? "black" : "transparent",
              }}
              onClick={() =>
                sections.skills
                  ? setSections((prev) => ({ ...prev, skills: false }))
                  : setSections((prev) => ({ ...prev, skills: true }))
              }
            >
              <div
                style={{
                  width: "7px",
                  height: "10px",
                  borderRight: "1px solid white",
                  borderBottom: "1px solid white",
                  transform: "rotate(45deg)",
                  visibility: sections.skills ? "visible" : "hidden",
                }}
              ></div>
            </div>
          </div>
          {/* <div className="cat">
            <p className="text-black1">Skill category #01</p>
          </div>
          {// New items }
          <div className="new-item gap-3 d-flex">
            <div className="input-box w-100">
              <label htmlFor="" className="w-100">
                <p className="p4"> Skill category name</p>
                <input type="text" onChange={handleCategoryChange} />
              </label>
            </div>
          </div> */}

          {/* Multiple Inputs */}
          <div className="new-item  w-100 mt-4 pt-3 gap-4 ">
            {/* <div className="input-box ">
              <label htmlFor="" className="w-100 h-center v-center ">
                <input name="0" onChange={handleChange} type="text" placeholder="Lorem ipsum" value={userData?.skills && userData?.skills.length >= 1 ? userData?.skills[0] : ""} />
                <img src={remove} alt="delete-icon" className="ms-3" />
              </label>
            </div> */}

            {totalSkills.map((skill, index) => (
              <div className={`input-box ${index > 0 ? "mt-4" : ""}`}>
                <label htmlFor="" className="w-100 h-center v-center">
                  <input
                    name={`Skill ${index}`}
                    onChange={(e) => handleChange(e, index)}
                    type="text"
                    placeholder="Write Skills Here"
                    value={userData?.skills ? userData?.skills[index] : ""}
                  />
                  <img
                    src={remove}
                    alt="delete-icon"
                    className="ms-3"
                    onClick={() => handleDelete(index)}
                  />
                </label>
              </div>
            ))}

            {/* AI Writer button */}
            <div
              className="writer-btn pointer mt-5"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              {/* <Button
                text="AI Writer"
                width="137px"
                height="39px"
                className="btn-prime-1 btn-primary text-white rounded  "
              /> */}
              <Button
                text="Add More Skills"
                onClick={handleAddMore}
                width="137px"
                height="39px"
                className="btn-prime-1 btn-primary text-white rounded  "
              />
              <Button
                text="AI Generation"
                onClick={handleASkillsGeneration}
                width="137px"
                height="39px"
                className="btn-prime-1 btn-primary text-white rounded  "
              />
            </div>
          </div>
          {/* Scroll Button */}
          {/* <div className="input-box mt-5 ">
            <label htmlFor="" className="w-100 h-center v-center">
              <Button
                text="^"
                height="39px"
                className="bg-scroll rounded  w-100"
              />
            </label>
          </div> */}
        </div>
      </div>
      <Modal show={downloadLimitExceed} onHide={handleDownloadLimitExceedModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Free Resources Utilized!</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          <div style={{ padding: "30px 10px", display: "flex", flexDirection: "column", gap: "30px", alignItems: "center" }}>
            <div style={{ display: "flex", alignItems: "flex-start", gap: "5px", width: "100%", flexDirection: "column" }}>
              <h5>Oh no, have exceeded the number of free downloads. Please upgrade your account to Pro Plan to continue enjoying Resume360. </h5>
              <h6 style={{ marginTop: "20px" }}>Benefits of Upgrading:</h6>
              <ul>
                <li>
                  Proofreading From Experts.
                </li>
                <li>
                  35+ professional resumes templates.
                </li>
                <li>
                  AI resume checker.
                </li>
                <li>
                  AI Writer.
                </li>
                <li>
                  Priority emails support.
                </li>
                <li>
                  And Much More.
                </li>
              </ul>
            </div>
            <button style={{ backgroundColor: "#562863", padding: "5px 7px", borderRadius: "5px" }} onClick={() => navigate("/money")} >Upgrade</button>
          </div>


        </Modal.Body>
      </Modal>
      <Modal show={loading} centered>
        <Modal.Header closeButton>
          <Modal.Title>Generating...</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div class="lds-ringDashboard">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Skill;
