import React, { useState } from 'react'
import Banner from '../home/banner'
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../config';
import { useNavigate } from 'react-router-dom';

const Welcome = ({ userData, setUserData, setGeneratedFromCv, userName, isPaidUser }) => {
    const [fromWelcome, setFromWelcome] = useState(true);
    const navigate = useNavigate();


    onAuthStateChanged(auth, (user) => {
        if (!user) {
            navigate("/login");
        }
    });


    return (
        <Banner fromWelcome={fromWelcome} userData={userData} setUserData={setUserData} setGeneratedFromCv={setGeneratedFromCv} userName={userName} isPaidUser={isPaidUser}></Banner>

    )
}

export default Welcome