/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import { React, useEffect, useState } from "react";
import "../../style/main.scss";
import facebook from "../../assets/icons/facebook.svg";
import apple from "../../assets/icons/apple.svg";
import google from "../../assets/icons/google.svg";
import cartoon from "../../assets/icons/cartoon.png";
import shade from "../../assets/images/shades/shade2.png";
import Button from "../shared/button";
import eyeicon from "../../assets/icons/eyeicon.svg"
import eyefill from "../../assets/icons/eye-fill.svg"
import {
  getAuth,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  updateProfile,
  sendEmailVerification,
  signOut,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import { collection, addDoc, setDoc, doc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import { auth, firestoredb } from "../../config";

const Register = () => {
  const navigate = useNavigate();

  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  // const [number, setContactNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPswd] = useState("");
  const [confirmPassword, setConfirmPswd] = useState("");
  // const [homeAddress, setHomeAddress] = useState("")
  const [errorObj, setErrorObj] = useState("");
  const [visible, setVisible] = useState(false);
  const [show, setShow] = useState(false);
  const [userisLogged, setUserisLogged] = useState(false);
  const provider = new GoogleAuthProvider();
  const Validation = () => {
    let errorobj = {
      name: false,
      number: false,
      email: false,
      password: false,
    };
    if ((fName == "") || (/\d/.test(fName))) {
      errorobj["name"] = true;
    }
    if (fName.length > 14) {
      errorobj["name"] = true;
    }
    if ((lName == "") || (/\d/.test(lName))) {
      errorobj["name"] = true;
    }
    if (lName.length > 14) {
      errorobj["name"] = true;
    }
    // if (number == "") {
    //   errorobj["number"] = true;
    // }
    if (email == "") {
      errorobj["email"] = true;
    }
    if (email.length > 40) {
      errorobj["email"] = true;
    }
    if (password == confirmPassword) {
      errorobj["password"] = false;
    } else {
      errorobj["password"] = true;
    }
    if (password.length < 8) {
      errorobj["password"] = true;
    }
    if (password.length > 62) {
      errorobj["password"] = true;
    }

    setErrorObj(errorobj);
    return errorobj;
  };

  useEffect(() => {
    // console.log(errorObj);
  }, [errorObj]);
  useEffect(() => {
    // console.log(email);
  }, [email]);

  const handleRegisterUser = async () => {
    const valid = Validation();
    // console.log(valid);
    if (valid.name || valid.name || valid.email || valid.password) {
      // console.log("errrrrr");
    } else {
      try {
        await createUserWithEmailAndPassword(auth, email, password)
          .then(async (res) => {
            const { user } = res;
            updateProfile(auth.currentUser, {
              displayName: fName + " " + lName,
            })
              .then(async () => {
                await setDoc(doc(firestoredb, "users", user.uid), {
                  uid: user.uid,
                  fullName: fName + " " + lName,
                  userEmail: email,
                  userBio: "",
                  gender: "",
                  phoneNumber: "",
                  address: "",
                  firstName: fName,
                  cvData: {
                    fName: fName,
                    lName: lName,
                    email: user.email
                  }
                })
                  .then((res1) => {
                    const userData = {
                      email: user.email,
                      name: user.displayName,
                      uid: user.uid,

                    };
                    const jsonString = JSON.stringify(userData);
                    localStorage.setItem("resumebuilderuser", jsonString);
                    toast("Signup success", { toastId: "userloggedin" });
                    sendEmailVerification(auth.currentUser)
                      .then((res) => {
                        // console.log(res);
                      })
                      .catch((err) => {
                        // console.log(err);
                      });

                    // signOut(auth)
                    //   .then(() => {
                    //     // console.log("User signed out successfully.");
                    //     navigate("/login");
                    //   })
                    //   .catch((error) => {
                    //     // console.log("Error signing out:", error);
                    //   });
                  })
                  .catch((error) => {
                    // console.log(error);
                    return false;
                  });
              })
              .catch((error) => {
                // console.log(error);
                return false;
              });
          })
          .catch((error) => {
            // console.log(error);
            if (
              error ==
              "FirebaseError: Firebase: Error (auth/email-already-in-use)."
            ) {
              toast("This email is already Registered", {
                toastId: "alreadyRegistered",
              });
            }
            return false;
          });
      } catch (err) {
        console.error(err);
        alert(err.message);
        return false;
      }
      return "";
    }
  };
  async function signup() {
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        // console.log(user);
        // IdP data available using getAdditionalUserInfo(result)
        // ...
        navigate("/login");
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        // console.log(error);
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  }
  useEffect(() => {
    // const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in.
        const { uid } = user;
        navigate("/login");
        setUserisLogged(true);
      } else {
        // User is signed out.
        setUserisLogged(false);

        // router.push({
        //     pathname: "/login",
        // });
      }
    });
    return () => unsubscribe();
  }, []);

  return (

    <div className="signin ">
      <div className="signin-content">
        <div className="left text-white">
          <h1>Ready to create your<br className="mob-hide" /> resume? </h1>
          <h3 className="mt-3">
            Get started for free. No <br /> credit card required.{" "}
          </h3>
          <p className="mt-3">If you already have an account</p>
          <p>
            You can{" "}
            <span
              className="text-purple pointer fw-semibold"
              onClick={() => navigate("/login")}
            >
              Login here!
            </span>
          </p>
        </div>
        <div className="cartoon-img img-fluid">
          <img src={cartoon} alt="signin-cartoon" />
        </div>
        <div className="right text-white">
          <h2>Sign Up</h2>
          <div className="input-boxes v-center flex-column">
            {/* <input type="email" name="" id="" placeholder="Enter Email"
            value={main.email}
          // onChange={handleInputChange} 
          /> */}
            <input
              type="text"
              name=""
              id=""
              placeholder="First Name"
              className="mb-3"
              value={fName}
              onChange={(e) => setFName(e.target.value)}
            // onChange={handleInputChange}
            />
            <input
              type="text"
              name=""
              id=""
              placeholder="Last Name"
              className="mb-3"
              value={lName}
              onChange={(e) => setLName(e.target.value)}
            // onChange={handleInputChange}
            />
            {errorObj.name ? <p>Please Enter Valid Name</p> : <></>}

            <input
              type="email"
              placeholder="Email"
              onChange={(e) => {
                const emailRegex =
                  /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
                const test = emailRegex.test(e.target.value);

                if (test) {
                  setEmail(e.target.value);
                } else {
                  // console.log("elese");

                  setEmail("");
                }
              }}
            />
            {errorObj.email ? <p>Please enter valid email address</p> : <></>}
            {/* <input
                type="number"
                name=""
                id=""
                placeholder="Contact number"
                className="mt-3"
                value={number}
                onChange={(e) => setContactNumber(e.target.value)}
              // onChange={handleInputChange}
              maxValue={2}
              
              />
              {errorObj.number ? <p>Hello number error</p> : <></>} */}
            <div className="firstpass">
              <div>
                <input


                  name=""
                  id=""
                  placeholder="Password"
                  className="mt-3"
                  value={password}
                  type={visible ? "text" : "password"}
                  onChange={(e) => setPswd(e.target.value)}
                // onChange={handleInputChange}
                /></div>
              <div className="passicon" onClick={() => setVisible(!visible)}>
                {visible ? <img className=" pointer" src={eyefill} alt="..." /> : <img className=" pointer" src={eyeicon} alt="..." />}
              </div>
            </div>
            <div className="signuppass ">
              <div>
                <input

                  name=""
                  id=""
                  type={show ? "text" : "password"}
                  placeholder="Confirm Password"
                  className="mt-3"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPswd(e.target.value)}
                // onChange={handleInputChange}
                /></div>
              <div className="confirmPass" onClick={() => setShow(!show)}>
                {
                  show ? <img className=" pointer" src={eyefill} /> : <img className=" pointer" src={eyeicon} alt="..." />
                }
              </div>
            </div>
            {errorObj.password ? <p>Invalid Password</p> : <></>}
            {/* <img className="viewcnfpass pointer" src={eyeicon} alt="..." /> */}
            {/* <img className="viewcnfpass pointer" src={eyefill} alt="..." /> */}
          </div>
          <div className="login-btn mt-5 pt-2">
            <Button
              text="Register"
              className="btn-prime btn-primary changeBtn  text-white br-8"
              // onClick={(e) => alert("clicked")}
              onClick={handleRegisterUser}
            />
          </div>
          <p className="v-center h-center mt-5 text-white">
            or continue with
          </p>
          <div className="icon-section v-center h-center mt-5">
            {/* <a href="#">
              <img src={facebook} alt="" />
            </a> */}

            <a href="#">
              <img src={google} alt="" onClick={signup} />
            </a>
          </div>
        </div>
      </div>
      <div className="shade">
        <img src={shade} alt="" />
      </div>
    </div>

  );
};

export default Register;
