/* eslint-disable no-unused-vars */
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

import { createUserWithEmailAndPassword, getAuth, sendEmailVerification, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { doc, getFirestore, setDoc } from "firebase/firestore"
import { getStorage } from "firebase/storage";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyB9SWMvx0tUInWzGybc0wN0cxex265oHpI",
    authDomain: "resumifi.firebaseapp.com",
    projectId: "resumifi",
    storageBucket: "resumifi.appspot.com",
    messagingSenderId: "814960496828",
    appId: "1:814960496828:web:f6d449cae3a852c88080b9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestoredb = getFirestore(app)
const auth = getAuth(app);
const storage = getStorage(app);

// export const registerWithEmailAndPassword = async (email, password, name,number) => {

//     let error = false;
//     await createUserWithEmailAndPassword(auth, email, password)
//         .then(async (res) => {
//             const user = res.user;

//             sendEmailVerification(res.user).then(res => {
//                 // console.log(res);
//             }).catch(err => {
//                 // console.log(err);
//             })
//             await setDoc(doc(firestoredb, "Users", user.uid), {
//                 uid: user.uid,
//                 email: email,
//                 isApproved: true,
//                 isAdmin: false,
//                 name:name,
//                 phoneNumber: number,

//             })
//                 .then((res) => {
//                     localStorage.isSignupSuccess = true;
//                     // console.log(user);
//                     let userData = {
//                         email: user.email,
//                         name: user.displayName,
//                         uid: user.uid,
//                     };
//                     const jsonString = JSON.stringify(userData);
//                     localStorage.setItem("tuxnftuser", jsonString);
//                     toast("Signup success");
//                     // window.location.reload(false);
//                     signOut(auth)
//                         .then(() => {
//                             // console.log("User signed out successfully.");
//                         })
//                         .catch((error) => {
//                             // console.log("Error signing out:", error);
//                         });

//                     // });
//                 })
//                 .catch(function (error) {
//                     // console.log(error);
//                     return false;
//                 });
//         })
//         .catch((error) => {
//             // An error occurred
//             // console.log(error);

//             if (error == "FirebaseError: Firebase: Error (auth/email-already-in-use).") {
//                 toast("This Email is already Registered")
//                 // console.log("test");
//             }
//             return false;
//         });
// }

// export const logInWithEmailAndPassword = async (email, password, name) => {
//     return new Promise((resolve, reject) => {
//         signInWithEmailAndPassword(auth, email, password, name)
//             .then((userCred) => {
//                 localStorage.isSigninSuccess = true;
//                 resolve({ success: true, userCred: userCred })
//                 toast("Login success", { toastId: "userloggedin" });
//             })
//             .catch((e) => {
//                 toast.error(" User and Password not correct", {
//                     toastId: "loginError",
//                 });
//                 reject({ success: false, error: e })
//             });
//     })
// };



export { firestoredb, auth, storage }