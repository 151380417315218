import React from "react";
import "../../style/main.scss";
import { Accordion } from "react-bootstrap";
const Faq = () => {
  return (
    <div id="faq">
      <div className="resume-faq">
        <h1 className="text-center text-white mb-5 pb-2">
          Frequently Asked Questions
        </h1>
        <div>
          <Accordion defaultActiveKey="" className="faq-accordian">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
              Why should I use a resume builder instead of creating a resume from scratch?

              </Accordion.Header>
              <Accordion.Body>
              A resume builder goes about as a remote helper, improving the resume creation process and guaranteeing a clean and proficient result. It saves time, giving an easy-to-understand interface that guides clients through each segment with effectiveness. This device goes past simple efficiency, lifting the nature of resumes by extending to key experiences to tailor content for explicit employment opportunity necessities and establishing a positive connection with expected businesses.

              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
              What information do I need to provide for the resume builder?
              </Accordion.Header>
              <Accordion.Body>
              For the resume builder, you'll have to give fundamental subtleties, for example, individual data, schooling history, work insight, abilities, and contact subtleties. The instrument will incite you through each segment, guaranteeing a complete and balanced portrayal. Counting explicit accomplishments, projects, and quantifiable outcomes can additionally improve the viability of your resume. The more itemized and exact the data you input, the more custom-fitted and effective your last resume will be.

              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>
              Do I need any special skills to use the resume builder?

              </Accordion.Header>
              <Accordion.Body>
              Not the least bit! The resume builder is intended to be easy to understand, requiring no extraordinary abilities. Its instinctive point of interaction guides you through the cycle, provoking essential data and giving supportive ideas en route. Whether you're an old pro or a first-time work searcher, the device improves the resume creation process, guaranteeing that anybody can make an expert and powerful resume easily.

              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
              <Accordion.Header>
              Can I use the resume builder for academic or research positions?

              </Accordion.Header>
              <Accordion.Body>
              Our resume builder goes past versatility, offering particular elements for the scholarly world and examination. Tailor your archive with accuracy, featuring distributions, meeting introductions, and exploration approaches. The instrument's instinctive connection point guarantees that even perplexing subtleties are introduced flawlessly.

              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
              Can using a resume builder improve my chances of getting hired?

              </Accordion.Header>
              <Accordion.Body>
              Totally! Utilizing a resume manufacturer essentially upgrades your possibilities of getting employed. It guarantees your resume is expertly organized, outwardly engaging, and customized to explicit work necessities. The device guides you through introducing your abilities and encounters successfully, establishing a long-term connection with bosses. With a cleaned and champion resume, you increment your perceivability and present a convincing defense for what reason you're the ideal up-and-comer, eventually working on your possibilities in the cutthroat work market.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
              Will my resume look professional even if I'm not in a creative field?

              </Accordion.Header>
              <Accordion.Body>
              Totally! Our resume builder offers a different scope of formats, guaranteeing a clean and proficient appearance custom-fitted to different enterprises. Regardless of whether you're not in an imaginative field, the formats are intended to grandstand your abilities and encounters in a spotless, coordinated, and proficient way. You can pick a layout that lines up with your industry, permitting you to give your capabilities a dash of refinement and significance.

              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default Faq;
