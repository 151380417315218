import React, { useState, useEffect } from "react";
import "../../style/main.scss";
import search from "../../assets/icons/search.svg";
import review from "../../assets/icons/reviews.svg";
import target from "../../assets/icons/target.svg";
import girl from "../../assets/images/Dashboard/girl.png";
import boy from "../../assets/images/Dashboard/boy.png";
import { setDoc, updateDoc, doc, getDoc } from "firebase/firestore";
import { auth, firestoredb, storage } from "../../config";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { onAuthStateChanged } from "firebase/auth";
import Button from "../shared/button";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const Reviewrequest = ({ userData, handleDbSave, setUserData }) => {

    const [loading, setLoading] = useState("")
    const [userId, setUserId] = useState("");
    const [allReviewRequests, setAllReviewRequests] = useState([])
    const [onReviewClick, setOnReviewClick] = useState(false);
    const [currrentIndex, setCurrentIndex] = useState();
    const [reviewText, setReviewText] = useState("")

    const user = auth.currentUser;

    const handleQuilChange = (value) => {
        // console.log(value);
        // console.log(value)
        setReviewText(value);
    }

    const handleOnReviewClick = (index, reviewRequest) => {
        setOnReviewClick(true);
        setCurrentIndex(index);
        setUserData(reviewRequest);
    }
    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (auth.currentUser != null) {
                setUserId(auth.currentUser.uid);
            }
        });
    }, []);

    const getDataFromDb = async () => {
        setLoading(true);

        if (user) {
            // console.log("Fetching Data from DB");
            const docRef = doc(firestoredb, "review", "allreviews");
            const docSnap = await getDoc(docRef);
            let reviewArr = []
            if (docSnap.exists()) {
                // console.log("Document Review data gs:", { ...docSnap.data() });
                for (const key in docSnap.data()) {
                    if (docSnap.data().hasOwnProperty(key)) {
                        if (!docSnap.data()[key].isReviewed) {
                            reviewArr.push(docSnap.data()[key])
                            // console.log(`${key}: ${docSnap.data()[key]}`);

                        }
                    }
                }

                // console.log("ra, ", reviewArr);
                setAllReviewRequests(reviewArr);
                // if (docSnap.data().cvData) {
                //     const { fName, lName, phoneNumber, dateOfBirth, address, streetNo, city, postal, country } = docSnap.data();
                //     // console.log({ fName, lName, phoneNumber, dateOfBirth, address, streetNo, city, postal, country })
                //     setUserData({ fName, lName, phoneNumber, dateOfBirth, address, streetNo, city, postal, country })
                // }
                setLoading(false);

            } else {
                // docSnap.data() will be undefined in this case
                // console.log("No such document!");
                setLoading(false);

            }
        }
    };

    useEffect(() => {
        getDataFromDb();
    }, [userId]);

    // const handleRequestReview = async () => {
    //     // console.log("User ", auth.currentUser);
    //     const currentUserRef = doc(firestoredb, "users", auth.currentUser.uid);
    //     // setUserData((prev) => ({ ...prev, cvPicture: "" }));


    //     try {

    //         //fetching previous data first
    //         if (auth.currentUser) {
    //             // console.log("Fetching Data from DB");
    //             const docRef = doc(firestoredb, "users", auth.currentUser.uid);
    //             const docSnap = await getDoc(docRef);

    //             if (docSnap.exists()) {
    //                 // console.log("Document data gs:", docSnap.data());
    //                 if (docSnap.data().cvData) {
    //                     // await updateDoc(currentUserRef, {
    //                     //   cvForReview: true
    //                     // });
    //                     await handleDbSave(true);
    //                     setLoading(true);
    //                     const reviewDocRef = doc(firestoredb, "review", "allreviews");
    //                     const reviewDocSnap = await getDoc(reviewDocRef);
    //                     if (reviewDocSnap.exists()) {
    //                         await updateDoc(reviewDocRef, {
    //                             [auth.currentUser.uid]: { ...userData, userID: auth.currentUser.uid, isReviewed: false }
    //                         });
    //                     }
    //                     else {
    //                         await setDoc(reviewDocRef, {
    //                             [auth.currentUser.uid]: { ...userData, userID: auth.currentUser.uid, isReviewed: false }
    //                         })
    //                     }
    //                     toast("Your Resume Successfully Submitted for Review!");
    //                 }
    //                 else {

    //                     // // console.log("Success CV Data");
    //                     toast("Save your CV first!");

    //                 }
    //             } else {
    //                 // docSnap.data() will be undefined in this case
    //                 // console.log("No such document!");
    //             }
    //         }

    //         setLoading(false);
    //     } catch (e) {
    //         toast("Some Error Occured! Please try again")
    //         console.error("Error adding document: ", e);
    //         setLoading(false);
    //     }
    // };

    const handleReviewSubmit = async () => {
        // console.log("User ", auth.currentUser);
        const currentUserRef = doc(firestoredb, "users", auth.currentUser.uid);
        // setUserData((prev) => ({ ...prev, cvPicture: "" }));


        try {

            //fetching previous data first
            if (auth.currentUser) {
                // console.log("Fetching Data from DB");
                const docRef = doc(firestoredb, "users", allReviewRequests[currrentIndex].userID);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    // console.log("Document data gs:", docSnap.data());
                    if (true) {
                        // await updateDoc(currentUserRef, {
                        //   cvForReview: true
                        // });
                        await updateDoc(docRef, {
                            cvForReview: false,
                            cvData: { ...docSnap.data().cvData, reviewText: reviewText }
                        });
                        // const docReviewRef = doc(firestoredb, "review", "allreviews");
                        // const docReviewSnap = await getDoc(docReviewRef);




                        // await handleDbSave(false, reviewText, allReviewRequests[currrentIndex].userID);


                        setLoading(true);
                        const reviewDocRef = doc(firestoredb, "review", "allreviews");
                        const reviewDocSnap = await getDoc(reviewDocRef);
                        if (reviewDocSnap.exists()) {
                            await updateDoc(reviewDocRef, {
                                [allReviewRequests[currrentIndex].userID]: { ...allReviewRequests[currrentIndex], isReviewed: true }
                            });
                        }
                        else {
                            await setDoc(reviewDocRef, {
                                [allReviewRequests[currrentIndex].userID]: { ...allReviewRequests[currrentIndex], isReviewed: true }
                            })
                        }
                        toast("Your review has been Successfully Submitted to User!");
                        let tempArr = allReviewRequests;
                        tempArr = tempArr.filter((item, index) => {
                            if (index != currrentIndex) {
                                return true
                            }
                            else {
                                return false
                            }
                        })

                        setAllReviewRequests(tempArr)
                        setOnReviewClick(false);
                    }
                    else {

                        // // console.log("Success CV Data");
                        toast("Save your CV first!");

                    }
                } else {
                    // docSnap.data() will be undefined in this case
                    // console.log("No such document!");
                }
            }

            setLoading(false);
        } catch (e) {
            toast("Some Error Occured! Please try again")
            console.error("Error adding document: ", e);
            setLoading(false);
        }
    }

    return (
        <div>
            <div className="ProofReading ">
                <p className="p6">Reviews Requested</p>
                <p className="p5-1 mb-0">Following are the list of requests for Resume Review:</p>
                <p className="p4-1">
                    Analyze it and then suggest the changes.
                </p>
                <div className="inside-reviewRequest">
                    {allReviewRequests.length > 0 ?
                        allReviewRequests.map((reviewRequest, index) => (

                            <div className="adminReviewContainer">
                                {/* <img src={search} alt="search-icon" /> */}
                                <p className="p4-1">
                                    {reviewRequest.email ? reviewRequest.email : reviewRequest.fName ? reviewRequest.fName : `User ${index + 1}`}

                                </p>
                                <div className="reviewBtnContainer">
                                    <button className="reviewBtn" onClick={() => setUserData(reviewRequest)}>View</button>
                                    <button className="reviewBtn" onClick={() => handleOnReviewClick(index, reviewRequest)}>Make Suggestions</button>
                                </div>
                            </div>

                        )) :

                        <div className="two d-flex">
                            <p style={{ color: "black" }}>Good Work, No Review Requests!</p>
                        </div>
                    }
                </div>

            </div>

            <Modal show={loading} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Fetching Requests</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <div class="lds-ringDashboard">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>


            <Modal show={onReviewClick} centered onHide={() => setOnReviewClick(false)} >
                <Modal.Header closeButton style={{ backgroundColor: "white", color: "black" }}>
                    <Modal.Title>Write the Review</Modal.Title>
                </Modal.Header>

                <Modal.Body style={{ backgroundColor: "white" }}>
                    <div className="input-box d-flex flex-column gap-0 w-100 mt-14">
                        <div className="input-box w-100 mt-14">
                            <label htmlFor="" className="w-100">
                                <p className="p4">Write a review for the Resume</p>
                            </label>
                        </div>
                        <div className="w-100 editor-wrapper position-relative" style={{ padding: "30px 0" }}>
                            <ReactQuill
                                value={reviewText}
                                onChange={handleQuilChange}
                                modules={Reviewrequest.modules}
                                formats={Reviewrequest.formats}
                                placeholder="Add information about yourself or Just write few things about yourself and OUR AI will generate it for you."
                                style={{ paddingBottom: "20px" }}
                            />
                            {/* AI Writer Button */}
                            {/* <div style={{ position: "absolute", bottom: "100px", left: "15px" }}>
                                {!loading ? <div className="writer-btn pointer ">
                                    <Button
                                        text="AI Writer"
                                        width="137px"
                                        height="39px"
                                        className="btn-prime-1 btn-primary text-white rounded  "


                                    />
                                </div>
                                    :
                                    <div style={{ margin: "0", position: "relative", top: "48px", left: "42px" }} class="lds-ring"><div></div><div></div><div></div><div></div></div>}
                            </div> */}

                            <button style={{ backgroundColor: "#CB6CE6", padding: "4px 7px", borderRadius: "5px", position: "absolute", right: "0" }} onClick={handleReviewSubmit}>Submit</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

Reviewrequest.modules = {
    toolbar: [
        // [{ header: "1" }, { header: "2" }, { font: [] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        // ["link", "image"],
        // ["clean"],
    ],
};

Reviewrequest.formats = [
    "header",
    "font",
    "list",
    "bullet",
    "bold",
    "italic",
    "underline",
    "link",
    "image",
];

export default Reviewrequest;
