import { useState, useEffect } from "react";
import "./template29.scss";
import parse from "html-react-parser"


const Template29 = ({ userData, forDownload, sections, color, setColor, font, setFont, fontSize, setFontSize, lineHeight, setLineHeight, fromPreview }) => {

  const [currentScale, setCurrentScale] = useState("100%")
  useEffect(() => {
    if (!fromPreview) {
      setColor(userData.templateColor ? userData.templateColor : "#bbc9ff");
      setFont(`Poppins`);
      setLineHeight("1.4")

    }

  }, []);
  function handleScaleChange(event) {
    setCurrentScale(`${Math.trunc((event.instance.transformState.scale * 100))}%`);
  }
  const getColor = () => {
    return `:root{--t29Color: ${color};
    --t29LineHeight : ${lineHeight};
  --t29Font : ${font};
--paraFontSize: ${(fontSize + 16) * 0.625}px;
--subHeadingFontSize: ${(fontSize + 16)}px; 
--subHeadingFontSizethree: ${(fontSize + 16) * 0.375}px;
}`;
  };
  const getPageMarginsF = () => {
    return `@page :first { margin: ${"0px"} ${"0px"} ${"10px"} ${"0px"} !important; }`;
  };
  const getPageMargins = () => {
    return `@page { margin: ${"30px"} ${"0px"} ${"10px"} ${"0px"}; }`;
  };
  const getPageMarginst = () => {
    return `table { page-break-inside: avoid; }`;
  };

  return (
    <div ref={forDownload}>
      <style>{getColor()}</style>
      <style>{getPageMarginsF()}</style>
      <style>{getPageMargins()}</style>
      <style>{getPageMarginst()}</style>
      <div className="template29">
        <div className="top">
          <div className="left">
            <p>{userData?.phone ? userData?.phone : "Your Phone"}</p>
            <p>{userData?.email ? userData?.email : "Your Email"}</p>
            <p>{`${userData?.city || userData?.country
              ? `${userData?.city ? userData?.city : ""} , ${userData?.country ? userData?.country : ""
              }`
              : "Your Location"
              }`}</p>
          </div>
          <div className="right">
            <h1>{userData.fName ? userData.fName : ""} {userData.lName ? userData.lName : ""}</h1>
            <h4>{userData.role ? userData.role : ""}</h4>
          </div>
        </div>
        <div className="bottom">
          <div className="left">
            <div className="content">
              <h4>About Me</h4>
              <p>
                {parse(
                  userData?.summary
                    ? userData?.summary
                    : `Lorem ipsum dolor sit amet, consectetur adip iscing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ulla mco
                laboris nisi ut aliquip ex ea commodo consequat.`
                )}
              </p>
            </div>
            {sections.skills ?
              <div className="content">
                <h4>Skills</h4>
                <p>
                  {userData.skills ? userData.skills.map((skill, index) => (
                    // <li>{skill}</li>
                    <span>{skill}{userData.skills.length - 1 == index ? "" : ", "}</span>

                  ))
                    :
                    <li>UI/UX</li>

                  }
                  {/* <li>UI/UX</li>
              <li>Illustration</li> */}
                </p>
              </div>
              :

              ""}

            {/* <div className="lang">
              <h4>Language</h4>
              <ul>
                <li>Spanish</li>
                <li>English</li>
                <li> Japanese</li>
              </ul>
            </div> */}
            {sections.interests ?
              <div className="lang">
                <h4>Interest</h4>
                <p>
                  {userData.interests ? userData.interests.map((interest, index) => (
                    // <li>{interest}</li>
                    <span>{interest}{userData.interests.length - 1 == index ? "" : ", "}</span>
                  ))
                    :
                    <li>Cricket</li>
                  }
                  {/* <li>Cricket</li>
              <li>Football</li> */}
                </p>
              </div>
              :

              ""}
            {sections.certificates ?
              <div className="ref">
                <h4>Certificates</h4>
                {userData.certificates ? userData.certificates.map((certificate, index) => (
                  <p>
                    <span>{`${certificate.certificateName ? certificate.certificateName : ""}`}</span> <br />
                    <span>{`${certificate.authority ? certificate.authority : ""}`}</span>
                    <br />
                    {certificate.certificateDate ? certificate.certificateDate : ""}
                  </p>

                ))

                  :
                  <p>
                    <span>Meta React</span> <br />
                    <span>Coursera</span>
                    <br />
                    20-12-2022
                  </p>

                }
                {/* <p>
                <span>Meta React</span> <br />
                <span>Coursera</span>
                <br />
                20-12-2022
              </p> */}
              </div>
              :

              ""}




            {sections.awards ?
              <div className="ref">
                <h4>Awards</h4>
                {userData.awards ? userData.awards.map((award, index) => (
                  <p>
                    <span>{`${award.awardTitle ? award.awardTitle : ""}`}</span> <br />
                    {`${award.awardMonth ? award.awardMonth : ""}${award.awardMonth && award.awardYear ? " - " : ""}${award.awardYear ? award.awardYear : ""}`}
                  </p>
                ))

                  :
                  <p>
                    <span>Name</span> <br />
                    20-12-2022
                  </p>
                }
                {/* <p>
              <span>Name</span> <br />
              20-12-2022
            </p> */}

              </div>
              :

              ""}

            {sections.reference ?
              <div className="ref">
                <h4>Reference</h4>
                {userData.reference ? userData.reference.map((reference, index) => (
                  <div className="referenceSingle">
                    <p>
                      <span>{reference.personName ? reference.personName : ""}</span> <br />
                      {reference.referenceCompany ? reference.referenceCompany : ""}
                    </p>
                    <p>
                      <span>Phone</span> <br />
                      {reference.contact1 ? reference.contact1 : ""} <br />
                      {reference.contact2 ? reference.contact2 : ""}
                    </p>
                  </div>
                ))
                  :
                  <div className="referenceSingle">
                    <p>
                      <span>Lorna Alvarado</span> <br />
                      Company Name
                    </p>
                    <p>
                      <span>Phone</span> <br />
                      +123-456-7890
                    </p>
                  </div>
                }
                {/* <div className="referenceSingle">
              <p>
                <span>Lorna Alvarado</span> <br />
                Company Name
              </p>
              <p>
                <span>Phone</span> <br />
                +123-456-7890
              </p>
            </div>
            <div className="referenceSingle">
              <p>
                <span>Lorna Alvarado</span> <br />
                Company Name
              </p>
              <p>
                <span>Phone</span> <br />
                +123-456-7890
              </p>
            </div> */}
              </div>
              :
              ""}


          </div>
          <div className="right">
            {sections.experience ?
              <div className="experience">
                <h4>Experience</h4>
                {userData.experience ? userData.experience.map((experience) => (
                  <div className="content">
                    <h5>{`${experience.companyName ? experience.companyName : ""}${experience.companyName && (experience.cityName || experience.country) ? " - " : ""}${experience.cityName ? experience.cityName : ""}${experience.cityName && experience.country ? ", " : ""}${experience.country ? ` ${experience.country}` : ""}${(experience.companyName || experience.cityName || experience.country) && (experience.startMonth || experience.startYear || experience.endMonth || experience.endYear) ? " | " : ""}${experience?.startMonth ||
                      experience?.startYear ||
                      experience?.endMonth ||
                      experience?.endYear ||
                      experience?.currentlyWorkHere
                      ? `${experience?.startMonth ? experience?.startMonth : ""
                      } ${experience?.startYear ? experience?.startYear : ""
                      } ${experience?.endMonth ||
                        experience?.endYear ||
                        experience?.currentlyWorkHere
                        ? "-"
                        : ""
                      } ${experience?.currentlyWorkHere
                        ? "Present"
                        : `${experience?.endMonth ? experience?.endMonth : ""
                        } ${experience?.endYear ? experience?.endYear : ""
                        }`
                      }`
                      : "Your Company Tenure"
                      }`}</h5>
                    <p>{experience.jobTitle}</p>
                    <p className="job-position">
                      {parse(
                        experience?.description
                          ? experience?.description
                          : "Your Job Description"
                      )}
                    </p>
                  </div>
                ))
                  :
                  <div className="content">
                    <h5>Company Name 2017- 2020</h5>
                    <p>Job Position</p>
                    <p className="job-position">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Vitae, vero laboriosam nesciunt aut eaque ipsam, architecto,
                      autem fugit asperiores eligendi impedit accusamus minus
                      quidem.
                    </p>
                  </div>
                }
                {/* <div className="content">
              <h5>Company Name 2017- 2020</h5>
              <p>Job Position</p>
              <p className="job-position">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Vitae, vero laboriosam nesciunt aut eaque ipsam, architecto,
                autem fugit asperiores eligendi impedit accusamus minus
                quidem.
              </p>
            </div>
            <div className="content">
              <h5>Company Name 2017- 2020</h5>
              <p>Job Position</p>
              <p className="job-position">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Vitae, vero laboriosam nesciunt aut eaque ipsam, architecto,
                autem fugit asperiores eligendi impedit accusamus minus
                quidem.
              </p>
            </div> */}
              </div>
              :

              ""}

            {sections.education ?
              <div className="education">
                <h4 className="mt-4">Education</h4>
                {userData.education ? userData.education.map((education, index) => (
                  <div className="content">
                    <div className="left-content">
                      <p className="degree">{`${education?.startMonth ||
                        education?.startYear ||
                        education?.endMonth ||
                        education?.endYear ||
                        education?.currentlyStudyHere
                        ? `${education?.startMonth
                          ? education?.startMonth
                          : ""
                        } ${education?.startYear
                          ? education?.startYear
                          : ""
                        } ${education?.endMonth ||
                          education?.endYear ||
                          education?.currentlyStudyHere
                          ? "-"
                          : ""
                        } ${education?.currentlyStudyHere
                          ? "Present"
                          : `${education?.endMonth
                            ? education?.endMonth
                            : ""
                          } ${education?.endYear
                            ? education?.endYear
                            : ""
                          }`
                        }`
                        : "Your Degree Tenure"
                        }`}</p>
                      <p>{`${education.schoolName ? education.schoolName : ""}`}</p>
                      <p className="degree">{`${education.degreeName ? education.degreeName : ""}`}</p>
                    </div>
                    {/* <div className="right-content">
                      <p>
                        {parse(
                          education?.description
                            ? education?.description
                            : "Your Job Description"
                        )}
                      </p>
                    </div> */}
                  </div>
                ))
                  :
                  <div className="content">
                    <div className="left-content">
                      <p className="degree">2015</p>
                      <p>University Name</p>
                      <p className="degree">Your Degree</p>
                    </div>
                    <div className="right-content">
                      <p>
                        Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                        Odit quibusdam laudantium .
                      </p>
                    </div>
                  </div>
                }
                {/* <div className="content">
              <div className="left-content">
                <p className="degree">2015</p>
                <p>University Name</p>
                <p className="degree">Your Degree</p>
              </div>
              <div className="right-content">
                <p>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Odit quibusdam laudantium .
                </p>
              </div>
            </div>
            <div className="content">
              <div className="left-content">
                <p className="degree">2015</p>
                <p>University Name</p>
                <p className="degree">Your Degree</p>
              </div>
              <div className="right-content">
                <p>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Odit quibusdam laudantium .
                </p>
              </div>
            </div> */}
              </div>
              :

              ""}

            {sections.volunteer ?
              <div className="volunteer">
                <h4 className="mt-4">Volunteer</h4>
                {userData.volunteer ? (
                  userData.volunteer.map((volunteer, i) => (
                    <div className="content">
                      <h5> {`${volunteer.institutionName ? volunteer.institutionName : ""} ${volunteer.institutionName && volunteer.role ? "|" : ""} ${volunteer.role ? volunteer.role : ""}`} </h5>
                      <p>{`${volunteer?.startMonth ||
                        volunteer?.startYear ||
                        volunteer?.endMonth ||
                        volunteer?.endYear ||
                        volunteer?.currentlyVolunteerHere
                        ? `${volunteer?.startMonth ? volunteer?.startMonth : ""
                        } ${volunteer?.startYear ? volunteer?.startYear : ""
                        } ${volunteer?.endMonth ||
                          volunteer?.endYear ||
                          volunteer?.currentlyVolunteerHere
                          ? "-"
                          : ""
                        } ${volunteer?.currentlyVolunteerHere
                          ? "Present"
                          : `${volunteer?.endMonth ? volunteer?.endMonth : ""
                          } ${volunteer?.endYear ? volunteer?.endYear : ""
                          }`
                        }`
                        : "Your Institute Tenure"
                        }`}</p>
                      <p className="job-position">
                        {parse(
                          volunteer?.description
                            ? volunteer?.description
                            : "Your Volunteer Description"
                        )}
                      </p>
                    </div>
                  )))
                  :
                  <div className="content">
                    <h5> Name </h5>
                    <p>2017- 2020</p>
                    <p className="job-position">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Vitae, vero laboriosam nesciunt aut eaque ipsam, architecto,
                      autem fugit asperiores eligendi impedit accusamus minus
                      quidem.
                    </p>
                  </div>
                }
                {/* <div className="content">
              <h5> Name </h5>
              <p>2017- 2020</p>
              <p className="job-position">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Vitae, vero laboriosam nesciunt aut eaque ipsam, architecto,
                autem fugit asperiores eligendi impedit accusamus minus
                quidem.
              </p>
            </div> */}
              </div>
              :

              ""}

            {sections.publications ?
              <div className="volunteer">
                <h4 className="mt-4">Publications</h4>
                {userData.publications ? userData.publications.map((publication, index) => (
                  <div className="content">
                    <h5>{`${publication.publisher ? publication.publisher : ""} ${publication.publisher && publication.publicationTitle ? "|" : ""} ${publication.publicationTitle ? publication.publicationTitle : ""}`}</h5>
                    <p>{`${publication.publicationMonth ? publication.publicationMonth : ""}${publication.publicationMonth && publication.publicationYear ? ", " : ""}${publication.publicationYear ? publication.publicationYear : ""}`}</p>
                    <p className="job-position">{parse(
                      publication?.description
                        ? publication?.description
                        : "Your Publication Description"
                    )}</p>
                  </div>
                ))
                  :
                  <div className="content">
                    <h5>Publisher Name </h5>
                    <p>2017- 2020</p>
                    <p className="job-position">This is my Publicaion</p>
                  </div>
                }
                {/* <div className="content">
              <h5>Publisher Name </h5>
              <p>2017- 2020</p>
              <p className="job-position">This is my Publicaion</p>
            </div> */}
              </div>
              :

              ""}

          </div>
        </div>
      </div>
    </div>
  );
};

export default Template29;
