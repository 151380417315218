import React, { useState, useEffect } from 'react'
import { redirect, useNavigate } from 'react-router-dom'
import { onAuthStateChanged } from "firebase/auth";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { auth, firestoredb } from "../../config"
import axios from 'axios';
import { toast } from 'react-toastify';


const Success = ({ getDataFromDb }) => {
    const [userId, setUserId] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (auth.currentUser != null) {
                setUserId(auth.currentUser.uid);
            }
        });
    }, []);

    useEffect(() => {
        if (userId) {
            handleDbSave();

        }
    }, [userId])
    const navigate = useNavigate();
    const handleDbSave = async () => {
        // // console.log("callFromReview", callFromReview);
        // // console.log("User ", user.uid);
        // const currentUserRef = doc(firestoredb, "users", auth.currentUser.uid);
        // setUserData((prev) => ({ ...prev, cvPicture: "" }));
        setLoading(true);

        try {

            //fetching previous data first
            if (auth.currentUser) {
                // // console.log("Fetching Data from DB");
                const docRef = doc(firestoredb, "users", auth.currentUser.uid);
                const docSnap = await getDoc(docRef);
                const subscriptionDate = new Date();

                if (docSnap.exists()) {
                    // // console.log("Document data gs:", docSnap.data());
                    if (docSnap.data().stripeSessionId) {
                        try {
                            const tokenId = await auth.currentUser.getIdToken();
                            const config = {
                                headers: { Authorization: `Bearer ${tokenId}` }
                            };
                            // const sessionDetails = await axios.post("http://localhost:8080/get-session-info", { sessionId: docSnap.data().stripeSessionId })
                            const sessionDetails = await axios.post("https://resume-ai-nodejs.onrender.com/get-session-info", { sessionId: docSnap.data().stripeSessionId }, config)
                            // console.log("Session Details", sessionDetails);
                            if (sessionDetails.data.sessionDetails.payment_status == "paid") {
                                await updateDoc(docRef, {
                                    accountType: "pro",
                                    subscriptionDate: String(subscriptionDate)
                                });
                                // console.log("Data updated")
                                await getDataFromDb();
                                navigate("/dashboard");
                            }
                            else {
                                toast("Session Payment is incomplete");
                                navigate("/dashboard");

                            }
                        }
                        catch (e) {
                            // console.log(e);
                        }
                    }
                    else {
                        toast("Couldn't verify Payment")
                    }


                };
            }
        }
        catch (e) {
            // console.log(e);
        }
    }
    // useEffect(() => {
    //     // navigate("/")

    // }, [])
    return (
        <div style={{ textAlign: "center", padding: "20px", border: "1px solid white" }}>Payment has been made successfully. Your Account is been upgrading...<br></br>You will automatically be redirected. <br></br>Do not close this window</div>
    )
}

export default Success