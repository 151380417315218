import React, { useState, useEffect } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import parse from "html-react-parser"
import "./template9.scss";
function Template9({ userData, forDownload, sections, color, setColor, font, setFont, fontSize, setFontSize, lineHeight, setLineHeight, fromPreview }) {
  const [currentScale, setCurrentScale] = useState("100%")
  useEffect(() => {
    if (!fromPreview) {
      setColor(userData.templateColor ? userData.templateColor : "#3F5FC7");
      setFont(`Poppins`);
      setLineHeight("1.4")
    }

  }, []);
  function handleScaleChange(event) {
    setCurrentScale(`${Math.trunc((event.instance.transformState.scale * 100))}%`);
  }
  const getColor = () => {
    return `:root{--t9Color: ${color};
    --t9LineHeight : ${lineHeight};
  --t9Font : ${font};
--paraFontSize: ${fontSize * 0.70}px;
--subHeadingFontSize: ${fontSize}px; }`;
  };
  const getPageMarginsF = () => {
    return `@page :first { margin: ${"0px"} ${"0px"} ${"10px"} ${"0px"} !important; }`;
  };
  const getPageMargins = () => {
    return `@page { margin: ${"30px"} ${"0px"} ${"10px"} ${"0px"} !important; }`;
  };
  const getPageMarginst = () => {
    return `table { page-break-inside: avoid; }`;
  };

  return (
    // <TransformWrapper
    //   initialScale={1}
    //   maxScale={2}
    //   minScale={0.5}
    //   initialPositionX={0}
    //   initialPositionY={0}
    //   onTransformed={(e) => handleScaleChange(e)}

    // >
    //   {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
    //     <React.Fragment>
    //       <div className="tools" style={{ width: "25%", margin: "10px", display: "flex", gap: "6px" }}>
    //         <div style={{ display: "flex", gap: "10px" }}>
    //           <button onClick={() => zoomOut(0.1)} style={{ backgroundColor: "#180045", padding: "0 8px", borderRadius: "5px" }}>-</button>
    //           <p style={{ margin: "0", padding: "0", color: "black" }}>{currentScale}</p>
    //           <button onClick={() => zoomIn(0.1)} style={{ backgroundColor: "#180045", padding: "0 8px", borderRadius: "5px" }}>+</button>

    //         </div>

    //         <button onClick={() => resetTransform()} style={{ backgroundColor: "#180045", padding: "0 10px", borderRadius: "5px" }}>Reset</button>
    //       </div>
    //       <TransformComponent>
    <section className="template9-sec" ref={forDownload}>
      <style>{getColor()}</style>
      <style>{getPageMarginsF()}</style>
      <style>{getPageMargins()}</style>
      <style>{getPageMarginst()}</style>
      <h2 className="heading">
        <span>{userData.fName}</span> {userData.lName}
      </h2>
      <div className="top">
        <p className="para">{`${userData?.city || userData?.country
          ? `${userData?.city ? userData?.city : ""} , ${userData?.country ? userData?.country : ""
          }`
          : "Your Location"
          }`}</p>
        <span>|</span>
        <p className="para">{userData?.phone ? userData?.phone : "Your Phone"}</p>
        <span>|</span>
        <p className="para">{userData?.email ? userData?.email : "Your Email"}</p>
      </div>
      <div className="line"></div>
      <div className="profile-sec">
        <h4 className="sub-heading">PROFILE SUMMARY</h4>
        <p className="para">
          {parse(
            userData?.summary
              ? userData?.summary
              : `Lorem ipsum dolor sit amet, consectetur adip iscing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ulla mco
                laboris nisi ut aliquip ex ea commodo consequat.`
          )}
        </p>
      </div>
      {sections.skills || sections.strengths ?
        <>
          <div className="line"></div>
          <div className="skill-sec">
            {sections.skills ? <>
              <div className="left">
                <h4 className="sub-heading">SKILLS</h4>

                <ul className="ist">
                  {userData.skills ? userData.skills.map((skill) => (
                    <li>
                      <div className="para">{skill}</div>
                    </li>
                  ))
                    :
                    <>
                      <li>
                        <div className="para">Your Skill</div>
                      </li>
                      <li>
                        <div className="para">Your Skill</div>
                      </li>
                      <li>
                        <div className="para">Your Skill</div>
                      </li>
                      <li>
                        <div className="para">Your Skill</div>
                      </li></>
                  }
                </ul>
              </div>
            </>
              :
              ""}
            {sections.strengths ? <>
              <div className="right">
                <h4 className="sub-heading">SOFT SKILLS</h4>
                <ul className="ist">
                  {userData.strengths ? userData.strengths.map((strength) => (
                    <li>
                      <div className="para">{strength}</div>
                    </li>
                  ))
                    :
                    <>
                      <li>
                        <div className="para">Your Skill</div>
                      </li>
                      <li>
                        <div className="para">Your Skill</div>
                      </li>
                      <li>
                        <div className="para">Your Skill</div>
                      </li>
                      <li>
                        <div className="para">Your Skill</div>
                      </li></>
                  }
                </ul>
              </div>
            </>
              :
              ""}

          </div>
        </>
        :
        ""}
      {sections.experience ? <>
        <div className="line"></div>
        <div className="experience-sec">
          <h4 className="sub-heading">WORK EXPERIENCE</h4>
          {userData.experience ? userData.experience.map((experience) => (
            <div className="ist">
              <p className="para bold-para">
                {`${experience.companyName ? experience.companyName : ""}${experience.companyName && (experience.cityName || experience.country) ? " - " : ""}${experience.cityName ? experience.cityName : ""}${experience.cityName && experience.country ? ", " : ""}${experience.country ? ` ${experience.country}` : ""}${(experience.companyName || experience.cityName || experience.country) && (experience.startMonth || experience.startYear || experience.endMonth || experience.endYear) ? " | " : ""}${experience?.startMonth ||
                  experience?.startYear ||
                  experience?.endMonth ||
                  experience?.endYear ||
                  experience?.currentlyWorkHere
                  ? `${experience?.startMonth ? experience?.startMonth : ""
                  } ${experience?.startYear ? experience?.startYear : ""
                  } ${experience?.endMonth ||
                    experience?.endYear ||
                    experience?.currentlyWorkHere
                    ? "-"
                    : ""
                  } ${experience?.currentlyWorkHere
                    ? "Present"
                    : `${experience?.endMonth ? experience?.endMonth : ""
                    } ${experience?.endYear ? experience?.endYear : ""
                    }`
                  }`
                  : "Your Company Tenure"
                  }`}

              </p>
              <p className="para light-para">{experience.jobTitle}</p>


              {parse(
                experience?.description
                  ? experience?.description
                  : "Your Job Description"
              )}



            </div>
          ))

            :
            <div className="ist">
              <p className="para bold-para">
                Company Name,Any City <span>|</span> 2020-2022
              </p>
              <p className="para light-para">Job Title/Position</p>
              <ul>
                <li>
                  <p className="para">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim,
                    ullam!
                  </p>
                </li>
                <li>
                  <p className="para">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim,
                    ullam!
                  </p>
                </li>
                <li>
                  <p className="para">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim,
                    ullam!
                  </p>
                </li>
              </ul>
            </div>
          }
          {/* <div className="ist">
          <p className="para bold-para">
            Company Name,Any City <span>|</span> 2020-2022
          </p>
          <p className="para light-para">Job Title/Position</p>
          <ul>
            <li>
              <p className="para">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim,
                ullam!
              </p>
            </li>
            <li>
              <p className="para">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim,
                ullam!
              </p>
            </li>
            <li>
              <p className="para">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim,
                ullam!
              </p>
            </li>
          </ul>
        </div>
        <div className="ist">
          <p className="para bold-para">
            Company Name,Any City <span>|</span> 2020-2022
          </p>
          <p className="para light-para">Job Title/Position</p>
          <ul>
            <li>
              <p className="para">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim,
                ullam!
              </p>
            </li>
            <li>
              <p className="para">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim,
                ullam!
              </p>
            </li>
            <li>
              <p className="para">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim,
                ullam!
              </p>
            </li>
          </ul>
        </div> */}
        </div>
      </>
        :
        ""}

      {sections.projects ?
        <>
          <div className="line"></div>
          <div className="project-sec">
            <h4 className="sub-heading">PROJECTS</h4>
            {userData.projects ? userData.projects.map((project, index) => (
              <div className="ist">
                <p className="para bold-para">
                  {`${project.projectName ? project.projectName : ""}${project.projectName && (project.startMonth || project.startYear || project.endMonth || project.endYear) ? " | " : ""}${project?.startMonth ||
                    project?.startYear ||
                    project?.endMonth ||
                    project?.endYear ||
                    project?.currentlyWorkingOnThisProject
                    ? `${project?.startMonth
                      ? project?.startMonth
                      : ""
                    } ${project?.startYear
                      ? project?.startYear
                      : ""
                    } ${project?.endMonth ||
                      project?.endYear ||
                      project?.currentlyWorkingOnThisProject
                      ? "-"
                      : ""
                    } ${project?.currentlyWorkingOnThisProject
                      ? "Present"
                      : `${project?.endMonth
                        ? project?.endMonth
                        : ""
                      } ${project?.endYear
                        ? project?.endYear
                        : ""
                      }`
                    }`
                    : "Your Degree Tenure"
                    }`}
                </p>

                <p className="para">
                  {parse(
                    project?.description
                      ? project?.description
                      : "Your Project Description"
                  )}
                </p>

              </div>
            ))
              :
              <div className="ist">
                <p className="para bold-para">
                  Project Name <span>|</span> 2020-2022
                </p>
                <ul>
                  <li>
                    <p className="para">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim,
                      ullam!
                    </p>
                  </li>
                </ul>
              </div>
            }
            {/* <div className="ist">
          <p className="para bold-para">
            Project Name <span>|</span> 2020-2022
          </p>
          <ul>
            <li>
              <p className="para">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim,
                ullam!
              </p>
            </li>
          </ul>
        </div> */}
          </div>
        </>
        :
        ""}
      {sections.volunteer ?
        <>
          <div className="line"></div>
          <div className="volunteer-sec">
            <h4 className="sub-heading">VOLUNTEER</h4>
            {userData.volunteer ? userData.volunteer.map((volunteer, index) => (
              <div className="ist">
                <p className="para bold-para">
                  {`${volunteer.institutionName ? volunteer.institutionName : ""}${volunteer.institutionName && (volunteer.startMonth || volunteer.startYear || volunteer.endMonth || volunteer.endYear) ? " | " : ""}${volunteer?.startMonth ||
                    volunteer?.startYear ||
                    volunteer?.endMonth ||
                    volunteer?.endYear ||
                    volunteer?.currentlyVolunteerHere
                    ? `${volunteer?.startMonth
                      ? volunteer?.startMonth
                      : ""
                    } ${volunteer?.startYear
                      ? volunteer?.startYear
                      : ""
                    } ${volunteer?.endMonth ||
                      volunteer?.endYear ||
                      volunteer?.currentlyVolunteerHere
                      ? "-"
                      : ""
                    } ${volunteer?.currentlyVolunteerHere
                      ? "Present"
                      : `${volunteer?.endMonth
                        ? volunteer?.endMonth
                        : ""
                      } ${volunteer?.endYear
                        ? volunteer?.endYear
                        : ""
                      }`
                    }`
                    : "Your Degree Tenure"
                    }`}
                </p>

                <p className="para">
                  {parse(
                    volunteer?.description
                      ? volunteer?.description
                      : "Your Volunteer Description"
                  )}
                </p>

              </div>
            ))
              :
              <div className="ist">
                <p className="para bold-para">
                  Title <span>|</span> 2020-2022
                </p>
                <ul>
                  <li>
                    <p className="para">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim,
                      ullam!
                    </p>
                  </li>
                </ul>
              </div>}
            {// <div className="ist">
              //   <p className="para bold-para">
              //     Title <span>|</span> 2020-2022
              //   </p>
              //   <ul>
              //     <li>
              //       <p className="para">
              //         Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim,
              //         ullam!
              //       </p>
              //     </li>
              //   </ul>
              // </div>
            }
          </div>
        </>
        :
        ""}

      {sections.education ?
        <>
          <div className="line"></div>
          <div className="volunteer-sec">
            <h4 className="sub-heading">EDUCATION</h4>
            {userData.education ? userData.education.map((education) => (
              <div className="ist">
                <p className="para bold-para">
                  {`${education.degreeName ? education.degreeName : ""}${education.degreeName && (education.startMonth || education.startYear || education.endMonth || education.endYear) ? " | " : ""}${education?.startMonth ||
                    education?.startYear ||
                    education?.endMonth ||
                    education?.endYear ||
                    education?.currentlyStudyHere
                    ? `${education?.startMonth
                      ? education?.startMonth
                      : ""
                    } ${education?.startYear
                      ? education?.startYear
                      : ""
                    } ${education?.endMonth ||
                      education?.endYear ||
                      education?.currentlyStudyHere
                      ? "-"
                      : ""
                    } ${education?.currentlyStudyHere
                      ? "Present"
                      : `${education?.endMonth
                        ? education?.endMonth
                        : ""
                      } ${education?.endYear
                        ? education?.endYear
                        : ""
                      }`
                    }`
                    : "Your Degree Tenure"
                    }`}
                  {/* Education Qualification / Degree,Major <span>|</span> 2020-2022 */}
                </p>
                <p className="para light-para">{`${education.schoolName ? education.schoolName : ""} ${education.schoolName && (education.cityName || education.country) ? "-" : ""} ${education.cityName ? education.cityName : ""} ${education.cityName && education.country ? `,` : ""} ${education.country ? education.country : ""}`}</p>

                {parse(
                  education?.description
                    ? education?.description
                    : "Your Job Description"
                )}

              </div>
            ))

              :
              <div className="ist">
                <p className="para bold-para">
                  Education Qualification / Degree,Major <span>|</span> 2020-2022
                </p>
                <p className="para light-para">University Name,Any City</p>
                <ul>
                  <li>
                    <p className="para">
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit. Velit,
                      aperiam?
                    </p>
                  </li>
                </ul>
              </div>
            }
            {/* <div className="ist">
          <p className="para bold-para">
            Education Qualification / Degree,Major <span>|</span> 2020-2022
          </p>
          <p className="para light-para">University Name,Any City</p>
          <ul>
            <li>
              <p className="para">
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Velit,
                aperiam?
              </p>
            </li>
          </ul>
        </div>
        <div className="ist">
          <p className="para bold-para">
            Education Qualification / Degree,Major <span>|</span> 2020-2022
          </p>
          <p className="para light-para">University Name,Any City</p>
          <ul>
            <li>
              <p className="para">
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Velit,
                aperiam?
              </p>
            </li>
          </ul>
        </div> */}
          </div>
        </>
        :
        ""}

      {sections.certificates ?
        <>
          <div className="line"></div>
          <div className="volunteer-sec">
            <h4 className="sub-heading">Certification</h4>
            {userData.certificates ? userData.certificates.map((certificate, index) => (
              <div className="ist">
                <p className="para bold-para">
                  {`${certificate.certificateName ? certificate.certificateName : ""}`}
                  {/* Education Qualification / Degree,Major <span>|</span> 2020-2022 */}
                </p>
                <p className="para light-para">{`${certificate.authority ? certificate.authority : ""}${certificate.authority && certificate.certificateDate ? " | " : ""}${certificate.certificateDate ? certificate.certificateDate : ""}`}</p>



              </div>
            ))

              :
              <div className="ist">
                <p className="para bold-para">
                  Education Qualification / Degree,Major <span>|</span> 2020-2022
                </p>
                <p className="para light-para">University Name,Any City</p>
                <ul>
                  <li>
                    <p className="para">
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit. Velit,
                      aperiam?
                    </p>
                  </li>
                </ul>
              </div>
            }
            {/* <div className="ist">
          <p className="para bold-para">
            Education Qualification / Degree,Major <span>|</span> 2020-2022
          </p>
          <p className="para light-para">University Name,Any City</p>
          <ul>
            <li>
              <p className="para">
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Velit,
                aperiam?
              </p>
            </li>
          </ul>
        </div>
        <div className="ist">
          <p className="para bold-para">
            Education Qualification / Degree,Major <span>|</span> 2020-2022
          </p>
          <p className="para light-para">University Name,Any City</p>
          <ul>
            <li>
              <p className="para">
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Velit,
                aperiam?
              </p>
            </li>
          </ul>
        </div> */}
          </div>
        </>
        :
        ""}

      {sections.awards ?
        <>
          <div className="line"></div>
          <div className="volunteer-sec">
            <h4 className="sub-heading">Awards</h4>
            {userData.awards ? userData.awards.map((award, index) => (
              <div className="ist">
                <p className="para bold-para">
                  {`${award.awardTitle ? award.awardTitle : ""}`}
                  {/* Education Qualification / Degree,Major <span>|</span> 2020-2022 */}
                </p>
                <p className="para light-para">{`${award.issuer ? award.issuer : ""}${award.issuer && (award.awardMonth || award.awardYear) ? " | " : ""}${award.awardMonth ? award.awardMonth : ""}${award.awardMonth && award.awardYear ? ", " : ""}${award.awardYear ? award.awardYear : ""}`}</p>



              </div>
            ))

              :
              <div className="ist">
                <p className="para bold-para">
                  Education Qualification / Degree,Major <span>|</span> 2020-2022
                </p>
                <p className="para light-para">University Name,Any City</p>
                <ul>
                  <li>
                    <p className="para">
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit. Velit,
                      aperiam?
                    </p>
                  </li>
                </ul>
              </div>
            }
            {/* <div className="ist">
          <p className="para bold-para">
            Education Qualification / Degree,Major <span>|</span> 2020-2022
          </p>
          <p className="para light-para">University Name,Any City</p>
          <ul>
            <li>
              <p className="para">
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Velit,
                aperiam?
              </p>
            </li>
          </ul>
        </div>
        <div className="ist">
          <p className="para bold-para">
            Education Qualification / Degree,Major <span>|</span> 2020-2022
          </p>
          <p className="para light-para">University Name,Any City</p>
          <ul>
            <li>
              <p className="para">
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Velit,
                aperiam?
              </p>
            </li>
          </ul>
        </div> */}
          </div>
        </>
        :
        ""}


      {sections.publications ?
        <>
          <div className="line"></div>
          <div className="publication-sec">
            <h4 className="sub-heading">PUBLICATIONS</h4>
            {userData.publications ? userData.publications.map((publication, index) => (
              <div className="ist">
                <p className="para bold-para">
                  {`${publication.publicationTitle ? publication.publicationTitle : ""}${publication.publicationTitle && publication.publisher ? " | " : ""}${publication.publisher ? publication.publisher : ""}${(publication.publicationTitle && publication.publisher) && (publication.publicationMonth || publication.publicationYear) ? " | " : ""}${publication.publicationMonth ? publication.publicationMonth : ""}${publication.publicationMonth && publication.publicationYear ? ", " : ""}${publication.publicationYear ? publication.publicationYear : ""}`}
                  {/* Title <span>|</span> Book Name<span>|</span> 2020-2022 */}
                </p>

                <p className="para">
                  {parse(
                    publication?.description
                      ? publication?.description
                      : "Your Publication Description"
                  )}
                </p>

              </div>
            ))
              :
              <div className="ist">
                <p className="para bold-para">
                  Title <span>|</span> Book Name<span>|</span> 2020-2022
                </p>
                <ul>
                  <li>
                    <p className="para">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim,
                      ullam!
                    </p>
                  </li>
                </ul>
              </div>
            }
            {/* <div className="ist">
          <p className="para bold-para">
            Title <span>|</span> Book Name<span>|</span> 2020-2022
          </p>
          <ul>
            <li>
              <p className="para">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim,
                ullam!
              </p>
            </li>
          </ul>
        </div> */}
          </div>
        </>
        :
        ""}

      {sections.interests ?
        <>
          <div className="line"></div>
          <div className="interest-sec">
            <h4 className="sub-heading">INTERESTS</h4>

            <ul>
              {userData.interests ? userData.interests.map((interest, index) => (
                <li>
                  <p className="para">{interest}</p>
                </li>
              ))
                :
                <li>
                  <p className="para">Interest 1</p>
                </li>
              }
              {/* <li>
            <p className="para">1</p>
          </li>
          <li>
            <p className="para">2</p>
          </li> */}
            </ul>
          </div>
        </>
        :
        ""}

      { /*sections.languages ?
                <>
                  <div className="line"></div>
                  <div className="language-sec">
                    <h4 className="sub-heading">LANGUAGES</h4>

                    <ul>
                      {userData.languages ? userData.languages.map((language, index) => (
                        <li>
                          <p className="para">{language}</p>
                        </li>
                      ))
                        :
                        <li>
                          <p className="para">Language 1</p>
                        </li>}
                      {/* <li>
            <p className="para">1</p>
          </li>
          <li>
            <p className="para">2</p>
          </li> }
                    </ul>
                  </div>
                </>
                :
                      "" */}

      {sections.reference ?
        <>
          <div className="line"></div>
          <div className="reference-sec">
            <h4 className="sub-heading">REFERENCE</h4>
            {userData.reference ? userData.reference.map((reference, index) => (
              <div className="ist">
                <p className="para bold-para">{reference.referenceCompany}</p>
                <p className="para light-para">{reference.personName}</p>
                <p className="para ">{reference.contact1}</p>
                <p className="para ">{reference.contact2}</p>
              </div>
            ))
              :
              <div className="ist">
                <p className="para bold-para">Company Name</p>
                <p className="para light-para">Person Name</p>
                <p className="para ">Contact1</p>
                <p className="para ">Contact2</p>
              </div>
            }
            { // <div className="ist">
              //   <p className="para bold-para">Company Name</p>
              //   <p className="para light-para">Person Name</p>
              //   <p className="para ">Contact1</p>
              //   <p className="para ">Contact2</p>
              // </div>
            }
          </div>
        </>
        :
        ""}








    </section>
    //       </TransformComponent>
    //     </React.Fragment>
    //   )}
    // </TransformWrapper>
  );
}

export default Template9;
