import React from 'react'
import "../privacy/privacy.scss"
import { useNavigate } from 'react-router-dom'


const Terms = () => {
    const navigate = useNavigate();

    return (
        <div className='privacyContainer'>
            <div className="privacyHeading">
                {/* <h1>Data Privacy</h1> */}
            </div>
            <div className='privacyContent'>
                <div style={{ fontFamily: 'Arial, sans-serif', lineHeight: 1.6, margin: '20px' }}>
                    <h1 style={{ color: '#fff' }}>Terms and Conditions</h1>

                    <p>
                        By accessing and using <strong>Resumifi</strong> (the "Service"), you agree to comply with and be bound by the following terms and conditions.
                        Please read these Terms and Conditions carefully before using the Service.
                    </p>

                    <h2 style={{ color: '#fff' }}>1. Use of the Service</h2>

                    <p>
                        The Service is provided solely for the purpose of creating and enhancing professional resumes.
                        You agree not to use the Service for any unlawful or prohibited purpose or in any manner that could damage, disable, overburden, or impair the Service.
                    </p>

                    <h2 style={{ color: '#fff' }}>2. User Accounts</h2>

                    <p>
                        To access certain features of the Service, you may be required to create a user account.
                        You are responsible for maintaining the confidentiality of your account and password and for restricting access to your account.
                        You agree to accept responsibility for all activities that occur under your account or password.
                    </p>

                    <h2 style={{ color: '#fff' }}>3. User Content</h2>

                    <p>
                        You retain ownership of the content you upload to the Service.
                        By uploading content, you grant <strong>Resumifi</strong> a non-exclusive, royalty-free, worldwide, and perpetual license to use, display, and reproduce the content.
                    </p>

                    <h2 style={{ color: '#fff' }}>4. Modification of Terms</h2>

                    <p>
                        <strong>Resumifi</strong> reserves the right to modify or replace these Terms and Conditions at any time.
                        It is your responsibility to check the Terms and Conditions periodically for changes.
                        Your continued use of the Service after the posting of any changes to the Terms and Conditions constitutes acceptance of those changes.
                    </p>

                    <h2 style={{ color: '#fff' }}>5. Termination</h2>

                    <p>
                        <strong>Resumifi</strong> may terminate or suspend your access to the Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms and Conditions.
                    </p>

                    <h2 style={{ color: '#fff' }}>6. Disclaimer</h2>

                    <p>
                        The Service is provided on an "as-is" and "as-available" basis.
                        <strong> Resumifi</strong> makes no representations or warranties of any kind, express or implied, regarding the Service or the content.
                    </p>

                    <h2 style={{ color: '#fff' }}>7. Governing Law</h2>

                    <p>
                        These Terms and Conditions are governed by and construed in accordance with the laws of Country.
                    </p>

                    <h2 style={{ color: '#fff' }}>8. Contact Us</h2>

                    <p>If you have any questions or concerns about these Terms and Conditions, please contact us <span style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => navigate("/contactus")}>here</span>.</p>

                    <p>Thank you for using <strong>Resumifi</strong>.</p>

                    <p><strong>Resumifi</strong></p>
                </div>
            </div>
        </div>
    )
}

export default Terms