import React, { useState, useEffect } from "react";
import "../../style/main.scss";
import Button from "../shared/button";
import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import handleBackToResume from "../../utils/loginredirect";

const Join = () => {

  const navigate = useNavigate();
  const [userisLogged, setUserisLogged] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in.
        const { uid, email } = user;
        // navigate("/");
        setUserisLogged(user);
      } else {
        // User is signed out.
        setUserisLogged(user);

        // router.push({
        //     pathname: "/login",
        // });
      }
    });
    return () => unsubscribe();
  }, []);

  return (
    <div>
      <div className="joining bg-white h-center flex-column v-center ">
        <h1 className="large text-center mb-2">
          Join over <span className="pinky"> 29 Million </span> <br /> users
          from around the world
        </h1>
        <p className="text-purple text-center mb-4">
          Start for free! Try our AI resume Builder.{" "}
          {/* <span className="text-purple text-decoration-underline pointer" onClick={() => navigate("/login")}> */}
          <span className="text-purple text-decoration-underline pointer" onClick={() => handleBackToResume()}>
            Start Now{" "}
          </span>
        </p>
        <Button
          text="Create My Resume"
          width="242px"
          height="40px"
          className="btn-prime btn-primary br-35"
          // onClick={() => navigate("/login")}
          onClick={() => handleBackToResume()}
        />
      </div>
    </div>
  );
};

export default Join;
