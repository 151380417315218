import React, { useEffect, useState, useRef } from "react";
import "../../style/main.scss";
import Spinner from "react-bootstrap/Spinner";

import logo from "../../assets/images/logo.png";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Button from "./button";
import {
  EmailAuthProvider,
  getAuth,
  onAuthStateChanged,
  reauthenticateWithCredential,
  signOut,
  updatePassword,
} from "firebase/auth";
import { auth } from "../../config";
import { toast } from "react-toastify";

import Modal from "react-bootstrap/Modal";
import boy from "../../assets/images/Dashboard/boy.png";
import { Icon } from "@iconify/react";
import { adminUsers } from "../../adminUsers";
const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [userisLogged, setUserisLogged] = useState(false);
  const [show, setShow] = useState(false);
  const [navDropdown, setNavDropdown] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleNavDrop = () => setNavDropdown(!navDropdown);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [resetError, setResetError] = useState(null);
  const [isLoading, setisLoading] = useState("");

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [currentSubdomain, setCurrentSubdomain] = useState("")
  const [showCover, setShowCover] = useState(false);

  const handleResumeModalCover = () => {
    setShowCover(false);
  }

  const handleToggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const getRelavantRoute = () => {
    // console.log(window.location.hostname);
    const locationParts = window.location.hostname.split(".");
    let sliceTill = -2;

    // for localhost
    // console.log("lp", locationParts);
    // console.log("test", locationParts.slice(-1)[0] == "localhost");
    const isLocalHost = locationParts.slice(-1)[0] == "localhost";
    if (isLocalHost) {
      sliceTill = -1;
    }

    const subdomain = locationParts.slice(0, sliceTill).join("");
    // console.log(subdomain);
    setCurrentSubdomain(subdomain)

  }
  // const currrentSubdomain = getRelavantRoute()

  useEffect(() => {
    "heree"
    getRelavantRoute()
  }, [])


  const handleBackToResume = () => {


    const locationParts = window.location.hostname.split(".");
    let sliceTill = -2;

    // for localhost
    // console.log("lp", locationParts);
    // console.log("test", locationParts.slice(-1)[0] == "localhost");
    const isLocalHost = locationParts.slice(-1)[0] == "localhost";
    if (isLocalHost) {
      sliceTill = -1;
    }

    const subdomain = locationParts.slice(0, sliceTill).join("");
    // console.log(subdomain);

    //
    if (subdomain != "app") {

      // window.location.href = window.location.protocol + "//" + "app." + window.location.host;
    }
    if (!subdomain) {

      window.location.href = window.location.protocol + "//" + "app." + window.location.host + "/login";
    }
    else {

      window.location.href = window.location.protocol + "//" + "app." + window.location.host.split(".")[1] + ".com" + "/login";
    }


  }





  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        // Clicked outside the menu, close it
        setNavDropdown(false);
        // console.log("Outside")
      }

    };

    // Attach the event listener
    document.addEventListener('click', handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);


  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in.
        const { uid, email } = user;
        // navigate("/");
        setUserisLogged(user);
      } else {
        // User is signed out.
        setUserisLogged(user);

        // router.push({
        //     pathname: "/login",
        // });
      }
    });
    return () => unsubscribe();
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      // console.log(auth.currentUser);
      toast.success("Logout ", { toastId: "userloggedout" });
      navigate("/login"); // Redirect to the login page after signing out
      // window.location.reload();
    } catch (error) {
      // console.log("Error signing out:", error);
    }
  };

  const passwordResetValidation = () => {
    let obj = {
      oldPassword: false,
      newPassword: false,
      confirmPassword: false,
    };
    if (oldPassword == "" || oldPassword == undefined) {
      obj.oldPassword = true;
    }
    if (newPassword == "" || newPassword == undefined) {
      obj.newPassword = true;
    }
    if (confirmPassword == "" || confirmPassword == undefined) {
      obj.confirmPassword = true;
    }
    return obj;
  };

  const passwordReset = () => {
    // setPasswordUpdate(false)
    // console.log(oldPassword);
    // console.log(newPassword);
    // console.log(confirmPassword);
    const validReset = passwordResetValidation();

    // console.log(validReset);
    setResetError(validReset);
    if (
      validReset.confirmPassword ||
      validReset.newPassword ||
      validReset.oldPassword
    ) {
    } else {
      if (newPassword == confirmPassword) {
        const credential = EmailAuthProvider.credential(
          userisLogged.email,
          oldPassword
        );

        reauthenticateWithCredential(userisLogged, credential)
          .then(() => {
            // setisLoading(false);
            // User re-authenticated.
            updatePassword(userisLogged, newPassword)
              .then(() => {
                // Update successful.

                toast.success("Password Changed!", {
                  toastId: "passwordChanged",
                });
                window.location.reload(false);
                setisLoading(false);
              })
              .catch(async (error) => {
                setisLoading(false);
                // console.log(error);
              });
          })
          .catch((err) => {
            // An error ocurred
            // ...
            // console.log(err);
            toast.error("Old Password is not correct", {
              toastId: "OldPasswordError",
            });
            setisLoading(false);
          });
      } else {
        toast("Password does not match", { toastId: "passnotmatch" });
      }
    }
  };

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light ">
        <div className="container-fluid navbar-content " data-toggle="collapse" data-target=".navbar-collapse" >
          <a class="navbar-brand pointer" onClick={() => navigate("/")}>
            <img className="mainLogo" src={"/assets/images/resumifi.png"} alt="..." />
          </a>
          {/* <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <Icon
              icon="mingcute:menu-fill"
              color="white"
              width="24"
              height="24"
            />{" "}
          </button> */}
          {(location.pathname == "/login" || location.pathname == "/register") && currentSubdomain == "app" ?
            ""
            :
            <>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={handleToggleMenu}
              >
                {isMenuOpen ? (
                  // Cross icon when the menu is open
                  <Icon icon="charm:cross" color="white" width="24" height="24" />
                ) : (
                  // Menu icon when the menu is closed
                  <Icon icon="ic:baseline-menu" color="white" width="24" height="24" />
                )}
              </button>

              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav m-auto ">
                  {userisLogged ? (
                    <li class="nav-item">
                      <NavLink
                        class="nav-link "
                        aria-current="page"
                        to="/dashboard"
                      >
                        Dashboard
                      </NavLink>
                    </li>
                  ) : null}

                  <li class="nav-item">
                    <NavLink class="nav-link" to="/">
                      Home
                    </NavLink>
                  </li>
                  {/* <li class="nav-item">
                    <NavLink class="nav-link" to="/money">
                      Pricing
                    </NavLink>
                  </li> */}
                  {/* <li class="nav-item">
                <a class="nav-link" href="#">
                  About Us
                </a>
              </li> */}
                  {/* <li class="nav-item">
                <a class="nav-link" href="#">
                  Features
                </a>
              </li> */}

                  {
                    currentSubdomain == "app" ?
                      userisLogged && adminUsers.includes(auth?.currentUser?.email) ?
                        ""
                        :
                        <>

                          <li class="nav-item">
                            <NavLink class="nav-link" to="/templates">
                              Templates
                            </NavLink>
                          </li>
                          <li class="nav-item">
                            <NavLink class="nav-link" to="/coverletter">
                              Cover Letter
                            </NavLink>
                          </li>
                        </>
                      :
                      <>
                        <li class="nav-item">
                          <a class="" onClick={() => setShowCover(true)} style={{ textDecoration: "none", cursor: "pointer" }}>
                            Cover Letter
                          </a>
                        </li>
                        {/* <li class="nav-item">
                          <NavLink class="nav-link" to="/coverletter">
                            Terms of use
                          </NavLink>
                        </li> */}
                      </>}
                  <li class="nav-item">
                    <NavLink class="nav-link" to="/contactus">
                      Contact Us
                    </NavLink>
                  </li>
                  {userisLogged && adminUsers.includes(auth?.currentUser?.email) ?
                    ""
                    :
                    <li class="nav-item">
                      <NavLink class="nav-link" to="/money">
                        Pricing
                      </NavLink>
                    </li>
                  }

                </ul>


                <div class="dropdown " >
                  {userisLogged ? (
                    <button
                      class="btn btn-secondary  dropdown-toggle "
                      type="button"
                      onClick={handleNavDrop}
                    >
                      <img ref={menuRef}
                        src={auth.currentUser ? auth.currentUser.photoURL ? auth.currentUser.photoURL : boy : ""}
                        alt="profile-image"
                        width="51px"
                        height="51px"
                        style={{ borderRadius: "50%", objectFit: "cover" }}
                      />
                    </button>
                  ) : (
                    <>
                      <Button
                        text="Login"
                        variant="primary"
                        className="btn-prime btn-primary changeBtn text-white br-8 p-2"
                        onClick={() => handleBackToResume()}
                      />
                    </>
                  )}

                  {navDropdown ? (
                    <ul class="dropdown-menu" >
                      <li onClick={() => { navigate("/profile"); handleNavDrop(); }}>
                        <a class="dropdown-item" href="#">
                          Profile
                          <div className="khaali"></div>
                        </a>
                      </li>
                      {userisLogged && adminUsers.includes(auth?.currentUser?.email) ?

                        ""
                        :

                        <li onClick={() => { navigate("/history"); handleNavDrop(); }}>
                          <a class="dropdown-item" href="#">
                            Resume History
                            <div className="khaali"></div>
                          </a>
                        </li>
                      }
                      {/* <li>
                    <a class="dropdown-item" href="#">
                      AI Chatbot
                      <div className="khaali"></div>
                    </a>
                  </li> */}
                      <li onClick={() => { navigate("/contactus"); handleNavDrop(); }}>
                        <a class="dropdown-item" href="#">
                          Contact Us
                          <div className="khaali"></div>
                        </a>
                      </li>
                      {/* <li>
                    <a class="dropdown-item" href="#">
                      Need Any Help?
                      <div className="khaali"></div>
                    </a>
                  </li> */}
                      <li>
                        <a class="dropdown-item" onClick={() => { handleShow(); handleNavDrop(); }} style={{ cursor: "pointer" }}>
                          Change Password
                          <div className="khaali"></div>
                        </a>
                      </li>
                      <li onClick={() => { navigate("/privacy"); handleNavDrop(); }}>
                        <a class="dropdown-item" href="#">
                          Data Privacy
                          <div className="khaali"></div>
                        </a>
                      </li>
                      <li onClick={() => { navigate("/terms"); handleNavDrop(); }}>
                        <a class="dropdown-item" href="#">
                          Terms of Service
                          <div className="khaali"></div>
                        </a>
                      </li>
                      <li onClick={() => { navigate("/refund"); handleNavDrop(); }}>
                        <a class="dropdown-item" href="#">
                          Refund Policy

                        </a>
                      </li>
                      <li className="new-change ">
                        <div className="nav-btns">
                          {userisLogged ? (
                            <>
                              <Button
                                text="Log Out"
                                className="btn-prime-2 btn-primary br-35 text-white f-14"
                                width="73px"
                                height="37px"
                                onClick={() => { handleLogout(); handleNavDrop(); }}
                              />

                              {/* <Button
                            text="Change Password"
                            className="btn-prime-2 btn-primary br-35 text-white f-14"
                            width="73px"
                            height="37px"
                            onClick={() => handleShow()}
                          /> */}
                            </>
                          ) : (
                            <Button
                              text="Log In"
                              className="btn-prime-2 btn-primary br-35 text-white f-14"
                              width="73px"
                              height="37px"
                              onClick={() => navigate("/login")}
                            />
                          )}
                        </div>
                      </li>
                    </ul>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </>
          }
        </div>
      </nav>

      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {/* <div className="container"></div> */}
            <div className=" new mt-0">
              {/* <label htmlFor="">Old pass</label> */}
              <p>Enter old password</p>
              <input
                type="password"
                onChange={(e) => setOldPassword(e.target.value)}
              />
            </div>
            <div className="new mt-2">
              <p>Enter new password</p>
              <input
                type="password"
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
            <div className="new mt-2">
              <p>Confirm new password</p>
              <input
                type="password"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button text="Close" variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {isLoading ? (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            <Button
              text="Save Changes"
              variant="primary"
              onClick={passwordReset}
            >
              Save Changes
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <Modal show={showCover} onHide={handleResumeModalCover} centered>
        <Modal.Header closeButton>
          <Modal.Title>Create your Cover Letter</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          <div className="create-btn" style={{ marginTop: "30px", marginBottom: "30px", flexDirection: "column", gap: "10px" }}>
            <div style={{ display: "block", fontSize: "18px" }}> Sign in to Resumifi</div>
            <div className="new-btn" style={{ width: "100px", height: "35px" }}>

              <Button
                text="Login"
                fontSize={"14px"}
                width={"50px"}
                height={"30px"}
                // onClick={() => { // console.log("clicked"); navigate("/login") }}
                onClick={() => handleBackToResume()}

              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Navbar;
