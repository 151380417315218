import React, { useEffect } from "react";
import Banner from "../components/home/banner";
import Faq from "../components/home/faq";
import Footer from "../components/home/footer";
import Dreamjob from "../components/home/dreamjob";
import SelectTemplate from "../components/home/selectTemplate";
import Reviewed from "../components/home/reviewed";
import Money from "../components/home/money";
import Work from "../components/home/work";
import Timeline from "../components/home/timeline";
import Join from "../components/home/join";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../config";
import { redirect, useNavigate } from "react-router-dom";

const Home = ({ userData, setUserData, setGeneratedFromCv }) => {
  const navigate = useNavigate();
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (auth.currentUser != null) {
        // navigate("/welcome")
        // redirect("app.localhost:3000/welcome")
        // console.log(window.location.protocol + "//" + "app." + window.location.host);

        //

        // console.log(window.location.hostname);
        const locationParts = window.location.hostname.split(".");
        let sliceTill = -2;

        // for localhost
        // console.log("lp", locationParts);
        // console.log("test", locationParts.slice(-1)[0] == "localhost");
        const isLocalHost = locationParts.slice(-1)[0] == "localhost";
        if (isLocalHost) {
          sliceTill = -1;
        }

        const subdomain = locationParts.slice(0, sliceTill).join("");
        // console.log(subdomain);

        //
        if (subdomain != "app") {

          window.location.href = window.location.protocol + "//" + "app." + window.location.host;
        }
        // console.log("jj")
      }
    });
  }, []);
  return (
    <div>
      <Banner userData={userData} setUserData={setUserData} setGeneratedFromCv={setGeneratedFromCv} />
      <Dreamjob />
      <SelectTemplate />
      {/* <Timeline /> */}

      <Reviewed />
      <Join />
      <Work />
      <Money />
      <Faq />
      <Footer />
    </div>
  );
};

export default Home;
