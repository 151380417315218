import React from "react";
import profile from "../../assets/images/Dashboard/profile.png";
import Button from "../shared/button";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the Quill styles
import { useState } from "react";
import parse from "html-react-parser";
import axios from "axios";
import "../../style/main.scss";
import { useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { auth, firestoredb } from "../../config";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";

const PersonalInformation = ({ userData, setUserData, template, isPaidUser }) => {
  const [editorHtml, setEditorHtml] = useState("");
  const [loading, setLoading] = useState(false);
  const [newPic, setNewPic] = useState("")
  const [downloadLimitExceed, setDownloadLimitExceed] = useState(false);
  const navigate = useNavigate();
  const handleDownloadLimitExceedModal = () => {
    setDownloadLimitExceed(prev => !prev)
  }
  const handleChange = (html) => {
    setEditorHtml(html);
  };

  const handleInfoChange = (e) => {
    // console.log(e.target.name);
    // console.log(e.target.value)
    if (e.target.name == "cvPicture") {
      setUserData((prev) => ({ ...prev, cvPictureObj: e.target.files[0] }));
    }
    else {
      if (e.target.name == "email" && e.target.value.length > 40) {
        // console.log("here")
      }
      else if (e.target.name == "fName" && e.target.value.length > 12) {

      }
      else if (e.target.name == "lName" && e.target.value.length > 12) {

      }
      else if (e.target.name == "tBefore" && e.target.value.length > 6) {

      }
      else if (e.target.name == "role" && e.target.value.length > 30) {

      }

      // else if ((e.target.name == "phone" && isNaN(e.target.value)) || (e.target.name == "phone" && e.target.value.length > 17)) {
      //   if (e.target.value == "+") {
      //     setUserData((prev) => ({ ...prev, [e.target.name]: "+" }));
      //   }
      // }
      else if (e.target.name == "phone" && (e.target.value).length > 17) {
        // console.log("hh");
      }
      else {

        setUserData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
      }
    }

  }
  const handleQuilChange = (value) => {
    // console.log(value);
    // console.log(value)
    setUserData((prev) => ({ ...prev, summary: value }));
  }

  const handleAIWriter = async () => {

    if (isPaidUser) {
      if (userData?.summary && userData.summary.length > 15) {
        const tempData = { summary: parse(userData.summary).props.children }
        // console.log(tempData);
        try {
          const tokenId = await auth.currentUser.getIdToken();
          const config = {
            headers: { Authorization: `Bearer ${tokenId}` }
          };
          setLoading(true);
          // const result = await axios.post("http://localhost:8080/generateSummary", tempData);
          const result = await axios.post("https://resume-ai-nodejs.onrender.com/generateSummary", tempData, config);
          // console.log(result.data);
          if (!result.data.message) {

            setUserData((prev) => ({ ...prev, summary: result.data.summary }));
            setLoading(false);
          }
          else {
            toast.error("Some Error Occured. Please try again. If the error persists please contact support")
            setLoading(false);

          }
        }
        catch (e) {
          // console.error(e.message)
          toast.error("Some Error Occured. Please try again. If the error persists please contact support")

          setLoading(false);
        }
      }
      else {
        toast("Please Provide some details first to generate summary");
      }
    }
    else {
      const docRef = doc(firestoredb, "users", auth.currentUser.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        if (!docSnap.data().downloadCount) {
          if (userData?.summary && userData.summary.length > 15) {
            const tempData = { summary: parse(userData.summary).props.children }
            // console.log(tempData);
            try {
              const tokenId = await auth.currentUser.getIdToken();
              const config = {
                headers: { Authorization: `Bearer ${tokenId}` }
              };
              setLoading(true);
              // const result = await axios.post("http://localhost:8080/generateSummary", tempData);
              const result = await axios.post("https://resume-ai-nodejs.onrender.com/generateSummary", tempData, config);
              // console.log(result.data);
              if (!result.data.message) {

                setUserData((prev) => ({ ...prev, summary: result.data.summary }));
                setLoading(false);
              }
              else {
                toast.error("Some Error Occured. Please try again. If the error persists please contact support")
                setLoading(false);

              }
            }
            catch (e) {
              console.error(e.message)
              setLoading(false);
            }
          }
          else {
            alert("Please Provide some details first to generate summary");
          }
        }
        else if (docSnap.data().downloadCount < 2) {
          if (userData?.summary && userData.summary.length > 15) {
            const tempData = { summary: parse(userData.summary).props.children }
            // console.log(tempData);
            try {
              const tokenId = await auth.currentUser.getIdToken();
              const config = {
                headers: { Authorization: `Bearer ${tokenId}` }
              };
              setLoading(true);
              // const result = await axios.post("http://localhost:8080/generateSummary", tempData);
              const result = await axios.post("https://resume-ai-nodejs.onrender.com/generateSummary", tempData, config);
              // console.log(result.data);
              if (!result.data.message) {

                setUserData((prev) => ({ ...prev, summary: result.data.summary }));
                setLoading(false);
              }
              else {
                toast.error("Some Error Occured. Please try again. If the error persists please contact support")
                setLoading(false);

              }
            }
            catch (e) {
              console.error(e.message)
              setLoading(false);
            }
          }
          else {
            alert("Please Provide some details first to generate summary");
          }
        }
        else {
          // toast("You have exceeded the number of free downloads. Please upgrade your account to Pro Plan.")
          setDownloadLimitExceed(true);
        }
      }
    }




  }


  return (
    <div>
      <div className="bahr v-center h-center">
        <div className="personal-info">
          <div className="top-content d-flex v-center">
            {template ? template == 11 || template == 14 || template == 15 || template == 21 ?
              <div className="left">
                <label htmlFor="cvPicture" style={{ cursor: "pointer" }}>
                  <div className="profile-img">
                    <img src={`${userData?.cvPictureObj ? URL.createObjectURL(userData?.cvPictureObj) : userData?.cvPicture ? userData?.cvPicture : profile}`} alt="" />
                  </div>
                </label>
                <input name="cvPicture" id="cvPicture" type="file" style={{ display: "none" }} onChange={handleInfoChange}></input>
              </div>
              :
              null : null
            }

            <div className="right">
              {/* Name */}

              <div className="input-box ">
                {/* First Name */}

                <label htmlFor="" className="w-50">
                  <p className="p4"> First Name</p>
                  <input name="fName" type="text" onChange={handleInfoChange} value={userData?.fName ? userData?.fName : ""} />
                </label>

                {/* Last Name */}
                <label htmlFor="" className="w-50">
                  <p className="p4"> Last Name</p>
                  <input name="lName" type="text" onChange={handleInfoChange} value={userData?.lName ? userData?.lName : ""} />
                </label>
              </div>

              {/* Title */}
              <div className="input-box d-flex mt-14 ">
                {/* <label htmlFor="" className="w-50">
                  <p className="p4"> Title Before</p>
                  <input name="tBefore" type="text" onChange={handleInfoChange} value={userData?.tBefore ? userData?.tBefore : ""} />
                </label> */}
                <label htmlFor="" className="w-100">
                  <p className="p4">Role</p>
                  <input name="role" type="text" onChange={handleInfoChange} value={userData?.role ? userData?.role : ""} />
                </label>
              </div>
            </div>
            {/* phone */}
          </div>
          <div className="input-box w-100 mt-14 ">
            <label htmlFor="" className="w-100">
              <p className="p4"> Phone Number</p>
              <input name="phone" type="text" onChange={handleInfoChange} value={userData?.phone ? userData?.phone : ""} />
            </label>
          </div>

          {/* Email */}
          <div className="input-box w-100 mt-14 ">
            <label htmlFor="" className="w-100">
              <p className="p4"> Email Address</p>
              <input name="email" type="text" onChange={handleInfoChange} value={userData?.email ? userData?.email : ""} />
            </label>
          </div>

          {/* DOB */}
          <div className="btm mt-14">
            {/* <div className="input-box w-100 date-box">
              <label htmlFor="" className="w-100">
                <p className="p4"> Date of Birth</p>
                <input name="dateOfBirth" type="date" id="" onChange={handleInfoChange} value={userData?.dateOfBirth ? userData?.dateOfBirth : ""} />
              </label>
              <label htmlFor="" className="w-100">
                <p className="p4"> Nationality</p>
                <input name="nationality" type="text" onChange={handleInfoChange} value={userData?.nationality ? userData?.nationality : ""} />
              </label>
            </div> */}

            {/* Address */}
            <div className="input-box w-100 mt-14">
              <label htmlFor="" className="w-100">
                <p className="p4"> Address</p>
                <input name="address" type="text" onChange={handleInfoChange} value={userData?.address ? userData?.address : ""} />
              </label>
            </div>

            {/* Street No. */}
            <div className="btm mt-14">
              <div className="input-box w-100">
                <label htmlFor="" className="w-100">
                  <p className="p4"> Street No</p>
                  <input name="streetNo" type="text" onChange={handleInfoChange} value={userData?.streetNo ? userData?.streetNo : ""} />
                </label>
                <label htmlFor="" className="w-100">
                  <p className="p4"> City</p>
                  <input name="city" type="text" onChange={handleInfoChange} value={userData?.city ? userData?.city : ""} />
                </label>
              </div>
            </div>

            {/* Postal */}
            <div className="btm mt-14">
              <div className="input-box w-100">
                <label htmlFor="" className="w-100">
                  <p className="p4"> Postal</p>
                  <input name="postal" type="text" onChange={handleInfoChange} value={userData?.postal ? userData?.postal : ""} />
                </label>
                <label htmlFor="" className="w-100">
                  <p className="p4"> Country</p>
                  <input name="country" type="text" onChange={handleInfoChange} value={userData?.country ? userData?.country : ""} />
                </label>
              </div>
            </div>

            {/* Web */}
            <div className="input-box w-100 mt-14">
              <label htmlFor="" className="w-100">
                <p className="p4"> Web</p>
                <input name="web" type="text" onChange={handleInfoChange} value={userData?.web ? userData?.web : ""} />
              </label>
            </div>

            {/* Add custom field */}
            {/* <div className="add-custom-field pointer ">
              <Button text="+ Add Custom Fields" width="100%" height="34px" />
            </div> */}

            {/* Profile */}
            <div className="input-box d-flex flex-column gap-0 w-100 mt-14">
              <div className="input-box w-100 mt-14">
                <label htmlFor="" className="w-100">
                  <p className="p4">Create a Professional Career Summary</p>
                </label>
              </div>
              <div className="w-100 editor-wrapper position-relative">
                <ReactQuill
                  value={userData?.summary ? userData?.summary : ""}
                  onChange={handleQuilChange}
                  modules={PersonalInformation.modules}
                  formats={PersonalInformation.formats}
                  placeholder="Add information about yourself or Just write few things about yourself and OUR AI will generate it for you."
                  style={{ paddingBottom: "90px" }}
                />
                {/* AI Writer Button */}
                <div style={{ position: "absolute", bottom: "100px", left: "15px" }}>
                  {!loading ? <div className="writer-btn pointer ">
                    <Button
                      text="AI Writer"
                      width="137px"
                      height="39px"
                      className="btn-prime-1 btn-primary text-white rounded  "
                      onClick={handleAIWriter}

                    />
                  </div>
                    :
                    <div style={{ margin: "0", position: "relative", top: "48px", left: "42px" }} class="lds-ring"><div></div><div></div><div></div><div></div></div>}
                </div>
              </div>
            </div>



          </div>
        </div>
      </div>
      <Modal show={downloadLimitExceed} onHide={handleDownloadLimitExceedModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Free Resources Utilized!</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          <div style={{ padding: "30px 10px", display: "flex", flexDirection: "column", gap: "30px", alignItems: "center" }}>
            <div style={{ display: "flex", alignItems: "flex-start", gap: "5px", width: "100%", flexDirection: "column" }}>
              <h5>Oh no, have exceeded the number of free downloads. Please upgrade your account to Pro Plan to continue enjoying Resume360. </h5>
              <h6 style={{ marginTop: "20px" }}>Benefits of Upgrading:</h6>
              <ul>
                <li>
                  Proofreading From Experts.
                </li>
                <li>
                  35+ professional resumes templates.
                </li>
                <li>
                  AI resume checker.
                </li>
                <li>
                  AI Writer.
                </li>
                <li>
                  Priority emails support.
                </li>
                <li>
                  And Much More.
                </li>
              </ul>
            </div>
            <button style={{ backgroundColor: "#562863", padding: "5px 7px", borderRadius: "5px" }} onClick={() => navigate("/money")} >Upgrade</button>
          </div>


        </Modal.Body>
      </Modal>
    </div >
  );
};
// Quill modules and formats configurations
PersonalInformation.modules = {
  toolbar: [
    // [{ header: "1" }, { header: "2" }, { font: [] }],
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
    // ["link", "image"],
    // ["clean"],
  ],
};

PersonalInformation.formats = [
  "header",
  "font",
  "list",
  "bullet",
  "bold",
  "italic",
  "underline",
  "link",
  "image",
];
export default PersonalInformation;
