import React from "react";
import Button from "../shared/button";
import "../../style/main.scss";
import logo from "../../assets/images/logo.png";
import facebook from "../../assets/icons/facebook2.svg";
import telegram from "../../assets/icons/telegram.svg";
import git from "../../assets/icons/git.svg";
import figma from "../../assets/icons/figma.svg";
import insta from "../../assets/icons/insta.svg";
import social from "../../assets/icons/social.svg";
import { useNavigate } from "react-router-dom";
import handleBackToResume from "../../utils/loginredirect";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="footer">
        <div className="lets-do  v-center justify-content-between">
          <h4 className="text-purple">Take good design today</h4>
          <div className="v-center">
            <label className="large text-purple me-3">Let's do it!</label>

            <Button
              text="Get started"
              className="btn-prime-2 btn-primary br-35"
              width="150px"
              height="40px"
              // onClick={(e) => navigate("/login")}
              onClick={() => handleBackToResume()}
            />
          </div>
        </div>
        <div className="footer-links pt-5">
          <div className="site-logo">
            <img className="footerLogo" src={"/assets/images/resumifi.png"} alt="..." style={{ width: "170px" }} />
          </div>

          <div className="links">
            <div className="use-cases">
              <label htmlFor="">Use Cases</label>
              <ul>
                <li>
                  {/* <a href="#" onClick={() => navigate("/dashboard")}>Resumes</a> */}
                  <a onClick={() => handleBackToResume()} style={{ cursor: "pointer" }}>Resumes</a>
                </li>
                <li>
                  <a onClick={() => navigate("/templates")} style={{ cursor: "pointer" }}>Templates</a>
                </li>
                <li>
                  <a href="#features">Features</a>
                </li>
                <li>
                  {/* <a href="#" onClick={() => navigate("/dashboard")}>AI resume Builder</a> */}
                  <a onClick={() => handleBackToResume()} style={{ cursor: "pointer" }}>AI resume Builder</a>
                </li>
              </ul>
            </div>

            <div className="use-cases">
              <label htmlFor="">Company</label>
              <ul>
                {/* <li>
                  <a href="#">About Us</a>
                </li>
                <li>
                  <a href="#">Careers</a>
                </li> */}
                <li>
                  <a href="#faq">FAQs</a>
                </li>
                {/* <li>
                  <a href="#">Teams</a>
                </li> */}
                <li>
                  <a onClick={() => navigate("/contactus")} style={{ cursor: "pointer" }}>Contact Us</a>
                </li>
                <li>
                  <a onClick={() => navigate("/privacy")} style={{ cursor: "pointer" }}>Privacy</a>
                </li>
                <li>
                  <a onClick={() => navigate("/terms")} style={{ cursor: "pointer" }}>Terms</a>
                </li>
                <li>
                  <a onClick={() => navigate("/refund")} style={{ cursor: "pointer" }}>Refund</a>
                </li>
              </ul>
            </div>

            <div className="use-cases social-links">
              <label htmlFor="">Let's do it!</label>
              <ul>
                <li className="icons">
                  <a href="#">
                    <img src={facebook} alt="" />
                  </a>
                  <a href="#">
                    <img src={social} alt="" />
                  </a>
                  <a href="#">
                    <img src={telegram} alt="" />
                  </a>
                  <a href="#">
                    <img src={git} alt="" />
                  </a>
                  <a href="#">
                    <img src={insta} alt="" />
                  </a>

                  <a href="#">
                    <img src={figma} alt="" />
                  </a>
                </li>

                {/* <li>
                  <label>Subscribe</label>
                </li>
                <li>
                  <a style={{ maxWidth: "320px" }} className="d-flex">
                    Subscribe to stay tuned for new web design and latest
                    updates. Let's do it!
                  </a>
                </li> */}
                {/* <li className="subscribe v-center">
                  <input className="text-dark" type="email" placeholder="Enter your email Address" />
                  <Button
                    text="Subscribe"
                    height="100%"
                    width="112px"
                    className="btn-primary btn-prime-2"
                  />
                </li> */}
              </ul>
            </div>
          </div>
        </div>

        <div className="privacy v-center justify-content-center ">
          <div></div>
          {/* <div className="privacy-links">
            <a href="#">Privacy Policy</a>
            <a href="#">Terms of Use </a>
            <a href="#">Sales and Refunds </a>
            <a href="#">Legal </a>
            <a href="#">Site Map </a>
          </div> */}
          <div>
            <p className="body-small mb-0">
              {/* font-family: Poppins; font-size: 12px; font-style: normal;
              font-weight: 300; line-height: normal; */}
              © 2023 All Rights Reserved
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
