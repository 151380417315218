import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../style/main.scss";
import OctaRobo from "../../assets/images/pngegg.png"
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { auth, firestoredb } from "../../config";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const ToolBox = ({ userData, isPaidUser }) => {
  const [score, setScore] = useState(0);
  const [review, setReview] = useState({ review: [] })
  const [loading, setLoading] = useState("")
  const [downloadLimitExceed, setDownloadLimitExceed] = useState(false)
  const handleDownloadLimitExceedModal = () => {
    setDownloadLimitExceed(prev => !prev)
  }
  const navigate = useNavigate();
  useEffect(() => {
    if (((userData?.education ? userData?.education.length > 0 : false) && userData.fName && userData.lName && userData.email && userData.phone && userData.web && userData.summary && ((userData.projects ? userData?.projects.length > 0 : false) || (userData.experience ? userData?.experience.length > 0 : false)) && (userData.skills ? userData?.skills.length > 0 : false))) {
      // if (!userData.experience[0].description) {
      //   // console.log("In danger condition")
      //   handleAIReview();
      // }
      setReview({ review: ["You're All set"] });
    }
    else {
      handleAIReviewLimit();
    }

  }, [])

  useEffect(() => {
    setScore(0);
    // console.log("score " + score);
    if (userData?.fName || userData?.lName) {
      setScore((score) => score + 5);
    }
    if (userData?.role) {
      setScore((score) => score + 5);
    }
    if (userData?.city || userData?.country) {
      setScore((score) => score + 5)
    }
    if (userData?.phone || userData?.email) {
      setScore((score) => score + 5)
    }
    if (userData?.web) {
      setScore((score) => score + 5)
    }
    if (userData?.experience) {
      // console.log("Here in exp")
      setScore((score) => score + 20)
    }
    if (userData?.education) {
      // console.log("Here in edu")
      setScore((score) => score + 20)
    }
    if (userData.skills.length > 1) {
      // console.log("Here in skill")
      if (userData.skills.length > 4) {
        setScore((score) => score + 10)
      }
      else {
        setScore((score) => score + 5)
      }
    }
    if (userData?.summary) {
      setScore((score) => score + 10)
    }
    if (userData?.projects) {
      setScore((score) => score + 10)
    }
    if (userData.strengths.length > 1) {
      // console.log("Here in strength")
      setScore((score) => score + 5)
    }

  }, [userData])

  const handleAIReview = async () => {
    if (userData?.summary && userData.fName) {
      try {
        const tokenId = await auth.currentUser.getIdToken();
        const config = {
          headers: { Authorization: `Bearer ${tokenId}` }
        };
        setLoading(true);
        // const result = await axios.post("http://localhost:8080/generateResumeReview", userData);
        const result = await axios.post("https://resume-ai-nodejs.onrender.com/generateResumeReview", userData, config);
        // console.log("Helloo ", result.data);
        setReview(result.data)
        setLoading(false);
      }
      catch (e) {
        console.error(e.message)
        setReview({ review: ["Please fill some more information in the Resume first so that our OctaRobo (AI) can review your CV"] });
        setLoading(false);
      }
    }
    else {
      setReview({ review: ["Please fill some more information in the Resume first so that our OctaRobo (AI) can review your CV"] });
    }
  }

  const handleAIReviewLimit = async () => {
    if (userData?.summary && userData.fName) {
      if (isPaidUser) {
        handleAIReview();
      }
      else {
        const docRef = doc(firestoredb, "users", auth.currentUser.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          if (!docSnap.data().aiRecommendationCount) {
            handleAIReview();
            updateDoc(docRef, {
              aiRecommendationCount: 1
            })
          }
          else if (docSnap.data().aiRecommendationCount < 1) {
            handleAIReview();
            updateDoc(docRef, {
              aiRecommendationCount: Number(docSnap.data().aiRecommendationCount) + 1
            })
          }
          else {
            // toast("You have exceeded the number of free downloads. Please upgrade your account to Pro Plan.")
            setDownloadLimitExceed(true);
          }
        }
      }
    }
    else {
      setReview({ review: ["Please fill some more information in the Resume first so that our OctaRobo (AI) can review your CV"] });

    }
  }

  return (
    <div>
      <div className="toolbox ">
        <p className="p6">Resume Analysis</p>
        <div className="inside-toolbox ">
          <div className="score">
            <p className="p6 mb-0">Score</p>
            <p className="new-para-2">
              This score is calculated based on some variables.
            </p>
          </div>
          <div className="bottom">
            <div className="some-text d-flex justify-content-between">
              <p className="new-para-2">{`${score < 40 ? "Hmmm... Consider working on your resume" : score < 80 ? "Not great, not terrible. keep going!" : "You're going good!"}`}</p>
              <p className="new-para-2">{`${score}/100`}</p>
            </div>
            <div className="bar">
              <div className="bar-value"
                style={{ left: `${score}%` }}
              ></div>
            </div>
          </div>
        </div>
        <p style={{ marginTop: "20px", textAlign: "center" }}>
          {/* <img src={OctaRobo} style={{ width: "100px", height: "100px", position: "absolute", left: "230px" }}></img> */}
          OctaRobo Suggestions</p>
        <ul>

          {!loading ?
            review.review.map((review) => (
              <li style={{ border: "1px solid white", borderRadius: "5px", marginBottom: "10px", padding: "15px 5px 5px 15px", }}>
                {review}
              </li>
            )) :
            <div style={{ marginLeft: "50%", transform: "translate(-50%, -50%)", marginTop: "80px" }} class="lds-ring"><div></div><div></div><div></div><div></div></div>}
        </ul>
      </div>
      <Modal show={downloadLimitExceed} onHide={handleDownloadLimitExceedModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Free Resources Utilized!</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          <div style={{ padding: "30px 10px", display: "flex", flexDirection: "column", gap: "30px", alignItems: "center" }}>
            <div style={{ display: "flex", alignItems: "flex-start", gap: "5px", width: "100%", flexDirection: "column" }}>
              <h5>Oh no, have exceeded the number of free AI Recommendation. Please upgrade your account to Pro Plan to continue enjoying Resumifi. </h5>
              <h6 style={{ marginTop: "20px" }}>Benefits of Upgrading:</h6>
              <ul>
                <li>
                  Proofreading From Experts.
                </li>
                <li>
                  35+ professional resumes templates.
                </li>
                <li>
                  AI resume checker.
                </li>
                <li>
                  AI Writer.
                </li>
                <li>
                  Priority emails support.
                </li>
                <li>
                  And Much More.
                </li>
              </ul>
            </div>
            <button style={{ backgroundColor: "#562863", padding: "5px 7px", borderRadius: "5px" }} onClick={() => navigate("/money")} >Upgrade</button>
          </div>


        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ToolBox;
