import React, { useEffect, useState } from 'react'
import PersonalInformation from '../dashboard/personalInformation'
import girl from "../../assets/images/girl.jpg"
import { toast } from 'react-toastify'
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { updateProfile } from 'firebase/auth';
import { onAuthStateChanged } from "firebase/auth";
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';


import { auth, firestoredb, storage } from "../../config";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";



const Profile = () => {
    const [userData, setUserData] = useState("")
    const [newPic, setNewPic] = useState();
    const [dataChanged, setDataChanged] = useState(false);
    const [userId, setUserId] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()

    const user = auth.currentUser;

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (auth.currentUser != null) {
                setUserId(auth.currentUser.uid);
            }
            else {

            }
        });
    }, []);

    onAuthStateChanged(auth, (user) => {
        if (!user) {
            navigate("/login");
        }
    });

    useEffect(() => {
        // console.log(userData)

    }, [userData])
    const handleInfoChange = (e) => {
        setDataChanged(true);
        // console.log(e.target.name);
        // console.log(e.target.value)
        if (e.target.name == "pfp") {
            // setUserData((prev) => ({ ...prev, [e.target.name]: e.target.files[0] }));
            setNewPic(e.target.files[0]);
        }
        else {
            if (e.target.name == "email" && e.target.value.length > 40) {
                // console.log("here")
            }
            else if (e.target.name == "fName" && e.target.value.length > 12) {

            }
            else if (e.target.name == "lName" && e.target.value.length > 12) {

            }
            else if (e.target.name == "tBefore" && e.target.value.length > 6) {

            }
            else if (e.target.name == "role" && e.target.value.length > 30) {

            }

            // else if ((e.target.name == "phone" && isNaN(e.target.value)) || (e.target.name == "phone" && e.target.value.length > 17)) {
            //   if (e.target.value == "+") {
            //     setUserData((prev) => ({ ...prev, [e.target.name]: "+" }));
            //   }
            // }
            else {

                setUserData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
            }
        }

    }

    const handleDbSave = async () => {
        setLoading(true);
        // console.log("User ", user.uid);
        const currentUserRef = doc(firestoredb, "users", user.uid);
        setUserData((prev) => ({ ...prev, cvPicture: "" }));
        // console.log("if outside")

        if ((/\d/.test(userData.fName)) || (/\d/.test(userData.lName))) {
            // console.log("if h")
            toast("Name should not contain numbers");
            setLoading(false)
            return;
        }

        try {

            //fetching previous data first
            if (user) {
                // console.log("Fetching Data from DB");
                const docRef = doc(firestoredb, "users", user.uid);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    // console.log("Document data gs:", docSnap.data());

                    // setUserData({ ...docSnap.data().cvData, skills: docSnap.data().cvData.skills ? docSnap.data().cvData.skills : [], strengths: docSnap.data().cvData.strengths ? docSnap.data().cvData.strengths : [] })
                    // let cvHistory = docSnap.data().cvDataHistory;
                    // let timeStamp = new Date();
                    // timeStamp = String(timeStamp);
                    // timeStamp = timeStamp.split(" GMT");
                    // timeStamp = timeStamp[0];
                    // await updateDoc(currentUserRef, {
                    //   cvDataHistory: [...cvHistory, { ...userData, time: timeStamp, selectedTemplate: template, templateColor: color, templateLineHeight: lineHeight, templateFont: font, templateFontSize: fontSize }],
                    // });
                    // // console.log("Success CV Data History");

                    // await updateDoc(currentUserRef, {
                    //   cvData: { ...userData, time: timeStamp, selectedTemplate: template, templateColor: color, templateLineHeight: lineHeight, templateFont: font, templateFontSize: fontSize },
                    // });
                    // // console.log("Success CV Data");
                    // toast("Data Successfully save to Database");

                    if (dataChanged) {
                        if (newPic) {

                            const storageRef = ref(storage, `/user/${user.uid}`);

                            // 'file' comes from the Blob or File API
                            await uploadBytes(storageRef, newPic).then((snapshot) => {
                                // console.log('Uploaded a blob or file!');
                                getDownloadURL(snapshot.ref).then(async (url) => {
                                    setUserData((prev) => ({ ...prev, pfp: url }))
                                    // console.log("URL", url)
                                    await updateProfile(auth.currentUser, {
                                        photoURL: url
                                    }).then(() => {
                                        // console.log("Image uploaded")
                                    }).catch((error) => {
                                        // console.log("Picture Uploading Error", error);
                                    });
                                })
                            });

                            // await updateProfile(auth.currentUser, {
                            //     photoURL: userData.pfp
                            // }).then(() => {
                            //     // console.log("Image uploaded")
                            // }).catch((error) => {
                            //     // console.log("Picture Uploading Error", error);
                            // });
                        }
                        await updateDoc(currentUserRef, {
                            ...userData,
                        });
                        // console.log("Success CV Data");
                        toast("Data Successfully Updated");
                    }
                    else {
                        toast("No data Changed");
                    }





                } else {
                    // docSnap.data() will be undefined in this case
                    // console.log("No such document!");
                }
            }

            setLoading(false);

        } catch (e) {
            setLoading(false);

            console.error("Error adding document: ", e);
        }
    };
    const getDataFromDb = async () => {
        setLoading(true);

        if (user) {
            // console.log("Fetching Data from DB");
            const docRef = doc(firestoredb, "users", user.uid);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                // console.log("Document data gs:", docSnap.data());
                if (docSnap.data().cvData) {
                    const { fName, lName, phoneNumber, dateOfBirth, address, streetNo, city, postal, country } = docSnap.data();
                    // console.log({ fName, lName, phoneNumber, dateOfBirth, address, streetNo, city, postal, country })
                    setUserData({ fName: fName ? fName : "", lName: lName ? lName : "", phoneNumber: phoneNumber ? phoneNumber : "", dateOfBirth: dateOfBirth ? dateOfBirth : "", address: address ? address : "", streetNo: streetNo ? streetNo : "", city: city ? city : "", postal: postal ? postal : "", country: country ? country : "" })
                }
                setLoading(false);

            } else {
                // docSnap.data() will be undefined in this case
                // console.log("No such document!");
                setLoading(false);

            }
        }
    };
    useEffect(() => {
        getDataFromDb();
    }, [userId]);
    return (
        <div className='profileContainerMain'>
            <h1>Profile Settings</h1>
            <div className="profileContainer">
                <div className="left">
                    <label htmlFor='pfp' >
                        <img src={newPic ? URL.createObjectURL(newPic) : auth.currentUser ? auth.currentUser.photoURL ? auth.currentUser.photoURL : girl : girl} alt="" />
                    </label>
                    <input type="file" name="pfp" id="pfp" accept="image/png, image/gif, image/jpeg" style={{ display: "none" }} onChange={handleInfoChange} />
                </div>
                <div className="right">
                    <div className="inputForm">
                        <div className="input-box ">
                            {/* First Name */}

                            <label htmlFor="" className="w-50">
                                <p className="p4"> First Name</p>
                                <input name="fName" type="text" onChange={handleInfoChange} value={userData?.fName ? userData?.fName : ""} />
                            </label>

                            {/* Last Name */}
                            <label htmlFor="" className="w-50">
                                <p className="p4"> Last Name</p>
                                <input name="lName" type="text" onChange={handleInfoChange} value={userData?.lName ? userData?.lName : ""} />
                            </label>
                        </div>
                        <div className="input-box ">
                            {/* First Name */}

                            <label htmlFor="" className="w-50">
                                <p className="p4"> Phone Number</p>
                                <input name="phoneNumber" type="text" onChange={handleInfoChange} value={userData?.phoneNumber ? userData?.phoneNumber : ""} />
                            </label>

                            {/* Last Name */}
                            <label htmlFor="" className="w-50">
                                <p className="p4">Date of Birth</p>
                                <input name="dateOfBirth" type="date" onChange={handleInfoChange} value={userData?.dateOfBirth ? userData?.dateOfBirth : ""} />
                            </label>
                        </div>

                        {/* DOB */}
                        <div className="btm mt-14">
                            {/* <div className="input-box w-100 date-box">
              <label htmlFor="" className="w-100">
                <p className="p4"> Date of Birth</p>
                <input name="dateOfBirth" type="date" id="" onChange={handleInfoChange} value={userData?.dateOfBirth ? userData?.dateOfBirth : ""} />
              </label>
              <label htmlFor="" className="w-100">
                <p className="p4"> Nationality</p>
                <input name="nationality" type="text" onChange={handleInfoChange} value={userData?.nationality ? userData?.nationality : ""} />
              </label>
            </div> */}

                            {/* Address */}
                            <div className="input-box w-100 mt-14">
                                <label htmlFor="" className="w-100">
                                    <p className="p4"> Address</p>
                                    <input name="address" type="text" onChange={handleInfoChange} value={userData?.address ? userData?.address : ""} />
                                </label>
                            </div>

                            {/* Street No. */}
                            <div className="btm mt-14">
                                <div className="input-box w-100">
                                    <label htmlFor="" className="w-100">
                                        <p className="p4"> Street No</p>
                                        <input name="streetNo" type="text" onChange={handleInfoChange} value={userData?.streetNo ? userData?.streetNo : ""} />
                                    </label>
                                    <label htmlFor="" className="w-100">
                                        <p className="p4"> City</p>
                                        <input name="city" type="text" onChange={handleInfoChange} value={userData?.city ? userData?.city : ""} />
                                    </label>
                                </div>
                            </div>

                            {/* Postal */}
                            <div className="btm mt-14">
                                <div className="input-box w-100">
                                    <label htmlFor="" className="w-100">
                                        <p className="p4"> Postal</p>
                                        <input name="postal" type="text" onChange={handleInfoChange} value={userData?.postal ? userData?.postal : ""} />
                                    </label>
                                    <label htmlFor="" className="w-100">
                                        <p className="p4"> Country</p>
                                        <input name="country" type="text" onChange={handleInfoChange} value={userData?.country ? userData?.country : ""} />
                                    </label>
                                </div>
                            </div>

                        </div>

                    </div>
                    <button className='profileSaveButton' onClick={handleDbSave}>
                        Save
                    </button>
                </div>
            </div>
            <Modal show={loading} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Getting Profile Data</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <div class="lds-ringDashboard">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>

    )
}

export default Profile