import React, { useState, useEffect } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import parse from "html-react-parser";
import "./template8.scss";
import { Icon } from "@iconify/react";
function Template8({ userData, forDownload, sections, color, setColor, font, setFont, fontSize, setFontSize, lineHeight, setLineHeight, fromPreview }) {
  const [currentScale, setCurrentScale] = useState("100%")
  useEffect(() => {
    if (!fromPreview) {
      setColor(userData.templateColor ? userData.templateColor : "rgb(0, 0, 0)");
      setFont(`Poppins`);
      setLineHeight("1.4")
    }

  }, []);
  function handleScaleChange(event) {
    setCurrentScale(`${Math.trunc((event.instance.transformState.scale * 100))}%`);
  }
  const getColor = () => {
    return `:root{--t8Color: ${color};
    --t8LineHeight : ${lineHeight};
  --t8Font : ${font};
--paraFontSize: ${fontSize * 0.70}px;
--subHeadingFontSize: ${fontSize}px; }`;
  };
  const getPageMarginsF = () => {
    return `@page :first { margin: ${"0px"} ${"0px"} ${"10px"} ${"0px"} !important; }`;
  };
  const getPageMargins = () => {
    return `@page { margin: ${"30px"} ${"0px"} ${"10px"} ${"0px"} !important; }`;
  };
  const getPageMarginst = () => {
    return `table { page-break-inside: avoid; }`;
  };

  return (
    // <TransformWrapper
    //   initialScale={1}
    //   maxScale={2}
    //   minScale={0.5}
    //   initialPositionX={0}
    //   initialPositionY={0}
    //   onTransformed={(e) => handleScaleChange(e)}

    // >
    //   {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
    //     <React.Fragment>
    //       <div className="tools" style={{ width: "25%", margin: "10px", display: "flex", gap: "6px" }}>
    //         <div style={{ display: "flex", gap: "10px" }}>
    //           <button onClick={() => zoomOut(0.1)} style={{ backgroundColor: "#180045", padding: "0 8px", borderRadius: "5px" }}>-</button>
    //           <p style={{ margin: "0", padding: "0", color: "black" }}>{currentScale}</p>
    //           <button onClick={() => zoomIn(0.1)} style={{ backgroundColor: "#180045", padding: "0 8px", borderRadius: "5px" }}>+</button>

    //         </div>

    //         <button onClick={() => resetTransform()} style={{ backgroundColor: "#180045", padding: "0 10px", borderRadius: "5px" }}>Reset</button>
    //       </div>
    //       <TransformComponent>
    <section className="template8-sec" ref={forDownload}>
      <style>{getColor()}</style>
      <style>{getPageMargins()}</style>
      <style>{getPageMarginsF()}</style>
      <style>{getPageMarginst()}</style>
      <h2 className="heading">{`${userData?.fName || userData?.lName
        ? `${userData?.fName ? userData?.fName : ""} ${userData?.lName ? userData?.lName : ""
        }`
        : "Your Name"
        }`}</h2>
      <div className="top">
        <div className="location">
          <Icon icon="mdi:location" className="iconColor" width="24" height="24" />
          <div className="para">{`${userData?.city || userData?.country
            ? `${userData?.city ? userData?.city : ""}, ${userData?.country ? userData?.country : ""
            }`
            : "Your Location"
            }`}</div>
        </div>
        <div className="email">
          <Icon icon="ic:baseline-email" className="iconColor" width="24" height="24" />
          <div className="para">{userData?.email ? userData?.email : "Your Email"}</div>
        </div>
        <div className="phone">
          <Icon icon="ic:baseline-phone" className="iconColor" width="24" height="24" />
          <div className="para">{userData?.phone ? userData?.phone : "Your Phone"}</div>
        </div>
        <div className="linkdin">
          <Icon icon="mdi:web" className="iconColor" width="24" height="24" />
          <div className="para">{userData.web ? userData.web : "Your Linkedin"}</div>
        </div>
      </div>
      <div className="bline"></div>

      <div className="content">

        <div className="summary-sec">
          <h2 className="sub-heading">Summary</h2>
          <p className="para">
            {parse(
              userData?.summary
                ? userData?.summary
                : `Lorem ipsum dolor sit amet, consectetur adip iscing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ulla mco
                laboris nisi ut aliquip ex ea commodo consequat.`
            )}
          </p>
        </div>
        {
          sections.skills ? <>
            <div className="projects-sec">
              <h2 className="sub-heading">Skills</h2>
              <p className="para">
                {userData?.skills ? (
                  userData?.skills.map((skill, index) => {
                    if (index == userData.skills.length - 1) {
                      return `${skill}`;
                    } else {
                      return `${skill}, `;
                    }
                  })
                ) : (
                  <li>Your Skills</li>
                )}
              </p>
            </div>
          </>
            :
            ""

        }
        {
          sections.strengths ? <>
            <div className="projects-sec">
              <h2 className="sub-heading">Soft Skills</h2>
              <p className="para">
                {userData?.strengths ? (
                  userData?.strengths.map((strength, index) => {
                    if (index == userData.strengths.length - 1) {
                      return `${strength}`;
                    } else {
                      return `${strength}, `;
                    }
                  })
                ) : (
                  <li>Your Soft Skills</li>
                )}
              </p>
            </div>
          </>
            :
            ""

        }

        {
          sections.experience ? <>
            <div className="experience-sec">
              <h2 className="sub-heading">Experience</h2>

              {userData.experience ? userData.experience.map((experience) => (
                <>
                  <p className="para bold-para">
                    {`${experience.jobTitle ? experience.jobTitle : ""}${experience.jobTitle && experience.companyName ? " | " : ""}${experience.companyName ? experience.companyName : ""}${experience.companyName && (experience.cityName || experience.country) ? " | " : ""}${experience.cityName ? experience.cityName : ""}${experience.cityName && experience.country ? ", " : ""}${experience.country ? ` ${experience.country}` : ""}${(experience.companyName || experience.cityName || experience.country) && (experience.startMonth || experience.startYear || experience.endMonth || experience.endYear) ? " | " : ""}${experience?.startMonth ||
                      experience?.startYear ||
                      experience?.endMonth ||
                      experience?.endYear ||
                      experience?.currentlyWorkHere
                      ? `${experience?.startMonth ? experience?.startMonth : ""
                      } ${experience?.startYear ? experience?.startYear : ""
                      } ${experience?.endMonth ||
                        experience?.endYear ||
                        experience?.currentlyWorkHere
                        ? "-"
                        : ""
                      } ${experience?.currentlyWorkHere
                        ? "Present"
                        : `${experience?.endMonth ? experience?.endMonth : ""
                        } ${experience?.endYear ? experience?.endYear : ""
                        }`
                      }`
                      : "Your Company Tenure"
                      }`}
                  </p>
                  {parse(
                    experience?.description
                      ? experience?.description
                      : "Your Job Description"
                  )}

                </>
              ))

                :
                <>
                  <p className="para bold-para">
                    Mechanical Engineer Intern | Company A | New York, NY | June 2020 -
                    Present
                  </p>
                  <ul>
                    <li>
                      <div className="para">
                        Responsibility 1
                      </div>
                    </li>
                    <li>
                      <div className="para">
                        Responsibility 2
                      </div>
                    </li>
                    <li>
                      <div className="para">
                        Responsibility 3
                      </div>
                    </li>
                    <li>
                      <div className="para">
                        Responsibility 4
                      </div>
                    </li>
                    <li>
                      <div className="para">
                        Responsibility 5
                      </div>
                    </li>
                  </ul>
                </>
              }


            </div>
          </>
            :
            ""

        }

        {
          sections.projects ? <>
            <div className="projects-sec">
              <h2 className="sub-heading">PROJECTS</h2>
              {userData.projects ? userData.projects.map((project) => (
                <>
                  <p className="para bold-para">
                    {`${project.projectName ? project.projectName : ""}${project.projectName && project.projectType ? " | " : ""}${project.projectType ? project.projectType : ""}${(project.projectName || project.projectType) && (project.startMonth || project.startYear || project.endMonth || project.endYear) ? " | " : ""}${project?.startMonth ||
                      project?.startYear ||
                      project?.endMonth ||
                      project?.endYear ||
                      project?.currentlyWorkHere
                      ? `${project?.startMonth ? project?.startMonth : ""
                      } ${project?.startYear ? project?.startYear : ""
                      } ${project?.endMonth ||
                        project?.endYear ||
                        project?.currentlyWorkHere
                        ? "-"
                        : ""
                      } ${project?.currentlyWorkHere
                        ? "Present"
                        : `${project?.endMonth ? project?.endMonth : ""
                        } ${project?.endYear ? project?.endYear : ""
                        }`
                      }`
                      : "Your Company Tenure"
                      }`}
                  </p>
                  {parse(
                    project?.description
                      ? project?.description
                      : "Your Job Description"
                  )}

                </>
              ))

                :
                <>
                  <p className="para bold-para">
                    Mechanical Engineer Intern | Company A | New York, NY | June 2020 -
                    Present
                  </p>
                  <ul>
                    <li>
                      <div className="para">
                        Feature 1
                      </div>
                    </li>
                    <li>
                      <div className="para">
                        Feature 2
                      </div>
                    </li>
                    <li>
                      <div className="para">
                        Feature 3
                      </div>
                    </li>
                    <li>
                      <div className="para">
                        Feature 4
                      </div>
                    </li>
                    <li>
                      <div className="para">
                        Feature 5
                      </div>
                    </li>
                  </ul>
                </>
              }
            </div>
          </>
            :
            ""

        }

        {
          sections.volunteer ? <>
            <div className="volunteer-sec">
              <h2 className="sub-heading">Volunteer</h2>
              {userData.volunteer ?
                userData.volunteer.map((volunteer, index) => (
                  <>
                    <p className="para bold-para">
                      {`${volunteer.role ? volunteer.role : ""}${volunteer.role && volunteer.institutionName ? " | " : ""}${volunteer.institutionName ? volunteer.institutionName : ""}${volunteer.institutionName && (volunteer.cityName || volunteer.country) ? " | " : ""}${volunteer.cityName ? volunteer.cityName : ""}${volunteer.cityName && volunteer.country ? ", " : ""}${volunteer.country ? ` ${volunteer.country}` : ""}${(volunteer.institutionName || volunteer.cityName || volunteer.country) && (volunteer.startMonth || volunteer.startYear || volunteer.endMonth || volunteer.endYear) ? " | " : ""}${volunteer?.startMonth ||
                        volunteer?.startYear ||
                        volunteer?.endMonth ||
                        volunteer?.endYear ||
                        volunteer.currentlyVolunteerHere
                        ? `${volunteer?.startMonth ? volunteer?.startMonth : ""
                        } ${volunteer?.startYear ? volunteer?.startYear : ""
                        } ${volunteer?.endMonth ||
                          volunteer?.endYear ||
                          volunteer.currentlyVolunteerHere
                          ? "-"
                          : ""
                        } ${volunteer.currentlyVolunteerHere
                          ? "Present"
                          : `${volunteer?.endMonth ? volunteer?.endMonth : ""
                          } ${volunteer?.endYear ? volunteer?.endYear : ""
                          }`
                        }`
                        : "Your Company Tenure"
                        }`}
                    </p>

                    <div className="para">{parse(
                      volunteer?.description
                        ? volunteer?.description
                        : "Your volunteer Description"
                    )}</div>

                  </>
                ))
                :
                <>
                  <p className="para bold-para">
                    Mechanical Engineer Intern | Company A | New York, NY | June 2020 -
                    Present
                  </p>
                  <ul>
                    <li>
                      <div className="para">Feature 1</div>
                    </li>
                    <li>
                      <div className="para">Feature 2</div>
                    </li>
                    <li>
                      <div className="para">Feature 3</div>
                    </li>
                    <li>
                      <div className="para">Feature 4</div>
                    </li>
                    <li>
                      <div className="para">Feature 5</div>
                    </li>
                  </ul>
                </>}

            </div>
          </>
            :
            ""

        }
        {
          sections.education ? <>
            <div className="education-sec">
              <h2 className="sub-heading">Education</h2>
              {userData.education ? userData.education.map((education) => (
                <p className="para bold-para">
                  {`${education.degreeName ? education.degreeName : ""}${education.degreeName && education.schoolName ? " | " : ""}${education.schoolName ? education.schoolName : ""}${education.schoolName && (education.cityName || education.country) ? " | " : ""}${education.cityName ? education.cityName : ""}${education.cityName && education.country ? ", " : ""}${education.country ? ` ${education.country}` : ""}${(education.schoolName || education.cityName || education.country) && (education.startMonth || education.startYear || education.endMonth || education.endYear) ? " | " : ""}${education?.startMonth ||
                    education?.startYear ||
                    education?.endMonth ||
                    education?.endYear ||
                    education?.currentlyStudyHere
                    ? `${education?.startMonth ? education?.startMonth : ""
                    } ${education?.startYear ? education?.startYear : ""
                    } ${education?.endMonth ||
                      education?.endYear ||
                      education?.currentlyStudyHere
                      ? "-"
                      : ""
                    } ${education?.currentlyStudyHere
                      ? "Present"
                      : `${education?.endMonth ? education?.endMonth : ""
                      } ${education?.endYear ? education?.endYear : ""
                      }`
                    }`
                    : "Your Company Tenure"
                    }`}
                </p>
              ))

                :
                <p className="para bold-para">
                  BS in Mechanical Engineering | New York University | New York, NY |
                  2017
                </p>
              }

            </div>
          </>
            :
            ""

        }

        {
          sections.certificates ? <>
            <div className="education-sec">
              <h2 className="sub-heading">Certificate</h2>
              {userData.certificates ? userData.certificates.map((certificate) => (
                <p className="para bold-para">
                  {`${certificate.certificateName ? certificate.certificateName : ""}${certificate.certificateName && certificate.authority ? " | " : ""}${certificate.authority ? certificate.authority : ""}${(certificate.certificateName || certificate.authority) && certificate.certificateDate ? " | " : ""}${certificate.certificateDate ? certificate.certificateDate : ""}`}
                </p>
              ))

                :
                <p className="para bold-para">
                  BS in Mechanical Engineering | New York University | New York, NY |
                  2017
                </p>
              }

            </div>
          </>
            :
            ""

        }
        {
          sections.awards ? <>
            <div className="award-sec">
              <h2 className="sub-heading">Awards</h2>
              {userData.awards ?
                userData.awards.map((award, index) => (
                  <p className="para bold-para">
                    {`${award.awardTitle ? award.awardTitle : ""}${award.awardTitle && award.issuer ? " | " : ""}${award.issuer ? award.issuer : ""}${award.issuer && (award.cityName || award.country) ? " | " : ""}${award.cityName ? award.cityName : ""}${award.cityName && award.country ? ", " : ""}${award.country ? ` ${award.country}` : ""}${(award.issuer || award.cityName || award.country) && (award.awardMonth || award.awardYear) ? " | " : ""}${award.awardMonth ? award.awardMonth : ""}${award.awardMonth && award.awardYear ? ", " : ""}${award.awardYear ? award.awardYear : ""}`}
                  </p>
                ))
                :
                <p className="para bold-para">
                  BS in Mechanical Engineering | New York University | New York, NY |
                  2017
                </p>}

            </div>
          </>
            :
            ""

        }
        {
          sections.publications ? <>
            <div className="publication-sec">
              <h2 className="sub-heading">Publications</h2>
              {userData.publications ? userData.publications.map((publication, index) => (
                <>
                  <p className="para bold-para">{`${publication.publicationTitle ? publication.publicationTitle : ""}${publication.publicationTitle && publication.publisher ? " | " : ""}${publication.publisher ? publication.publisher : ""}${(publication.publicationTitle || publication.publisher) && (publication.publicationMonth || publication.publicationYear) ? " | " : ""}${publication.publicationMonth ? publication.publicationMonth : ""}${publication.publicationMonth && publication.publicationYear ? ", " : ""}${publication.publicationYear ? publication.publicationYear : ""}`}</p>

                  <p className="para">
                    {parse(
                      publication?.description
                        ? publication?.description
                        : "Your Reference Description"
                    )}
                  </p>

                </>
              ))
                :
                <>
                  <p className="para bold-para">Title | Book Name | 2017</p>
                  <ul>
                    <li>
                      <p className="para">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Tempora, officia?
                      </p>
                    </li>
                  </ul>
                </>}

            </div>
          </>
            :
            ""

        }

        {
          sections.interests ? <>
            <div className="interest-sec">
              <h2 className="sub-heading">Interests</h2>
              <ul>
                {userData.interests ? userData.interests.map((interest, index) => (
                  <li>
                    <p className="para">{interest}</p>
                  </li>
                ))
                  :
                  <li>
                    <p className="para">Interest 1</p>
                  </li>
                }

              </ul>
            </div>
          </>
            :
            ""

        }

        {/* {
                  sections.languages ? <>
                    <div className="language-sec">
                      <h2 className="sub-heading">Languages</h2>
                      <ul>
                        {userData.languages ? userData.languages.map((language, index) => (
                          <li>
                            <p className="para">{language}</p>
                          </li>
                        ))
                          :
                          <li>
                            <p className="para">1</p>
                          </li>}
                        {/* <li>
              <p className="para">1</p>
            </li>
            <li>
              <p className="para">2</p>
            </li> }
                      </ul>
                    </div>
                  </>
                    :
                    ""

                } */}
        {
          sections.reference ? <>
            <div className="reference-sec">
              <h2 className="sub-heading">Reference</h2>
              {userData.reference ? userData.reference.map((reference, index) => (
                <div className="detail">
                  <div className="ist">
                    <div className="head">
                      <p className="para bold-para">{reference.referenceCompany ? reference.referenceCompany : ""}</p>
                      <p className="para">{reference.contact1 ? reference.contact1 : ""}</p>
                    </div>
                    <div className="head">
                      <p className="para">{reference.personName ? reference.personName : ""}</p>
                      <p className="para">{reference.contact2 ? reference.contact2 : ""}</p>
                    </div>
                  </div>

                  <p className="para">
                    {parse(
                      reference?.description
                        ? reference?.description
                        : "Your Reference Description"
                    )}
                  </p>

                </div>
              ))
                :
                <div className="detail">
                  <div className="ist">
                    <div className="head">
                      <p className="para bold-para">Company Name</p>
                      <p className="para">Contact 1</p>
                    </div>
                    <div className="head">
                      <p className="para">Person Name</p>
                      <p className="para">Contact 2</p>
                    </div>
                  </div>
                  <ul>
                    <li>
                      <p className="para">
                        Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                        Iusto, amet.
                      </p>
                    </li>
                  </ul>
                </div>}
              {/* <div className="detail">
            <div className="ist">
              <div className="head">
                <p className="para bold-para">Company Name</p>
                <p className="para">Contact 1</p>
              </div>
              <div className="head">
                <p className="para">Person Name</p>
                <p className="para">Contact 2</p>
              </div>
            </div>
            <ul>
              <li>
                <p className="para">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Iusto, amet.
                </p>
              </li>
            </ul>
          </div>
          <div className="detail">
            <div className="ist">
              <div className="head">
                <p className="para bold-para">Company Name</p>
                <p className="para">Contact 1</p>
              </div>
              <div className="head">
                <p className="para">Person Name</p>
                <p className="para">Contact 2</p>
              </div>
            </div>
            <ul>
              <li>
                <p className="para">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Iusto, amet.
                </p>
              </li>
            </ul>
          </div> */}
            </div>
          </>
            :
            ""

        }



      </div >
    </section >
    //       </TransformComponent>
    //     </React.Fragment>
    //   )}
    // </TransformWrapper>
  );
}

export default Template8;
