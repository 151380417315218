import React, { useState } from "react";
import Button from "../shared/button";
import "../../style/main.scss";
import { Icon } from "@iconify/react";
import temple from "../../assets/images/Dashboard/thumbnail.png";
import template1 from "../../assets/images/tempelates/1.png";
import template2 from "../../assets/images/tempelates/2.png";
import template3 from "../../assets/images/tempelates/3.png";
import template4 from "../../assets/images/tempelates/4.png";
import template5 from "../../assets/images/tempelates/5.png";
import newTemplate0 from "../../assets/images/tempelates/Template1.png";
import newTemplate1 from "../../assets/images/tempelates/t6.png";
import newTemplate2 from "../../assets/images/tempelates/t7.png";
import newTemplate3 from "../../assets/images/tempelates/t8.png";
import newTemplate4 from "../../assets/images/tempelates/t9.png";
import newTemplate5 from "../../assets/images/tempelates/Template10.png";
import newTemplate6 from "../../assets/images/tempelates/Template11.png";
import newTemplate7 from "../../assets/images/tempelates/Template12.png";
import newTemplate8 from "../../assets/images/tempelates/Template13.png";
import newTemplate9 from "../../assets/images/tempelates/Template14.png";
import newTemplate10 from "../../assets/images/tempelates/Template15.png";
import newTemplate11 from "../../assets/images/tempelates/Template16.png";
import newTemplate12 from "../../assets/images/tempelates/t17.png";
import newTemplate13 from "../../assets/images/tempelates/t18.png";
import newTemplate14 from "../../assets/images/tempelates/t19.png";
import newTemplate15 from "../../assets/images/tempelates/t20.png";
import newTemplate16 from "../../assets/images/tempelates/t21.png";
import newTemplate17 from "../../assets/images/tempelates/Template22.png";
import newTemplate18 from "../../assets/images/tempelates/Template23.png";
import newTemplate19 from "../../assets/images/tempelates/Template24.png";
import newTemplate20 from "../../assets/images/tempelates/Template25.png";
import newTemplate21 from "../../assets/images/tempelates/template26.png";
import newTemplate22 from "../../assets/images/tempelates/template27.png";
import newTemplate23 from "../../assets/images/tempelates/template28.png";
import newTemplate24 from "../../assets/images/tempelates/template29.png";
import newTemplate25 from "../../assets/images/tempelates/template30.png";

const defaultColor = [
  {
    template: "6",
    color: "#3B3B80"
  },
  {
    template: "7",
    color: "rgb(0, 0, 0)"
  },

  {
    template: "8",
    color: "rgb(0, 0, 0)"
  },
  {
    template: "9",
    color: "#3F5FC7"
  },
  {
    template: "17",
    color: "rgb(0, 210, 230)"
  },
  {
    template: "18",
    color: "#DCB4A3"
  },

  {
    template: "19",
    color: "#000"
  },
  {
    template: "20",
    color: "rgb(0, 0, 0)"
  },
  {
    template: "21",
    color: "#000"
  },
  {
    template: "26",
    color: "#f4f4f4"
  },
  {
    template: "27",
    color: "#942637"
  },
  {
    template: "28",
    color: "#2f2f2f"
  },
  {
    template: "29",
    color: "#bbc9ff"
  },
  {
    template: "30",
    color: "#d69460"
  }
]

const Design = ({
  color,
  setColor,
  template,
  setTemplate,
  font,
  setFont,
  fontSize,
  setFontSize,
  setLineHeight,
  lineHeight,
  setUserData,
}) => {
  const [selected, setSelected] = useState("#8d4b55");

  const resetToDefaultColor = () => {

    defaultColor.map((color) => {
      if (template == String(color.template - 6)) {

        setColor(color.color)
        setUserData((prev) => ({
          ...prev,
          templateColor: color.color,
        }))
      }
    })


  }

  return (
    <div>
      <div className="design d-flex flex-column v-center  ">
        <div className="inside-design ">
          <div className="top ">
            <p className="p6">Customization</p>
          </div>
          <div className="design-settings">
            <Icon icon="fa-solid:icons" color="black" width="16" height="16" />
            <span className="noselect">|</span>
            <div class="dropdown font-dropdown">
              <button
                class="btn dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{ fontFamily: font }}
              >
                {font}
              </button>
              <ul class="dropdown-menu">
                <li style={{ fontFamily: "Arial" }}>
                  <a
                    style={{ fontFamily: "Arial", cursor: "pointer" }}
                    class="dropdown-item"

                    onClick={() => {
                      setFont("Arial");
                      setUserData((prev) => ({
                        ...prev,
                        templateFont: "Arial",
                      }));
                    }}
                  >
                    Arial
                  </a>
                </li>
                <li style={{ fontFamily: "Poppins" }}>
                  <a
                    style={{ fontFamily: "Poppins", cursor: "pointer" }}
                    class="dropdown-item"

                    onClick={() => {
                      setFont("Poppins");
                      setUserData((prev) => ({
                        ...prev,
                        templateFont: "Poppins",
                      }));
                    }}
                  >
                    Poppins
                  </a>
                </li>
                <li style={{ fontFamily: "Roboto" }}>
                  <a
                    style={{ fontFamily: "Roboto", cursor: "pointer" }}
                    class="dropdown-item"

                    onClick={() => {
                      setFont("Roboto");
                      setUserData((prev) => ({
                        ...prev,
                        templateFont: "Roboto",
                      }));
                    }}
                  >
                    Roboto
                  </a>
                </li>
                <li style={{ fontFamily: "Montserrat" }}>
                  <a
                    style={{ fontFamily: "Montserrat", cursor: "pointer" }}
                    class="dropdown-item"

                    onClick={() => {
                      setFont("Montserrat");
                      setUserData((prev) => ({
                        ...prev,
                        templateFont: "Montserrat",
                      }));
                    }}
                  >
                    Montserrat
                  </a>
                </li>
                {template == "20" ?
                  <li style={{ fontFamily: "Lora" }}>
                    <a
                      style={{ fontFamily: "Lora", cursor: "pointer" }}
                      class="dropdown-item"

                      onClick={() => {
                        setFont("Lora");
                        setUserData((prev) => ({
                          ...prev,
                          templateFont: "Lora",
                        }));
                      }}
                    >
                      Lora
                    </a>
                  </li>
                  :
                  ""}
                <li style={{ fontFamily: "Lato" }}>
                  <a
                    style={{ fontFamily: "Lato", cursor: "pointer" }}
                    class="dropdown-item"

                    onClick={() => {
                      setFont("Lato");
                      setUserData((prev) => ({
                        ...prev,
                        templateFont: "Lato",
                      }));
                    }}
                  >
                    Lato
                  </a>
                </li>
                <li style={{ fontFamily: "Cursive" }}>
                  <a
                    style={{ fontFamily: "Cursive", cursor: "pointer" }}
                    class="dropdown-item"

                    onClick={() => {
                      setFont("Cursive");
                      setUserData((prev) => ({
                        ...prev,
                        templateFont: "Cursive",
                      }));
                    }}
                  >
                    Cursive
                  </a>
                </li>
                {/* <li>
                  <a
                    class="dropdown-item"
                    href="#"
                    onClick={() => {
                      setFont("Courier");
                      setUserData(prev => ({ ...prev, templateFont: "Courier" }));
                    }}
                  >
                    Courier
                  </a>
                </li> */}
              </ul>
            </div>
            <span className="noselect">|</span>
            <div className="font-size">
              <Icon
                icon="typcn:minus"
                color="black"
                width="16"
                height="16"
                onClick={() =>
                  fontSize > 16 ? setFontSize((prev) => prev - 1) : ""
                }
              />
              <p className="noselect">{fontSize}</p>
              <Icon
                icon="typcn:plus"
                color="black"
                width="16"
                height="16"
                onClick={() => {
                  if (fontSize < 24) {
                    setFontSize((prev) => prev + 1);
                  }
                  setUserData((prev) => ({
                    ...prev,
                    templateFontSize: fontSize,
                  }));
                }}
              />
            </div>

            <span className="noselect">|</span>
            <div class="dropdown lineheight-dropdown">
              <button
                class="btn dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <Icon
                  icon="gg:format-line-height"
                  color="black"
                  width="20"
                  height="20"
                />
                <p>{lineHeight}</p>
              </button>
              <ul class="dropdown-menu">
                <li class="dropdown-item">
                  <span>1</span>{" "}
                  <input
                    type="range"
                    min="1"
                    max="2"
                    step="0.1"
                    onChange={(e) => {
                      setLineHeight(e.target.value);
                      setUserData((prev) => ({
                        ...prev,
                        templateLineHeight: e.target.value,
                      }));
                    }}
                    value={lineHeight}
                  />
                  <span>2</span>
                </li>
              </ul>
            </div>
            <span className="noselect">|</span>

            <input
              type="color"
              className="color-div"
              value={color}
              onChange={(e) => {
                setColor(e.target.value);
                setUserData((prev) => ({
                  ...prev,
                  templateColor: e.target.value,
                }));
              }}
            />

            <p style={{ textDecoration: "underline", cursor: "pointer" }} onClick={resetToDefaultColor}>Reset Color</p>
          </div>
          {/* New items */}
          {/* <div className="new-item gap-3 d-flex">
            <div className="input-box  w-100">
              <label htmlFor="" className="w-50">
                <p className="p7">Color</p>
                <select
                  onChange={(e) => {
                    setColor(e.target.value);
                    setSelected(e.target.value);
                  }}
                  style={{ backgroundColor: color }}
                  value={color}
                >
                  {template == 4 ? (
                    <>
                      <option
                        value="#F7F7F7"
                        style={{ backgroundColor: "#F7F7F7", padding: "10px" }}
                      >
                        ㅤㅤㅤㅤㅤ
                      </option>
                      <option
                        value="lightcyan"
                        style={{
                          backgroundColor: "lightcyan",
                          padding: "10px",
                        }}
                      >
                        ㅤㅤㅤㅤㅤ
                      </option>
                      <option
                        value="lightgoldenrodyellow"
                        style={{
                          backgroundColor: "lightgoldenrodyellow",
                          padding: "10px",
                        }}
                      >
                        ㅤㅤㅤㅤㅤ
                      </option>
                      <option
                        value="lightyellow"
                        style={{
                          backgroundColor: "lightyellow",
                          padding: "10px",
                        }}
                      ></option>
                      <option
                        value="#E1F8DC"
                        style={{ backgroundColor: "#E1F8DC", padding: "10px" }}
                      >
                        ㅤㅤㅤㅤㅤ
                      </option>
                    </>
                  ) : (
                    <>
                      <option
                        value="#8d4b55"
                        style={{ backgroundColor: "#8d4b55", padding: "10px" }}
                      >
                        ㅤㅤㅤㅤㅤ
                      </option>
                      <option
                        value="teal"
                        style={{ backgroundColor: "teal", padding: "10px" }}
                      >
                        ㅤㅤㅤㅤㅤ
                      </option>
                      <option
                        value="cadetblue"
                        style={{
                          backgroundColor: "cadetblue",
                          padding: "10px",
                        }}
                      ></option>
                      <option
                        value="#443d41"
                        style={{ backgroundColor: "#443d41", padding: "10px" }}
                      >
                        ㅤㅤㅤㅤㅤ
                      </option>

                      <option
                        value="	#4f5a69"
                        style={{ backgroundColor: "#4f5a69", padding: "10px" }}
                      >
                        ㅤㅤㅤㅤㅤ
                      </option>
                      <option
                        value="rgb(55, 88, 197)"
                        style={{
                          backgroundColor: "rgb(55, 88, 197)",
                          padding: "10px",
                        }}
                      >
                        ㅤㅤㅤㅤㅤ
                      </option>
                      <option
                        value="rgb(59, 59, 128)"
                        style={{
                          backgroundColor: "rgb(59, 59, 128)",
                          padding: "10px",
                        }}
                      >
                        ㅤㅤㅤㅤㅤ
                      </option>
                      <option
                        value="rgb(0, 0, 0)"
                        style={{
                          backgroundColor: "rgb(0, 0, 0)",
                          padding: "10px",
                        }}
                      >
                        ㅤㅤㅤㅤㅤ
                      </option>
                    </>
                  )}
                </select>
              </label>

              <label htmlFor="" className="w-50">
                <p className="p7">Font</p>
                <select
                  onChange={(e) => {
                    setFont(e.target.value);
                  }}
                  style={{ fontFamily: font }}
                  value={font}
                >
                  <option
                    value="Arial"
                    style={{ padding: "10px", fontFamily: "Arial" }}
                  >
                    Arial
                  </option>
                  <option
                    value={`"Poppins", monospace;`}
                    style={{
                      padding: "10px",
                      fontFamily: `"Poppins", monospace;`,
                    }}
                  >
                    Poppins
                  </option>
                  <option
                    value="cursive"
                    style={{ padding: "10px", fontFamily: "cursive" }}
                  >
                    Cursive
                  </option>
                  <option
                    value="courier"
                    style={{ padding: "10px", fontFamily: "courier" }}
                  >
                    Courier
                  </option>
                </select>
              </label>
              <label htmlFor="" className="w-50">
                <p className="p7">Line Spacing</p>
                <input
                  type="range"
                  name="points"
                  min="1"
                  max="2"
                  step="0.1"
                  onChange={(e) => setLineHeight(e.target.value)}
                  value={lineHeight}
                />
              </label>
            </div>
          </div> */}

          {/* <div className="new-item mt-14 gap-3 d-flex">
            <div className="input-box page-attrib w-100">
              <label htmlFor="" className="w-50">
                <p className="p7">Font Size</p>
                <select
                  onChange={(e) => {
                    setFontSize(e.target.value);
                  }}
                  style={{ fontFamily: font }}
                  value={fontSize}
                >
                  <option
                    value={16}
                    style={{ padding: "10px", fontSize: 16, fontFamily: font }}
                  >
                    Extra Small
                  </option>
                  <option
                    value={18}
                    style={{ padding: "10px", fontSize: 18, fontFamily: font }}
                  >
                    Small
                  </option>
                  <option
                    value={20}
                    style={{ padding: "10px", fontSize: 20, fontFamily: font }}
                  >
                    Normal
                  </option>
                  <option
                    value={22}
                    style={{ padding: "10px", fontSize: 22, fontFamily: font }}
                  >
                    Large
                  </option>
                  <option
                    value={24}
                    style={{ padding: "10px", fontSize: 24, fontFamily: font }}
                  >
                    Extra Large
                  </option>
                </select>
              </label>
            </div>
          </div> */}
        </div>
        {/* Templates */}
        <div className="bottom-design ">
          <div className="top ">
            <p className="p6">Templates</p>
          </div>
          <div className="design-template templates">
            <div className="templates-content">
              {templates.map((item, index) => {
                return index == 4 ||
                  index == 5 ||
                  index == 6 ||
                  index == 7 ||
                  index == 8 ||
                  index == 9 ||
                  index == 10 ||
                  index == 16 ||
                  index == 17 ||
                  index == 18 ||
                  index == 19 ? (
                  ""
                ) : (
                  <div
                    className="template-card"
                    style={{
                      border: "1px solid rgb(24, 0, 69)",
                      borderRadius: "5px",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={item.template}
                      alt="template"
                      className="img-fluid"
                    />
                    <div className="use-template ">
                      <Button
                        text="Use Template"
                        width="118px"
                        height="30px"
                        className="btn-prime-2 btn-primary text-white rounded "
                        onClick={(e) => {
                          setTemplate(index);
                          setUserData((prev) => ({
                            ...prev,
                            selectedTemplate: index,
                          }));
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const templates = [
  {
    template: newTemplate1,
  },
  {
    template: newTemplate2,
  },
  {
    template: newTemplate3,
  },
  {
    template: newTemplate4,
  },

  {
    template: newTemplate5,
  },
  {
    template: newTemplate6,
  },
  {
    template: newTemplate7,
  },
  {
    template: newTemplate8,
  },
  {
    template: newTemplate9,
  },
  {
    template: newTemplate10,
  },
  {
    template: newTemplate11,
  },
  {
    template: newTemplate12,
  },
  {
    template: newTemplate13,
  },
  {
    template: newTemplate14,
  },
  {
    template: newTemplate15,
  },
  {
    template: newTemplate16,
  },
  {
    template: newTemplate17,
  },
  {
    template: newTemplate18,
  },
  {
    template: newTemplate19,
  },
  {
    template: newTemplate20,
  },
  {
    template: newTemplate21,
  },
  {
    template: newTemplate22,
  },
  {
    template: newTemplate23,
  },
  {
    template: newTemplate24,
  },
  {
    template: newTemplate25,
  },
];
export default Design;
