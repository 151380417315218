import React from 'react'
import "../privacy/privacy.scss"
import { useNavigate } from 'react-router-dom'



const Return = () => {
    const navigate = useNavigate();

    return (
        <div className='privacyContainer'>
            <div className="privacyHeading">
                {/* <h1>Data Privacy</h1> */}
            </div>
            <div className='privacyContent'>
                <div style={{ fontFamily: 'Arial, sans-serif', lineHeight: 1.6, margin: '20px' }}>
                    <h1 style={{ color: '#fff' }}>Refund Policy</h1>

                    <p>
                        This Refund Policy applies to the services provided by <strong>Resumifi</strong> (the "Service").
                        Please read this policy carefully before using the Service.
                    </p>

                    <h2 style={{ color: '#fff' }}>1. Refund Eligibility</h2>

                    <p>
                        We offer a refund for the purchase of premium services within a specified period after the purchase.
                        To be eligible for a refund, you must request it within 3 days from the date of purchase* .
                    </p>

                    <h2 style={{ color: '#fff' }}>2. How to Request a Refund</h2>

                    <p>
                        To request a refund, please contact our support team <span style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => navigate("/contactus")}>here</span> with your order details and the reason for the refund request.
                        We will review your request and respond as soon as possible.
                    </p>

                    <h2 style={{ color: '#fff' }}>3. Refund Process</h2>

                    <p>
                        Once your refund request is approved, we will process the refund to the original method of payment within 7 days.
                        Please note that it may take additional time for the refund to appear in your account, depending on your financial institution.
                    </p>

                    <h2 style={{ color: '#fff' }}>4. Non-Refundable Items</h2>

                    <p>
                        Certain items are non-refundable, including but not limited to:
                        <ul>
                            <li>Downloadable products</li>
                            <li>Services that have already been provided</li>
                        </ul>
                    </p>

                    <h2 style={{ color: '#fff' }}>5. Changes to This Policy</h2>

                    <p>
                        <strong>Resumifi</strong> reserves the right to modify or replace this Refund Policy at any time.
                        It is your responsibility to check the Refund Policy periodically for changes.
                        Your continued use of the Service after the posting of any changes to the Refund Policy constitutes acceptance of those changes.
                    </p>

                    <h2 style={{ color: '#fff' }}>6. Contact Us</h2>

                    <p>If you have any questions or concerns about this Refund Policy, please contact us <span style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => navigate("/contactus")}>here</span>.</p>

                    <p>Thank you for using <strong>Resumifi</strong>.</p>

                    <p><strong>Resumifi</strong></p>
                </div>
            </div>
        </div>
    )
}

export default Return