import React, { useState } from "react";
import { auth } from "../../config";
import { sendPasswordResetEmail } from "firebase/auth";
import Button from "../shared/button";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ResetPassword = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [resetSuccess, setResetSuccess] = useState(false);

    const sendPasswordReset = async () => {
        try {
            await sendPasswordResetEmail(auth, email);
            toast("Password reset link sent!", { toastId: "passowrdresetlnksent" });
            setResetSuccess(true);
        } catch (err) {
            console.error(err);
            alert(err.message);
        }
    };

    const handleReturnToLogin = () => {
        navigate("/login");
    };

    return (
        <div>
            <h2>Reset Password</h2>
            {resetSuccess ? (
                <>
                    <p>Password reset email sent. Check your inbox.</p>
                    <Button
                        text="Return to Login"
                        onClick={handleReturnToLogin}
                    />
                </>
            ) : (
                <>
                    <input
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <Button
                        text="Reset Password"
                        onClick={sendPasswordReset}
                    />
                </>
            )}
        </div>
    );
};

export default ResetPassword;
