import React, { useState, useEffect } from "react";
import "../../style/main.scss";
import Button from "../shared/button";
import tick from "../../assets/icons/tick.svg";
import { loadStripe } from '@stripe/stripe-js';
import axios from "axios";
import { auth } from "../../config";
import { onAuthStateChanged } from "firebase/auth";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import handleBackToResume from "../../utils/loginredirect";
const Money = ({ isPaidUser }) => {
  const [userId, setUserId] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (auth.currentUser != null) {
        setUserId(auth.currentUser.uid);
      }
    });
  }, []);

  const handleFreePlan = async () => {
    if (userId) {
      navigate("/welcome")
    }
    else {
      handleBackToResume();

    }
  }

  const makePayment = async (name, price) => {
    if (userId) {


      setLoading(true);
      const stripe = await loadStripe('pk_live_51OTBxXEzZOz6ElrPXhTVwDaB4ZQe22JPNINMqPR91zRkzIDSyssthXQvNOJu3Q5pUspeyt9Ws7kBXwaC1vHwXFZq00UCjXkEpA');

      try {
        const tokenId = await auth.currentUser.getIdToken();
        const config = {
          headers: { Authorization: `Bearer ${tokenId}` }
        };
        // const response = await axios.post("http://localhost:8080/create-checkout-session", {
        //   name: name,
        //   price: price,
        //   userId: auth.currentUser.uid
        // });
        const response = await axios.post("https://resume-ai-nodejs.onrender.com/create-checkout-session", {
          name: name,
          price: price,
          userId: auth.currentUser.uid
        }, config);

        // console.log(response.data);

        const session = response.data
        const result = stripe.redirectToCheckout({
          sessionId: session.id
        })
        if (result.error) {
          // console.log(result.error);
          // setLoading(false)
        }
        setLoading(false)
      }
      catch (e) {
        // console.log("Error Making a post request to Stripe Route", e);
        setLoading(false)

      }
    }
    else {
      // navigate("/login")
      handleBackToResume();
    }



  }

  return (
    <div>
      <div className="money">
        <div className="heading v-center h-center">
          <h1 className="text-white">Choose a  </h1>
          <h1 className="pinky ms-4">plan.</h1></div>
        <p className="mb-2 v-center h-center"> 1,436 Premiums bought in the last 24 hours.</p>

        <div className="three-cards d-flex  h-center">
          <div className="card-1 mt-5">
            <div className="top c-center v-center flex-column">
              <div className="heading c-center v-center flex-column">
                <h3>Free Plan</h3>
                <p>No Card Required</p>
              </div>
              <div className="dollar d-flex c-center v-center mt-5">
                <h2>
                  {" "}
                  Free
                </h2>
                {/* <p className="mb-0 ">/ month</p> */}
              </div>
              <div className="upgrade-btn pointer mt-5">
                {!isPaidUser ?
                  <Button
                    text="Create Resume"
                    width="141px"
                    height="38px"
                    className="btn-prime-1 btn-primary text-white rounded  "
                    onClick={() => handleFreePlan()}
                  />
                  :
                  <Button
                    text="Subscribed"
                    width="141px"
                    height="38px"
                    className="btn-prime-1 btn-primary text-white rounded  "
                    disabled={true}
                    cursor={"not-allowed"}
                  />
                }

              </div>
              <p className="new-para-2 mt-3">2 trial downloads for every features</p>
              <div className="bor-line text-white"></div>
            </div>
            <div className="bottom">
              <p className="new-para-2">
                <img src={tick} alt="tick" />
                1 resume builder
              </p>
              <p className="new-para-2">
                <img src={tick} alt="tick" />
                1 resume revamp
              </p>
              <p className="new-para-2">
                <img src={tick} alt="tick" />
                1 AI resume analysis
              </p>
              <p className="new-para-2">
                <img src={tick} alt="tick" />
                1 cover letter
              </p>
              <p className="new-para-2">
                <img src={tick} alt="tick" />
                1 AI  recommendation to fix resume
              </p>
              <p className="new-para-2">
                <img src={tick} alt="tick" />2 free pdf downloads
              </p>
              {/* <p className="new-para-2">
                <img src={tick} alt="tick" />
                Revamp Resume
              </p>
              <p className="new-para-2">
                <img src={tick} alt="tick" />
                Resume Analysis
              </p>
              <p className="new-para-2">
                <img src={tick} alt="tick" />
                AI Recommendation to fix Resume
              </p>
              <p className="new-para-2">
                <img src={tick} alt="tick" />
                LinkedIn Integration
              </p>
              <p className="new-para-2">
                <img src={tick} alt="tick" />
                AI Career Summary Writer
              </p>
              <p className="new-para-2">
                <img src={tick} alt="tick" />
                AI JD Bullet Point Writer
              </p>
              <p className="new-para-2">
                <img src={tick} alt="tick" />
                AI Scoring
              </p>
              <p className="new-para-2">
                <img src={tick} alt="tick" />
                AI Toolbox
              </p>
              <p className="new-para-2">
                <img src={tick} alt="tick" />
                Unlimited Pdf Downloads
              </p>
              <p className="new-para-2">
                <img src={tick} alt="tick" />
                Unlimited Words Downloads
              </p> */}
            </div>
          </div>

          <div className="card-1 mt-5">
            <div className="top c-center v-center flex-column">
              <div className="heading c-center v-center flex-column">
                <h3>Pro Plan</h3>
                <p>Unlimited Functionality</p>
              </div>
              <div className="dollar d-flex c-center v-center mt-5">
                <h2>
                  {" "}
                  <sup>$</sup>27
                </h2>
                <p className="mb-0 ">/ month</p>
              </div>
              <div className="upgrade-btn pointer mt-5">
                {!isPaidUser ?

                  <Button
                    text="Upgrade Now"
                    width="141px"
                    height="38px"
                    className="btn-prime-1 btn-primary text-white rounded  "
                    onClick={() => makePayment("Monthly Package", 27)}
                  />
                  :
                  <Button
                    text="Subscribed"
                    width="141px"
                    height="38px"
                    className="btn-prime-1 btn-primary text-white rounded  "
                    disabled={true}
                    cursor={"not-allowed"}
                  />}
              </div>
              <p className="new-para-2 mt-3">14-day money back guarantee</p>
              <div className="bor-line text-white"></div>
            </div>
            <div className="bottom">
              <p className="new-para-2">
                <img src={tick} alt="tick" />
                Resume Builder
              </p>
              <p className="new-para-2">
                <img src={tick} alt="tick" />
                Cover Letter Builder
              </p>
              <p className="new-para-2">
                <img src={tick} alt="tick" />50+ Templates
              </p>
              <p className="new-para-2">
                <img src={tick} alt="tick" />
                Revamp Resume
              </p>
              <p className="new-para-2">
                <img src={tick} alt="tick" />
                Resume Analysis
              </p>
              <p className="new-para-2">
                <img src={tick} alt="tick" />
                AI Recommendation to fix Resume
              </p>
              <p className="new-para-2">
                <img src={tick} alt="tick" />
                LinkedIn Integration
              </p>
              <p className="new-para-2">
                <img src={tick} alt="tick" />
                AI Career Summary Writer
              </p>
              <p className="new-para-2">
                <img src={tick} alt="tick" />
                AI JD Bullet Point Writer
              </p>
              <p className="new-para-2">
                <img src={tick} alt="tick" />
                AI Scoring
              </p>
              <p className="new-para-2">
                <img src={tick} alt="tick" />
                AI Toolbox
              </p>
              <p className="new-para-2">
                <img src={tick} alt="tick" />
                Unlimited Pdf Downloads
              </p>
              <p className="new-para-2">
                <img src={tick} alt="tick" />
                Unlimited Words Downloads
              </p>
            </div>


            <p className="text-center p4-1 mt-4 d-none" style={{ maxWidth: "334px" }}>
              Are you a student or a teacher? Try AI resume builder Premium for
              free today.
            </p>
          </div>

          {/* <div className="card-1 mt-5">
            <div className="top c-center v-center flex-column">
              <div className="heading c-center v-center flex-column">
                <h3>Yearly</h3>
                <p>Pay $28 for an year</p>
              </div>
              <div className="dollar d-flex c-center v-center mt-5">
                <h2>
                  {" "}
                  <sup>$</sup>28
                </h2>
                <p className="mb-0 ">/ month</p>
              </div>
              <div className="upgrade-btn pointer mt-5">
                {!isPaidUser ?
                  <Button
                    text="Upgrade Now"
                    width="141px"
                    height="38px"
                    className="btn-prime-1 btn-primary text-white rounded  "
                    onClick={() => makePayment("Yearly Package", 28)}
                  />
                  :
                  <Button
                    text="Subscribed"
                    width="141px"
                    height="38px"
                    className="btn-prime-1 btn-primary text-white rounded  "
                    disabled={true}
                    cursor={"not-allowed"}
                  />}
              </div>
              <p className="new-para-2 mt-3">14-day money back guarantee</p>
              <div className="bor-line text-white"></div>
            </div>
            <div className="bottom">
              <p className="new-para-2">
                <img src={tick} alt="tick" />
                35+ professional resumes templates.
              </p>
              <p className="new-para-2">
                <img src={tick} alt="tick" />
                Proofreading from experts.
              </p>
              <p className="new-para-2">
                <img src={tick} alt="tick" />7 personal templates.
              </p>
              <p className="new-para-2">
                <img src={tick} alt="tick" />
                Full customization options.
              </p>
              <p className="new-para-2">
                <img src={tick} alt="tick" />
                AI resume checker.
              </p>
              <p className="new-para-2">
                <img src={tick} alt="tick" />
                Priority emails & chat support.
              </p>
              <p className="new-para-2">
                <img src={tick} alt="tick" />
                AI resume builder for mobile.
              </p>
              <p className="new-para-2">
                <img src={tick} alt="tick" />
                AI Writer.
              </p>
            </div>
          </div> */}
        </div>
      </div>
      <Modal show={loading} centered>
        <Modal.Header closeButton>
          <Modal.Title>Processing the Payment Page</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div class="lds-ringDashboardM">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Money;
