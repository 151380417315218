import React from "react";

const Button = ({
  text,
  imageSrc,
  className,
  onClick,
  height,
  width,
  disabled,
  bgColor,
  border,
  cursor,
  fontSize
}) => {
  const buttonClassName = className || "";
  const buttonStyle = {
    minHeight: height,
    minWidth: width,
    backgroundColor: bgColor,
    border: border,
    cursor: cursor,
    fontSize: fontSize
  };
  const handleClick = () => {
    if (!disabled) {
      onClick();
    }
  };
  return (
    <button
      className={buttonClassName}
      style={buttonStyle}
      onClick={handleClick}
      disabled={disabled}
    >
      {imageSrc && <img src={imageSrc} alt="Button Image" />}
      {text}
    </button>
  );
};

export default Button;
