import React, { useEffect, useRef, useState } from 'react'
import { Icon } from "@iconify/react";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import emailjs from "@emailjs/browser"


const Contactus = () => {
    const [data, setData] = useState({})
    const formRef = useRef();
    const handleInputChange = (e) => {
        setData(prev => ({ ...prev, [e.target.name]: e.target.value }))
    }

    const handleQuilChange = (value) => {
        // console.log(value);
        // console.log(value)
        setData((prev) => ({ ...prev, message: value }));
    }

    const sendEmail = (e) => {
        e.preventDefault();


        // const formData = new FormData();
        // formData.append("name", data.fName ? data.fName : "");
        // formData.append("email", data.email ? data.email : "");
        // formData.append("message", data.message ? data.message : "");


        emailjs.sendForm('service_v7y77hj', 'template_wjlf0rt', formRef.current, 'd5f8SuV9dMOPubWGW')
            .then((result) => {
                // console.log(result.text);
            }, (error) => {
                // console.log(error.text);
            });

        e.target.reset();
    };

    useEffect(() => {
        // console.log(data)
    }, [data])
    return (
        <div className='contactContainer'>
            <h1 className="mainHeading">Contact us</h1>
            <p className="subHeading">Any question or remarks? Just write us a message!</p>
            <div className="contactContent">
                <div className="left">
                    <div className="contactInfo">
                        <h2>Contact Information</h2>
                        <p>Say something !</p>
                    </div>
                    <div className="contactDetails">
                        <div className="contactDetail">
                            <Icon
                                icon={"mdi:phone"}
                                color="white"
                                width="25"
                                height="25"
                            />
                            <p>+1231 2322 334</p>
                        </div>
                        <div className="contactDetail">
                            <Icon
                                icon={"mdi:email"}
                                color="white"
                                width="25"
                                height="25"
                            />
                            <p>admin@resumifi.com</p>
                        </div>
                        <div className="contactDetail">
                            <Icon
                                icon={"mdi:location"}
                                color="white"
                                width="37"
                                height="37"
                            />
                            <p>132 Dartmouth Street Boston, Massachusetts 02156 United States</p>
                        </div>
                    </div>
                    <div className="contactSocials">
                        <div className="contactSocial">
                            <Icon
                                icon={"mdi:twitter"}
                                color="black"
                                width="23"
                                height="23"
                            />
                        </div>
                        <div className="contactSocial">
                            <Icon
                                icon={"mdi:instagram"}
                                color="black"
                                width="23"
                                height="23"
                            />
                        </div>
                        <div className="contactSocial">
                            <Icon
                                icon={"ic:baseline-discord"}
                                color="black"
                                width="23"
                                height="23"
                            />
                        </div>
                    </div>
                </div>
                <form ref={formRef} onSubmit={sendEmail} className='right'>

                    <div className="input-box ">
                        {/* First Name */}

                        <label htmlFor="" className="w-50">
                            <p className="p4"> First Name</p>
                            <input name="fName" type="text" onChange={handleInputChange} />
                        </label>


                        {/* Last Name */}
                        <label htmlFor="" className="w-50">
                            <p className="p4"> Last Name</p>
                            <input name="lName" type="text" onChange={handleInputChange} />
                        </label>
                    </div>
                    <div className="input-box ">
                        <label htmlFor="" className="w-50">
                            <p className="p4"> Email</p>
                            <input name="email" type="text" onChange={handleInputChange} />
                        </label>

                        {/* Last Name */}
                        <label htmlFor="" className="w-50">
                            <p className="p4"> Phone Number</p>
                            <input name="phone" type="text" />
                        </label>
                    </div>
                    <div className="input-box ">
                        <label htmlFor="" className="w-100">
                            <p className="p4">Message</p>
                            <div className="w-100">

                                <textarea className='contactTextArea' name="message" id="" cols="30" rows="10" placeholder='Enter you message!' style={{ resize: "none" }}></textarea>
                            </div>
                        </label>
                    </div>
                    <button className='profileSaveButton'>
                        Send
                    </button>

                </form>
            </div>
        </div>
    )
}
Contactus.modules = {
    toolbar: false
};

Contactus.formats = [
    "header",
    "font",
    "list",
    "bullet",
    "bold",
    "italic",
    "underline",
    "link",
    "image",
];
export default Contactus;