import React, { useEffect } from "react";
import "./template23.scss";
import { Icon } from "@iconify/react";
import girl from "../../assets/images/girl.jpg"

import parse from "html-react-parser"

function Template23({
  userData,
  forDownload,
  sections,
  color,
  setColor,
  font,
  setFont,
  fontSize,
  setFontSize,
  lineHeight,
  setLineHeight,
  fromPreview
}) {

  useEffect(() => {

    if (!fromPreview) {

      setColor(userData.templateColor ? userData.templateColor : "#5A98AD")
    }
  }, [])

  const getColor = () => {
    return `:root{--t23Color: ${color};
    --t23LineHeight : ${lineHeight};
  --t23Font : ${font};
--paraFontSize: ${fontSize * 0.70}px;
--subHeadingFontSize: ${fontSize}px; }`;
  };
  const getPageMarginsF = () => {
    return `@page :first { margin: ${"0px"} ${"0px"} ${"10px"} ${"0px"} !important; }`;
  };
  const getPageMargins = () => {
    return `@page { margin: ${"30px"} ${"0px"} ${"10px"} ${"0px"} !important; }`;
  };
  const getPageMarginst = () => {
    return `table { page-break-inside: avoid; }`;
  };

  return (
    <section className="template23-sec" ref={forDownload}>
      <style>{getColor()}</style>
      <style>{getPageMargins()}</style>
      <style>{getPageMarginsF()}</style>
      <style>{getPageMarginst()}</style>
      <div className="header">
        <img src={`${userData?.cvPictureObj ? URL.createObjectURL(userData?.cvPictureObj) : userData?.cvPicture ? userData?.cvPicture : girl}`} alt="..." />
        <h2 className="heading">{`${userData?.fName || userData?.lName
          ? `${userData?.fName ? userData?.fName : ""} ${userData?.lName ? userData?.lName : ""
          }`
          : "Your Name"
          }`}</h2>
        <h3 className="status">{userData?.role ? userData?.role : "Your Role"}</h3>
      </div>
      <div className="summary">
        <p className="para">
          {parse(
            userData?.summary
              ? userData?.summary
              : `Lorem ipsum dolor sit amet, consectetur adip iscing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ulla mco
                laboris nisi ut aliquip ex ea commodo consequat.`
          )}
        </p>
      </div>
      <h4 className="sub-heading">CONTACT</h4>
      <div className="contact-sec">
        <div>
          <Icon icon="mdi:location" color="gray" width="20" height="20" />
          <p className="para">

            {`${userData?.city || userData?.country
              ? `${userData?.city ? userData?.city : ""} , ${userData?.country ? userData?.country : ""
              }`
              : "Your Location"
              }`}


          </p>
        </div>
        <div>
          <Icon icon="ic:baseline-phone" color="gray" width="20" height="20" />
          <p className="para">{userData?.phone ? userData?.phone : "Your Phone"}</p>
        </div>
        <div>
          <Icon icon="ic:baseline-email" color="gray" width="20" height="20" />{" "}
          <p className="para">{userData?.email ? userData?.email : "Your Email"}</p>
        </div>

        <div>
          <Icon icon="mdi:web" color="gray" width="20" height="20" />
          <a className="para" href="#">
            {userData?.web ? userData?.web : "Your Web"}

          </a>
        </div>
      </div>
      {sections.skills || sections.strengths ? <>
        <h4 className="sub-heading">SKILLS</h4>
        <div className="skill-sec">
          {sections.skills ? <>
            <div className="ist">
              <p className="para bold-para"></p>
              <div>
                {userData.skills
                  ? userData.skills.map((skill, index) => (

                    <p className="para">{skill}{userData.skills.length - 1 == index ? "" : ", "}</p>

                  ))
                  : <>
                    <p className="para">Skill 1</p>
                    <p className="para">Skill 1</p>
                    <p className="para">Skill 1</p>
                    <p className="para">Skill 1</p>
                    <p className="para">Skill 1</p>
                  </>}
              </div>
            </div>
          </> : ""}
          {sections.strengths ? <>
            <div className="ist">
              <p className="para bold-para">Soft Skills</p>
              <div>
                {userData.strengths
                  ? userData.strengths.map((strength, index) => (

                    <p className="para">{strength}{userData.strengths.length - 1 == index ? "" : ", "}</p>

                  ))
                  : <>
                    <p className="para">Skill 1</p>
                    <p className="para">Skill 1</p>
                    <p className="para">Skill 1</p>
                    <p className="para">Skill 1</p>
                    <p className="para">Skill 1</p>
                  </>}
              </div>
            </div>
          </> : ""}


        </div>
      </> : ""}

      {sections.education ? <>
        <h4 className="sub-heading">EDUCATION</h4>
        <div className="education-sec">
          {userData.education ? userData.education.map((education, index) => (
            <>
              <div className="ist">
                <p className="para bold-para">{education.degreeName}</p>
                <p className="para light-para">
                  {`${education.schoolName}`} <span>|</span> {`${education.cityName}`} <span>|</span> {`${education.country}`}
                </p>
                <p className="para">{`${education?.startMonth ||
                  education?.startYear ||
                  education?.endMonth ||
                  education?.endYear ||
                  education?.currentlyStudyHere
                  ? `${education?.startMonth
                    ? education?.startMonth
                    : ""
                  } ${education?.startYear
                    ? education?.startYear
                    : ""
                  } ${education?.endMonth ||
                    education?.endYear ||
                    education?.currentlyStudyHere
                    ? "-"
                    : ""
                  } ${education?.currentlyStudyHere
                    ? "Present"
                    : `${education?.endMonth
                      ? education?.endMonth
                      : ""
                    } ${education?.endYear
                      ? education?.endYear
                      : ""
                    }`
                  }`
                  : "Your Degree Tenure"
                  }`}</p>
              </div>
            </>
          )) :
            <>
              <div className="ist">
                <p className="para bold-para">Degree</p>
                <p className="para light-para">
                  Institute <span>|</span> City <span>|</span> Country
                </p>
                <p className="para">Date</p>
              </div>
            </>
          }

        </div>
      </> : ""}
      {sections.certificates ? <>
        <h4 className="sub-heading">Certificates</h4>
        <div className="certificate-sec">
          {userData.certificates ? (
            userData.certificates.map((certificate, i) => (
              <>
                <div className="ist">
                  <p className="para bold-para">{certificate.certificateName}</p>
                  <p className="para light-para">
                    {certificate.authority}
                  </p>
                  <p className="para">{`${certificate.certificateDate ? certificate.certificateDate : ""}`}</p>
                </div>

              </>
            ))
          ) : (
            <>
              <div className="ist">
                <p className="para bold-para">Degree</p>
                <p className="para light-para">
                  Institute <span>|</span> City <span>|</span> Country
                </p>
                <p className="para">Date</p>
              </div>
            </>
          )}

        </div>
      </> : ""}

      {sections.experience ? <>
        <h4 className="sub-heading">EXPERIENCE</h4>
        <div className="experience-sec">
          {userData.experience ? (
            userData.experience.map((experience, i) => (
              <>
                <div className="ist">
                  <p className="para bold-para">{experience.jobTitle}</p>
                  <p className="para light-para">
                    {experience.companyName} <span>| </span>
                    {`${experience?.startMonth ||
                      experience?.startYear ||
                      experience?.endMonth ||
                      experience?.endYear ||
                      experience?.currentlyWorkHere
                      ? `${experience?.startMonth ? experience?.startMonth : ""
                      } ${experience?.startYear ? experience?.startYear : ""
                      } ${experience?.endMonth ||
                        experience?.endYear ||
                        experience?.currentlyWorkHere
                        ? "-"
                        : ""
                      } ${experience?.currentlyWorkHere
                        ? "Present"
                        : `${experience?.endMonth ? experience?.endMonth : ""
                        } ${experience?.endYear ? experience?.endYear : ""
                        }`
                      }`
                      : "Your Company Tenure"
                      }`}
                  </p>
                  <p className="para">
                    {parse(
                      experience?.description
                        ? experience?.description
                        : "Your Job Description"
                    )}
                  </p>
                </div>
              </>
            ))
          ) : (
            <>
              <div className="ist">
                <p className="para bold-para">Developer</p>
                <p className="para light-para">
                  Company Name <span>|</span>
                  Date
                </p>
                <p className="para">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum,
                  illo.
                </p>
              </div>
            </>
          )}

        </div>
      </> : ""}
      {sections.projects ? <>
        <h4 className="sub-heading">PROJECTS</h4>
        <div className="project-sec">
          {userData.projects ? (
            userData.projects.map((project, i) => (
              <>
                <div className="ist">
                  <p className="para bold-para">{project.projectType}</p>
                  <p className="para light-para">
                    {project.projectName} <span>| </span>
                    {`${project?.startMonth ||
                      project?.startYear ||
                      project?.endMonth ||
                      project?.endYear ||
                      project?.currentlyWorkingOnThisProject
                      ? `${project?.startMonth ? project?.startMonth : ""
                      } ${project?.startYear ? project?.startYear : ""
                      } ${project?.endMonth ||
                        project?.endYear ||
                        project?.currentlyWorkingOnThisProject
                        ? "-"
                        : ""
                      } ${project?.currentlyWorkingOnThisProject
                        ? "Present"
                        : `${project?.endMonth ? project?.endMonth : ""
                        } ${project?.endYear ? project?.endYear : ""
                        }`
                      }`
                      : "Your Company Tenure"
                      }`}
                  </p>
                  <p className="para">
                    {parse(
                      project?.description
                        ? project?.description
                        : "Your Job Description"
                    )}
                  </p>
                </div>
              </>
            ))
          ) : (
            <>
              <div className="ist">
                <p className="para bold-para">Company Name</p>
                <p className="para light-para">
                  Project Name <span>|</span>
                  Date
                </p>
                <p className="para">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum,
                  illo.
                </p>
              </div>
            </>
          )}

        </div>
      </> : ""}
      {sections.volunteer ? <>
        <h4 className="sub-heading">Volunteers</h4>
        <div className="volunteer-sec">
          {userData.volunteer ? (
            userData.volunteer.map((volunteer, i) => (
              <>
                <div className="ist">
                  <p className="para light-para">
                    {volunteer.institutionName} <span>| </span>
                    {`${volunteer?.startMonth ||
                      volunteer?.startYear ||
                      volunteer?.endMonth ||
                      volunteer?.endYear ||
                      volunteer?.currentlyVolunteerHere
                      ? `${volunteer?.startMonth ? volunteer?.startMonth : ""
                      } ${volunteer?.startYear ? volunteer?.startYear : ""
                      } ${volunteer?.endMonth ||
                        volunteer?.endYear ||
                        volunteer?.currentlyVolunteerHere
                        ? "-"
                        : ""
                      } ${volunteer?.currentlyVolunteerHere
                        ? "Present"
                        : `${volunteer?.endMonth ? volunteer?.endMonth : ""
                        } ${volunteer?.endYear ? volunteer?.endYear : ""
                        }`
                      }`
                      : "Your Company Tenure"
                      }`}
                  </p>
                  <p className="para">
                    {parse(
                      volunteer?.description
                        ? volunteer?.description
                        : "Your Volunteer Description"
                    )}
                  </p>
                </div>
              </>
            ))
          ) : (
            <>
              <div className="ist">
                <p className="para light-para">
                  Project Name <span>|</span>
                  Date
                </p>
                <p className="para">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum,
                  illo.
                </p>
              </div>
            </>
          )}

        </div>
      </> : ""}

      {sections.publications ? <>
        <h4 className="sub-heading">Publications</h4>
        <div className="publication-sec">
          {userData.publications ? (
            userData.publications.map((publication, i) => (
              <>
                <div className="ist">
                  <p className="para bold-para">{publication.publisher}</p>
                  <p className="para light-para">
                    {publication.publicationTitle} <span>| </span>
                    {`${publication.publicationMonth ? publication.publicationMonth : ""}${publication.publicationMonth && publication.publicationYear ? ", " : ""}${publication.publicationYear ? publication.publicationYear : ""}`}
                  </p>
                  <p className="para">
                    {parse(
                      publication?.description
                        ? publication?.description
                        : "Your Publication Description"
                    )}
                  </p>
                </div>

              </>
            ))
          ) : (
            <>
              <div className="ist">
                <p className="para bold-para">Publisher Name</p>
                <p className="para light-para">
                  Book Name <span>|</span>
                  Date
                </p>
                <p className="para">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum,
                  illo.
                </p>
              </div>
            </>
          )}


        </div>
      </> : ""}

      {sections.interests ? <>
        <h4 className="sub-heading">INTERESTS</h4>
        <div className="skill-sec">
          {sections.interests ? <>
            <div className="ist">
              <p className="para bold-para"></p>
              <div>
                {userData.interests
                  ? userData.interests.map((interest, index) => (

                    <p className="para">{interest}{userData.interests.length - 1 == index ? "" : ", "}</p>

                  ))
                  : <>
                    <p className="para">Interest 1</p>
                    <p className="para">Interest 1</p>
                    <p className="para">Interest 1</p>
                    <p className="para">Interest 1</p>
                    <p className="para">Interest 1</p>
                  </>}
              </div>
            </div>
          </> : ""}



        </div>
      </> : ""}

      {sections.reference ? <>
        <h4 className="sub-heading">Reference</h4>
        <div className="reference-sec">
          {userData.reference ? userData.reference.map((reference, index) => (
            <>
              <div className="ist">
                <p className="para bold-para">
                  {reference.referenceCompany} <span>| </span>
                  {reference.contact1}
                </p>
                <p className="para light-para">
                  {reference.personName} <span>| </span>
                  {reference.contact2}
                </p>
                <p className="para">
                  {parse(
                    reference?.description
                      ? reference?.description
                      : "Your Reference Description"
                  )}
                </p>
              </div>

            </>
          ))
            :
            <>
              <div className="ist">
                <p className="para bold-para">
                  Company Name <span>|</span>
                  Contact1
                </p>
                <p className="para light-para">
                  Person Name <span>|</span>
                  Contact2
                </p>
                <p className="para">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum,
                  illo.
                </p>
              </div>
            </>}

        </div>
      </> : ""}

    </section>
  );
}

export default Template23;
