import React, { useState } from "react";
import "../../style/main.scss";
import Button from "../../components/shared/button";
import shade from "../../assets/images/shades/shade2.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { auth, firestoredb } from "../../config";
import { Modal } from "react-bootstrap";
const Fix = ({ setUserData, setGeneratedFromCv, isPaidUser }) => {

  const [data, setData] = useState({});
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [reviews, setReviews] = useState("");
  const [downloadLimitExceed, setDownloadLimitExceed] = useState(false)

  const handleDownloadLimitExceedModal = () => {
    setDownloadLimitExceed(prev => !prev)
  }

  const handleReviewMyResume = async () => {
    // console.log(data)
    if (data.cv && data.jobTitle && data.jobDescription) {
      setLoading(true);
      setUserData({})
      const formData = new FormData();
      formData.append("cv", data.cv);
      formData.append("jobTitle", data.jobTitle);
      try {
        formData.append("jobDescription", data.jobDescription);
        setGeneratedFromCv(true);
        // const result = await axios.post("https://octaloop-resume-ai-50bd4465d773.herokuapp.com/pdfResume", formData);
        // const result = await axios.post("http://localhost:8080/pdfResume", formData);
        // const result = await axios.post("https://resumebuilderserver.fly.dev/fixResume", formData);
        const result = await axios.post("https://resume-ai-nodejs.onrender.com/fixResume", formData);
        // console.log(result.data);
        setUserData(result.data);
        navigate("/dashboard")
        setLoading(false);
        // downloadPng();
      } catch (e) {
        setGeneratedFromCv(false)
        console.error(e.message)
        setLoading(false);
      }
    }
    else {
      alert("Make sure all Data is Given")
    }


  }

  const handleReviewMyResumeNew = async () => {
    // console.log(data)
    if (data.cv && data.jobTitle && data.jobDescription) {
      if (data.cv && data.cv.type != "application/pdf") {
        toast.error("Please select .pdf file!")
        setLoading(false);
      }
      else {
        setLoading(true);
        // setUserData({})
        const formData = new FormData();
        formData.append("cv", data.cv);
        formData.append("jobTitle", data.jobTitle);
        try {
          const tokenId = await auth.currentUser.getIdToken();
          const config = {
            headers: { Authorization: `Bearer ${tokenId}` }
          };
          formData.append("jobDescription", data.jobDescription);
          setGeneratedFromCv(true);
          // const result = await axios.post("https://octaloop-resume-ai-50bd4465d773.herokuapp.com/pdfResume", formData);
          // const result = await axios.post("http://localhost:8080/pdfResume", formData);
          // const result = await axios.post("https://resumebuilderserver.fly.dev/fixResume", formData);
          const result = await axios.post("https://resume-ai-nodejs.onrender.com/fixNewResume", formData, config);
          // console.log("Reviews Data", result.data);
          if (!result.data.message) {

            setReviews(result.data);
            // navigate("/dashboard")
            setLoading(false);
            // downloadPng();
          }
          else {
            toast.error("Oops, some error occured, Please try again")
            setLoading(false);

          }
        } catch (e) {
          setGeneratedFromCv(false)
          // console.error(e.message)
          toast.error("Oops, some error occured, Please try again")

          setLoading(false);
        }
      }

    }
    else {
      // alert("Make sure all Fields are Filled");
      toast("Make sure all Fields are Filled");
    }


  }

  const handleReviewMyResumeNewLimit = async () => {
    if (isPaidUser) {
      handleReviewMyResumeNew();
    }
    else {
      const docRef = doc(firestoredb, "users", auth.currentUser.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        if (!docSnap.data().analyzeAndFixResumeCount) {
          handleReviewMyResumeNew();
          updateDoc(docRef, {
            analyzeAndFixResumeCount: 1
          })
        }
        else if (docSnap.data().analyzeAndFixResumeCount < 1) {
          handleReviewMyResumeNew();
          updateDoc(docRef, {
            analyzeAndFixResumeCount: Number(docSnap.data().analyzeAndFixResumeCount) + 1
          })
        }
        else {
          // toast("You have exceeded the number of free downloads. Please upgrade your account to Pro Plan.")
          setDownloadLimitExceed(true);
        }
      }
    }
  }

  const handleAIWriter = async () => {
    if (data && data.jobTitle) {
      const tempData = { jobTitle: data.jobTitle }
      // console.log(tempData);
      try {
        if (!loading) {


          const tokenId = await auth.currentUser.getIdToken();
          const config = {
            headers: { Authorization: `Bearer ${tokenId}` }
          };
          setLoading(true);
          // const result = await axios.post("http://localhost:8080/generateSummary", tempData);
          const result = await axios.post("https://resume-ai-nodejs.onrender.com/generateJobDescriptionNew", tempData, config);
          // console.log(result.data);
          if (!result.data.message) {

            setData((prev) => ({ ...prev, jobDescription: result.data.jobDescription }));
            setLoading(false);
          }
          else {
            setLoading(false);
            toast.error("Oops, some error occured, Please try again later");
          }
        }
      }
      catch (e) {
        console.error(e.message)
        setLoading(false);
      }
    }
    else {
      // alert("Please Provide some details first to generate summary");
      toast("Please Provide job title first to generate job description")
    }
  }


  return (
    <div>
      <div className="fix flex-column v-center h-center">
        <div className="one flex-column v-center h-center">
          <h2>Fix your resume using AI</h2>
          <p className="mt-2">
            Find out if your resume fits the job you’re applying for
          </p>
          <div className="upload-btn">
            <label htmlFor="uploadCv">
              <div
                text="Upload Your Resume"

                className="btn-secondary-1 mt-4"
                style={{ minWidth: "221px", height: "53px", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "lighter", cursor: "pointer", padding: "10px" }}

              >{data.cv != "" && data.cv != undefined ? data.cv.name : `Upload Your Resume`}</div>
            </label>
            <input id="uploadCv" type="file" accept="application/pdf" onChange={(e) => setData({ ...data, cv: e.target.files[0] })} style={{ display: "none" }}></input>
            <p style={{ fontSize: "14px", textAlign: "end", marginTop: "5px" }}>*Please select Resume in PDF format</p>
          </div>
          <div className="input-box flex-column">
            <label htmlFor="#">
              <p className="d-flex">Enter the job title you are applying for</p>
              <input value={data.jobTitle ? data.jobTitle : ""} type="text" name="" id="" placeholder="UIUX Designer" onChange={(e) => { setData({ ...data, jobTitle: e.target.value }) }} />
            </label>
            <label htmlFor="#" className="mt-4">
              <p className="d-flex">Enter the job description you are applying for &nbsp; <a style={{ color: "#3898FF", cursor: "pointer" }} onClick={handleAIWriter}>(Generate Here)</a></p>
              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                placeholder="Input job description."
                value={data.jobDescription ? data.jobDescription : ""}
                onChange={(e) => { setData({ ...data, jobDescription: e.target.value }) }}
              ></textarea>
            </label>
            <div className="review-btn" style={{ display: "flex", justifyContent: "center" }}>
              {!loading ?
                <div className="btnOuterWrapper">
                  <Button
                    text="Review Resume"
                    className="btn-secondary-1 mt-2  "
                    onClick={handleReviewMyResumeNewLimit}

                  />
                  <Button
                    text="Back"
                    className="btn-secondary-1   "
                    onClick={() => navigate("/dashboard")}
                  />
                </div>
                :
                <div style={{}} class="lds-ring"><div style={{ borderColor: "#4A58FF transparent transparent transparent" }}></div><div style={{ borderColor: "#4A58FF transparent transparent transparent" }}></div><div style={{ borderColor: "#4A58FF transparent transparent transparent" }}></div><div style={{ borderColor: "#4A58FF transparent transparent transparent" }}></div></div>}

            </div>
          </div>
          {reviews ?
            <>
              <h3 class="mt-4" >Review for your Resume</h3>
              <div className="fixReviewContainer ">


                {reviews.strengths ?
                  <div className="fixReviewCard mt-4">
                    <h4>Strengths</h4>
                    <ul style={{ listStyle: "disc" }}>
                      {reviews.strengths ? reviews.strengths.map((strength) => (
                        <li>
                          {strength}
                        </li>
                      )) : ""}
                    </ul>
                  </div>
                  :

                  ""}

                {reviews.weaknesses ?
                  <div className="fixReviewCard mt-4">
                    <h4>Weakness</h4>
                    <ul style={{ listStyle: "disc" }}>
                      {reviews.weaknesses ? reviews.weaknesses.map((weakness) => (
                        <li>
                          {weakness}
                        </li>
                      )) : ""}
                    </ul>
                  </div>
                  :

                  ""}

                {reviews.gaps ?
                  <div className="fixReviewCard mt-4">
                    <h4>Gaps</h4>
                    <ul style={{ listStyle: "disc" }}>
                      {reviews.gaps ? reviews.gaps.map((gap) => (
                        <li>
                          {gap}
                        </li>
                      )) : ""}
                    </ul>
                  </div>
                  :

                  ""}
                {reviews.suggestions ?
                  <div className="fixReviewCard mt-4">
                    <h4>Suggestions</h4>
                    <ul style={{ listStyle: "disc" }}>
                      {reviews.suggestions ? reviews.suggestions.map((suggestion) => (
                        <li>
                          {suggestion}
                        </li>
                      )) : ""}
                    </ul>
                  </div>
                  :

                  ""}


              </div>
            </>
            :
            ""}



        </div>
        <div className="two">
          <div className="shade-img">
            <img src={shade} alt="shade" className="img-fluid" />
          </div>
        </div>
      </div >
      <Modal show={downloadLimitExceed} onHide={handleDownloadLimitExceedModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Free Resources Utilized!</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          <div style={{ padding: "30px 10px", display: "flex", flexDirection: "column", gap: "30px", alignItems: "center" }}>
            <div style={{ display: "flex", alignItems: "flex-start", gap: "5px", width: "100%", flexDirection: "column" }}>
              <h5>Oh no, have exceeded the number of free Analyze and Fix Resume. Please upgrade your account to Pro Plan to continue enjoying Resumifi. </h5>
              <h6 style={{ marginTop: "20px" }}>Benefits of Upgrading:</h6>
              <ul>
                <li>
                  Proofreading From Experts.
                </li>
                <li>
                  35+ professional resumes templates.
                </li>
                <li>
                  AI resume checker.
                </li>
                <li>
                  AI Writer.
                </li>
                <li>
                  Priority emails support.
                </li>
                <li>
                  And Much More.
                </li>
              </ul>
            </div>
            <button style={{ backgroundColor: "#562863", padding: "5px 7px", borderRadius: "5px" }} onClick={() => navigate("/money")} >Upgrade</button>
          </div>


        </Modal.Body>
      </Modal>
    </div >
  );
};

export default Fix;
