import React, { useEffect } from "react";
import "../../style/main.scss";
import Button from "../shared/button";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the Quill styles
import { useState } from "react";
import parse from "html-react-parser";
import axios from "axios";
import { toast } from "react-toastify";
const Volunteer = ({ setUserData, userData, sections, setSections, isFetched }) => {
    const [editorHtml, setEditorHtml] = useState("");
    const [first, setfirst] = useState(false);
    const [totalExperience, setTotalExperience] = useState([]);
    const [isCompanyName, setIsCompanyName] = useState(false);
    const [newArrayMade, setNewArrayMade] = useState(false);
    const [already, setAlready] = useState(false);
    const [currentActiveIndex, setCurrentActiveIndex] = useState(null);
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        if (userData?.volunteer) {

            userData?.volunteer?.map((item, i) => {
                setTotalExperience(prev => [...prev, 1]);
            })
        }
    }, [isFetched])
    const handleChange = (html) => {
        setEditorHtml(html);
    };
    const handleInfoChange = (e, index = currentActiveIndex) => {
        // console.log(e.target.name);
        // console.log(e.target.value);
        // console.log(index)
        if (index == totalExperience.length) {
            setNewArrayMade(true);
        }

        if (e.target.name == "institutionName" && e.target.value == "") {
            setIsCompanyName(false);
        }
        if (e.target.name == "institutionName" && e.target.value != "") {
            setIsCompanyName(true);
        }

        // setUserData((prev) => ({
        //   ...prev,
        //   volunteer: { ...prev?.volunteer, [e.target.name]: e.target.value },
        // }));

        let temp = userData.volunteer == undefined ? [] : userData.volunteer;
        temp[index] = { ...temp[index], [e.target.name]: e.target.value }
        setUserData((prev) => ({ ...prev, volunteer: temp }))


    };
    const handleQuilChange = (value, index = currentActiveIndex) => {

        // console.log(value);
        // console.log(index);
        if (index == totalExperience.length) {
            setNewArrayMade(true);
        }


        // setUserData((prev) => ({
        //   ...prev,
        //   volunteer: { ...prev?.volunteer, description: value },
        // }));

        let temp = userData.volunteer == undefined ? [] : userData.volunteer;
        temp[index] = { ...temp[index], description: value }
        setUserData((prev) => ({ ...prev, volunteer: temp }))
    };



    const handleExp = async (index) => {
        setfirst(!first);
        // console.log("here", index)

        if (newArrayMade) {
            if (userData?.volunteer) {
                let a = userData.volunteer;
                a.pop();
                setUserData(prev => ({ ...prev, volunteer: a }));
                setNewArrayMade(false);

            }
        }
        setCurrentActiveIndex(index);
    };



    const handleAddMore = async () => {
        if (userData.volunteer ? userData.volunteer[currentActiveIndex] : false) {
            if (userData.volunteer[currentActiveIndex].institutionName != undefined && userData.volunteer[currentActiveIndex].institutionName != "") {
                setTotalExperience(prev => [...prev, 1]);
                setIsCompanyName(false)
                setfirst(!first)
                setNewArrayMade(false);
            }
            else {
                toast("Please Enter Company Name");
            }

        }
        else {
            toast("Please fill some data first")
        }


    };
    const handleDelete = () => {
        if (userData.volunteer ? userData.volunteer[currentActiveIndex] : false) {
            if (!newArrayMade) {
                let temp = userData.volunteer;
                temp.splice(currentActiveIndex, 1);
                setUserData(prev => ({ ...prev, volunteer: temp }));
                setIsCompanyName(false)
                setfirst(!first)
                setNewArrayMade(false);
                if (currentActiveIndex != totalExperience.length) {
                    let t = totalExperience;
                    t.pop();
                    setTotalExperience(t)
                }
            }
            else {
                handleExp(null)
            }
        }
        else {
            handleExp(null)
        }

    }

    const handleAIWriter = async () => {
        if (userData?.volunteer && userData?.volunteer[currentActiveIndex] && userData?.volunteer[currentActiveIndex].description && userData?.volunteer[currentActiveIndex].description.length > 29) {
            let tempData = { description: parse(userData?.volunteer[currentActiveIndex].description).props.children }
            // console.log("test1", parse(userData?.volunteer[currentActiveIndex].description))
            // console.log("test", Array.isArray(tempData.description));

            if (Array.isArray(tempData.description)) {
                let newString = "";
                tempData.description.map((item) => {
                    newString = newString + " " + item.props.children;
                });
                tempData = { description: newString }
            }

            let tempIndex = currentActiveIndex;
            try {
                setLoading(true);
                // const result = await axios.post("http://localhost:8080/generateJobDescription", tempData);
                const result = await axios.post("https://resume-ai-nodejs.onrender.com/generateJobDescription", tempData);
                // console.log("check response", result.data);

                let temp = userData.volunteer == undefined ? [] : userData.volunteer;
                temp[tempIndex] = { ...temp[tempIndex], description: result.data.description }
                setUserData((prev) => ({ ...prev, volunteer: temp }))

                setLoading(false);
            }
            catch (e) {
                console.error(e.message)
                setLoading(false);
            }
        }
        else {
            alert("Please Provide some details first to generate summary");
        }
    }

    // Only to check value of totalExperience during development.
    useEffect(() => {
        // console.log(totalExperience);
        // console.log("Current Active Index", currentActiveIndex);
    }, [totalExperience, currentActiveIndex])

    return (
        <div>
            {" "}
            <div className="bahr v-center h-center ">
                <div className="strength w-100">
                    <div className="top" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} >
                        <p className="text-purple" style={{ margin: "0" }} >Volunteer</p>
                        <div style={{ width: "15px", height: "15px", display: "flex", justifyContent: "center", alignItems: "flex-start", border: "1px solid black", backgroundColor: sections.volunteer ? "black" : "transparent" }} onClick={() => sections.volunteer ? setSections(prev => ({ ...prev, volunteer: false })) : setSections(prev => ({ ...prev, volunteer: true }))}>
                            <div style={{ width: "7px", height: "10px", borderRight: "1px solid white", borderBottom: "1px solid white", transform: "rotate(45deg)", visibility: sections.volunteer ? "visible" : "hidden" }}></div>
                        </div>
                    </div>

                    {first ? (
                        <div>
                            {/* New items */}
                            <div className="new-item gap-3 d-flex">
                                <div className="input-box  w-100">
                                    <label htmlFor="" className="w-50">
                                        <p className="p4">Institution Name</p>
                                        <input
                                            name="institutionName"
                                            onChange={handleInfoChange}
                                            type="text"
                                            value={
                                                userData?.volunteer ? userData?.volunteer[currentActiveIndex]?.institutionName
                                                    ? userData?.volunteer[currentActiveIndex]?.institutionName
                                                    : "" : ""
                                            }
                                        />
                                    </label>
                                    <label htmlFor="" className="w-50">
                                        <p className="p4">Role</p>
                                        <input
                                            name="role"
                                            onChange={handleInfoChange}
                                            type="text"
                                            value={
                                                userData?.volunteer ? userData?.volunteer[currentActiveIndex]?.role
                                                    ? userData?.volunteer[currentActiveIndex]?.role
                                                    : "" : ""
                                            }
                                        />
                                    </label>
                                </div>
                            </div>
                            <div className="new-item mt-3 gap-3 d-flex">
                                <div className="input-box w-100">
                                    <label htmlFor="" className="w-50">
                                        <p className="p4"> City</p>
                                        <input
                                            name="cityName"
                                            onChange={handleInfoChange}
                                            type="text"
                                            value={
                                                userData?.volunteer ? userData?.volunteer[currentActiveIndex]?.cityName
                                                    ? userData?.volunteer[currentActiveIndex]?.cityName
                                                    : "" : ""
                                            }
                                        />
                                    </label>
                                    <label htmlFor="" className="w-50">
                                        <p className="p4"> Country</p>
                                        <input
                                            name="country"
                                            onChange={handleInfoChange}
                                            type="text"
                                            value={
                                                userData?.volunteer ? userData?.volunteer[currentActiveIndex]?.country
                                                    ? userData?.volunteer[currentActiveIndex]?.country
                                                    : "" : ""
                                            }
                                        />
                                    </label>
                                </div>
                            </div>
                            <div className="para-z mt-3">
                                <p className="p4">Time Period</p>
                            </div>
                            <div className="new-item time-period mt-2 gap-3 d-flex">
                                <div className="input-box w-100">
                                    <label htmlFor="" className="w-25">
                                        <input
                                            name="startMonth"
                                            onChange={handleInfoChange}
                                            type="text"
                                            placeholder="Month"
                                            value={
                                                userData?.volunteer ? userData?.volunteer[currentActiveIndex]?.startMonth
                                                    ? userData?.volunteer[currentActiveIndex]?.startMonth
                                                    : "" : ""
                                            }
                                        />
                                    </label>
                                    <label htmlFor="" className="w-25">
                                        <input
                                            name="startYear"
                                            onChange={handleInfoChange}
                                            type="text"
                                            placeholder="Year"
                                            value={
                                                userData?.volunteer ? userData?.volunteer[currentActiveIndex]?.startYear
                                                    ? userData?.volunteer[currentActiveIndex]?.startYear
                                                    : "" : ""
                                            }
                                        />
                                    </label>
                                    <label htmlFor="" className="w-25">
                                        <input
                                            name="endMonth"
                                            onChange={handleInfoChange}
                                            type="text"
                                            placeholder="Month"
                                            value={
                                                userData?.volunteer ? userData?.volunteer[currentActiveIndex]?.endMonth
                                                    ? userData?.volunteer[currentActiveIndex]?.endMonth
                                                    : "" : ""
                                            }
                                        />
                                    </label>
                                    <label htmlFor="" className="w-25">
                                        <input
                                            name="endYear"
                                            onChange={handleInfoChange}
                                            type="text"
                                            placeholder="Year"
                                            value={
                                                userData?.volunteer ? userData?.volunteer[currentActiveIndex]?.endYear
                                                    ? userData?.volunteer[currentActiveIndex]?.endYear
                                                    : "" : ""
                                            }
                                        />
                                    </label>
                                </div>
                            </div>
                            <div className="currently v-center h-center mt-2">
                                <div
                                    style={{
                                        width: "10px",
                                        height: "10px",
                                        border: "1px solid black",
                                        marginRight: "5px",
                                        backgroundColor: `${userData?.volunteer ? userData?.volunteer[currentActiveIndex]?.currentlyVolunteerHere
                                            ? "black"
                                            : "transparent"
                                            : "transparent"}`,
                                    }}
                                    onClick={() => {
                                        let temp = userData.volunteer == undefined ? [] : userData.volunteer;
                                        temp[currentActiveIndex] = { ...temp[currentActiveIndex], currentlyVolunteerHere: temp[currentActiveIndex] ? !temp[currentActiveIndex]?.currentlyVolunteerHere : true }
                                        setUserData((prev) => ({ ...prev, volunteer: temp }))
                                    }
                                    }
                                ></div>
                                <p className="p4">I am currently volunteering here</p>
                            </div>

                            {/* Multiple Inputs */}
                            <div className="new-item-1  w-100 gap-4 ">
                                {/* Description */}
                                <div className="input-box d-flex flex-column gap-0 w-100 mt-14">
                                    <div className="input-box w-100 mt-14">
                                        <label htmlFor="" className="w-100">
                                            <p className="p4">Description</p>
                                        </label>
                                    </div>
                                    <div className="w-100 editor-wrapper position-relative">
                                        <ReactQuill
                                            placeholder={`Add information about your Volunteer Work.`}
                                            value={
                                                userData?.volunteer ? userData?.volunteer[currentActiveIndex]?.description
                                                    ? userData?.volunteer[currentActiveIndex]?.description
                                                    : "" : ""
                                            }
                                            onChange={(value) => handleQuilChange(value)}
                                            modules={Volunteer.modules}
                                            formats={Volunteer.formats}
                                            height="300px"
                                        />
                                        {/* <div className="writer-btn pointer">
                                            {!loading ? <Button
                                                text="AI Writer"
                                                width="137px"
                                                height="39px"
                                                className="btn-prime-1 btn-primary text-white rounded  "
                                                onClick={handleAIWriter}

                                            />
                                                :
                                                <div style={{ margin: "0", position: "relative", top: "48px", left: "42px" }} class="lds-ring"><div></div><div></div><div></div><div></div></div>}


                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            {/* AI Writer button */}
                            <div className="threebtnwrapper">
                                <Button
                                    text="Save"
                                    height="39px"
                                    className="bg-scroll rounded bg-darkPurple w-100"
                                    onClick={handleAddMore}
                                    disabled={loading}
                                />
                                <Button
                                    text="Close"
                                    height="39px"
                                    className="bg-scroll rounded bg-darkPurple w-100"
                                    onClick={() => handleExp(null)}
                                    disabled={loading}
                                />
                                <Button
                                    text="Delete"
                                    height="39px"
                                    className="bg-scroll rounded bg-darkPurple w-100"
                                    onClick={() => handleDelete(null)}
                                    disabled={loading}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="input-box mt-5 ">
                            <label htmlFor="" className="w-100 h-center v-center" style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                                {totalExperience.map((item, index) => {
                                    return (
                                        userData.volunteer && userData.volunteer[index]?.institutionName != undefined && userData.volunteer[index]?.institutionName != "" ?
                                            <Button
                                                key={index}
                                                text={`${userData.volunteer[index]?.institutionName}`}
                                                height="39px"
                                                className="bg-scroll rounded bg-darkPurple w-100"
                                                onClick={() => {
                                                    // console.log(index);
                                                    handleExp(index)
                                                }}
                                            /> : null
                                    )
                                })}

                            </label>
                        </div>
                    )}

                    {/* Scroll Button */}
                    {!first ? <div className="input-box mt-5 ">
                        <label htmlFor="" className="w-100 h-center v-center">
                            <Button
                                text="Add more"
                                height="39px"
                                className="bg-scroll rounded bg-darkPurpleDotted w-100"
                                onClick={() => handleExp(totalExperience.length)}
                            />
                        </label>
                    </div>
                        :
                        null}

                </div>
            </div>
        </div>
    );
};
// Quill modules and formats configurations
Volunteer.modules = {
    toolbar: [
        // [{ header: "1" }, { header: "2" }, { font: [] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        // ["link", "image"],
        // ["clean"],
    ],
};

Volunteer.formats = [
    "header",
    "font",
    "list",
    "bullet",
    "bold",
    "italic",
    "underline",
    "link",
    "image",
];
export default Volunteer;
