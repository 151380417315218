import { useState, useEffect } from "react";
import "./template28.scss";
import { Icon } from "@iconify/react";
import parse from "html-react-parser"


const Template28 = ({ userData, forDownload, sections, color, setColor, font, setFont, fontSize, setFontSize, lineHeight, setLineHeight, fromPreview }) => {

  const [currentScale, setCurrentScale] = useState("100%")
  useEffect(() => {
    if (!fromPreview) {
      setColor(userData.templateColor ? userData.templateColor : "#2f2f2f");
      setFont(`Poppins`);
      setLineHeight("1.4")

    }

  }, []);
  function handleScaleChange(event) {
    setCurrentScale(`${Math.trunc((event.instance.transformState.scale * 100))}%`);
  }
  const getColor = () => {
    return `:root{--t28Color: ${color};
    --t28LineHeight : ${lineHeight};
  --t28Font : ${font};
--paraFontSize: ${(fontSize + 16) * 0.675}px;
--subHeadingFontSize: ${(fontSize + 16)}px; 
--subHeadingFontSizethree: ${(fontSize + 16) * 0.375}px;
}`;
  };
  const getPageMarginsF = () => {
    return `@page :first { margin: ${"0px"} ${"0px"} ${"10px"} ${"0px"} !important; }`;
  };
  const getPageMargins = () => {
    return `@page { margin: ${"30px"} ${"0px"} ${"10px"} ${"0px"}; }`;
  };
  const getPageMarginst = () => {
    return `table { page-break-inside: avoid; }`;
  };

  return (
    <div ref={forDownload}>
      <style>{getColor()}</style>
      <style>{getPageMarginsF()}</style>
      <style>{getPageMargins()}</style>
      <style>{getPageMarginst()}</style>
      <div className="template28">
        <div className="top">
          <h1>{userData.fName ? userData.fName : ""} {userData.lName ? userData.lName : ""}</h1>
          <h4>{userData.role ? userData.role : ""}</h4>
          <div className="epa">
            <p>
              <span>E</span> : {userData?.email ? userData?.email : "Your Email"}
            </p>
            <p>
              <span>P</span> : {userData?.phone ? userData?.phone : "Your Phone"}
            </p>
            <p>
              <span>A</span> : {`${userData?.city || userData?.country
                ? `${userData?.city ? userData?.city : ""} , ${userData?.country ? userData?.country : ""
                }`
                : "Your Location"
                }`}
            </p>
          </div>
        </div>
        <div className="bottom">
          <div className="content">
            <h4>PROFILE</h4>
            <p>
              {parse(
                userData?.summary
                  ? userData?.summary
                  : `Lorem ipsum dolor sit amet, consectetur adip iscing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ulla mco
                laboris nisi ut aliquip ex ea commodo consequat.`
              )}
            </p>
          </div>

          {sections.skills ?
            <div className="content-last ">
              <h4>MY SKILLS</h4>
              <div className="last-two">
                <div className="left-last skillsAll">
                  {userData.skills ? userData.skills.map((skill) => (

                    <p >
                      {skill}

                    </p>
                  ))

                    :
                    <p >
                      Web Design

                    </p>
                  }
                  {/* <p >
                Web Design

              </p>
              <p>
                Branding

              </p> */}
                </div>
              </div>
            </div>
            :
            ""}

          {sections.experience ?
            <div className="content">
              <h4>WORK EXPERIENCE</h4>
              <div className="two">

                {userData.experience ? userData.experience.map((experience) => (
                  <div className="left">
                    <ul>
                      <li>
                        <span>
                          {`${experience?.startMonth ||
                            experience?.startYear ||
                            experience?.endMonth ||
                            experience?.endYear ||
                            experience?.currentlyWorkHere
                            ? `${experience?.startMonth ? experience?.startMonth : ""
                            } ${experience?.startYear ? experience?.startYear : ""
                            } ${experience?.endMonth ||
                              experience?.endYear ||
                              experience?.currentlyWorkHere
                              ? "-"
                              : ""
                            } ${experience?.currentlyWorkHere
                              ? "Present"
                              : `${experience?.endMonth ? experience?.endMonth : ""
                              } ${experience?.endYear ? experience?.endYear : ""
                              }`
                            }`
                            : "Your Company Tenure"
                            }`} <br /> {experience.jobTitle} <br /> {`${experience.companyName ? experience.companyName : ""}`}
                        </span>
                        <br />
                        {parse(
                          experience?.description
                            ? experience?.description
                            : "Your Job Description"
                        )}
                      </li>
                    </ul>
                  </div>
                ))
                  :
                  <div className="left">
                    <ul>
                      <li>
                        <span>
                          2014-2016 <br /> Senior Graphic Designer
                        </span>
                        <br />
                        Boroclie studio
                      </li>
                    </ul>
                  </div>
                }
                {/* <div className="left">
              <ul>
                <li>
                  <span>
                    2014-2016 <br /> Senior Graphic Designer
                  </span>
                  <br />
                  Boroclie studio
                </li>
              </ul>
            </div>
            <div className="left">
              <ul>
                <li>
                  <span>
                    2014-2016 <br /> Senior Graphic Designer
                  </span>
                  <br />
                  Boroclie studio
                </li>
              </ul>
            </div> */}
              </div>
            </div>
            :
            ""}

          {sections.volunteer ?
            <div className="content">
              <h4>Volunteer</h4>
              <div className="two">
                {userData.volunteer ? (
                  userData.volunteer.map((volunteer, i) => (
                    <div className="left">
                      <ul>
                        <li>
                          <span>
                            {`${volunteer.institutionName ? volunteer.institutionName : ""} ${volunteer.institutionName && volunteer.role ? "|" : ""} ${volunteer.role ? volunteer.role : ""}`} <br /> {`${volunteer?.startMonth ||
                              volunteer?.startYear ||
                              volunteer?.endMonth ||
                              volunteer?.endYear ||
                              volunteer?.currentlyVolunteerHere
                              ? `${volunteer?.startMonth ? volunteer?.startMonth : ""
                              } ${volunteer?.startYear ? volunteer?.startYear : ""
                              } ${volunteer?.endMonth ||
                                volunteer?.endYear ||
                                volunteer?.currentlyVolunteerHere
                                ? "-"
                                : ""
                              } ${volunteer?.currentlyVolunteerHere
                                ? "Present"
                                : `${volunteer?.endMonth ? volunteer?.endMonth : ""
                                } ${volunteer?.endYear ? volunteer?.endYear : ""
                                }`
                              }`
                              : "Your Institute Tenure"
                              }`}
                          </span>
                          <br />{parse(
                            volunteer?.description
                              ? volunteer?.description
                              : "Your Volunteer Description"
                          )}
                        </li>
                      </ul>
                    </div>
                  )))
                  :
                  <div className="left">
                    <ul>
                      <li>
                        <span>
                          Name <br /> 2014-2016
                        </span>
                        <br />I volunteer
                      </li>
                    </ul>
                  </div>
                }
                {/* <div className="left">
              <ul>
                <li>
                  <span>
                    Name <br /> 2014-2016
                  </span>
                  <br />I volunteer
                </li>
              </ul>
            </div>
            <div className="left">
              <ul>
                <li>
                  <span>
                    Name <br /> 2014-2016
                  </span>
                  <br />I volunteer
                </li>
              </ul>
            </div> */}
              </div>
            </div>
            :
            ""}

          {sections.education ?
            <div className="content">
              <h4>EDUCATION</h4>
              <div className="two">
                {userData.education ? userData.education.map((education, index) => (
                  <div className="left">
                    <ul>
                      <li>
                        <span>{`${education.degreeName ? education.degreeName : ""}`}</span>
                        <br />
                        {`${education?.startMonth ||
                          education?.startYear ||
                          education?.endMonth ||
                          education?.endYear ||
                          education?.currentlyStudyHere
                          ? `${education?.startMonth
                            ? education?.startMonth
                            : ""
                          } ${education?.startYear
                            ? education?.startYear
                            : ""
                          } ${education?.endMonth ||
                            education?.endYear ||
                            education?.currentlyStudyHere
                            ? "-"
                            : ""
                          } ${education?.currentlyStudyHere
                            ? "Present"
                            : `${education?.endMonth
                              ? education?.endMonth
                              : ""
                            } ${education?.endYear
                              ? education?.endYear
                              : ""
                            }`
                          }`
                          : "Your Degree Tenure"
                          }`}
                        <br />
                        {`${education.schoolName ? education.schoolName : ""}`}
                      </li>
                    </ul>
                  </div>
                ))
                  :
                  <div className="left">
                    <ul>
                      <li>
                        <span>Bachelor of Design</span>
                        <br />
                        2005-2009
                        <br />
                        Berlin University
                      </li>
                    </ul>
                  </div>
                }
                {/* <div className="left">
              <ul>
                <li>
                  <span>Bachelor of Design</span>
                  <br />
                  2005-2009
                  <br />
                  Berlin University
                </li>
              </ul>
            </div> */}
              </div>
            </div>
            :
            ""}



          {sections.certificates ?
            <div className="content">
              <h4>Certification</h4>
              <div className="two">
                {userData.certificates ? userData.certificates.map((certificate, index) => (
                  <div className="left">
                    <ul>
                      <li>
                        <span>
                          {`${certificate.certificateName ? certificate.certificateName : ""}`} <br /> {`${certificate.authority ? certificate.authority : ""}`}
                        </span>
                        <br />
                        {certificate.certificateDate ? certificate.certificateDate : ""}
                      </li>
                    </ul>
                  </div>
                ))
                  :
                  <div className="left">
                    <ul>
                      <li>
                        <span>
                          Meta <br /> Coursera
                        </span>
                        <br />
                        2014-2016
                      </li>
                    </ul>
                  </div>
                }
                {/* <div className="left">
              <ul>
                <li>
                  <span>
                    Meta <br /> Coursera
                  </span>
                  <br />
                  2014-2016
                </li>
              </ul>
            </div>
            <div className="left">
              <ul>
                <li>
                  <span>
                    Meta <br /> Coursera
                  </span>
                  <br />
                  2014-2016
                </li>
              </ul>
            </div> */}
              </div>
            </div>
            :
            ""}

          {sections.awards ?
            <div className="content">
              <h4>Awards</h4>
              <div className="two">
                {userData.awards ? userData.awards.map((award, index) => (
                  <div className="left">
                    <ul>
                      <li>
                        <span>{`${award.awardTitle ? award.awardTitle : ""}`}</span>
                        <br />
                        {`${award.awardMonth ? award.awardMonth : ""}${award.awardMonth && award.awardYear ? " - " : ""}${award.awardYear ? award.awardYear : ""}`}
                      </li>
                    </ul>
                  </div>
                ))
                  :
                  <div className="left">
                    <ul>
                      <li>
                        <span>Meta</span>
                        <br />
                        2014-2016
                      </li>
                    </ul>
                  </div>
                }
                {/* <div className="left">
              <ul>
                <li>
                  <span>Meta</span>
                  <br />
                  2014-2016
                </li>
              </ul>
            </div>
            <div className="left">
              <ul>
                <li>
                  <span>Meta</span>
                  <br />
                  2014-2016
                </li>
              </ul>
            </div> */}
              </div>
            </div>
            :
            ""}

          {sections.publications ?
            <div className="content">
              <h4>Publications</h4>
              <div className="two">
                {userData.publications ? userData.publications.map((publication, index) => (
                  <div className="left">
                    <ul>
                      <li>
                        <span>{publication.publisher ? publication.publisher : ""}</span> <br />
                        <span> {publication.publicationTitle ? publication.publicationTitle : ""}   </span>                 <br />
                        {`${publication.publicationMonth ? publication.publicationMonth : ""}${publication.publicationMonth && publication.publicationYear ? ", " : ""}${publication.publicationYear ? publication.publicationYear : ""}`}
                        <br />
                        {parse(
                          publication?.description
                            ? publication?.description
                            : "Your Publication Description"
                        )}
                      </li>
                    </ul>
                  </div>
                ))
                  :
                  <div className="left">
                    <ul>
                      <li>
                        <span>Rawalpindi Publishers</span> <br />
                        <span> Name</span>
                        <br />
                        2014-2016
                        <br />
                        This is my publication
                      </li>
                    </ul>
                  </div>
                }
                {/* <div className="left">
              <ul>
                <li>
                  <span>Rawalpindi Publishers</span> <br />
                  <span> Name</span>
                  <br />
                  2014-2016
                  <br />
                  This is my publication
                </li>
              </ul>
            </div> */}
              </div>
            </div>
            :
            ""}

          {sections.interests ?
            <div className="content">
              <h4>Interests</h4>
              <div className="two">
                {userData.interests ? userData.interests.map((interest, index) => (
                  <div className="left">
                    <ul>
                      <li>{interest}</li>
                    </ul>
                  </div>
                ))
                  :
                  <div className="left">
                    <ul>
                      <li>Reading</li>
                    </ul>
                  </div>
                }
                {/* <div className="left">
              <ul>
                <li>Reading</li>
              </ul>
            </div>
            <div className="left">
              <ul>
                <li>Sports</li>
              </ul>
            </div> */}
              </div>
            </div>
            :
            ""}

          {sections.reference ?
            <div className="content">
              <h4>Reference</h4>
              <div className="two">
                {userData.reference ? userData.reference.map((reference, index) => (
                  <div className="left">
                    <ul>
                      <li>
                        <span>{reference.referenceCompany ? reference.referenceCompany : ""}</span>
                        <br />
                        {reference.personName ? reference.personName : ""}
                        <br />
                        {reference.contact1 ? reference.contact1 : ""} <br />
                        {reference.contact2 ? reference.contact2 : ""}
                      </li>
                    </ul>
                  </div>
                ))
                  :
                  <div className="left">
                    <ul>
                      <li>
                        <span>Octaloop</span>
                        <br />
                        Tehsin
                        <br />
                        123-456-7890
                      </li>
                    </ul>
                  </div>
                }
                {/* <div className="left">
              <ul>
                <li>
                  <span>Octaloop</span>
                  <br />
                  Tehsin
                  <br />
                  123-456-7890
                </li>
              </ul>
            </div>
            <div className="left">
              <ul>
                <li>
                  <span>Octaloop</span>
                  <br />
                  Tehsin
                  <br />
                  123-456-7890
                </li>
              </ul>
            </div>
            <div className="left">
              <ul>
                <li>
                  <span>Octaloop</span>
                  <br />
                  Tehsin
                  <br />
                  123-456-7890
                </li>
              </ul>
            </div>
            <div className="left">
              <ul>
                <li>
                  <span>Octaloop</span>
                  <br />
                  Tehsin
                  <br />
                  123-456-7890
                </li>
              </ul>
            </div> */}
              </div>
            </div>
            :
            ""}

        </div>
      </div>
    </div>
  );
};

export default Template28;
