import React from 'react'
import "./privacy.scss"
import { useNavigate } from 'react-router-dom'
import Footer from '../home/footer';

const Privacy = () => {
    const navigate = useNavigate();
    return (
        <div className='privacyContainer'>
            <div className="privacyHeading">
                {/* <h1>Data Privacy</h1> */}
            </div>
            <div className='privacyContent'>
                <div style={{ fontFamily: 'Arial, sans-serif', lineHeight: 1.6, margin: '20px' }}>
                    <h1 style={{ color: '#fff' }}>Data Privacy Policy</h1>

                    <h2 style={{ color: '#fff' }}>Introduction</h2>

                    <p>
                        Welcome to <strong>Resumifi</strong>, a platform designed to empower users in creating and enhancing their professional resumes.
                        At <strong>Resumifi</strong>, we understand the importance of your personal information, and we are committed to safeguarding your privacy.
                        This Data Privacy Policy outlines how we collect, use, and protect your data.
                    </p>

                    <h2 style={{ color: '#fff' }}>Information We Collect</h2>

                    <h3 style={{ color: '#fff' }}>User-Provided Information</h3>

                    <ul>
                        <li><strong>Account Information:</strong> When you sign up for an account, we collect your name, email address, and password to secure your account.</li>
                        <li><strong>Resume Data:</strong> Users can upload their existing resumes. The data from these resumes is processed using programming language functions to populate the resume builder.</li>
                    </ul>

                    <h3 style={{ color: '#fff' }}>Automatically Collected Information</h3>

                    <ul>
                        <li><strong>Usage Data:</strong> We collect information about how users interact with our platform, including pages visited, features used, and any errors encountered. This data helps us improve our services.</li>
                    </ul>

                    <h3 style={{ color: '#fff' }}>Cookies and Similar Technologies</h3>

                    <ul>
                        <li><strong>Cookies:</strong> We use cookies to enhance user experience and collect information about preferences. You can manage cookie preferences in your browser settings.</li>
                    </ul>

                    <h2 style={{ color: '#fff' }}>How We Use Your Information</h2>

                    <ul>
                        <li><strong>Resume Building:</strong> The primary purpose of collecting resume data is to populate the resume builder, enabling users to edit and customize their existing CVs.</li>
                        <li><strong>Improving Services:</strong> We analyze usage data to enhance our platform, identify trends, and fix issues.</li>
                        <li><strong>Communication:</strong> We may use your email address to send important notifications related to your account, updates, and promotional content. You can opt-out of promotional emails.</li>
                    </ul>

                    <h2 style={{ color: '#fff' }}>Data Security</h2>

                    <p>We prioritize the security of your information:</p>

                    <ul>
                        <li><strong>Encryption:</strong> All data transmission is encrypted using industry-standard protocols.</li>
                        <li><strong>Access Controls:</strong> Limited access to personal data is granted only to authorized personnel.</li>
                        <li><strong>Regular Audits:</strong> We conduct regular security audits to identify and address vulnerabilities.</li>
                    </ul>

                    <h2 style={{ color: '#fff' }}>Third-Party Services</h2>

                    <p>We may use third-party services for analytics, authentication, and other purposes. These services have their own privacy policies, and we encourage you to review them.</p>

                    <h2 style={{ color: '#fff' }}>Data Retention</h2>

                    <p>We retain your data for as long as necessary for the purposes outlined in this policy or as required by law.</p>

                    <h2 style={{ color: '#fff' }}>Your Choices</h2>

                    <ul>
                        <li><strong>Editing and Deleting Data:</strong> You can edit or delete your account and associated data at any time.</li>
                        <li><strong>Cookies:</strong> You can manage cookie preferences in your browser.</li>
                    </ul>

                    <h2 style={{ color: '#fff' }}>Updates to this Policy</h2>

                    <p>We may update this Data Privacy Policy to reflect changes in our practices. We will notify you of significant changes through email or prominent notices on our website.</p>

                    <h2 style={{ color: '#fff' }}>Contact Us</h2>

                    <p>If you have any questions or concerns about this Data Privacy Policy, please contact us <span style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => navigate("/contactus")}>here</span>.</p>

                    <p>Thank you for trusting <strong>Resumifi</strong>. We are dedicated to providing a secure and reliable platform for your resume-building needs.</p>

                    <p><strong>Resumifi</strong></p>
                </div>
            </div>

        </div>

    )
}

export default Privacy