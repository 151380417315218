import React from "react";
import "../../style/main.scss";
import Button from "../shared/button";
import star from "../../assets/icons/star.svg";

// import CV from "../../assets/images/Slider-images/CV.png";
// import CV from "../../../public/assets/CV.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";

const SelectTemplate = () => {
  const navigate = useNavigate();

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2.5,
    slidesToScroll: 1,
    centerPadding: "0px",
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 787,
        settings: {
          slidesToShow: 2,
        },
      },

      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2,
          slidesToShow: 1.9,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 1,
          slidesToShow: 1.7,
        },
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 1.65,
        },
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 2.5,
          slidesToShow: 2,
        },
      },
    ],
  };
  return (
    <div>
      <div className="selectTemplate-slider d-flex">
        <div className="left">
          <h1 className="text-purple2">
            Beautiful ready-to-use resume templates
          </h1>
          <p className="new-para mt-4 text-brown">
            Win over employers and recruiters by using one of our 25+ elegant,
            professionally-designed resume templates. Download to word or PDF.
          </p>
          <div className="template-btn pointer">
            <Button
              text="Select Template"
              width="210px"
              height="58px"
              className="btn-prime-3 btn-primary text-white rounded  "
              onClick={(e) => navigate("/templates")}
            />
          </div>
          <div className="star">
            <Icon icon="ion:star" color="ffd600" width="48" height="48" />
            <Icon icon="ion:star" color="ffd600" width="48" height="48" />
            <Icon icon="ion:star" color="ffd600" width="48" height="48" />
            <Icon icon="ion:star" color="ffd600" width="48" height="48" />
            <Icon icon="ion:star-half" color="ffd600" width="48" height="48" />
            <h3 className="new-h3 mt-5">4.5 out of 5</h3>
            <p className="new-para-2 text-brown">
              based on 46,316 reviews{" "}
              {/* <a  target="_blank" className="text-decoration-underline">
                Trustpilot
              </a> */}
            </p>
          </div>
        </div>
        <div className="right">
          <div className="template-slider">
            <Slider {...settings}>
              {/* <div className="imgs">
                <img src="\assets\CV.png" alt="sasa" />
              </div>
              <div className="imgs">
                <img src="\assets\CV6.png" alt="sasa" />
              </div>
              <div className="imgs">
                <img src="\assets\CV8.png" alt="sasa" />
              </div>
              <div className="imgs">
                <img src="\assets\CV9.png" alt="sasa" />
              </div> */}
              <div className="imgs">
                <img src="\assets\template27.png" alt="sasa" />
              </div>
              <div className="imgs">
                <img src="\assets\template28.png" alt="sasa" />
              </div>
              <div className="imgs">
                <img src="\assets\template26.png" alt="sasa" />
              </div>
              <div className="imgs">
                <img src="\assets\template30.png" alt="sasa" />
              </div>

              {/* <div>
                <img src="\assets\CV6.png" alt="sasa" />
              </div>
              <div>
                <img src="\assets\CV8.png" alt="sasa" />
              </div> */}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectTemplate;
