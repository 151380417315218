/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import "../../style/main.scss";
import facebook from "../../assets/icons/facebook.svg";
import apple from "../../assets/icons/apple.svg";
import google from "../../assets/icons/google.svg";
import cartoon from "../../assets/icons/cartoon.png";
import shade from "../../assets/images/shades/shade2.png";
import Button from "../shared/button";
import eyeicon from "../../assets/icons/eyeicon.svg"
import eyefill from "../../assets/icons/eye-fill.svg"
import { useLocation, useNavigate } from "react-router-dom";
import { auth, firestoredb } from "../../config";
import {
  GoogleAuthProvider,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { toast } from "react-toastify";

import Modal from "react-bootstrap/Modal";
import {
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
// import { LinkedIn } from 'react-linkedin-login-oauth2';
// You can use provided image shipped by this package or using your own
// import linkedin from 'react-linkedin-login-oauth2/assets/linkedin.png';

const Login = ({ newSignInWithGoogle, setNewSignInWithGoogle }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [resetEmail, setResetEmail] = useState("");
  const [Error, setError] = useState("");

  const [password, setPswd] = useState("");
  const [errorObj, setErrorObj] = useState("");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [vbl, setVbl] = useState(false)
  const [resetEmailSent, setResetEmailSent] = useState(false);
  const [resetEmailTimer, setResetEmailTimer] = useState(null);
  const location = useLocation();
  const Validation = () => {
    let errorobj = { email: false, password: false };

    if (email === "") {
      errorobj.email = true;
    }
    if (email.length > 40) {
      errorobj.email = true;
    }

    if (password === "") {
      errorobj.password = true;
    }

    setErrorObj(errorobj);
    return errorobj;
  };

  const handleLoginUser = async (e) => {
    // e.preventDefault();
    const validationError = Validation();

    if (!validationError.email && !validationError.password) {
      try {
        const userCred = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
        localStorage.setItem("isSigninSuccess", true);
        toast.success("Login success", { toastId: "userloggedin" });
        // console.log(auth.currentUser);
        if (location.state) {
          if (location.state.toWhere) {
            navigate(location.state.toWhere);
          }
          else {

            navigate("/welcome");
          }

        }
        else {

          navigate("/welcome");
        }
      } catch (error) {
        toast.error("Invalid Credentials", {
          toastId: "loginError",
        });
      }
    }
  };

  const signInWithGoogle = async () => {
    const googleProvider = new GoogleAuthProvider();
    // console.log(googleProvider);

    await signInWithPopup(auth, googleProvider)
      .then(async (user) => {
        // console.log("user from google", user.user);
        // console.log("user from google Full", user._tokenResponse.firstName);
        const q = query(
          collection(firestoredb, "users"),
          where("uid", "==", user.user.uid)
        );
        const docs = await getDocs(q);
        // console.log(docs.docs.length);
        if (docs.docs.length === 0) {
          await setDoc(doc(firestoredb, "users", user.user.uid), {
            uid: user.user.uid,
            email: user.user.email,
            firstName: user._tokenResponse.firstName,
            cvData: {
              fName: user._tokenResponse.firstName,
              lName: user._tokenResponse.lastName,
              email: user._tokenResponse.email
            }
            // password: user.password
          })
            .then((res) => {
              // console.log("here")
              toast.success("Login success", { toastId: "userloggedin" });
              setNewSignInWithGoogle(!newSignInWithGoogle);
              if (location.state) {
                if (location.state.toWhere) {
                  navigate(location.state.toWhere);
                }
                else {

                  navigate("/welcome");
                }

              }
              else {

                navigate("/welcome");
              }
            })
            .catch((err) => {
              // console.log(err);
            });
        }
        else {
          // console.log("here")
          toast.success("Login success", { toastId: "userloggedin" });
          if (location.state) {
            if (location.state.toWhere) {
              navigate(location.state.toWhere);
            }
            else {

              navigate("/welcome");
            }

          }
          else {

            navigate("/welcome");
          }
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  // onAuthStateChanged(auth, (user) => {
  //   if (auth.currentUser != null) {
  //     navigate("/");
  //   }
  // });

  const sendPasswordReset = async () => {
    if (resetEmail) {
      try {
        if (!resetEmailSent) {
          await sendPasswordResetEmail(auth, resetEmail);
          toast("Password reset email sent. Check your inbox.", {
            toastId: "passowrdresetlnksent",
          });
          setResetEmailSent(true);
          const timer = setTimeout(() => {
            setResetEmailSent(false);
          }, 30000);
          setResetEmailTimer(timer);

          // handleClose();
        } else {
          setError("Password reset email has already been sent. Please wait.");
        }
      } catch (err) {
        console.error(err);
        alert(err.message);
        setError("Error sending password reset email. Please try again.");
      }
    } else {
      toast("Please enter email", { toastId: "dsadsadasd" });
    }
  };

  useEffect(() => {
    // const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in.
        const { uid } = user;
        navigate("/welcome");
        // setUserisLogged(true);
      } else {
        // User is signed out.
        // setUserisLogged(false);

        // router.push({
        //     pathname: "/login",
        // });
      }
    });
    return () => unsubscribe();
  }, []);
  return (
    <div>
      <div className="signin ">
        <div className="signin-content">
          <div className="left text-white">
            <h1>Ready to create resume? </h1>
            <h3 className="mt-3">
              Get started for free. No <br /> credit card required.{" "}
            </h3>
            <p className="mt-3">If you don’t have an account,</p>
            <p>
              You can
              <span
                className="text-purple pointer fw-semibold"
                onClick={() => navigate("/register")}
              >
                {" "}
                Register here!
              </span>
            </p>
          </div>
          <div className="cartoon-img img-fluid">
            <img src={cartoon} alt="signin-cartoon" />
          </div>
          <div className="right text-white">
            <h2>Sign In</h2>
            <div className="input-boxes v-center flex-column">
              <input
                type="email"
                placeholder="Email"
                onChange={(e) => {
                  const emailRegex =
                    /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
                  const test = emailRegex.test(e.target.value);

                  if (test) {
                    setEmail(e.target.value);
                  } else {
                    // // console.log("elese");

                    setEmail("");
                  }
                }}
              />
              {errorObj.email && <p>Please enter email </p>}
              <div className="pass">
                <div className="inppas">
                  <input
                    type={vbl ? "text" : "password"}

                    name=""
                    id=""
                    placeholder="Password"
                    className="mt-3"
                    value={password}
                    onChange={(e) => setPswd(e.target.value)}

                  />
                  {errorObj.password && <p>Please enter password</p>}
                  <div className="eyebtn" onClick={() => setVbl(!vbl)}>
                    {
                      vbl ? <img className=" pointer" src={eyefill} alt="..." /> : <img className=" pointer" src={eyeicon} alt="..." />
                    }


                  </div>
                </div>
                {/* <div className="eyebtn" onClick={() => setVbl(!vbl)}>
                  {
                    vbl ? <img className=" pointer" src={eyefill} alt="..." /> : <img className=" pointer" src={eyeicon} alt="..." />
                  }


                </div> */}

              </div>
            </div>
            <p className="body-medium mt-2 text-white end pointer">
              <span
                className="text-white pointer fw-semibold "
                onClick={handleShow}
              >
                Forgot password ?
              </span>
            </p>
            <div className="login-btn">
              <Button
                text="Login"
                className="btn-prime btn-primary changeBtn text-white br-8"
                onClick={handleLoginUser}
              />
            </div>
            <p className="v-center h-center mt-5 text-white">
              or continue with
            </p>
            <div className="icon-section v-center h-center mt-5">
              {/* <a href="#">
                <img src={facebook} alt="" />
              </a>
               <a href="#">
                <img src={apple} alt="" />
                </a> */}
              {/* <LinkedIn
                  clientId="77j0dryzgonq4c"
                  redirectUri={`${window.location.origin}/signin-linkedin`}
                  onSuccess={(code) => {
                    // console.log(code);
                  }}
                  onError={(error) => {
                    // console.log(error);
                  }}
                >
                  {({ linkedInLogin }) => (
                    <img
                      onClick={linkedInLogin}
                      src={linkedin}
                      alt="Sign in with Linked In"
                      style={{ maxWidth: '180px', cursor: 'pointer' }}
                    />
                  )}
                </LinkedIn>

              </a> */}
              <a href="#">
                <img src={google} alt="" onClick={signInWithGoogle} />
              </a>
            </div>
          </div>
        </div>
        <div className="shade">
          <img src={shade} alt="" />
        </div>
      </div>
      <Modal centered show={show} onHide={handleClose} >
        <Modal.Header closeButton={true}>
          <Modal.Title>Forgot Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className=" new mt-0">
              <p>Enter your email</p>
              <input
                type="text"
                placeholder="email"
                onChange={(e) => setResetEmail(e.target.value)}
              />
            </div>
            {/* <a>Resent Link</a> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {
            <Button
              text="Forget Password"
              variant="primary"
              onClick={sendPasswordReset}
            >
              Forget Password
            </Button>
          }
        </Modal.Footer>
      </Modal>

    </div>
  );
};

export default Login;
