import { useState, useEffect } from "react";
import "./template27.scss";
import { Icon } from "@iconify/react";
import parse from "html-react-parser"
import girl from "../../assets/images/girl.jpg"



const Template27 = ({ userData, forDownload, sections, color, setColor, font, setFont, fontSize, setFontSize, lineHeight, setLineHeight, fromPreview }) => {

  const [currentScale, setCurrentScale] = useState("100%")
  useEffect(() => {
    if (!fromPreview) {
      setColor(userData.templateColor ? userData.templateColor : "#942637");
      setFont(`Poppins`);
      setLineHeight("1.4")

    }

  }, []);
  function handleScaleChange(event) {
    setCurrentScale(`${Math.trunc((event.instance.transformState.scale * 100))}%`);
  }
  const getColor = () => {
    return `:root{--t27Color: ${color};
    --t27LineHeight : ${lineHeight};
  --t27Font : ${font};
--paraFontSize: ${(fontSize + 21) * 0.444}px;
--subHeadingFontSize: ${(fontSize + 21)}px; 
--subHeadingFontSizethree: ${(fontSize + 21) * 0.333}px;
}`;
  };
  const getPageMarginsF = () => {
    return `@page :first { margin: ${"0px"} ${"0px"} ${"10px"} ${"0px"} !important; }`;
  };
  const getPageMargins = () => {
    return `@page { margin: ${"30px"} ${"0px"} ${"10px"} ${"0px"}; }`;
  };
  const getPageMarginst = () => {
    return `table { page-break-inside: avoid; }`;
  };

  return (
    <div ref={forDownload}>
      <style>{getColor()}</style>
      <style>{getPageMarginsF()}</style>
      <style>{getPageMargins()}</style>
      <style>{getPageMarginst()}</style>
      <div className="template27">
        <div className="top">
          <div className="left">
            <label htmlFor="1">
              {/* <input
                type="file"
                src=""
                accept=".png"
                alt="img"
                id="1"
                style={{ display: "none" }}
              /> */}
              <img src={`${userData?.cvPictureObj ? URL.createObjectURL(userData?.cvPictureObj) : userData?.cvPicture ? userData?.cvPicture : girl}`} alt="img" />
            </label>
          </div>
          <div className="right">
            <h1>{userData.fName ? userData.fName : ""} {userData.lName ? userData.lName : ""}</h1>
            <h3 className="text-light">{userData.role ? userData.role : ""}</h3>
          </div>
        </div>
        <div className="bottom">
          <div className="left">
            <div className="content">
              <h3>My Contact</h3>
              <p>
                <Icon icon="mdi-light:email" width="30" height="30" />
                <span>{userData?.email ? userData?.email : "Your Email"}</span>
              </p>
              <p>
                <Icon icon="prime:whatsapp" width="30" height="30" />
                <span> {userData?.phone ? userData?.phone : "Your Phone"}</span>
              </p>
              <p>
                <Icon icon="mdi:location" width="30" height="30" />
                <span> {`${userData?.city || userData?.country
                  ? `${userData?.city ? userData?.city : ""} , ${userData?.country ? userData?.country : ""
                  }`
                  : "Your Location"
                  }`}</span>
              </p>
              <p>
                <Icon icon="solar:global-outline" width="30" height="30" />
                <span>{userData.web ? userData.web : ""}</span>
              </p>
            </div>
            {sections.skills ?
              <div className="content">
                <h3> Skills</h3>
                <ul>
                  {userData.skills ? userData.skills.map((skill) => (
                    <li>{skill}</li>

                  ))
                    :
                    <li>Financial modeling and reporting</li>

                  }
                  {/* <li>Financial modeling and reporting</li>
            <li>Business valuation</li>
            <li>Advanced SAS proficiency</li>
            <li>Observation</li>
            <li>Multi-tasking</li> */}
                </ul>
              </div>
              :
              ""}

            {sections.education ?
              <div className="content">
                <h3>Education </h3>
                {userData.education ? userData.education.map((education, index) => (
                  <ul className="edu-back">
                    <li>
                      {`${education.schoolName ? education.schoolName : ""}`} <br />
                      <span>{`${education.degreeName ? education.degreeName : ""}`}</span> <br />
                      {`${education?.startMonth ||
                        education?.startYear ||
                        education?.endMonth ||
                        education?.endYear ||
                        education?.currentlyStudyHere
                        ? `${education?.startMonth
                          ? education?.startMonth
                          : ""
                        } ${education?.startYear
                          ? education?.startYear
                          : ""
                        } ${education?.endMonth ||
                          education?.endYear ||
                          education?.currentlyStudyHere
                          ? "-"
                          : ""
                        } ${education?.currentlyStudyHere
                          ? "Present"
                          : `${education?.endMonth
                            ? education?.endMonth
                            : ""
                          } ${education?.endYear
                            ? education?.endYear
                            : ""
                          }`
                        }`
                        : "Your Degree Tenure"
                        }`}
                    </li>
                  </ul>
                ))
                  :
                  <ul className="edu-back">
                    <li>
                      Borcelle Business School <br />
                      <span>Masters in Accounting</span> <br />
                      Completed in 2016
                    </li>
                  </ul>
                }
                {/* <ul className="edu-back">
            <li>
              Borcelle Business School <br />
              <span>Masters in Accounting</span> <br />
              Completed in 2016
            </li>
          </ul> */}


              </div>
              :
              ""}

            {sections.certificates ?
              <div className="content">
                <h3> Certification </h3>
                <ul className="edu-back">
                  {userData.certificates ? userData.certificates.map((certificate, index) => (
                    <li>
                      {`${certificate.authority ? certificate.authority : ""}`} <br />
                      <span>{`${certificate.certificateName ? certificate.certificateName : ""}`}</span> <br />
                      {certificate.certificateDate ? certificate.certificateDate : ""}
                    </li>
                  ))
                    :
                    <li>
                      Coursera <br />
                      <span>Masters in Accounting</span> <br />
                      Completed in 2016
                    </li>
                  }
                  {/* <li>
              Coursera <br />
              <span>Masters in Accounting</span> <br />
              Completed in 2016
            </li> */}
                </ul>
              </div>
              :
              ""}
            {sections.interests ?
              <div className="content">
                <h3> Interests</h3>
                <ul>
                  {userData.interests ? userData.interests.map((interest) => (
                    <li>{interest}</li>

                  ))
                    :
                    <li>Financial modeling and reporting</li>

                  }
                  {/* <li>Financial modeling and reporting</li>
            <li>Business valuation</li>
            <li>Advanced SAS proficiency</li>
            <li>Observation</li>
            <li>Multi-tasking</li> */}
                </ul>
              </div>
              :
              ""}
            {sections.publications ?
              <div className="content">
                <h3> Publication </h3>
                <ul className="edu-back">
                  {userData.publications ? userData.publications.map((publication, index) => (
                    <li>
                      {`${publication.publicationTitle ? publication.publicationTitle : ""}`} <br />
                      {`${publication.publisher ? publication.publisher : ""}`} <br />
                      <span>{parse(
                        publication?.description
                          ? publication?.description
                          : "Your Reference Description"
                      )}</span>
                      {`${publication.publicationMonth ? publication.publicationMonth : ""}${publication.publicationMonth && publication.publicationYear ? ", " : ""}${publication.publicationYear ? publication.publicationYear : ""}`}
                    </li>
                  ))
                    :
                    <li>
                      Name <br />
                      <span>This is my publication</span> <br />
                      2016
                    </li>
                  }
                  {/* <li>
              Name <br />
              <span>This is my publication</span> <br />
              2016
            </li> */}
                </ul>
              </div>
              :
              ""}

          </div>
          <div className="right">
            <div className="content">
              <h3>About Me</h3>
              <p>
                {parse(
                  userData?.summary
                    ? userData?.summary
                    : `Lorem ipsum dolor sit amet, consectetur adip iscing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ulla mco
                laboris nisi ut aliquip ex ea commodo consequat.`
                )}
              </p>
            </div>
            {sections.experience ?
              <div className="content">
                <h3>Professional Experience</h3>
                {
                  userData.experience ? userData.experience.map((experience) => (
                    <div className="experienceSingle">
                      <h5>
                        {`${experience.companyName ? experience.companyName : ""}${experience.companyName && experience.jobTitle ? " | " : ""}${experience.jobTitle ? experience.jobTitle : ""}`} <br />
                        <span className="italic">{`${experience?.startMonth ||
                          experience?.startYear ||
                          experience?.endMonth ||
                          experience?.endYear ||
                          experience?.currentlyWorkHere
                          ? `${experience?.startMonth ? experience?.startMonth : ""
                          } ${experience?.startYear ? experience?.startYear : ""
                          } ${experience?.endMonth ||
                            experience?.endYear ||
                            experience?.currentlyWorkHere
                            ? "-"
                            : ""
                          } ${experience?.currentlyWorkHere
                            ? "Present"
                            : `${experience?.endMonth ? experience?.endMonth : ""
                            } ${experience?.endYear ? experience?.endYear : ""
                            }`
                          }`
                          : "Your Company Tenure"
                          }`}</span>
                      </h5>
                      <p>Key responsibilities:</p>
                      {parse(
                        experience?.description
                          ? experience?.description
                          : "Your Job Description"
                      )}
                    </div>
                  ))
                    :
                    <div className="experienceSingle">
                      <h5>
                        Ginyard International Co. | Financial Analyst <br />
                        <span className="italic">2020 – Present</span>
                      </h5>
                      <p>Key responsibilities:</p>
                      <ul>
                        <li>Analyze current and past financial data</li>
                        <li>
                          Suggest budgets and improvements based on the above
                          information
                        </li>
                      </ul>
                    </div>
                }
                {/* <div className="experienceSingle">
            <h5>
              Ginyard International Co. | Financial Analyst <br />
              <span className="italic">2020 – Present</span>
            </h5>
            <p>Key responsibilities:</p>
            <ul>
              <li>Analyze current and past financial data</li>
              <li>
                Suggest budgets and improvements based on the above
                information
              </li>
            </ul>
          </div> */}



              </div>

              :
              ""}

            {sections.volunteer ?
              <div className="content">
                <h3>Volunteer</h3>
                {userData.volunteer ? (
                  userData.volunteer.map((volunteer, i) => (
                    <div className="volunteerSingle">
                      <h5>{`${volunteer.institutionName ? volunteer.institutionName : ""}${volunteer.institutionName && (volunteer.startMonth || volunteer.startYear || volunteer.endMonth || volunteer.endYear) ? " | " : ""}${volunteer?.startMonth ||
                        volunteer?.startYear ||
                        volunteer?.endMonth ||
                        volunteer?.endYear ||
                        volunteer?.currentlyVolunteerHere
                        ? `${volunteer?.startMonth
                          ? volunteer?.startMonth
                          : ""
                        } ${volunteer?.startYear
                          ? volunteer?.startYear
                          : ""
                        } ${volunteer?.endMonth ||
                          volunteer?.endYear ||
                          volunteer?.currentlyVolunteerHere
                          ? "-"
                          : ""
                        } ${volunteer?.currentlyVolunteerHere
                          ? "Present"
                          : `${volunteer?.endMonth
                            ? volunteer?.endMonth
                            : ""
                          } ${volunteer?.endYear
                            ? volunteer?.endYear
                            : ""
                          }`
                        }`
                        : "Your Degree Tenure"
                        }`}</h5>
                      <p>{parse(
                        volunteer?.description
                          ? volunteer?.description
                          : "Your Volunteer Description"
                      )}</p>
                    </div>
                  )))
                  :
                  <div className="volunteerSingle">
                    <h5>Police - 2020</h5>
                    <p>I volunteer</p>
                  </div>
                }
                {/* <div className="volunteerSingle">
            <h5>Police - 2020</h5>
            <p>I volunteer</p>
          </div> */}

              </div>
              :
              ""}
            {sections.awards ?
              <div className="content">
                <h3>Awards</h3>
                {userData.awards ? (
                  userData.awards.map((award, i) => (
                    <div className="volunteerSingle">
                      <h5>{`${award.awardTitle ? award.awardTitle : ""}${award.awardTitle && (award.awardMonth || award.awardYear) ? " | " : ""}${award.awardMonth ? award.awardMonth : ""}${award.awardMonth && award.awardYear ? ", " : ""}${award.awardYear ? award.awardYear : ""}`}</h5>

                    </div>
                  )))
                  :
                  <div className="volunteerSingle">
                    <h5>Police - 2020</h5>
                    <p>I volunteer</p>
                  </div>
                }
                {/* <div className="volunteerSingle">
            <h5>Police - 2020</h5>
            <p>I volunteer</p>
          </div> */}

              </div>
              :
              ""}

            {sections.reference ?
              <div className="content">
                <h3>Reference</h3>
                {userData.reference ? userData.reference.map((reference, index) => (
                  <div className="referenceSingle">
                    <h5>{`${reference.personName ? reference.personName : ""}${reference.personName && reference.referenceCompany ? " - " : ""}${reference.referenceCompany ? reference.referenceCompany : ""}`}</h5>
                    <p>{reference.contact1 ? reference.contact1 : ""}<br />
                      {reference.contact2 ? reference.contact2 : ""}</p>
                  </div>
                ))
                  :
                  <div className="referenceSingle">
                    <h5>Name - Company name</h5>
                    <p> 123-456-7890</p>
                  </div>
                }
                {/* <div className="referenceSingle">
            <h5>Name - Company name</h5>
            <p> 123-456-7890</p>
          </div> */}
              </div>
              :
              ""}

          </div>
        </div>
      </div>
    </div>
  );
};

export default Template27;
