import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import template1 from "../../assets/images/tempelates/1.png";
import template2 from "../../assets/images/tempelates/2.png";
import template3 from "../../assets/images/tempelates/3.png";
import template4 from "../../assets/images/tempelates/4.png";
import template5 from "../../assets/images/tempelates/5.png";
import template6 from "../../assets/images/tempelates/6.png";
import Template6 from "../template6/template6";
import Template7 from "../template7/template7";

import { useReactToPrint } from "react-to-print";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Template8 from "../template8/template8";
import Template9 from "../template9/template9";
import Template10 from "../template10/template10";
import Template11 from "../template11/template11";
import Template12 from "../template12/template12";
import Template13 from "../template13/template13";
import Template14 from "../template14/templateforteen";
import Template15 from "../template15/template15";
import Template16 from "../template16/template16";
import Template17 from "../template17/template17";
import Template18 from "../template18/template18";
import Template19 from "../template19/template19";
import Template20 from "../template20/template20";
import Template21 from "../template21/template21";
import Template22 from "../template22/template22";
import Template23 from "../template23/template23";
import Template24 from "../template24/template24";
import Template25 from "../template25/template25";
import techData from "../template/tech.json"
import Button from "../shared/button";
import { useNavigate } from "react-router-dom";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { auth, firestoredb } from "../../config";
import Template26 from "../template26/template26";
import Template27 from "../template27/template27";
import Template28 from "../template28/template28";
import Template29 from "../template29/template29";
import Template30 from "../template30/template30";


const Resumehistory = ({ userData, setUserData }) => {
    const [currentCategory, setCurrentCategory] = useState("All");
    const [fromPreview, setFromPreview] = useState(true);
    const [history, setHistory] = useState([])
    const [userId, setUserId] = useState("");

    const user = auth.currentUser;

    const navigate = useNavigate();
    const sections = {
        skills: true,
        strengths: true,
        experience: true,
        projects: true,
        volunteer: false,
        education: true,
        certificates: true,
        awards: true,
        publications: false,
        interests: false,
        languages: true,
        reference: false
    }

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (auth.currentUser != null) {
                setUserId(auth.currentUser.uid);
            }
        });
    }, []);
    const getDataFromDb = async () => {
        if (user) {
            // console.log("Fetching Data from DB");
            const docRef = doc(firestoredb, "users", user.uid);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                // console.log("Document data gs:", docSnap.data());
                if (docSnap.data().cvDataHistory) {
                    //   setUserData({ ...docSnap.data().cvData, skills: docSnap.data().cvData.skills ? docSnap.data().cvData.skills : [], strengths: docSnap.data().cvData.strengths ? docSnap.data().cvData.strengths : [] })
                    //   setIsFetched(true);
                    //   setUserName(
                    //     docSnap.data().firstName ? docSnap.data().firstName : "User"
                    //   );
                    // console.log("history", docSnap.data().cvDataHistory)
                    setHistory(docSnap.data().cvDataHistory)
                }
            } else {
                // docSnap.data() will be undefined in this case
                // console.log("No such document!");
            }
        }
    };

    useEffect(() => {
        getDataFromDb();
    }, [userId]);

    return (
        <div>
            <div className="templates">
                <div className="headHistoryContainer">
                    <h1 className="headHistory">History</h1>
                    <p className="headSubHeading">
                        Here are the all resumes you have been created!
                    </p>
                </div>


                <div className="templates-content">
                    {currentCategory == "All"
                        ? history.map((item, index) => {
                            return (
                                // <div className="template-card">
                                //   <img src={item.template} alt="template" className="img-fluid" />
                                //   <div className="use-template ">
                                //     <Button
                                //       text="Use Template"
                                //       width="118px"
                                //       height="30px"
                                //       className="btn-prime-2 btn-primary text-white rounded "
                                //       onClick={(e) => alert("Template Selected")}
                                //     />
                                //   </div>
                                // </div>

                                <div className="template-card">
                                    <h5
                                        style={{
                                            textAlign: "center",
                                            marginBottom: "5px",
                                            padding: "5px",
                                        }}
                                    >
                                        {`${item.time}`}
                                    </h5>
                                    <div
                                        className="cv-inner"
                                        style={{
                                            backgroundColor: "white",
                                            overflow: "hidden"
                                        }}
                                    >
                                        {item.selectedTemplate ?
                                            item.selectedTemplate == 0 ?
                                                <Template6
                                                    userData={item}
                                                    lineHeight={"1.2"}
                                                    color={"darkBlue"}
                                                    fromPreview={fromPreview}
                                                    sections={sections}
                                                // fontSize={22}
                                                ></Template6>
                                                :
                                                item.selectedTemplate == 1 ?
                                                    <Template7
                                                        userData={item}
                                                        lineHeight={"1.2"}
                                                        color={"darkBlue"}
                                                        fromPreview={fromPreview}
                                                        sections={sections}
                                                    // fontSize={22}
                                                    ></Template7>
                                                    :
                                                    item.selectedTemplate == 2 ?
                                                        <Template8
                                                            userData={item}
                                                            lineHeight={"1.2"}
                                                            color={"darkBlue"}
                                                            fromPreview={fromPreview}
                                                            sections={sections}
                                                        // fontSize={22}
                                                        ></Template8>
                                                        :
                                                        item.selectedTemplate == 3 ?
                                                            <Template9
                                                                userData={item}
                                                                lineHeight={"1.2"}
                                                                color={"darkBlue"}
                                                                fromPreview={fromPreview}
                                                                sections={sections}
                                                            // fontSize={22}
                                                            ></Template9>
                                                            :
                                                            item.selectedTemplate == 4 ?
                                                                <Template10
                                                                    userData={item}
                                                                    lineHeight={"1.2"}
                                                                    color={"darkBlue"}
                                                                    fromPreview={fromPreview}
                                                                    sections={sections}
                                                                // fontSize={22}
                                                                ></Template10>
                                                                :
                                                                item.selectedTemplate == 5 ?
                                                                    <Template11
                                                                        userData={item}
                                                                        lineHeight={"1.2"}
                                                                        color={"darkBlue"}
                                                                        fromPreview={fromPreview}
                                                                        sections={sections}
                                                                    // fontSize={22}
                                                                    ></Template11>
                                                                    :
                                                                    item.selectedTemplate == 6 ?
                                                                        <Template12
                                                                            userData={item}
                                                                            lineHeight={"1.2"}
                                                                            color={"darkBlue"}
                                                                            fromPreview={fromPreview}
                                                                            sections={sections}
                                                                        // fontSize={22}
                                                                        ></Template12>
                                                                        :
                                                                        item.selectedTemplate == 7 ?
                                                                            <Template13
                                                                                userData={item}
                                                                                lineHeight={"1.2"}
                                                                                color={"darkBlue"}
                                                                                fromPreview={fromPreview}
                                                                                sections={sections}
                                                                            // fontSize={22}
                                                                            ></Template13>
                                                                            :
                                                                            item.selectedTemplate == 8 ?
                                                                                <Template14
                                                                                    userData={item}
                                                                                    lineHeight={"1.2"}
                                                                                    color={"darkBlue"}
                                                                                    fromPreview={fromPreview}
                                                                                    sections={sections}
                                                                                // fontSize={22}
                                                                                ></Template14>
                                                                                :
                                                                                item.selectedTemplate == 9 ?
                                                                                    <Template15
                                                                                        userData={item}
                                                                                        lineHeight={"1.2"}
                                                                                        color={"darkBlue"}
                                                                                        fromPreview={fromPreview}
                                                                                        sections={sections}
                                                                                    // fontSize={22}
                                                                                    ></Template15>
                                                                                    :
                                                                                    item.selectedTemplate == 10 ?
                                                                                        <Template16
                                                                                            userData={item}
                                                                                            lineHeight={"1.2"}
                                                                                            color={"darkBlue"}
                                                                                            fromPreview={fromPreview}
                                                                                            sections={sections}
                                                                                        // fontSize={22}
                                                                                        ></Template16>
                                                                                        :
                                                                                        item.selectedTemplate == 11 ?
                                                                                            <Template17
                                                                                                userData={item}
                                                                                                lineHeight={"1.2"}
                                                                                                color={"darkBlue"}
                                                                                                fromPreview={fromPreview}
                                                                                                sections={sections}
                                                                                            // fontSize={22}
                                                                                            ></Template17>
                                                                                            :
                                                                                            item.selectedTemplate == 12 ?
                                                                                                <Template18
                                                                                                    userData={item}
                                                                                                    lineHeight={"1.2"}
                                                                                                    color={"darkBlue"}
                                                                                                    fromPreview={fromPreview}
                                                                                                    sections={sections}
                                                                                                // fontSize={22}
                                                                                                ></Template18>
                                                                                                :
                                                                                                item.selectedTemplate == 13 ?
                                                                                                    <Template19
                                                                                                        userData={item}
                                                                                                        lineHeight={"1.2"}
                                                                                                        color={"darkBlue"}
                                                                                                        fromPreview={fromPreview}
                                                                                                        sections={sections}
                                                                                                    // fontSize={22}
                                                                                                    ></Template19>
                                                                                                    :
                                                                                                    item.selectedTemplate == 14 ?
                                                                                                        <Template20
                                                                                                            userData={item}
                                                                                                            lineHeight={"1.2"}
                                                                                                            color={"darkBlue"}
                                                                                                            fromPreview={fromPreview}
                                                                                                            sections={sections}
                                                                                                        // fontSize={22}
                                                                                                        ></Template20>
                                                                                                        :
                                                                                                        item.selectedTemplate == 15 ?
                                                                                                            <Template21
                                                                                                                userData={item}
                                                                                                                lineHeight={"1.2"}
                                                                                                                color={"darkBlue"}
                                                                                                                fromPreview={fromPreview}
                                                                                                                sections={sections}
                                                                                                            // fontSize={22}
                                                                                                            ></Template21>
                                                                                                            :
                                                                                                            item.selectedTemplate == 16 ?
                                                                                                                <Template22
                                                                                                                    userData={item}
                                                                                                                    lineHeight={"1.2"}
                                                                                                                    color={"darkBlue"}
                                                                                                                    fromPreview={fromPreview}
                                                                                                                    sections={sections}
                                                                                                                // fontSize={22}
                                                                                                                ></Template22>
                                                                                                                :
                                                                                                                item.selectedTemplate == 17 ?
                                                                                                                    <Template23
                                                                                                                        userData={item}
                                                                                                                        lineHeight={"1.2"}
                                                                                                                        color={"darkBlue"}
                                                                                                                        fromPreview={fromPreview}
                                                                                                                        sections={sections}
                                                                                                                    // fontSize={22}
                                                                                                                    ></Template23>
                                                                                                                    :
                                                                                                                    item.selectedTemplate == 18 ?
                                                                                                                        <Template24
                                                                                                                            userData={item}
                                                                                                                            lineHeight={"1.2"}
                                                                                                                            color={"darkBlue"}
                                                                                                                            fromPreview={fromPreview}
                                                                                                                            sections={sections}
                                                                                                                        // fontSize={22}
                                                                                                                        ></Template24>
                                                                                                                        :
                                                                                                                        item.selectedTemplate == 19 ?
                                                                                                                            <Template25
                                                                                                                                userData={item}
                                                                                                                                lineHeight={"1.2"}
                                                                                                                                color={"#2B582B"}
                                                                                                                                fromPreview={fromPreview}
                                                                                                                                sections={sections}
                                                                                                                            // fontSize={22}
                                                                                                                            ></Template25>
                                                                                                                            :
                                                                                                                            item.selectedTemplate == 20 ?
                                                                                                                                <Template26
                                                                                                                                    userData={item}
                                                                                                                                    lineHeight={"1.2"}
                                                                                                                                    color={"#f4f4f4"}
                                                                                                                                    fromPreview={fromPreview}
                                                                                                                                    sections={sections}
                                                                                                                                // fontSize={22}
                                                                                                                                ></Template26>
                                                                                                                                :
                                                                                                                                item.selectedTemplate == 21 ?
                                                                                                                                    <Template27
                                                                                                                                        userData={item}
                                                                                                                                        lineHeight={"1.2"}
                                                                                                                                        color={"#942637"}
                                                                                                                                        fromPreview={fromPreview}
                                                                                                                                        sections={sections}
                                                                                                                                    // fontSize={22}
                                                                                                                                    ></Template27>
                                                                                                                                    :
                                                                                                                                    item.selectedTemplate == 22 ?
                                                                                                                                        <Template28
                                                                                                                                            userData={item}
                                                                                                                                            lineHeight={"1.2"}
                                                                                                                                            color={"#2f2f2f"}
                                                                                                                                            fromPreview={fromPreview}
                                                                                                                                            sections={sections}
                                                                                                                                        // fontSize={22}
                                                                                                                                        ></Template28>
                                                                                                                                        :
                                                                                                                                        item.selectedTemplate == 23 ?
                                                                                                                                            <Template29
                                                                                                                                                userData={item}
                                                                                                                                                lineHeight={"1.2"}
                                                                                                                                                color={"#bbc9ff"}
                                                                                                                                                fromPreview={fromPreview}
                                                                                                                                                sections={sections}
                                                                                                                                            // fontSize={22}
                                                                                                                                            ></Template29>
                                                                                                                                            :
                                                                                                                                            item.selectedTemplate == 24 ?
                                                                                                                                                <Template30
                                                                                                                                                    userData={item}
                                                                                                                                                    lineHeight={"1.2"}
                                                                                                                                                    color={"#d69460"}
                                                                                                                                                    fromPreview={fromPreview}
                                                                                                                                                    sections={sections}
                                                                                                                                                // fontSize={22}
                                                                                                                                                ></Template30>
                                                                                                                                                :
                                                                                                                                                ""

                                            :
                                            <Template6
                                                userData={item}
                                                lineHeight={"1.2"}
                                                color={"darkBlue"}
                                                fromPreview={fromPreview}
                                                sections={sections}
                                            // fontSize={22}
                                            ></Template6>
                                        }

                                    </div>
                                    <div className="use-template ">
                                        <Button
                                            text="Use This"
                                            width="118px"
                                            height="30px"
                                            className="btn-prime-2 btn-primary text-white rounded "
                                            onClick={(e) => {
                                                setUserData(item);
                                                navigate("/dashboard", { state: { checkForCustomization: true } });
                                            }}
                                        />
                                    </div>
                                </div>
                            );
                        })
                        : techData.map((item, index) => {
                            return (
                                // <div className="template-card">
                                //   <img src={item.template} alt="template" className="img-fluid" />
                                //   <div className="use-template ">
                                //     <Button
                                //       text="Use Template"
                                //       width="118px"
                                //       height="30px"
                                //       className="btn-prime-2 btn-primary text-white rounded "
                                //       onClick={(e) => alert("Template Selected")}
                                //     />
                                //   </div>
                                // </div>
                                currentCategory == item.category ? (
                                    <div className="template-card">
                                        <h5
                                            style={{
                                                textAlign: "center",
                                                marginBottom: "5px",
                                                padding: "5px",
                                            }}
                                        >
                                            {item.role}
                                        </h5>
                                        <div
                                            className="cv-inner"
                                            style={{
                                                backgroundColor: "white",
                                                overflow: "hidden"
                                            }}
                                        >
                                            <Template9
                                                userData={item}
                                                lineHeight={"1.8"}
                                                color={item.templateColor}
                                                fromPreview={fromPreview}
                                                sections={sections}
                                            ></Template9>
                                        </div>
                                        <div className="use-template ">
                                            <Button
                                                text="Use Template"
                                                width="118px"
                                                height="30px"
                                                className="btn-prime-2 btn-primary text-white rounded "
                                                onClick={(e) => {

                                                    setUserData(item);
                                                    navigate("/dashboard");
                                                }}
                                            />
                                        </div>
                                    </div>
                                ) : null
                            );
                        })}
                </div>
            </div>
        </div>
    );
}

export default Resumehistory