import React from "react";
import "../../style/main.scss";
import ex from "../../assets/icons/export.svg";
import Button from "../shared/button";

const Share = () => {
  return (
    <div>
      <div className="Share ">
        <div className="d-flex v-center">
          <img src={ex} alt="" />
          <h5 className="p6 fw-500 mb-0">Share</h5>
        </div>
        <div className="inside-Share ">
          <div className="score">
            <h5 className="fw-600 mb-0">Share Your resume with the world!</h5>
            <p className="fw-400 mt-3">
              Ask your network for feedback or simply put your resume out there.
              Don’t forget, most jobs get filled by referral.
            </p>
          </div>
          <div className="score-on ">
            <h5 className="fw-500">Share on:</h5>
            <div className="share-sec d-flex">
              <div className="share-btn v-center h-center facebook">
                <Button text="Facebook" className="some-btn" />
              </div>
              <div className="share-btn v-center h-center linkedIn ">
                <Button text="LinkedIn" className="some-btn " />
              </div>
              <div className="share-btn v-center h-center twitter">
                <Button text="Twitter" className="some-btn" />
              </div>
              <div className="share-btn v-center h-center mess">
                <Button text="Messanger" className="some-btn" />
              </div>
              <div className="share-btn v-center h-center whatsapp">
                <Button text="Whatsapp" className="some-btn" />
              </div>
            </div>
          </div>
          <div className="link mt-5">
            <h5 className="fw-500 mb-0">Get Link</h5>
            <p className="fw-400 ">
              Share your resume with anyone using this link.
            </p>
            <div className="copy">
              <div className="new-item w-100 mt-3 gap-3 d-flex">
                <div className="input-box w-100">
                  <label htmlFor="" className="w-100 share-label">
                    <input
                      type="text"
                      placeholder="https://Lorem ipsum dolor sit amet//.com"
                    />
                    <Button
                      text="Copy Link"
                      width="120px"
                      height="30px"
                      className="btn-prime-1 btn-primary text-white rounded  "
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Share;
