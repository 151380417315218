import React, { useState, useEffect } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import "./template7.scss";
import { Icon } from "@iconify/react";
import parse from "html-react-parser"
function Template7({ userData, forDownload, sections, color, setColor, font, setFont, fontSize, setFontSize, lineHeight, setLineHeight, fromPreview }) {

  const [currentScale, setCurrentScale] = useState("100%")

  useEffect(() => {
    if (!fromPreview) {
      setColor(userData.templateColor ? userData.templateColor : "rgb(0, 0, 0)");
      setFont(`Poppins`);
      setLineHeight("1.4")
    }

  }, []);

  function handleScaleChange(event) {
    setCurrentScale(`${Math.trunc((event.instance.transformState.scale * 100))}%`);
  }

  const getColor = () => {
    return `:root{--t7Color: ${color};
    --t7LineHeight : ${lineHeight};
  --t7Font : ${font};
--paraFontSize: ${fontSize * 0.70}px;
--subHeadingFontSize: ${fontSize}px; }`;
  };
  const getPageMarginsF = () => {
    return `@page :first { margin: ${"0px"} ${"0px"} ${"10px"} ${"0px"} !important; }`;
  };
  const getPageMargins = () => {
    return `@page { margin: ${"30px"} ${"0px"} ${"10px"} ${"0px"} !important; }`;
  };
  const getPageMarginst = () => {
    return `table { page-break-inside: avoid; }`;
  };
  return (
    // <TransformWrapper
    //   initialScale={1}
    //   maxScale={2}
    //   minScale={0.5}
    //   initialPositionX={0}
    //   initialPositionY={0}
    //   onTransformed={(e) => handleScaleChange(e)}

    // >
    //   {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
    //     <React.Fragment>
    //       <div className="tools" style={{ width: "25%", margin: "10px", display: "flex", gap: "6px" }}>
    //         <div style={{ display: "flex", gap: "10px" }}>
    //           <button onClick={() => zoomOut(0.1)} style={{ backgroundColor: "#180045", padding: "0 8px", borderRadius: "5px" }}>-</button>
    //           <p style={{ margin: "0", padding: "0", color: "black" }}>{currentScale}</p>
    //           <button onClick={() => zoomIn(0.1)} style={{ backgroundColor: "#180045", padding: "0 8px", borderRadius: "5px" }}>+</button>

    //         </div>

    //         <button onClick={() => resetTransform()} style={{ backgroundColor: "#180045", padding: "0 10px", borderRadius: "5px" }}>Reset</button>
    //       </div>
    //       <TransformComponent>
    <section className="template7-sec" ref={forDownload}>
      <style>{getPageMargins()}</style>
      <style>{getPageMarginsF()}</style>
      <style>{getPageMarginst()}</style>
      <style>{getColor()}</style>
      <h2 className="heading">{`${userData?.fName || userData?.lName
        ? `${userData?.fName ? userData?.fName : ""} ${userData?.lName ? userData?.lName : ""
        }`
        : "Your Name"
        }`}</h2>
      <div className="top">
        <div className="address">
          <Icon icon="mdi:house" className="iconColor" width="24" height="24" />
          {/* <p className="para">{`${userData.address ? userData.address : ""}${userData.address && userData.streetNo ? ", " : ""}${userData.streetNo ? userData.streetNo : ""}${(userData.address && (userData.postal || userData.city)) || (userData.address && (userData.postal || userData.city)) ? ", " : ""}${userData.postal ? userData.postal : ""} ${userData.city ? userData.city : ""}${(userData.address && userData.streetNo && userData.country) || (userData.address && userData.postal && userData.country) || (userData.address && userData.city && userData.country) || (userData.address && userData.country) ? ", " : ""}${userData.country ? userData.country : ""}`}</p> */}
          <p className="para">{`${userData?.city || userData?.country
            ? `${userData?.city ? userData?.city : ""} , ${userData?.country ? userData?.country : ""
            }`
            : "Your Location"
            }`}</p>
        </div>
        <div className="phone">
          <Icon icon="ic:baseline-phone" className="iconColor" width="24" height="24" />
          <p className="para">{userData?.phone ? userData?.phone : "Your Phone"}</p>
        </div>
        <div className="email">
          <Icon icon="ic:baseline-email" className="iconColor" width="24" height="24" />
          <p className="para">{userData?.email ? userData?.email : "Your Email"}</p>
        </div>
      </div>

      <div className="borderline"></div>

      <p className="para">
        {parse(
          userData?.summary
            ? userData?.summary
            : `Lorem ipsum dolor sit amet, consectetur adip iscing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ulla mco
                laboris nisi ut aliquip ex ea commodo consequat.`
        )}
      </p>


      {sections.skills || sections.strengths ?
        <>
          <div className="borderline"></div>
          <div className="skill">
            <h4 className="sub-heading">SKILLS</h4>
            <div className="content">
              {sections.skills ?
                <div className="ist">
                  {/* <p className="para bold-para">Hard Skills</p> */}
                  <ul>
                    {userData.skills ?
                      userData.skills.map((skill, index) => (
                        <li>
                          <p className="para">{skill}</p>
                        </li>
                      ))
                      :
                      ""}

                  </ul>
                </div>
                :
                ""}
              {sections.strengths ?
                <div className="ist">
                  <p className="para bold-para">Soft Skills</p>
                  <ul>
                    {userData.strengths ?
                      userData.strengths.map((strength, index) => (
                        <li>
                          <p className="para">{strength}</p>
                        </li>
                      ))
                      :
                      ""}

                  </ul>
                </div>
                :
                ""}

            </div>
          </div>
          {/* <div className="borderline"></div>

      <div className="strength">
        <h4 className="sub-heading">STRENGTH</h4>
        <ul>
          <li>
            <p className="para">Strength1</p>
          </li>
          <li>
            <p className="para">Strength1</p>
          </li>
        </ul>
      </div> */}
        </>
        : ""}

      {sections.experience ?
        <>
          <div className="borderline"></div>
          <table>
            <div className="work-experience">
              <h4 className="sub-heading">Work Experience</h4>

              {userData.experience ? (
                userData.experience.map((experience, i) => (
                  <>
                    <div className="head">
                      <p className="para bold-para">{experience.jobTitle}</p>
                      <p className="para">{`${experience?.startMonth ||
                        experience?.startYear ||
                        experience?.endMonth ||
                        experience?.endYear ||
                        experience?.currentlyWorkHere
                        ? `${experience?.startMonth ? experience?.startMonth : ""
                        } ${experience?.startYear ? experience?.startYear : ""
                        } ${experience?.endMonth ||
                          experience?.endYear ||
                          experience?.currentlyWorkHere
                          ? "-"
                          : ""
                        } ${experience?.currentlyWorkHere
                          ? "Present"
                          : `${experience?.endMonth ? experience?.endMonth : ""
                          } ${experience?.endYear ? experience?.endYear : ""
                          }`
                        }`
                        : "Your Company Tenure"
                        }`}</p>
                    </div>
                    <p className="para">{experience.companyName}</p>
                    <p>
                      {parse(
                        experience?.description
                          ? experience?.description
                          : "Your Job Description"
                      )}
                    </p>
                  </>
                ))
              ) : (
                <>
                  <div className="head">
                    <p className="para bold-para">Job Title</p>
                    <p className="para">August 2016 - Present</p>
                  </div>
                  <p className="para">Company Name</p>
                  <p className="para">
                    <ul>
                      <li>
                        Responsibility 1
                      </li>
                      <li>
                        Responsibility 2
                      </li>
                      <li>
                        Responsibility 3
                      </li>
                      <li>
                        CResponsibility 4
                      </li>
                      <li>
                        Responsibility 5
                      </li>
                    </ul>
                  </p>
                </>
              )}

              {/* <div className="head">
          <p className="para bold-para">Emergency Room - Staff RN</p>
          <p className="para">August 2016 - Present</p>
        </div>
        <p className="para">DFW General Hospital, Dallas, Texas</p>
        <p className="para">
          A proud member of DFW General Hospital's Emergency Room RN Staff.
          Collaborated with an amazing team of healthcare professionals in a
          Level 1 trauma center in downtown Dallas, Texas - with patient volume
          often exceeding 50 people of diverse ages.
        </p>
        <ul>
          <li>
            Triage patients to determine the priority Of care based on
            psychological, physical, or social needs.
          </li>
          <li>
            Managed patient transportation to ICU & Extended Care facilities.
          </li>
          <li>
            Provided individual care to patients with a diverse range of medical
            emergencies.
          </li>
          <li>
            Closely monitored patients during intake, treatment, and emergency
            medical situations.
          </li>
          <li>
            Administered medications within compliance of State of Texas
            regulations.
          </li>
        </ul>
        <div className="head">
          <p className="para bold-para">
            Maintained a positive attitude in a high-stress environment.
          </p>
          <p className="para">Jun 2014 - Jul 2016</p>
        </div>
        <p className="para">Metro General Hospital, Ft Worth, Texas</p>
        <p className="para">
          Participated in an 18-month RN Clinical Practicum program -
          administered through The University of Texas Dallas nursing school.
          Completed 960 clinical hours in a high-paced general hospital -
          engaging with patients, caregivers, and hospital staff
        </p>
        <ul>
          <li>
            Performed emergency medical procedures including cardiac life
            support, CPR, and emergency first aid.
          </li>
          <li>
            Collaborated with healthcare professionals to manage, plan, and
            access patient care in a high-volume medical clinic.
          </li>
          <li>
            Completed medical care including intravenous infusions, blood
            product transfusions, and oxygen intake, Received constructive
            criticism during weekly reviews from peers and made improvements in
            a timely manner
          </li>
        </ul> */}
            </div>
          </table>
        </>
        :

        ""}

      {sections.projects ?
        <>
          <div className="borderline"></div>
          <table>
            <div className="project">
              <h4 className="sub-heading">PROJECTS</h4>
              {userData.projects ? (
                userData.projects.map((project, i) => (
                  <>
                    <div className="head">

                      <p className="para bold-para">{project.projectName}</p>

                      <p className="para">{`${project?.startMonth ||
                        project?.startYear ||
                        project?.endMonth ||
                        project?.endYear ||
                        project?.currentlyWorkingOnThisProject
                        ? `${project?.startMonth ? project?.startMonth : ""
                        } ${project?.startYear ? project?.startYear : ""
                        } ${project?.endMonth ||
                          project?.endYear ||
                          project?.currentlyWorkingOnThisProject
                          ? "-"
                          : ""
                        } ${project?.currentlyWorkingOnThisProject
                          ? "Present"
                          : `${project?.endMonth ? project?.endMonth : ""
                          } ${project?.endYear ? project?.endYear : ""
                          }`
                        }`
                        : "Your Company Tenure"
                        }`}</p>
                    </div>
                    <p>
                      {parse(
                        project?.description
                          ? project?.description
                          : "Your Job Description"
                      )}
                    </p>
                  </>
                ))
              ) : (
                <>
                  <div className="head">
                    <p className="para">Project type</p>
                    <p className="para bold-para">Project Name</p>
                    <p className="para">August 2016 - Present</p>
                  </div>
                  <p className="para">Detail</p>
                </>
              )}
              {/* <div className="head">
          <p className="para bold-para">Project Name</p>
          <p className="para">August 2016 - Present</p>
        </div>
        <p className="para">Detail</p> */}
            </div>
          </table>
        </>
        :

        ""}

      {
        sections.volunteer ? <>
          <div className="borderline"></div>

          <div className="volunteer">
            <h4 className="sub-heading">VOLUNTEER</h4>
            {userData.volunteer ? (
              userData.volunteer.map((volunteer, i) => (
                <>
                  <div className="head">
                    <p className="para bold-para">{volunteer.institutionName}</p>
                    <p className="para">{`${volunteer?.startMonth ||
                      volunteer?.startYear ||
                      volunteer?.endMonth ||
                      volunteer?.endYear ||
                      volunteer?.currentlyVolunteerHere
                      ? `${volunteer?.startMonth ? volunteer?.startMonth : ""
                      } ${volunteer?.startYear ? volunteer?.startYear : ""
                      } ${volunteer?.endMonth ||
                        volunteer?.endYear ||
                        volunteer?.currentlyVolunteerHere
                        ? "-"
                        : ""
                      } ${volunteer?.currentlyVolunteerHere
                        ? "Present"
                        : `${volunteer?.endMonth ? volunteer?.endMonth : ""
                        } ${volunteer?.endYear ? volunteer?.endYear : ""
                        }`
                      }`
                      : "Your Company Tenure"
                      }`}</p>
                  </div>
                  <p>{parse(
                    volunteer?.description
                      ? volunteer?.description
                      : "Your Volunteer Description"
                  )}</p>
                </>
              ))
            ) : (
              <>
                <div className="head">
                  <p className="para bold-para">Volunteer Name</p>
                  <p className="para">August 2016 - Present</p>
                </div>
                <p className="para">Detail</p>
              </>
            )}
            {/* <div className="head">
    <p className="para bold-para">Volunteer Name</p>
    <p className="para">August 2016 - Present</p>
  </div>
  <p className="para">Detail</p> */}
          </div>
        </>
          :
          ""

      }

      {
        sections.education ? <>
          <div className="borderline"></div>

          <div className="education">
            <h4 className="sub-heading">Education</h4>

            {userData.education ? userData.education.map((education, index) => (
              <>
                <div className="head">
                  <p className="para bold-para">{education.degreeName}</p>
                  <p className="para">{`${education?.startMonth ||
                    education?.startYear ||
                    education?.endMonth ||
                    education?.endYear ||
                    education?.currentlyStudyHere
                    ? `${education?.startMonth
                      ? education?.startMonth
                      : ""
                    } ${education?.startYear
                      ? education?.startYear
                      : ""
                    } ${education?.endMonth ||
                      education?.endYear ||
                      education?.currentlyStudyHere
                      ? "-"
                      : ""
                    } ${education?.currentlyStudyHere
                      ? "Present"
                      : `${education?.endMonth
                        ? education?.endMonth
                        : ""
                      } ${education?.endYear
                        ? education?.endYear
                        : ""
                      }`
                    }`
                    : "Your Degree Tenure"
                    }`}</p>
                </div>
                <p className="para">{`${education.schoolName ? education.schoolName : ""}${education.schoolName && (education.cityName || education.country) ? " - " : ""}${education.cityName ? education.cityName : ""}${education.cityName && education.country ? ", " : ""}${education.country ? education.country : ""}`}</p>
              </>
            )) :
              <>
                <div className="head">
                  <p className="para bold-para">Degree Name</p>
                  <p className="para">August 2016 - Present</p>
                </div>
                <p className="para">Institution Name, City, Country</p>
              </>
            }

            {/* <div className="head">
    <p className="para bold-para">Emergency Room - Staff RN</p>
    <p className="para">August 2016 - Present</p>
  </div>
  <p className="para">DFW General Hospital, Dallas, Texas</p>
  <div className="head">
    <p className="para bold-para">Emergency Room - Staff RN</p>
    <p className="para">August 2016 - Present</p>
  </div>
  <div className="head">
    <p className="para bold-para">Emergency Room - Staff RN</p>
    <p className="para">August 2016 - Present</p>
  </div>
  <div className="head">
    <p className="para bold-para">Emergency Room - Staff RN</p>
    <p className="para">August 2016 - Present</p>
  </div>
  <p className="para">DFW General Hospital, Dallas, Texas</p> */}
          </div>
        </>
          :
          ""

      }

      {
        sections.certificates ? <>
          <div className="borderline"></div>

          <div className="certification">
            <h4 className="sub-heading">CERTIFICATION</h4>
            {userData.certificates ? (
              userData.certificates.map((certificate, i) => (
                <>
                  <div className="head">
                    <p className="para bold-para">{certificate.certificateName}</p>
                    <p className="para">{`${certificate.certificateDate ? certificate.certificateDate : ""}`}</p>

                  </div>
                  <p className="para">{`${certificate.authority}`}</p>

                </>
              ))
            ) : (
              <>
                <div className="head">
                  <p className="para bold-para">Certificate Name</p>
                  <p className="para">August 2016 - Present</p>
                </div>
                <p className="para">Certificate details</p>
              </>
            )}

          </div>
        </>
          :
          ""

      }
      {
        sections.awards ? <>
          <div className="borderline"></div>

          <div className="awards">
            <h4 className="sub-heading">AWARDS</h4>
            {userData.awards ? userData.awards.map((award, index) => (
              <div className="head">
                <p className="para bold-para">{award.awardTitle}</p>
                <p className="para">{`${award.awardMonth ? award.awardMonth : ""}${award.awardMonth && award.awardYear ? ", " : ""}${award.awardYear ? award.awardYear : ""}`}</p>
              </div>
            ))
              :
              <div className="head">
                <p className="para bold-para">Award Name</p>
                <p className="para">August 2016, Present</p>
              </div>
            }
            {/* <div className="head">
    <p className="para bold-para">Award Name</p>
    <p className="para">August 2016 - Present</p>
  </div> */}
          </div>
        </>
          :
          ""

      }

      {
        sections.publications ? <>
          <div className="borderline"></div>

          <div className="publications">
            <h4 className="sub-heading">PUBLICATIONS</h4>
            {userData.publications ? (
              userData.publications.map((publication, i) => (
                <>
                  <p className="para">{publication.publisher}</p>
                  <div className="head">
                    <p className="para bold-para">{publication.publicationTitle}</p>
                    <p className="para">{`${publication.publicationMonth ? publication.publicationMonth : ""}${publication.publicationMonth && publication.publicationYear ? ", " : ""}${publication.publicationYear ? publication.publicationYear : ""}`}</p>
                  </div>

                  <p >
                    {parse(
                      publication?.description
                        ? publication?.description
                        : "Your Publication Description"
                    )}
                  </p>

                </>
              ))
            ) : (
              <>
                <p className="para">Title</p>
                <div className="head">
                  <p className="para bold-para">Book Name</p>
                  <p className="para">August 2016 - Present</p>
                </div>
                <ul>
                  <li>
                    <p className="para">
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nostrum,
                      ex?
                    </p>
                  </li>
                </ul>
              </>
            )}
            {/* <p className="para">Title</p>
  <div className="head">
    <p className="para bold-para">Book Name</p>
    <p className="para">August 2016 - Present</p>
  </div>
  <ul>
    <li>
      <p className="para">
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nostrum,
        ex?
      </p>
    </li>
  </ul> */}
          </div>
        </>
          :
          ""

      }


      {
        sections.interests ? <>
          <div className="borderline"></div>

          <div className="interests">
            <h4 className="sub-heading">INTERESTS</h4>
            <ul>
              {userData.interests ? userData.interests.map((interest, index) => (
                <li>
                  <p className="para">{interest}</p>
                </li>
              ))
                :
                <li>
                  <p className="para">Interest1</p>
                </li>}

              {/* <li>
      <p className="para">Interest1</p>
    </li>
    <li>
      <p className="para">Interest2</p>
    </li> */}
            </ul>
          </div>
        </>
          :
          ""

      }

      {/* {
                sections.languages ? <>
                  <div className="borderline"></div>

                  <div className="languages">
                    <h4 className="sub-heading">LANGUAGES</h4>
                    <ul>
                      {userData.languages ? userData.languages.map((language, index) => (
                        <li>
                          <p className="para">{language}</p>
                        </li>
                      ))
                        :
                        <li>
                          <p className="para">Language1</p>
                        </li>}
                      {/* <li>
      <p className="para">Language1</p>
    </li>
    <li>
      <p className="para">Language2</p>
    </li> }
                    </ul>
                  </div>
                </>
                  :
                  ""

              } */}

      {
        sections.reference ? <>
          <div className="borderline"></div>

          <div className="reference">
            <h4 className="sub-heading">REFERENCE</h4>
            {userData.reference ? userData.reference.map((reference, index) => (
              <>
                <div className="head">
                  <h3 className="para bold-para">{reference.referenceCompany}</h3>
                  <p className="para ">{reference.contact1}</p>
                </div>
                <div className="head">
                  <h3 className="para ">{reference.personName}</h3>
                  <p className="para ">{reference.contact2}</p>
                </div>

                <p>
                  {parse(
                    reference?.description
                      ? reference?.description
                      : "Your Reference Description"
                  )}
                </p>

              </>
            ))
              :
              <>
                <div className="head">
                  <h3 className="para bold-para">Company Name</h3>
                  <p className="para ">Contact1</p>
                </div>
                <div className="head">
                  <h3 className="para ">Person Name</h3>
                  <p className="para ">Contact2</p>
                </div>
                <ul>
                  <li>
                    <p className="para">
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nostrum,
                      ex?
                    </p>
                  </li>
                </ul>
              </>}
            {/* <div className="head">
    <h3 className="para bold-para">Company Name</h3>
    <p className="para ">Contact1</p>
  </div>
  <div className="head">
    <h3 className="para ">Person Name</h3>
    <p className="para ">Contact2</p>
  </div>
  <ul>
    <li>
      <p className="para">
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nostrum,
        ex?
      </p>
    </li>
  </ul> */}
          </div>
        </>
          :
          ""

      }

    </section>
    //       </TransformComponent>
    //     </React.Fragment>
    //   )}
    // </TransformWrapper>
  );
}

export default Template7;
