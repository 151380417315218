import React, { useEffect, useRef, useState } from "react";
import "../../style/main.scss";
import FillIn from "./fillIn";
import Button from "../shared/button";
import Design from "./design";
import ToolBox from "./toolBox";
import ProofReading from "./proofReading";
import Download from "./download";
import Share from "./share";
import template1 from "../../assets/images/tempelates/1.png";
import Template1 from "../template/template1";
import { Icon } from "@iconify/react";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";
import { auth, firestoredb, storage } from "../../config";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { updateProfile } from "firebase/auth";

import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

import TemplateN1 from "../template1/Template1";
import Templete2 from "../templete2/Templete2";
import Template3 from "../template3/Template3";
import Template4 from "../template4/Template4";
import Template5 from "../template5/Template5";
import Template6 from "../template6/template6";
import Template7 from "../template7/template7";

import { useReactToPrint } from "react-to-print";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Template8 from "../template8/template8";
import Template9 from "../template9/template9";
import Template10 from "../template10/template10";
import Template11 from "../template11/template11";
import Template12 from "../template12/template12";
import Template13 from "../template13/template13";
import Template14 from "../template14/templateforteen";
import Template15 from "../template15/template15";
import Template16 from "../template16/template16";
import Template17 from "../template17/template17";
import Template18 from "../template18/template18";
import Template19 from "../template19/template19";
import Template20 from "../template20/template20";
import Template21 from "../template21/template21";
import Template22 from "../template22/template22";
import Template23 from "../template23/template23";
import Template24 from "../template24/template24";
import Template25 from "../template25/template25";
import Template26 from "../template26/template26";
import { toast } from "react-toastify";
import Reviewrequests from "./reviewrequests";
import Template27 from "../template27/template27";
import Template28 from "../template28/template28";
import Template29 from "../template29/template29";
import Template30 from "../template30/template30";
import { adminUsers } from "../../adminUsers";

const resumeStyle = {
  width: "595px",
  height: "842px",
  margin: "0 auto",
  backgroundColor: "#f2f2f2",
  fontFamily: "Arial, sans-serif",
};

const headerStyle = {
  backgroundColor: "#333",
  color: "#fff",
  textAlign: "center",
  padding: "20px",
  fontFamily: "Arial, sans-serif",
};

const contentStyle = {
  padding: "20px",
  fontFamily: "Arial, sans-serif",
  color: "#333",
};
const Dashboard = ({
  userData,
  setUserData,
  generatedFromCv,
  isFetched,
  setIsFetched,
  comeFromWelcome,
  first,
  setFirst,
  isPaidUser,
}) => {
  const [selectTab, setSelectTab] = useState("Fill In");
  const [sideMenu, setSideMenu] = useState(false);
  // const [userData, setUserData] = useState({});
  const [sections, setSections] = useState({
    strengths: true,
    experience: true,
    projects: true,
    skills: true,
  });
  const [totalPages, setTotalPages] = useState("1");
  const [isSectionChanged, setIsSectionChanged] = useState(false);
  const [downloadLimitExceed, setDownloadLimitExceed] = useState(false)

  const handleDownloadLimitExceedModal = () => {
    setDownloadLimitExceed(prev => !prev)
  }
  useEffect(() => {
    setSections({
      skills: userData.skills ? true : false,
      strengths: userData.strengths ? false : false,
      experience: userData.experience ? true : false,
      projects: userData.projects ? false : false,
      volunteer: userData.volunteer ? true : false,
      education: userData.education ? true : false,
      certificates: userData.certificates ? true : false,
      awards: userData.awards ? true : false,
      publications: userData.publications ? true : false,
      interests: userData.interests ? true : false,
      languages: userData.languages ? true : false,
      reference: userData.reference ? true : false,
    });
    if (first) {
      if (userData.selectedTemplate) {
        setTemplate(userData.selectedTemplate);
        setFirst(false);
      }
      if (userData.templateColor) {
        setColor(userData.templateColor);
        // // console.log("Here in color");
        setFirst(false);
      }
      if (userData.templateFont) {
        setFont(userData.templateFont);
        setFirst(false);
      }
      if (userData.templateLineHeight) {
        setLineHeight(userData.templateLineHeight);
        setFirst(false);
      }
      if (userData.templateFontSize) {
        setFontSize(userData.templateFontSize);
        setFirst(false);
      }
    }
  }, [isFetched]);

  useEffect(() => {
    if (location.state) {
      if (location.state.checkForCustomization) {
        if (userData.selectedTemplate) {
          setTemplate(userData.selectedTemplate);
          setFirst(false);
        }
        if (userData.templateColor) {
          setColor(userData.templateColor);
          // // console.log("Here in color");
          setFirst(false);
        }
        if (userData.templateFont) {
          setFont(userData.templateFont);
          setFirst(false);
        }
        if (userData.templateLineHeight) {
          setLineHeight(userData.templateLineHeight);
          setFirst(false);
        }
        if (userData.templateFontSize) {
          setFontSize(userData.templateFontSize);
          setFirst(false);
        }
      }
    }

  })

  useEffect(() => {
    if (user) {
      if (adminUsers.includes(user?.email)) {
        setSections({
          skills: userData.skills ? true : false,
          strengths: userData.strengths ? false : false,
          experience: userData.experience ? true : false,
          projects: userData.projects ? false : false,
          volunteer: userData.volunteer ? true : false,
          education: userData.education ? true : false,
          certificates: userData.certificates ? true : false,
          awards: userData.awards ? true : false,
          publications: userData.publications ? true : false,
          interests: userData.interests ? true : false,
          languages: userData.languages ? true : false,
          reference: userData.reference ? true : false,
        });
      }

    }
  }, [userData]);

  useEffect(() => {
    setSections({
      skills: userData.skills ? true : false,
      strengths: userData.strengths ? false : false,
      experience: userData.experience ? true : false,
      projects: userData.projects ? false : false,
      volunteer: userData.volunteer ? true : false,
      education: userData.education ? true : false,
      certificates: userData.certificates ? true : false,
      awards: userData.awards ? true : false,
      publications: userData.publications ? true : false,
      interests: userData.interests ? true : false,
      languages: userData.languages ? true : false,
      reference: userData.reference ? true : false,
    });
  }, [isSectionChanged]);

  const user = auth.currentUser;
  const resumeRef = useRef();
  const newRef = useRef();
  const [color, setColor] = useState("");
  const [font, setFont] = useState("poppinsFont");
  const [lineHeight, setLineHeight] = useState("1.4");
  const [template, setTemplate] = useState(0);
  const [iconData, setIconData] = useState();
  const [cv, setCv] = useState("");
  const [loading, setLoading] = useState("");
  const [fontSize, setFontSize] = useState(20);
  const [linkedinModelShow, setLinkedinModelShow] = useState(false);
  const [linkedinUrl, setLinkedinUrl] = useState("");

  useEffect(() => {
    setTemplate(userData.selectedTemplate ? userData.selectedTemplate : 0);
  }, []);
  const location = useLocation();
  useEffect(() => {
    // // console.log(userData);
    // // console.log(sections);
    if (user) {
      // // console.log("User", user);
      // // console.log("User", user.displayName);
      // // console.log("User", user.email);
      // // console.log("User", user.phoneNumber);
      // // console.log(location);
      if (location.state) {
        if (location.state.comeFromWelcome) {
          setSelectTab("ProofReading");
        }
      }
    }
  }, [userData, sections]);

  const toggleMenu = () => {
    setSideMenu(!sideMenu);
  };
  const handleTab = (tag) => {
    setSelectTab(tag);
  };

  const handlePrint = useReactToPrint({
    content: () => resumeRef.current,
  });

  const handlePrintCustom = async () => {
    if (isPaidUser) {
      handlePrint();
    }
    else {
      const docRef = doc(firestoredb, "users", auth.currentUser.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        if (!docSnap.data().downloadCount) {
          handlePrint();
          updateDoc(docRef, {
            downloadCount: 1
          })
        }
        else if (docSnap.data().downloadCount < 2) {
          handlePrint();
          updateDoc(docRef, {
            downloadCount: Number(docSnap.data().downloadCount) + 1
          })
        }
        else {
          // toast("You have exceeded the number of free downloads. Please upgrade your account to Pro Plan.")
          setDownloadLimitExceed(true);
        }
      }
    }
  }

  useEffect(() => {
    if (user) {
      if (adminUsers.includes(user?.email)) {
        handleTab("admin");
      }
    }
  }, [user]);

  useEffect(() => {
    // Define a function to update the sideMenu state based on window width
    const handleWindowResize = () => {
      if (window.innerWidth > 1400) {
        setSideMenu(true);
      } else {
        setSideMenu(false);
      }
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleWindowResize);

    // Initial setup on component mount
    handleWindowResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const navigate = useNavigate();

  onAuthStateChanged(auth, (user) => {
    if (!user) {
      navigate("/login");
    }
  });

  const handleDbSave = async () => {
    // // console.log("callFromReview", callFromReview);
    // // console.log("User ", user.uid);
    const currentUserRef = doc(firestoredb, "users", user.uid);
    // setUserData((prev) => ({ ...prev, cvPicture: "" }));
    setLoading(true);

    try {
      //fetching previous data first
      if (user) {
        // // console.log("Fetching Data from DB");
        const docRef = doc(firestoredb, "users", user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          // // console.log("Document data gs:", docSnap.data());
          if (docSnap.data().cvDataHistory) {
            // setUserData({ ...docSnap.data().cvData, skills: docSnap.data().cvData.skills ? docSnap.data().cvData.skills : [], strengths: docSnap.data().cvData.strengths ? docSnap.data().cvData.strengths : [] })
            let cvHistory = docSnap.data().cvDataHistory;
            let timeStamp = new Date();
            timeStamp = String(timeStamp);
            timeStamp = timeStamp.split(" GMT");
            timeStamp = timeStamp[0];
            let urlDb =
              userData.cvPicture != undefined ? userData.cvPicture : "";

            if (userData.cvPictureObj) {
              const storageRef = ref(
                storage,
                `/cvdata/${user.uid}/${timeStamp}`
              );

              // 'file' comes from the Blob or File API
              await uploadBytes(storageRef, userData.cvPictureObj).then(
                (snapshot) => {
                  // // console.log('Uploaded a blob or file!');
                  getDownloadURL(snapshot.ref).then(async (url) => {
                    // setUserData((prev) => ({ ...prev, pfp: url }))
                    // // console.log("URL", url)
                    urlDb = url;
                    await updateDoc(currentUserRef, {
                      cvDataHistory: [
                        ...cvHistory,
                        {
                          ...userData,
                          time: timeStamp,
                          selectedTemplate: template,
                          templateColor: color,
                          templateLineHeight: lineHeight,
                          templateFont: font,
                          templateFontSize: fontSize,
                          cvPicture: url,
                          cvPictureObj: "",
                        },
                      ],
                    });
                    // // console.log("Success CV Data History");

                    await updateDoc(currentUserRef, {
                      cvData: {
                        ...userData,
                        time: timeStamp,
                        selectedTemplate: template,
                        templateColor: color,
                        templateLineHeight: lineHeight,
                        templateFont: font,
                        templateFontSize: fontSize,
                        cvPicture: url,
                        cvPictureObj: "",
                      },
                    });
                    // // console.log("Success CV Data");
                    toast("Data Successfully save to Database");
                  });
                }
              );

              // await updateProfile(auth.currentUser, {
              //   photoURL: userData.pfp
              // }).then(() => {
              //   // console.log("Image uploaded")
              // }).catch((error) => {
              //   // console.log("Picture Uploading Error", error);
              // });
            } else {
              // // console.log("to make sure", urlDb);
              await updateDoc(currentUserRef, {
                cvDataHistory: [
                  ...cvHistory,
                  {
                    ...userData,
                    time: timeStamp,
                    selectedTemplate: template,
                    templateColor: color,
                    templateLineHeight: lineHeight,
                    templateFont: font,
                    templateFontSize: fontSize,
                    cvPicture: urlDb,
                    cvPictureObj: "",
                  },
                ],
              });
              // // console.log("Success CV Data History");

              await updateDoc(currentUserRef, {
                cvData: {
                  ...userData,
                  time: timeStamp,
                  selectedTemplate: template,
                  templateColor: color,
                  templateLineHeight: lineHeight,
                  templateFont: font,
                  templateFontSize: fontSize,
                  cvPicture: urlDb,
                  cvPictureObj: "",
                },
              });
              // // console.log("Success CV Data");
              toast("Data Successfully save to Database");
            }
          } else {
            let timeStamp = new Date();
            timeStamp = String(timeStamp);
            timeStamp = timeStamp.split(" GMT");
            timeStamp = timeStamp[0];

            let urlDb =
              userData.cvPicture != undefined ? userData.cvPicture : "";

            if (userData.cvPictureObj) {
              const storageRef = ref(
                storage,
                `/cvdata/${user.uid}/${timeStamp}`
              );

              // 'file' comes from the Blob or File API
              await uploadBytes(storageRef, userData.cvPictureObj).then(
                (snapshot) => {
                  // // console.log('Uploaded a blob or file!');
                  getDownloadURL(snapshot.ref).then(async (url) => {
                    // setUserData((prev) => ({ ...prev, pfp: url }))
                    // // console.log("URL", url)
                    urlDb = url;
                    // // console.log("to make sure", urlDb)
                    await updateDoc(currentUserRef, {
                      cvDataHistory: [
                        {
                          ...userData,
                          time: timeStamp,
                          selectedTemplate: template,
                          templateColor: color,
                          templateLineHeight: lineHeight,
                          templateFont: font,
                          templateFontSize: fontSize,
                          cvPicture: url,
                          cvPictureObj: "",
                        },
                      ],
                    });
                    // // console.log("Success Data Hostory");
                    await updateDoc(currentUserRef, {
                      cvData: {
                        ...userData,
                        time: timeStamp,
                        selectedTemplate: template,
                        templateColor: color,
                        templateLineHeight: lineHeight,
                        templateFont: font,
                        templateFontSize: fontSize,
                        cvPicture: url,
                        cvPictureObj: "",
                      },
                    });
                    // // console.log("Success CV Data");
                    toast("Data Successfully save to Database");
                  });
                }
              );

              // await updateProfile(auth.currentUser, {
              //   photoURL: userData.pfp
              // }).then(() => {
              //   // console.log("Image uploaded")
              // }).catch((error) => {
              //   // console.log("Picture Uploading Error", error);
              // });
            } else {
              // // console.log("to make sure", urlDb)
              await updateDoc(currentUserRef, {
                cvDataHistory: [
                  {
                    ...userData,
                    time: timeStamp,
                    selectedTemplate: template,
                    templateColor: color,
                    templateLineHeight: lineHeight,
                    templateFont: font,
                    templateFontSize: fontSize,
                    cvPicture: urlDb,
                    cvPictureObj: "",
                  },
                ],
              });
              // // console.log("Success Data Hostory");
              await updateDoc(currentUserRef, {
                cvData: {
                  ...userData,
                  time: timeStamp,
                  selectedTemplate: template,
                  templateColor: color,
                  templateLineHeight: lineHeight,
                  templateFont: font,
                  templateFontSize: fontSize,
                  cvPicture: urlDb,
                  cvPictureObj: "",
                },
              });
              // // console.log("Success CV Data");
              toast("Data Successfully save to Database");
            }

            // // console.log("to make sure", urlDb)
            // await updateDoc(currentUserRef, {
            //   cvDataHistory: [{ ...userData, time: timeStamp, selectedTemplate: template, templateColor: color, templateLineHeight: lineHeight, templateFont: font, templateFontSize: fontSize, cvPicture: urlDb, cvPictureObj: "" }],
            // });
            // // console.log("Success Data Hostory");
            // await updateDoc(currentUserRef, {
            //   cvData: { ...userData, time: timeStamp, selectedTemplate: template, templateColor: color, templateLineHeight: lineHeight, templateFont: font, templateFontSize: fontSize, cvPicture: urlDb, cvPictureObj: "" },
            // });
            // // console.log("Success CV Data");
            // toast("Data Successfully save to Database");
          }
        } else {
          // docSnap.data() will be undefined in this case
          // // console.log("No such document!");
        }
      }

      setLoading(false);
    } catch (e) {
      toast("Some Error Occured! Please try again");
      console.error("Error adding document: ", e);
      setLoading(false);
    }
  };

  // Do not uncomment this

  // useEffect(() => {
  //   // Fetch the list of Iconify icons
  //   axios.get('https://api.iconify.design/collection?prefix=mdi')
  //     .then((response) => {
  //       // console.log("res", response)
  //       // Extract icon names from the response
  //       // const icons = response.data.map((icon) => icon.name);
  //       // // console.log("icons", icons)
  //       setIconData(response.data.categories)
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching Iconify icons:', error);
  //     });
  // }, []);

  const handleCvGenerateButton = async () => {
    if (cv && cv.type != "application/pdf") {
      toast.error("Please select .pdf file!")
      setLoading(false);
    }
    else {
      setLoading(true);
      // setUserData({})
      const formData = new FormData();
      formData.append("cv", cv);
      try {
        const tokenId = await auth.currentUser.getIdToken();
        const config = {
          headers: { Authorization: `Bearer ${tokenId}` }
        };
        // setGeneratedFromCv(true);
        // const result = await axios.post("https://octaloop-resume-ai-50bd4465d773.herokuapp.com/pdfResume", formData);
        // const result = await axios.post("http://localhost:8080/pdfResume", formData);
        const result = await axios.post(
          "https://resume-ai-nodejs.onrender.com/pdfResume",
          formData, config
        );
        // // console.log("result", result.data);
        if (!result.data.message) {
          setUserData({});

          setUserData(result.data);
          setIsSectionChanged(!isSectionChanged);
          setIsFetched(!setIsFetched);


        }
        else {
          toast.error("Oops, some error occured, Please try again")
        }
        // navigate("/dashboard")
        setLoading(false);
        // downloadPng();
      } catch (e) {
        // setGeneratedFromCv(false)

        // console.error(e.message);
        toast.error("Oops, some error occured, Please try again")

        setLoading(false);
      }
    }

  };
  const handleCvGenerateButtonLimit = async () => {
    if (isPaidUser) {
      handleCvGenerateButton();
    }
    else {
      const docRef = doc(firestoredb, "users", auth.currentUser.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        if (!docSnap.data().revampCount) {
          handleCvGenerateButton();
          updateDoc(docRef, {
            revampCount: 1
          })
        }
        else if (docSnap.data().revampCount < 1) {
          handleCvGenerateButton();
          updateDoc(docRef, {
            revampCount: Number(docSnap.data().revampCount) + 1
          })
        }
        else {
          // toast("You have exceeded the number of free downloads. Please upgrade your account to Pro Plan.")
          setDownloadLimitExceed(true);
        }
      }
    }
  }
  useEffect(() => {
    if (cv !== "") {
      handleCvGenerateButtonLimit();
    }
  }, [cv]);

  useEffect(() => {
    // // console.log("Height of Div", resumeRef.current.offsetHeight);
    setTotalPages(Math.ceil(resumeRef.current.offsetHeight / 1113));
    // setTotalPages("1");
  }, [userData, font, lineHeight, fontSize, template, isFetched]);
  const handleLinkedinModelShow = () => {
    if (!loading) {
      setLinkedinModelShow(!linkedinModelShow);
    }
  };

  const monthInWords = (month) => {
    if (month == 1) {
      return "January";
    } else if (month == 2) {
      return "February";
    } else if (month == 3) {
      return "March";
    } else if (month == 4) {
      return "April";
    } else if (month == 5) {
      return "May";
    } else if (month == 6) {
      return "June";
    } else if (month == 7) {
      return "July";
    } else if (month == 8) {
      return "August";
    } else if (month == 9) {
      return "September";
    } else if (month == 10) {
      return "October";
    } else if (month == 11) {
      return "November";
    } else if (month == 12) {
      return "December";
    } else {
      return null;
    }
  };

  const handleGenerateFromLinkedin = async () => {
    if (linkedinUrl != "") {
      setLoading(true);
      // setUserData({})
      const formData = new FormData();
      formData.append("cv", cv);

      const options = {
        method: "GET",
        url: "https://linkedin-profile-data-api.p.rapidapi.com/",
        params: {
          username: `${linkedinUrl}`,
        },
        headers: {
          'X-RapidAPI-Key': '320f203981msh51cd0eed9f015c1p16ce88jsn72ee9cf5a96c',
          'X-RapidAPI-Host': 'linkedin-profile-data-api.p.rapidapi.com'
        },
      };

      try {
        setLinkedinModelShow(false);
        const result = await axios.request(options);
        // // console.log("lets see", result.data);
        let experiences;
        if (Array.isArray(result.data.position)) {
          experiences = result.data.position.map((experience) => {
            return {
              companyName: `${experience?.companyName
                ? experience?.companyName
                : "Your Company Name"
                }`,
              jobTitle: `${experience?.title ? experience?.title : ""}`,
              description: `${experience?.description ? experience?.description : ""
                }`,
              startMonth: `${experience?.start?.month
                ? monthInWords(experience?.start?.month)
                  ? monthInWords(experience?.start?.month)
                  : experience?.start?.month
                : ""
                }`,
              startYear: `${experience?.starts?.year ? experience?.starts?.year : ""
                }`,
              endMonth: `${experience?.end?.month
                ? monthInWords(experience?.end?.month)
                  ? monthInWords(experience?.end?.month)
                  : experience?.end?.month
                : ""
                }`,
              endYear: `${experience?.end?.year ? experience?.end?.year : ""}`,
              cityName: `${experience?.location ? experience?.location : ""}`,
              country: `${experience?.location ? experience?.location : ""}`,
            };
          });
        }
        let educations;
        if (Array.isArray(result.data.educations)) {
          educations = result.data.educations.map((education) => {
            return {
              schoolName: `${education?.schoolName ? education?.schoolName : "Your School"
                }`,
              degreeName: `${education?.degree ? education?.degree : ""}`,
              description: `${education?.fieldOfStudy ? education?.fieldOfStudy : ""
                }`,
              startMonth: `${education?.start?.month
                ? monthInWords(education?.start?.month)
                  ? monthInWords(education?.start?.month)
                  : education?.start?.month
                : ""
                }`,
              startYear: `${education?.start?.year ? education?.start?.year : ""
                }`,
              endMonth: `${education?.end?.month
                ? monthInWords(education?.end?.month)
                  ? monthInWords(education?.end?.month)
                  : education?.end?.month
                : ""
                }`,
              endYear: `${education?.end?.year ? education?.end?.year : ""}`,
              cityName: `${education?.location ? education?.location : ""}`,
              country: `${education?.location ? education?.location : ""}`,
            };
          });
        }
        let skillsExtracted = [];
        if (Array.isArray(result.data.skills)) {
          let skills = result.data.skills.map((skill) => {
            skillsExtracted.push(skill.name);
          });
        }

        setUserData({});

        setUserData({
          fName: result.data.firstName,
          lName: result.data.lastName,
          summary: result.data.summary,
          experience: experiences,
          role: experiences[0] ? experiences[0].jobTitle : "",
          skills: skillsExtracted,
          education: educations,
          country: result.data.geo.country,
          city: result.data.geo.city,
          web: "linkedin.com/in/" + linkedinUrl,
          cvPicture: result.data.profilePicture,
        });
        // navigate("/dashboard")
        setIsSectionChanged(!isSectionChanged);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }

      // try {
      //   setGeneratedFromCv(true);
      //   // const result = await axios.post("https://octaloop-resume-ai-50bd4465d773.herokuapp.com/pdfResume", formData);
      //   // const result = await axios.post("http://localhost:8080/pdfResume", formData);
      //   const result = await axios.get("https://cors-anywhere.herokuapp.com/https://nubela.co/proxycurl/api/v2/linkedin", {
      //     params: { url: "https://www.linkedin.com/in/" + linkedinUrl },
      //     headers: {
      //       Authorization: "Bearer lMh91tIR7vuzmFx9HOprlg",
      //     }
      //   });
      //   // console.log(result.data);
      //   let experiences = result.data.experiences.map((experience) => {
      //     return ({
      //       companyName: experience?.company,
      //       jobTitle: experience?.title,
      //       description: experience?.description,
      //       startMonth: experience?.starts_at?.month,
      //       startYear: experience?.starts_at?.year,
      //       endMonth: experience?.ends_at?.month,
      //       endYear: experience?.ends_at?.year
      //     })
      //   })

      //   let educations = result.data.education.map((education) => {
      //     return ({
      //       schoolName: education?.school,
      //       degreeName: education?.title,
      //       description: education?.description,
      //       startMonth: education?.starts_at?.month,
      //       startYear: education?.starts_at?.year,
      //       endMonth: education?.ends_at?.month,
      //       endYear: education?.ends_at?.year
      //     })
      //   })

      //   setUserData({
      //     fName: result.data.first_name,
      //     lName: result.data.last_name,
      //     summary: result.data.summary,
      //     experience: experiences,
      //     role: result.data.experiences[0] ? result.data.experiences[0].title : "",
      //     skills: result.data.skills,
      //     education: educations,
      //     country: result.data.country_full_name,
      //     city: result.data.city,
      //     web: "linkedin.com/in/" + result.data.public_identifier
      //   });
      //   navigate("/dashboard")
      //   setLoading(false);
      //   // downloadPng();
      // } catch (e) {
      //   setGeneratedFromCv(false)
      //   console.error(e.message)
      //   setLoading(false);
      // }
    } else {
      alert("Please enter Linkedin Url.");
    }
  };

  return (
    <div>
      <div className="dashboard d-flex">
        <div className="side-menu-btn" onClick={toggleMenu}>
          <Icon
            icon={sideMenu ? "ep:close-bold" : "ion:menu"}
            color="black"
            width="48"
            height="48"
          />
        </div>
        {sideMenu ? (
          <div
            className="left-new d-flex flex-column v-center"
            style={{ width: "17%" }}
          >
            <div className="list d-flex flex-column justify-content-flex-start">
              {user ? (
                !(adminUsers.includes(user?.email)) ? (
                  <>
                    <p
                      role="button"
                      className={`${selectTab === "Fill In" ? "p5" : ""
                        } sideMenuBtn`}
                      onClick={() => handleTab("Fill In")}
                    >
                      Create Resume
                    </p>
                    <label htmlFor="revamp">
                      <p
                        role="button"
                        className={`sideMenuBtn`}
                      // onClick={() => handleTab("Revamp")}
                      >
                        Revamp Resume
                      </p>
                    </label>
                    <input
                      id="revamp"
                      type="file"
                      accept="application/pdf"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        // setLoading(true);
                        setCv(e.target.files[0]);
                      }}
                      onBlur={() => {
                        // // console.log("cancel");
                      }}
                    ></input>

                    <p
                      role="button"
                      className="sideMenuBtn"
                      onClick={() => navigate("/fix")}
                    >
                      Analyze and Fix resume
                    </p>

                    <p
                      role="button"
                      className={`${selectTab === "Design" ? "p5" : ""
                        } sideMenuBtn`}
                      onClick={() => handleTab("Design")}
                    >
                      Design
                    </p>
                    <p
                      role="button"
                      className={`${selectTab === "Toolbox" ? "p5" : ""
                        } sideMenuBtn`}
                      onClick={() => handleTab("Toolbox")}
                    >
                      AI Toolbox
                    </p>
                    <p
                      role="button"
                      className={`${selectTab === "ProofReading" ? "p5" : ""
                        } sideMenuBtn`}
                      onClick={() => handleTab("ProofReading")}
                    >
                      Proofreading
                    </p>
                    <p
                      role="button"
                      className={`${selectTab === "Download" ? "p5" : ""
                        } sideMenuBtn`}
                      onClick={() => handleTab("Download")}
                    >
                      Download
                    </p>
                  </>
                ) : null
              ) : null}
              {user ? (
                adminUsers.includes(user?.email) ? (
                  <p
                    role="button"
                    className={`${selectTab === "admin" ? "p5" : ""
                      } sideMenuBtn`}
                    onClick={() => handleTab("admin")}
                  >
                    Requested Reviews
                  </p>
                ) : (
                  ""
                )
              ) : (
                ""
              )}

              {/* <p
                role="button"
                className={`${selectTab === "Share" ? "p5" : ""} sideMenuBtn`}
                onClick={() => handleTab("Share")}
              >
                Share
              </p> */}
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="center">
          <div
            className="title"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "5px 0",
            }}
          >
            {user ? (
              adminUsers.includes(user?.email) ? (
                <h2
                  className="text-black"
                  style={{ margin: "0", fontSize: "26px" }}
                >{`Hello Admin`}</h2>
              ) : (
                <h2 className="text-black" style={{ margin: "0" }}>{`TITLE: ${userData?.fName ? `${userData?.fName}'s` : "Your"
                  } resume`}</h2>
              )
            ) : null}
          </div>

          {selectTab === "Fill In" ? (
            <div style={{ position: "relative" }}>
              <FillIn
                userData={userData}
                setUserData={setUserData}
                sections={sections}
                setSections={setSections}
                isFetched={isFetched}
                template={template}
                isPaidUser={isPaidUser}
              />

              <button
                style={{
                  backgroundColor: "#AC50C6",
                  marginTop: "10px",
                  padding: "5px 26px",
                  borderRadius: "5px",
                  position: "absolute",
                  right: "0px",
                }}
                onClick={handleDbSave}
              >
                Save
              </button>
            </div>
          ) : (
            <></>
          )}

          {selectTab === "Design" ? (
            <Design
              setColor={setColor}
              color={color}
              template={template}
              setTemplate={setTemplate}
              font={font}
              setFont={setFont}
              fontSize={fontSize}
              setFontSize={setFontSize}
              lineHeight={lineHeight}
              setLineHeight={setLineHeight}
              setUserData={setUserData}
              setIs
            />
          ) : (
            <></>
          )}
          {selectTab === "Toolbox" ? <ToolBox userData={userData} isPaidUser={isPaidUser} /> : <></>}
          {selectTab === "ProofReading" ? (
            <ProofReading
              userData={userData}
              handleDbSave={handleDbSave}
              isPaidUser={isPaidUser}
            />
          ) : (
            <></>
          )}
          {selectTab === "Download" ? (
            <Download
              resumeRef={resumeRef}
              userData={userData}
              isPaidUser={isPaidUser}
            />
          ) : (
            <></>
          )}
          {selectTab === "Share" ? <Share /> : <></>}
          {selectTab === "admin" ? (
            <Reviewrequests
              userData={userData}
              handleDbSave={handleDbSave}
              setUserData={setUserData}
            />
          ) : (
            <></>
          )}

          {/* {design["Fillin"] ? (
            <>
              <FillIn />
            </>
          ) : design["Design"] ? (
            <>
              <Design />
            </>
          ) : design["Toolbox"] ? (
            <>
              <ToolBox />
            </>
          ) : design["Proofreading"] ? (
            <>
              <ProofReading />
            </>
          ) : design["Download"] ? (
            <>
              <Download />
            </>
          ) : design["Share"] ? (
            <>
              <Share />
            </>
          ) : (
            <></>
          )} */}
          {/* 
          
          Do not uncomment this
          
          <div style={{ marginTop: "100px", width: "100%", height: "200px", overflow: "auto", border: "1px solid black", display: "flex", flexWrap: "wrap", alignContent: "flex-start" }}>
            {iconData ? Object.keys(iconData).map((objKey) => (
              iconData[objKey].map((iconName) => (
                <Icon
                  icon={`mdi:${iconName}`}
                  color="black"
                  width="30"
                  height="30"
                />
              ))
            )) : ""} */}

          {/* {iconData ? iconData.map((iconC) => {
              return (
                iconC.map((iconName) => {
                  <Icon
                    icon={`mdi:${iconName}`}
                    color="black"
                    width="30"
                    height="30"
                  />
                })
              )
            }) : ""} 



          </div>*/}
        </div>
        <div className="right-new">
          <h5 style={{ color: "black" }}>{`${totalPages} Page(s)`}</h5>
          {/* <div className="right-btn h-center">
            <Button
              text="Preview"
              className="btn-prime-1 btn-primary text-white rounded  "
            />
            <Button
              text="Examples"
              className="btn-prime-1 btn-primary text-white rounded  "
            />
            <Button text="MY AI" className=" text-white rounded  " />
          </div> */}

          {/* template == 0 ? (
            <div className="resume-preview">
              <div className="resume-preview-content">
                {/* <img src={template1} alt="as" className="w-100 h-100" /> }

                <Template1
                  userData={userData}
                  sections={sections}
                  color={color}
                  setColor={setColor}
                  font={font}
                  lineHeight={lineHeight}
                  forDownload={resumeRef}
                />
                {/* <Template11 forDownload={resumeRef}></Template11> }
              </div>
            </div>
          ) : */}
          {
            !adminUsers.includes(user?.email) ?
              <div className="button-sec">
                <button className="ist" onClick={handleLinkedinModelShow}>
                  Upload from linkedIn
                </button>
                <button className="second" onClick={() => navigate("/templates")}>
                  Templates
                </button>
                <button className="third" onClick={() => setSelectTab("Design")}>
                  Design
                </button>
                <button className="forth">
                  <Icon
                    icon="material-symbols:download"
                    color="black"
                    width="20"
                    height="20"
                    onClick={handlePrintCustom}
                  />
                </button>
                <button className="fifth">
                  <Icon
                    icon="fluent:share-28-filled"
                    color="black"
                    width="20"
                    height="20"
                    onClick={() => setSelectTab("Download")}
                  />
                </button>
              </div>
              :
              ""}
          {template == 0 ? (
            <div className="resume-preview">
              <div className="resume-preview-content">
                {/* <img src={template1} alt="as" className="w-100 h-100" /> */}

                <Template6
                  userData={userData}
                  sections={sections}
                  setSections={setSections}
                  color={color}
                  setColor={setColor}
                  forDownload={resumeRef}
                  lineHeight={lineHeight}
                  font={font}
                  setFont={setFont}
                  fontSize={fontSize}
                  setFontSize={setFontSize}
                ></Template6>
              </div>
            </div>
          ) : template == 1 ? (
            <div className="resume-preview">
              <div className="resume-preview-content">
                {/* <img src={template1} alt="as" className="w-100 h-100" /> */}

                <Template7
                  userData={userData}
                  sections={sections}
                  color={color}
                  setColor={setColor}
                  forDownload={resumeRef}
                  lineHeight={lineHeight}
                  font={font}
                  setFont={setFont}
                  fontSize={fontSize}
                  setFontSize={setFontSize}
                  setLineHeight={setLineHeight}
                ></Template7>
              </div>
            </div>
          ) : template == 2 ? (
            <div className="resume-preview">
              <div className="resume-preview-content">
                {/* <img src={template1} alt="as" className="w-100 h-100" /> */}

                <Template8
                  userData={userData}
                  sections={sections}
                  color={color}
                  setColor={setColor}
                  forDownload={resumeRef}
                  lineHeight={lineHeight}
                  font={font}
                  setFont={setFont}
                  fontSize={fontSize}
                  setFontSize={setFontSize}
                  setLineHeight={setLineHeight}
                ></Template8>
              </div>
            </div>
          ) : template == 3 ? (
            <div className="resume-preview">
              <div className="resume-preview-content">
                {/* <img src={template1} alt="as" className="w-100 h-100" /> */}

                <Template9
                  userData={userData}
                  sections={sections}
                  color={color}
                  setColor={setColor}
                  forDownload={resumeRef}
                  lineHeight={lineHeight}
                  font={font}
                  setFont={setFont}
                  fontSize={fontSize}
                  setFontSize={setFontSize}
                  setLineHeight={setLineHeight}
                ></Template9>
              </div>
            </div>
          ) : template == 4 ? (
            <div className="resume-preview">
              <div className="resume-preview-content">
                {/* <img src={template1} alt="as" className="w-100 h-100" /> */}

                <Template10
                  userData={userData}
                  sections={sections}
                  color={color}
                  setColor={setColor}
                  forDownload={resumeRef}
                  lineHeight={lineHeight}
                  font={font}
                  setFont={setFont}
                  te
                  fontSize={fontSize}
                  setFontSize={setFontSize}
                  setLineHeight={setLineHeight}
                ></Template10>
              </div>
            </div>
          ) : template == 5 ? (
            <div className="resume-preview">
              <div className="resume-preview-content">
                {/* <img src={template1} alt="as" className="w-100 h-100" /> */}

                <Template11
                  userData={userData}
                  sections={sections}
                  color={color}
                  setColor={setColor}
                  forDownload={resumeRef}
                  lineHeight={lineHeight}
                  font={font}
                  setFont={setFont}
                  fontSize={fontSize}
                  setFontSize={setFontSize}
                  setLineHeight={setLineHeight}
                ></Template11>
              </div>
            </div>
          ) : template == 6 ? (
            <div className="resume-preview">
              <div className="resume-preview-content">
                {/* <img src={template1} alt="as" className="w-100 h-100" /> */}

                <Template12
                  userData={userData}
                  sections={sections}
                  color={color}
                  setColor={setColor}
                  forDownload={resumeRef}
                  lineHeight={lineHeight}
                  font={font}
                  setFont={setFont}
                  fontSize={fontSize}
                  setFontSize={setFontSize}
                  setLineHeight={setLineHeight}
                ></Template12>
              </div>
            </div>
          ) : template == 7 ? (
            <div className="resume-preview">
              <div className="resume-preview-content">
                {/* <img src={template1} alt="as" className="w-100 h-100" /> */}

                <Template13
                  userData={userData}
                  sections={sections}
                  color={color}
                  setColor={setColor}
                  forDownload={resumeRef}
                  lineHeight={lineHeight}
                  font={font}
                  setFont={setFont}
                  fontSize={fontSize}
                  setFontSize={setFontSize}
                  setLineHeight={setLineHeight}
                ></Template13>
              </div>
            </div>
          ) : template == 8 ? (
            <div className="resume-preview">
              <div className="resume-preview-content">
                {/* <img src={template1} alt="as" className="w-100 h-100" /> */}

                <Template14
                  userData={userData}
                  sections={sections}
                  color={color}
                  setColor={setColor}
                  forDownload={resumeRef}
                  lineHeight={lineHeight}
                  font={font}
                  setFont={setFont}
                  fontSize={fontSize}
                  setFontSize={setFontSize}
                  setLineHeight={setLineHeight}
                ></Template14>
              </div>
            </div>
          ) : template == 9 ? (
            <div className="resume-preview">
              <div className="resume-preview-content">
                {/* <img src={template1} alt="as" className="w-100 h-100" /> */}

                <Template15
                  userData={userData}
                  sections={sections}
                  color={color}
                  setColor={setColor}
                  forDownload={resumeRef}
                  lineHeight={lineHeight}
                  font={font}
                  setFont={setFont}
                  fontSize={fontSize}
                  setFontSize={setFontSize}
                  setLineHeight={setLineHeight}
                ></Template15>
              </div>
            </div>
          ) : template == 10 ? (
            <div className="resume-preview">
              <div className="resume-preview-content">
                {/* <img src={template1} alt="as" className="w-100 h-100" /> */}

                <Template16
                  userData={userData}
                  sections={sections}
                  color={color}
                  setColor={setColor}
                  forDownload={resumeRef}
                  lineHeight={lineHeight}
                  font={font}
                  setFont={setFont}
                  fontSize={fontSize}
                  setFontSize={setFontSize}
                  setLineHeight={setLineHeight}
                ></Template16>
              </div>
            </div>
          ) : template == 11 ? (
            <div className="resume-preview">
              <div className="resume-preview-content">
                {/* <img src={template1} alt="as" className="w-100 h-100" /> */}

                <Template17
                  userData={userData}
                  sections={sections}
                  color={color}
                  setColor={setColor}
                  forDownload={resumeRef}
                  lineHeight={lineHeight}
                  font={font}
                  setFont={setFont}
                  fontSize={fontSize}
                  setFontSize={setFontSize}
                  setLineHeight={setLineHeight}
                ></Template17>
              </div>
            </div>
          ) : template == 12 ? (
            <div className="resume-preview">
              <div className="resume-preview-content">
                {/* <img src={template1} alt="as" className="w-100 h-100" /> */}

                <Template18
                  userData={userData}
                  sections={sections}
                  color={color}
                  setColor={setColor}
                  forDownload={resumeRef}
                  lineHeight={lineHeight}
                  font={font}
                  setFont={setFont}
                  fontSize={fontSize}
                  setFontSize={setFontSize}
                  setLineHeight={setLineHeight}
                ></Template18>
              </div>
            </div>
          ) : template == 13 ? (
            <div className="resume-preview">
              <div className="resume-preview-content">
                {/* <img src={template1} alt="as" className="w-100 h-100" /> */}

                <Template19
                  userData={userData}
                  sections={sections}
                  color={color}
                  setColor={setColor}
                  forDownload={resumeRef}
                  lineHeight={lineHeight}
                  font={font}
                  setFont={setFont}
                  fontSize={fontSize}
                  setFontSize={setFontSize}
                  setLineHeight={setLineHeight}
                ></Template19>
              </div>
            </div>
          ) : template == 14 ? (
            <div className="resume-preview">
              <div className="resume-preview-content">
                {/* <img src={template1} alt="as" className="w-100 h-100" /> */}

                <Template20
                  userData={userData}
                  sections={sections}
                  color={color}
                  setColor={setColor}
                  forDownload={resumeRef}
                  lineHeight={lineHeight}
                  font={font}
                  setFont={setFont}
                  fontSize={fontSize}
                  setFontSize={setFontSize}
                  setLineHeight={setLineHeight}
                ></Template20>
              </div>
            </div>
          ) : template == 15 ? (
            <div className="resume-preview">
              <div className="resume-preview-content">
                {/* <img src={template1} alt="as" className="w-100 h-100" /> */}

                <Template21
                  userData={userData}
                  sections={sections}
                  color={color}
                  setColor={setColor}
                  forDownload={resumeRef}
                  lineHeight={lineHeight}
                  font={font}
                  setFont={setFont}
                  fontSize={fontSize}
                  setFontSize={setFontSize}
                  setLineHeight={setLineHeight}
                ></Template21>
              </div>
            </div>
          ) : template == 16 ? (
            <div className="resume-preview">
              <div className="resume-preview-content">
                {/* <img src={template1} alt="as" className="w-100 h-100" /> */}

                <Template22
                  userData={userData}
                  sections={sections}
                  color={color}
                  setColor={setColor}
                  forDownload={resumeRef}
                  lineHeight={lineHeight}
                  font={font}
                  setFont={setFont}
                  fontSize={fontSize}
                  setFontSize={setFontSize}
                  setLineHeight={setLineHeight}
                ></Template22>
              </div>
            </div>
          ) : template == 17 ? (
            <div className="resume-preview">
              <div className="resume-preview-content">
                {/* <img src={template1} alt="as" className="w-100 h-100" /> */}

                <Template23
                  userData={userData}
                  sections={sections}
                  color={color}
                  setColor={setColor}
                  forDownload={resumeRef}
                  lineHeight={lineHeight}
                  font={font}
                  setFont={setFont}
                  fontSize={fontSize}
                  setFontSize={setFontSize}
                  setLineHeight={setLineHeight}
                ></Template23>
              </div>
            </div>
          ) : template == 18 ? (
            <div className="resume-preview">
              <div className="resume-preview-content">
                {/* <img src={template1} alt="as" className="w-100 h-100" /> */}

                <Template24
                  userData={userData}
                  sections={sections}
                  color={color}
                  setColor={setColor}
                  forDownload={resumeRef}
                  lineHeight={lineHeight}
                  font={font}
                  setFont={setFont}
                  fontSize={fontSize}
                  setFontSize={setFontSize}
                  setLineHeight={setLineHeight}
                ></Template24>
              </div>
            </div>
          ) : template == 19 ? (
            <div className="resume-preview">
              <div className="resume-preview-content">
                {/* <img src={template1} alt="as" className="w-100 h-100" /> */}

                <Template25
                  userData={userData}
                  sections={sections}
                  color={color}
                  setColor={setColor}
                  forDownload={resumeRef}
                  lineHeight={lineHeight}
                  font={font}
                  setFont={setFont}
                  fontSize={fontSize}
                  setFontSize={setFontSize}
                  setLineHeight={setLineHeight}
                ></Template25>
              </div>
            </div>
          ) : template == 20 ? (
            <div className="resume-preview">
              <div className="resume-preview-content">
                {/* <img src={template1} alt="as" className="w-100 h-100" /> */}

                <Template26
                  userData={userData}
                  sections={sections}
                  color={color}
                  setColor={setColor}
                  forDownload={resumeRef}
                  lineHeight={lineHeight}
                  font={font}
                  setFont={setFont}
                  fontSize={fontSize}
                  setFontSize={setFontSize}
                  setLineHeight={setLineHeight}
                ></Template26>
              </div>
            </div>
          ) : template == 21 ? (
            <div className="resume-preview">
              <div className="resume-preview-content">
                {/* <img src={template1} alt="as" className="w-100 h-100" /> */}

                <Template27
                  userData={userData}
                  sections={sections}
                  color={color}
                  setColor={setColor}
                  forDownload={resumeRef}
                  lineHeight={lineHeight}
                  font={font}
                  setFont={setFont}
                  fontSize={fontSize}
                  setFontSize={setFontSize}
                  setLineHeight={setLineHeight}
                ></Template27>
              </div>
            </div>
          ) : template == 22 ? (
            <div className="resume-preview">
              <div className="resume-preview-content">
                {/* <img src={template1} alt="as" className="w-100 h-100" /> */}

                <Template28
                  userData={userData}
                  sections={sections}
                  color={color}
                  setColor={setColor}
                  forDownload={resumeRef}
                  lineHeight={lineHeight}
                  font={font}
                  setFont={setFont}
                  fontSize={fontSize}
                  setFontSize={setFontSize}
                  setLineHeight={setLineHeight}
                ></Template28>
              </div>
            </div>
          ) : template == 23 ? (
            <div className="resume-preview">
              <div className="resume-preview-content">
                {/* <img src={template1} alt="as" className="w-100 h-100" /> */}

                <Template29
                  userData={userData}
                  sections={sections}
                  color={color}
                  setColor={setColor}
                  forDownload={resumeRef}
                  lineHeight={lineHeight}
                  font={font}
                  setFont={setFont}
                  fontSize={fontSize}
                  setFontSize={setFontSize}
                  setLineHeight={setLineHeight}
                ></Template29>
              </div>
            </div>
          ) : template == 24 ? (
            <div className="resume-preview">
              <div className="resume-preview-content">
                {/* <img src={template1} alt="as" className="w-100 h-100" /> */}

                <Template30
                  userData={userData}
                  sections={sections}
                  color={color}
                  setColor={setColor}
                  forDownload={resumeRef}
                  lineHeight={lineHeight}
                  font={font}
                  setFont={setFont}
                  fontSize={fontSize}
                  setFontSize={setFontSize}
                  setLineHeight={setLineHeight}
                ></Template30>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {/* <div ref={newRef} className="ResumeTemplate" style={{ width: "595px", height: "842px", backgroundColor: "cadetblue", position: "relative", display: "flex", justifyContent: "center", alignItems: "center" }}>

        <div style={{ width: "555px", height: "802px", backgroundColor: "white", zIndex: "1", color: "black", padding: "0 12px" }}>
          <h1 style={{ fontSize: "22px", padding: "0", display: "inline-block" }}>Sheraz Alam</h1>

        </div>



      </div> */}
      <Modal show={loading} centered>
        <Modal.Header>
          <Modal.Title>Processing Your Resume</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div class="lds-ringDashboard">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={linkedinModelShow} onHide={handleLinkedinModelShow} centered>
        <Modal.Header>
          <Modal.Title>Import Data from Linkedin</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div class="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          ) : (
            <div
              style={{
                padding: "30px 10px",
                display: "flex",
                flexDirection: "column",
                gap: "30px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  width: "100%",
                }}
              >
                linkedin.com/in/
                <input
                  type="text"
                  placeholder="linkedin Username"
                  style={{
                    border: "none",
                    borderRadius: "5px",
                    padding: "2px 10px",
                    width: "100%",
                  }}
                  value={linkedinUrl}
                  onChange={(e) => setLinkedinUrl(e.target.value)}
                ></input>
              </div>
              <button
                style={{
                  backgroundColor: "#562863",
                  padding: "5px",
                  borderRadius: "5px",
                }}
                onClick={handleGenerateFromLinkedin}
              >
                Import
              </button>
            </div>
          )}
        </Modal.Body>
      </Modal>
      <Modal show={downloadLimitExceed} onHide={handleDownloadLimitExceedModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Free Resources Utilized!</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          <div style={{ padding: "30px 10px", display: "flex", flexDirection: "column", gap: "30px", alignItems: "center" }}>
            <div style={{ display: "flex", alignItems: "flex-start", gap: "5px", width: "100%", flexDirection: "column" }}>
              <h5>Oh no, have exceeded the number of free Allowed Resources. Please upgrade your account to Pro Plan to continue enjoying Resumifi. </h5>
              <h6 style={{ marginTop: "20px" }}>Benefits of Upgrading:</h6>
              <ul>
                <li>
                  Proofreading From Experts.
                </li>
                <li>
                  35+ professional resumes templates.
                </li>
                <li>
                  AI resume checker.
                </li>
                <li>
                  AI Writer.
                </li>
                <li>
                  Priority emails support.
                </li>
                <li>
                  And Much More.
                </li>
              </ul>
            </div>
            <button style={{ backgroundColor: "#562863", padding: "5px 7px", borderRadius: "5px" }} onClick={() => navigate("/money")} >Upgrade</button>
          </div>


        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Dashboard;
