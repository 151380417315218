import React from "react";
import "../../style/main.scss";
import Button from "../shared/button";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the Quill styles
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
const Education = ({ setUserData, userData, isFetched, sections, setSections }) => {
  const [editorHtml, setEditorHtml] = useState("");
  const [first, setfirst] = useState(false);
  const [totalExperience, setTotalExperience] = useState([]);
  const [isschoolName, setIsschoolName] = useState(false);
  const [newArrayMade, setNewArrayMade] = useState(false);
  const [already, setAlready] = useState(false);
  const [currentActiveIndex, setCurrentActiveIndex] = useState(null);
  useEffect(() => {
    // console.log("Inside desired useEffect")
    if (userData?.education) {
      userData?.education?.map((item, i) => {
        setTotalExperience((prev) => [...prev, 1]);
      });
    }
  }, [isFetched]);
  const handleChange = (html) => {
    setEditorHtml(html);
  };
  const handleInfoChange = (e, index = currentActiveIndex) => {
    // console.log(e.target.name);
    // console.log(e.target.value);
    // console.log(index);
    if (index == totalExperience.length) {
      setNewArrayMade(true);
    }

    if (e.target.name == "schoolName" && e.target.value == "") {
      setIsschoolName(false);
    }
    if (e.target.name == "schoolName" && e.target.value != "") {
      setIsschoolName(true);
    }


    // setUserData((prev) => ({
    //   ...prev,
    //   education: { ...prev?.education, [e.target.name]: e.target.value },
    // }));

    let temp = userData.education == undefined ? [] : userData.education;
    temp[index] = { ...temp[index], [e.target.name]: e.target.value };
    setUserData((prev) => ({ ...prev, education: temp }));
  };
  const handleQuilChange = (value, index = currentActiveIndex) => {
    // console.log(value);
    // console.log(index);
    if (index == totalExperience.length) {
      setNewArrayMade(true);
    }

    // setUserData((prev) => ({
    //   ...prev,
    //   education: { ...prev?.education, description: value },
    // }));

    let temp = userData.education == undefined ? [] : userData.education;
    temp[index] = { ...temp[index], description: value };
    setUserData((prev) => ({ ...prev, education: temp }));
  };

  const handleExp = async (index) => {
    setfirst(!first);
    // console.log("here", index);

    if (newArrayMade) {
      if (userData?.education) {
        let a = userData.education;
        a.pop();
        setUserData((prev) => ({ ...prev, education: a }));
        setNewArrayMade(false);
      }
    }
    setCurrentActiveIndex(index);
  };

  const handleAddMore = async () => {
    if (userData.education ? userData.education[currentActiveIndex] : false) {
      if (
        userData.education[currentActiveIndex].schoolName != undefined &&
        userData.education[currentActiveIndex].schoolName != ""
      ) {
        setTotalExperience((prev) => [...prev, 1]);
        setIsschoolName(false);
        setfirst(!first);
        setNewArrayMade(false);
      } else {
        toast("Please Enter School Name");
      }
    }
    else {
      toast("Please fill some data first")
    }

  };
  const handleDelete = () => {
    if (userData.education ? userData.education[currentActiveIndex] : false) {

      if (!newArrayMade) {
        let temp = userData.education;
        temp.splice(currentActiveIndex, 1);
        setUserData((prev) => ({ ...prev, education: temp }));
        setIsschoolName(false);
        setfirst(!first);
        setNewArrayMade(false);
        if (currentActiveIndex != totalExperience.length) {
          let t = totalExperience;
          t.pop();
          setTotalExperience(t);
        }
      } else {
        handleExp(null);
      }
    }
    else {
      // toast("Please fill some data first")
      handleExp(null);
    }

  };

  // Only to check value of totalExperience during development.
  useEffect(() => {
    // console.log(totalExperience);
    // console.log("Current Active Index", currentActiveIndex);
    // console.log("userData", userData);
  }, [totalExperience, currentActiveIndex]);

  return (
    <div>
      {" "}
      <div className="bahr v-center h-center ">
        <div className="strength w-100">
          <div className="top ">
            <p className="text-purple">Education</p>
            <div style={{ width: "15px", height: "15px", display: "flex", justifyContent: "center", alignItems: "flex-start", border: "1px solid black", backgroundColor: sections.education ? "black" : "transparent" }} onClick={() => sections.education ? setSections(prev => ({ ...prev, education: false })) : setSections(prev => ({ ...prev, education: true }))}>
              <div style={{ width: "7px", height: "10px", borderRight: "1px solid white", borderBottom: "1px solid white", transform: "rotate(45deg)", visibility: sections.education ? "visible" : "hidden" }}></div>
            </div>
          </div>

          {first ? (
            <div>
              {/* New items */}
              <div className="new-item gap-3 d-flex">
                <div className="input-box  w-100">
                  <label htmlFor="" className="w-50">
                    <p className="p4">School Name</p>
                    <input
                      name="schoolName"
                      onChange={handleInfoChange}
                      type="text"
                      value={
                        userData?.education
                          ? userData?.education[currentActiveIndex]?.schoolName
                            ? userData?.education[currentActiveIndex]
                              ?.schoolName
                            : ""
                          : ""
                      }
                    />
                  </label>
                  <label htmlFor="" className="w-50">
                    <p className="p4"> Degree Name</p>
                    <input
                      name="degreeName"
                      onChange={handleInfoChange}
                      type="text"
                      value={
                        userData?.education
                          ? userData?.education[currentActiveIndex]?.degreeName
                            ? userData?.education[currentActiveIndex]
                              ?.degreeName
                            : ""
                          : ""
                      }
                    />
                  </label>
                </div>
              </div>
              <div className="new-item mt-3 gap-3 d-flex">
                <div className="input-box w-100">
                  <label htmlFor="" className="w-50">
                    <p className="p4"> City</p>
                    <input
                      name="cityName"
                      onChange={handleInfoChange}
                      type="text"
                      value={
                        userData?.education
                          ? userData?.education[currentActiveIndex]?.cityName
                            ? userData?.education[currentActiveIndex]?.cityName
                            : ""
                          : ""
                      }
                    />
                  </label>
                  <label htmlFor="" className="w-50">
                    <p className="p4"> Country</p>
                    <input
                      name="country"
                      onChange={handleInfoChange}
                      type="text"
                      value={
                        userData?.education
                          ? userData?.education[currentActiveIndex]?.country
                            ? userData?.education[currentActiveIndex]?.country
                            : ""
                          : ""
                      }
                    />
                  </label>
                </div>
              </div>
              <div className="para-z mt-3">
                <p className="p4">Time Period</p>
              </div>
              <div className="new-item time-period mt-2 gap-3 d-flex">
                <div className="input-box w-100">
                  <label htmlFor="" className="w-25">
                    <input
                      name="startMonth"
                      onChange={handleInfoChange}
                      type="text"
                      placeholder="Month"
                      value={
                        userData?.education
                          ? userData?.education[currentActiveIndex]?.startMonth
                            ? userData?.education[currentActiveIndex]
                              ?.startMonth
                            : ""
                          : ""
                      }
                    />
                  </label>
                  <label htmlFor="" className="w-25">
                    <input
                      name="startYear"
                      onChange={handleInfoChange}
                      type="text"
                      placeholder="Year"
                      value={
                        userData?.education
                          ? userData?.education[currentActiveIndex]?.startYear
                            ? userData?.education[currentActiveIndex]?.startYear
                            : ""
                          : ""
                      }
                    />
                  </label>
                  <label htmlFor="" className="w-25">
                    <input
                      name="endMonth"
                      onChange={handleInfoChange}
                      type="text"
                      placeholder="Month"
                      value={
                        userData?.education
                          ? userData?.education[currentActiveIndex]?.endMonth
                            ? userData?.education[currentActiveIndex]?.endMonth
                            : ""
                          : ""
                      }
                    />
                  </label>
                  <label htmlFor="" className="w-25">
                    <input
                      name="endYear"
                      onChange={handleInfoChange}
                      type="text"
                      placeholder="Year"
                      value={
                        userData?.education
                          ? userData?.education[currentActiveIndex]?.endYear
                            ? userData?.education[currentActiveIndex]?.endYear
                            : ""
                          : ""
                      }
                    />
                  </label>
                </div>
              </div>
              <div className="currently v-center h-center mt-2">
                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    border: "1px solid black",
                    marginRight: "5px",
                    backgroundColor: `${userData?.education
                      ? userData?.education[currentActiveIndex]
                        ?.currentlyStudyHere
                        ? "black"
                        : "transparent"
                      : "transparent"
                      }`,
                  }}
                  onClick={() => {
                    let temp =
                      userData.education == undefined ? [] : userData.education;
                    temp[currentActiveIndex] = {
                      ...temp[currentActiveIndex],
                      currentlyStudyHere: temp[currentActiveIndex]
                        ? !temp[currentActiveIndex]?.currentlyStudyHere
                        : true,
                    };
                    setUserData((prev) => ({ ...prev, education: temp }));
                  }}
                ></div>
                <p className="p4">I am currently studying here</p>
              </div>

              {/* Multiple Inputs */}
              <div className="new-item-1  w-100 gap-4 ">
                {/* Description */}
                <div className="input-box d-flex flex-column gap-0 w-100 mt-14">
                  <div className="input-box w-100 mt-14">
                    <label htmlFor="" className="w-100">
                      <p className="p4">Description</p>
                    </label>
                  </div>
                  <div className="w-100 editor-wrapper position-relative">
                    <ReactQuill
                      value={
                        userData?.education
                          ? userData?.education[currentActiveIndex]?.description
                            ? userData?.education[currentActiveIndex]
                              ?.description
                            : ""
                          : ""
                      }
                      onChange={(value) => handleQuilChange(value)}
                      modules={Education.modules}
                      formats={Education.formats}
                      height="300px"
                      placeholder={`Add information about your Degree.`}
                    />
                    {/* <div className="writer-btn pointer">
                                            <Button
                                                text="AI Writer"
                                                width="137px"
                                                height="39px"
                                                className="btn-prime-1 btn-primary text-white rounded  "
                                                onClick={() => // console.log("sa")}
                                            />

                                        </div> */}
                  </div>
                </div>
              </div>
              {/* AI Writer button */}
              <Button
                text="Save"
                height="39px"
                className="bg-scroll rounded bg-darkPurple w-100 mt-4"
                onClick={handleAddMore}
              />
              <Button
                text="Close"
                height="39px"
                className="bg-scroll rounded bg-darkPurple w-100 mt-2"
                onClick={() => handleExp(null)}
              />
              <Button
                text="Delete"
                height="39px"
                className="bg-scroll rounded bg-darkPurple w-100 mt-2"
                onClick={() => handleDelete(null)}
              />
            </div>
          ) : (
            <div className="input-box mt-5 ">
              <label
                htmlFor=""
                className="w-100 h-center v-center"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                {totalExperience.map((item, index) => {
                  return userData.education &&
                    (userData.education[index]?.schoolName != undefined || userData.education[index]?.degreeName != undefined) &&
                    (userData.education[index]?.schoolName != "" || userData.education[index]?.degreeName != "") ? (
                    <Button
                      key={index}
                      text={`${userData.education[index]?.schoolName ? userData.education[index]?.schoolName : userData.education[index]?.degreeName}`}
                      height="39px"
                      className="bg-scroll rounded bg-darkPurple w-100"
                      onClick={() => {
                        // console.log(index);
                        handleExp(index);
                      }}
                    />
                  ) : null;
                })}
              </label>
            </div>
          )}

          {/* Scroll Button */}
          {!first ? (
            <div className="input-box mt-5 ">
              <label htmlFor="" className="w-100 h-center v-center">
                <Button
                  text="Add more"
                  height="39px"
                  className="bg-scroll rounded bg-darkPurpleDotted w-100"
                  onClick={() => handleExp(totalExperience.length)}
                />
              </label>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
// Quill modules and formats configurations
Education.modules = {
  toolbar: [
    // [{ header: "1" }, { header: "2" }, { font: [] }],
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
    // ["link", "image"],
    // ["clean"],
  ],
};

Education.formats = [
  "header",
  "font",
  "list",
  "bullet",
  "bold",
  "italic",
  "underline",
  "link",
  "image",
];
export default Education;
